<template>
  <v-card>
    <v-card-title>
      <v-icon left color="primary">mdi-account-lock-outline</v-icon>
      Usage, Limits & Plans
      <v-chip
        v-if="isPro"
        color="primary"
        small
        class="ml-10"
      >
        <v-icon small left>mdi-star-three-points-outline</v-icon>
        PRO
      </v-chip>
      <v-chip
        v-if="isTeam"
        color="success"
        small
        class="ml-10"
      >
        <v-icon small left>mdi-collage</v-icon>
        TEAM
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="activeTab">
        <v-tab>
          <v-icon left>mdi-account-outline</v-icon>
          Standard
        </v-tab>
        <v-tab>
          <v-icon left>mdi-star-three-points-outline</v-icon>
          Pro
        </v-tab>
        <v-tab>
          <v-icon left>mdi-collage</v-icon>
          Pro Team
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <template>
                <v-progress-linear
                  v-for="(limit, key) in regularLimitItems"
                  :key="key"
                  :value="calculatePercentage(limit.used, limit.max)"
                  :color="getLimitColor(limit.used, limit.max)"
                  height="25"
                  rounded
                  class="mb-3"
                >
                  <template v-slot:default="{ value }">
                    <strong>
                      {{ formatLimitName(key) }}: {{ limit.used }} / {{ limit.max }} ({{ Math.round(value) }}%)
                      <v-tooltip bottom v-if="doesNotReset(key, false)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            color="grey"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-infinity
                          </v-icon>
                        </template>
                        <span>This limit does not reset</span>
                      </v-tooltip>
                    </strong>
                  </template>
                </v-progress-linear>
                <v-alert type="info" outlined class="mb-3">
                  Time until reset: {{ limits.regular.time_left_for_reset }}
                </v-alert>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-alert v-if="!isPro" type="info" outlined class="modern-alert">
                <div class="alert-content">
                  <div class="alert-message">
                    Unlock significant higher limits with our Pro Plan (100X+). Upload up to 10 files at once.
                  </div>
                  <div class="btn-container">
                    <v-btn
                      color="primary"
                      outlined
                      rounded
                      elevation="1"
                      @click="upgradePlan('pro')"
                      :loading="subscriptionLoading"
                      class="login-btn"
                    >
                      <v-icon left>mdi-star-three-points-outline</v-icon>
                      <span>Upgrade to Pro</span>
                      <span class="pricing-info ml-2">$20/month</span>
                    </v-btn>
                  </div>
                </div>
              </v-alert>
              <template>
                <v-progress-linear
                  v-for="(limit, key) in proLimitItems"
                  :key="key"
                  :value="calculatePercentage(limit.used, limit.max)"
                  :color="getLimitColor(limit.used, limit.max)"
                  height="25"
                  rounded
                  class="mb-3"
                >
                  <template v-slot:default="{ value }">
                    <strong>
                      {{ formatLimitName(key) }}: {{ limit.used }} / {{ limit.max }} ({{ Math.round(value) }}%)
                      <v-tooltip bottom v-if="doesNotReset(key, true)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            color="grey"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-infinity
                          </v-icon>
                        </template>
                        <span>This limit does not reset</span>
                      </v-tooltip>
                    </strong>
                  </template>
                </v-progress-linear>
                <v-alert type="info" outlined>
                  Time until reset: {{ limits.pro.time_left_for_reset }}
                </v-alert>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
            <v-alert
                v-if="!isTeam"
                type="info"
                outlined
              >
                Our Pro Team plan offers Max Limits and Collaboration Features (+ Upload up to 50 files at once).
                <ul>
                  <li>Manage team members</li>
                  <li>Role-Based Access Control (RBAC)</li>
                  <li>Share projects</li>
                  <li>Create up to 5 universes for comprehensive knowledge management</li>
                  <li>>2X the limits of Pro plan</li>
                  <li>Dedicated namespaces</li>
                  <li>7-day data retention for uploads</li>
                  <li>StoryCurve logging for deep entity analysis</li>
                  <li>Integration with other productivity tools</li>
                  <li>Run your own LLM models</li>
                  <li>SSO support</li>
                </ul>
                <v-btn
                v-if="!isTeam && isPro"
                class="mt-3 pro-team-upgrade-btn"
                color="primary"
                @click="upgradePlan('team-pro')"
                :loading="subscriptionLoading"
              >
                <v-icon left>mdi-collage</v-icon>
                <span>Upgrade to Pro Team</span>
                <span class="pricing-info ml-2">$40/month/seat</span>
              </v-btn>
              </v-alert>
              <template>
                <v-progress-linear
                  v-for="(limit, key) in proTeamLimitItems"
                  :key="key"
                  :value="calculatePercentage(limit.used, limit.max)"
                  :color="getLimitColor(limit.used, limit.max)"
                  height="25"
                  rounded
                  class="mb-3"
                >
                  <template v-slot:default="{ value }">
                    <strong>
                      {{ formatLimitName(key) }}: {{ limit.used }} / {{ limit.max }} ({{ Math.round(value) }}%)
                      <v-tooltip bottom v-if="doesNotReset(key, 'team')">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            color="grey"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-infinity
                          </v-icon>
                        </template>
                        <span>This limit does not reset</span>
                      </v-tooltip>
                    </strong>
                  </template>
                </v-progress-linear>
                <v-alert type="info" outlined>
                  Time until reset: {{ limits.pro_team.time_left_for_reset }}
                </v-alert>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: 'UserLimitsDisplay',
  props: {
    isPro: {
      type: Boolean,
      default: false
    },
    isTeam: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      activeTab: null,
    };
  },
  computed: {
    ...mapState("auth", ["currentUser", "loading", "oauthLoading"]),
    ...mapState("websocket", ["subscriptionLoading"]),
    ...mapGetters('user_limits', ['getUserLimits']),
    limits() {
      return this.getUserLimits || { regular: {}, pro: {} };
    },
    regularLimitItems() {
      const { time_left_for_reset, ...items } = this.limits.regular;
      return items;
    },
    proLimitItems() {
      const { time_left_for_reset, ...items } = this.limits.pro;
      return items;
    },
    proTeamLimitItems() {
      const { time_left_for_reset, ...items } = this.limits.pro_team;
      return items;
    },

  },
  methods: {
    ...mapActions('auth', ['oauthLogin']),
    ...mapActions('websocket', ['showTempMessageFn', 'upgradePlan']),
    navigateToEmailLogin() {
      // Navigate to the login page
      this.$router.push({ name: 'BasicLogin' });
    },
    calculatePercentage(used, max) {
      if (max === 0) return 0; // Avoid division by zero.
      return (used / max) * 100;
    },
    getLimitColor(used, max) {
      const percentage = this.calculatePercentage(used, max);
      if (percentage < 50) return 'green';
      if (percentage < 80) return 'orange';
      return 'red';
    },
    formatLimitName(key) {
      return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    doesNotReset(key, isPro) {
      const regularNonResetKeys = ['planets', 'galaxies', 'universes', 'entities', 'rag_tokens', 'rag_tokens_using_top_LLM'];
      const proNonResetKeys = ['universes', 'entities', 'planets', 'galaxies'];

      if (isPro) {
        return proNonResetKeys.includes(key);
      } else {
        return regularNonResetKeys.includes(key);
      }
    },
  },
};
</script>

<style scoped>
.pro-upgrade-btn {
  position: relative;
  overflow: hidden;
}

.pricing-info {
  font-size: 0.8em;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.pro-upgrade-btn:hover .pricing-info {
  opacity: 1;
}

pro-team-upgrade-btn {
  position: relative;
  overflow: hidden;
}

.pricing-info {
  font-size: 0.8em;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.pro-team-upgrade-btn:hover .pricing-info {
  opacity: 1;
}

.modern-alert {
  border-radius: 12px !important;
}

.alert-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.alert-message {
  font-size: 1.1rem;
  font-weight: 500;
}

.btn-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 4px;
}

.login-btn {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: 500;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .btn-container {
    flex-direction: column;
  }

  .login-btn {
    max-width: 100%;
  }
}

</style>
