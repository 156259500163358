<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div id="chat" :class="{ 'with-feed-open': jobUpdatesFeedVisible }">
     <JobUpdatesFeed
      :visible.sync="jobUpdatesFeedVisible"
      ref="jobUpdatesFeed"
      :user="user"
      :project-id="selectedGalaxy"
      @update-job-count="updateJobCount"
      @processing-jobs-change="updateProcessingJobsStatus"
      @processing-job-count-change="updateProcessingJobCount"
      @mini-change="handleMiniChange"
      @handle-submissions-icon-click="handleSubmissionsIconClick"
    />
    <RagTokensContainer
        :ragTokens="ragTokenCount"
        :ragType="ragType"
        :isFeedMini="isFeedMini"
        :showSparks="showSparks"
        :selected-text.sync="selectedText"
        :latest-user-input="latestUserInput"
        :showRagContent.sync="showRagContent"
        :socket="socket"
        @update-chat-input="handleChatInputUpdate"
      />
    <!-- Other Components -->
    <div class="temp-message-container">
      <transition name="slide-fade">
        <div v-if="showTempMessage" :class="['temp-message', `temp-message--${tempMessage.type}`]">
          <v-icon left :color="iconColor">{{ iconName }}</v-icon>
          <span class="temp-message-content">{{ tempMessage.text }}</span>
          <v-btn icon small @click="closeTempMessage" class="close-btn" :color="iconColor">
            <v-icon small right>mdi-close</v-icon>
          </v-btn>
        </div>
      </transition>
    </div>

    <!-- Chat Container -->
    <div class="chat-container">
      <v-progress-linear
        :active="isLoadingMoreMessages"
        :indeterminate="true"
        absolute
        top
        height="2"
        color="primary"
      ></v-progress-linear>
      <div class="chat-header">
        <div class="header-left">
          <div class="left-controls-navigator">
            <!-- User Account Section -->
            <div class="control-group">
              <v-menu offset-y content-class="modern-menu">
                <template v-slot:activator="{ on, attrs }">
                  <div class="account-control">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      class="control-btn"
                    >
                      <v-icon
                          left
                          v-if="!currentUser.loggedIn"
                        >
                          mdi-incognito
                        </v-icon>
                      <v-icon
                        left
                        v-else
                      >
                        mdi-incognito-off
                      </v-icon>
                    </v-btn>
                    <div class="status-badges" v-if="user.is_pro || user.is_pro_team">
                      <span class="status-badge" v-if="user.is_pro && !user.is_pro_team">
                        <v-icon small color="success">mdi-star-three-points-outline</v-icon>
                      </span>
                      <span class="status-badge" v-if="user.is_pro_team">
                        <v-icon small color="success">mdi-account-group</v-icon>
                      </span>
                    </div>
                  </div>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6">
                        <v-icon
                          left
                          color="success"
                          v-if="!currentUser.loggedIn"
                        >
                          mdi-incognito
                        </v-icon>
                        <v-icon
                          left
                          color="success"
                          v-else
                        >
                          mdi-incognito-off
                        </v-icon>
                        {{ user.display_name || 'Anonymous' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Universes: {{ user.organizations.length }} | Galaxies: {{ user.projects.length }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.is_pro">
                    <v-list-item-content>
                      <v-list-item-title>
                      Subscription:
                      <v-chip
                        small
                        :color="getSubscriptionStatusColor(user.subscription_status)"
                        text-color="white"
                      >
                        {{ getSubscriptionStatusText(user.subscription_status) }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="user.subscription_status === 'canceled' && user.subscription_end_date">
                      Access until: {{ formatDate(user.subscription_end_date) }}
                    </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.is_pro_team && user.role">
                    <v-list-item-content>
                      <v-list-item-title>
                        Role: {{ user.role }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="user.is_pro_team" @click="navigateToMembers">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-account-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Manage Team Members</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="toggleDarkTheme">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-theme-light-dark</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Switch to {{ darkMode ? 'Light Mode' : 'Dark Mode' }}</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="!user.is_pro"
                    @click="upgradePlan('pro')"
                    :disabled="subscriptionLoading"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="!subscriptionLoading" color="primary">mdi-star-three-points-outline</v-icon>
                      <v-progress-circular
                        v-else
                        indeterminate
                        size="24"
                        width="2"
                        color="error"
                      ></v-progress-circular>
                    </v-list-item-icon>
                    <v-list-item-title class="d-flex align-center">
                      {{ subscriptionLoading ? 'Upgrading...' : 'Try Pro' }}
                      <v-chip
                        v-if="!user.is_pro && !subscriptionLoading"
                        x-small
                        color="primary"
                        class="ml-2 pro-chip"
                      >
                        PRO
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!currentUser.loggedIn">
                    <v-list-item-icon>
                      <v-icon color="success">mdi-incognito-off</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="d-flex align-center">
                        Login (Optional)
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-btn
                          color="primary"
                          text
                          @click="oauthLogin"
                          class="px-2 mt-2"
                          :loading="oauthLoading"
                        >
                          <v-icon left>mdi-google</v-icon>
                          Google
                        </v-btn>
                        <v-btn
                          v-if="!currentUser.loggedIn"
                          text
                          color="primary"
                          class="mt-2 ml-5"
                          @click="navigateToEmailLogin"
                          :loading="loading"
                        >
                          <v-icon left>mdi-email-outline</v-icon>
                          Email
                        </v-btn>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="['active', 'team_pro'].includes(user.subscription_status)" @click="showCancelConfirmation">
                    <v-list-item-icon>
                      <v-icon color="tips">mdi-steering-off</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Cancel Subscription</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="user.subscription_status === 'canceled'"
                    :disabled="subscriptionLoading"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="!subscriptionLoading" class="success black--text">mdi-star-three-points-outline</v-icon>
                      <v-progress-circular
                        v-else
                        indeterminate
                        size="24"
                        width="2"
                        color="error"
                      ></v-progress-circular>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ subscriptionLoading ? 'Reactivating...' : 'Reactivate Subscription' }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="!subscriptionLoading">
                        Choose a plan to reactivate:
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn small color="primary" class="mt-2" value="pro" :disabled="subscriptionLoading" @click="upgradePlan('pro')">
                          Pro
                        </v-btn>
                        <v-btn small color="primary" class="mt-2" value="team_pro" :disabled="subscriptionLoading" @click="upgradePlan('team_pro')">
                          Pro Team
                        </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="currentUser.loggedIn" @click="logout">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Logout</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <!-- Processing Status -->
              <v-tooltip bottom content-class="modern-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <div class="status-indicator-jobs" v-bind="attrs" v-on="on">
                    <v-progress-circular
                      v-if="hasProcessingJobs"
                      color="error"
                      size="20"
                      width="2"
                      indeterminate
                    ></v-progress-circular>
                    <v-icon v-else color="grey">mdi-check-circle</v-icon>
                  </div>
                </template>
                <span>{{ jobsTooltipText }}</span>
              </v-tooltip>

              <!-- Cancellation Confirmation Dialog -->
              <v-dialog v-model="showCancelDialog" max-width="700">
                <v-card>
                  <v-card-title class="headline">Confirm Cancellation</v-card-title>
                  <v-card-text>
                    <p>Are you sure you want to cancel your {{ user.is_pro_team ? 'Pro Team' : 'Pro' }} subscription?</p>
                    <p>Please note the following:</p>
                    <ul>
                      <li>Your {{ user.is_pro_team ? 'Pro Team' : 'Pro' }} status will remain active until {{ formatDate(user.subscription_end_date) }}.</li>
                      <li>You will continue to have access to all {{ user.is_pro_team ? 'Pro Team' : 'Pro' }} features until this date.</li>
                      <li>You will not be charged again unless you reactivate your subscription.</li>
                      <template v-if="user.is_pro_team">
                        <li>All team members associated with this subscription will also lose access at the end of the billing period.</li>
                        <li>Current team size: {{ user.team_size }} member{{ user.team_size !== 1 ? 's' : '' }}</li>
                      </template>
                      <li>You can reactivate your subscription at any time before {{ formatDate(user.subscription_end_date) }} to maintain uninterrupted access.</li>
                    </ul>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="showCancelDialog = false">Keep Subscription</v-btn>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="confirmCancelSubscription"
                      :loading="cancelLoading"
                    >
                      Confirm Cancellation
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <!-- Action Controls -->
            <div class="control-group">

              <v-tooltip bottom content-class="modern-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="showCreateEditGalaxy"
                    class="control-btn"
                  >
                    <new-galaxy-icon />
                  </v-btn>
                </template>
                <span>New Chat for a Topic/Project</span>
              </v-tooltip>

              <combined-settings-dialog
                class="control-item"
                @open-knowledge-empowerment-settings="openSettingsDialog"
                @open-projects-dialog="openProjectsDialog"
                @open-file-upload-dialog="openFileUploadDialog"
                @open-image-upload-dialog="openImageUploadDialog"
                @open-youtube-submit-dialog="openYoutubeSubmitDialog"
                @handle-submissions-icon-click="handleSubmissionsIconClick"
                @open-objects-dialog="openObjectsDialog"
                @open-submit-text-dialog="openSubmitTextDialog"
                @handle-liked-messages-processing-icon-click="handleLikedMessagesProcessingIconClick"
                @handle-limits-icon-click="handleLimitsIconClick"
              />
            </div>
          </div>
        </div>

        <!-- Chat Header Center-->
        <template>
          <div class="header-center">
            <v-container fluid class="pa-0">
              <v-row no-gutters align="center" justify="center">
                <!-- Cosmic Hierarchy Selectors -->
                <v-col cols="auto" class="mr-2">
                  <div class="cosmic-navigator">
                    <!-- Breadcrumb Icons -->
                    <div class="navigation-breadcrumbs">
                      <div
                        class="nav-item"
                        @click="toggleSelector('universe')"
                        :class="{ 'active': expandedSelector === 'universe' }"
                      >
                        <v-icon small class="nav-icon">mdi-star-four-points-outline</v-icon>
                        <div class="nav-dot"></div>
                        <div class="nav-label">{{ selectedUniverse ? truncateText(currentUniverseName, 10) : 'Universe' }}</div>
                      </div>

                      <div class="nav-separator">
                        <v-icon>mdi-pan-right</v-icon>
                      </div>

                      <div
                        class="nav-item"
                        @click="toggleSelector('galaxy')"
                        :class="{ 'active': expandedSelector === 'galaxy' }"
                      >
                        <v-icon small class="nav-icon">mdi-star-three-points-outline</v-icon>
                        <div class="nav-dot"></div>
                        <div class="nav-label">{{ selectedGalaxy ? truncateText(currentGalaxyName, 10) : 'Galaxy' }}</div>
                      </div>

                      <div class="nav-separator">
                        <v-icon>mdi-pan-right</v-icon>
                      </div>

                      <div
                        class="nav-item"
                        @click="toggleSelector('planet')"
                        :class="{ 'active': expandedSelector === 'planet' }"
                      >
                        <v-icon small class="nav-icon">mdi-earth</v-icon>
                        <div class="nav-dot"></div>
                        <div class="nav-label">{{ selectedProject ? truncateText(currentProjectName, 10) : 'Planet' }}</div>
                      </div>
                    </div>

                    <!-- Selectors -->
                    <v-expand-transition>
                      <div v-if="isExpanded" class="selector-panel">
                        <!-- Universe Selector -->
                        <div class="selector-container" v-show="expandedSelector === 'universe'">
                          <v-select
                            v-model="selectedUniverse"
                            :items="universes"
                            item-text="name"
                            item-value="slug"
                            label="Select Universe"
                            dense
                            class="modern-select"
                            @change="onUniverseChange"
                            :menu-props="{ contentClass: 'modern-select-menu' }"
                          >
                            <template v-slot:prepend>
                              <v-icon color="success" class="selector-icon">mdi-star-four-points-outline</v-icon>
                            </template>
                            <template v-slot:append>
                              <v-btn
                                icon
                                small
                                @click.stop="showCreateEditUniverse"
                                class="create-btn"
                                v-tooltip="'Create New Universe'"
                              >
                                <v-icon small>mdi-plus-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-icon small left color="success">mdi-star-four-points-outline</v-icon>
                                  {{ item.name }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </div>

                        <!-- Galaxy Selector -->
                        <div class="selector-container" v-show="expandedSelector === 'galaxy'">
                          <v-select
                            v-model="selectedGalaxy"
                            :items="galaxies"
                            item-text="name"
                            item-value="id"
                            label="Select Galaxy"
                            dense
                            class="modern-select"
                            @change="onGalaxyChange"
                            :menu-props="{ contentClass: 'modern-select-menu' }"
                          >
                            <template v-slot:prepend>
                              <v-icon color="success" class="selector-icon">mdi-star-three-points-outline</v-icon>
                            </template>
                            <template v-slot:append>
                              <v-btn
                                icon
                                small
                                @click.stop="showCreateEditGalaxy"
                                class="create-btn"
                                v-tooltip="'Create New Galaxy'"
                              >
                                <v-icon small>mdi-plus-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-icon small left color="success">mdi-star-three-points-outline</v-icon>
                                  {{ item.name }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </div>

                        <!-- Planet Selector -->
                        <div class="selector-container" v-show="expandedSelector === 'planet'">
                          <v-select
                            v-model="selectedProject"
                            :items="projects"
                            item-text="name"
                            item-value="id"
                            label="Select Planet"
                            dense
                            class="modern-select"
                            @change="selectProject"
                            :menu-props="{ contentClass: 'modern-select-menu' }"
                          >
                            <template v-slot:prepend>
                              <v-icon color="success" class="selector-icon">mdi-earth</v-icon>
                            </template>
                            <template v-slot:append>
                              <v-btn
                                icon
                                small
                                @click.stop="openCreateProjectDialog"
                                class="create-btn"
                                v-tooltip="'Create New Planet'"
                              >
                                <v-icon small>mdi-plus-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-icon small left color="success">mdi-earth</v-icon>
                                  {{ item.name }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </v-expand-transition>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </template>
        <!-- Chat Header Right-->
        <div class="header-right">
          <div class="right-controls-navigator">
            <help-menu class="control-item" />
            <!-- IAO Status -->
            <v-tooltip bottom content-class="modern-tooltip" max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="iao-status"
                  v-bind="attrs"
                  v-on="on"
                  :class="{ 'disabled': !hasActiveObject }"
                >
                  <div class="status-content">
                    <v-icon class="status-icon">
                      {{ chipIcon }}
                    </v-icon>
                    <span class="status-text-iao">{{ chipText }}</span>
                  </div>
                  <div class="status-indicator-iao" v-if="hasActiveObject"></div>
                </div>
              </template>
              <div class="tooltip-content">
                <div class="tooltip-header">Interactive Active Object (IAO)</div>
                <div class="tooltip-description">
                  An Interactive Active Object is a specific item or concept that becomes the focus of the conversation. When enabled:
                  <ul>
                    <li>RAG context narrows focus on this object</li>
                    <li>Includes relevant information about child objects (if any)</li>
                    <li>Enhances conversation specificity and relevance</li>
                  </ul>
                  <em>This allows for more precise and context-aware interactions.</em>
                </div>
              </div>
            </v-tooltip>

            <!-- Control Buttons -->
            <div class="control-buttons">
              <v-tooltip bottom content-class="modern-tooltip" max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="handleToggleBAOPrepend"
                    v-bind="attrs"
                    v-on="on"
                    class="control-btn"
                    :class="{ 'active': shouldPrependBAO }"
                  >
                    <v-icon
                      :color="shouldPrependBAO ? 'success' : 'success'"
                      class="control-icon"
                    >
                    {{ shouldPrependBAO ? 'mdi-image-filter-center-focus' : 'mdi-focus-auto' }}
                  </v-icon>
                  </v-btn>
                </template>
                <div class="tooltip-content">
                  <div class="tooltip-header">
                    {{ shouldPrependBAO ? 'Disable' : 'Enable' }} Focus on Active Object
                  </div>
                  <div class="tooltip-description">
                    {{ shouldPrependBAO
                      ? 'Currently enabled: Context is focused on the selected object'
                      : 'Currently disabled: Context includes broader information'
                    }}
                  </div>
                </div>
              </v-tooltip>

              <v-tooltip bottom content-class="modern-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="handleResetBAO"
                    v-bind="attrs"
                    v-on="on"
                    class="control-btn"
                    :disabled="!activeObject"
                  >
                    <v-icon color="error">mdi-image-filter-center-focus-weak</v-icon>
                  </v-btn>
                </template>
                <span>Turn focus off</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>  <!-- Chat Header End-->
      <!-- Messages Contianer -->
      <div
        class="messages-container"
        :class="messagesContainerClasses"
        ref="messagesContainer"
      >
        <!-- Intro/Tuto -->
        <div v-if="messages.length === 0">
          <FeatureGrid @chat-input="handleFeatureInputAction" />
        </div>

        <!-- All Chat Messages -->
        <div class="messages" v-for="message in filteredMessages" :key="message.id">
          <!-- Bot Messages -->
          <div v-if="message.user === 'bot'" class="bot-message">
            <div class="message-content">
              <div v-if="message.typing" class="typing-indicator" ref="typingIndicator">
                <div class="dot-container">
                  <div class="dot"></div>
                </div>
                <span class="timer">T+{{timerSeconds}}s</span>
                <div class="status-message">{{ statusMessage }}</div>
              </div>
              <!-- Dynamic Component (Search Results) -->
              <div v-if="message.component">
                <component
                  :is="message.component"
                  v-bind="message.componentProps"
                />
              </div>
              <template>
                <div v-if="message.conceptMappingAndLateralThinkingHtml">
                  <template>
                    <div class="concept-mapping-container">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            class="concept-mapping-toggle"
                            @click="toggleConceptMapVisibility(message)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="#A9A9A9">
                              {{ message.conceptMapExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                            <template v-if="!message.conceptMapExpanded">
                              <v-icon small class="ml-1" color="#A9A9A9">mdi-map</v-icon>
                              <span class="concept-map-label ml-1">Concept Map</span>
                            </template>
                          </div>
                        </template>
                        <span>{{ message.conceptMapExpanded ? 'Hide Concept Map' : 'Show Concept Map' }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <div
                    v-if="message.conceptMapExpanded"
                    class="concept-mapping-container"
                  >
                    <ConceptMap
                      :key="'concept-map-' + message.id"
                      :data="message.conceptMappingAndLateralThinkingHtml"
                      :messageId="message.id"
                      :shouldAnimate="message.conceptMapExpanded"
                    />
                  </div>
                </div>
              </template>
              <div class="bot-answer" :class="{ 'fade-in': !message.typing }">
                <p
                  v-if="!message.typing && message.text"
                  @mouseup="handleRagTextSelection"
                >
                <citation-renderer
                  :html="sanitizeHTML(message.text)"
                  @citation-click="handleCitationClick"
                />
                </p>
              </div>
              <div v-if="message.relationshipsHtml" class="relationships-container">
                <p class="relationships-title">
                  <strong>
                    <v-icon left>mdi-file-tree-outline</v-icon> Relationships
                  </strong>
                </p>
                <div v-for="(relationship, index) in message.parsedRelationships" :key="index" class="relationship-item">
                  <div class="relationship-entity">{{ relationship.entity1 }}</div>
                  <v-icon class="relationship-icon">mdi-arrow-right-bold</v-icon>
                  <div class="relationship-type">{{ relationship.type }}</div>
                  <v-icon class="relationship-icon">mdi-arrow-right-bold</v-icon>
                  <div class="relationship-entity">{{ relationship.entity2 }}</div>
                </div>
              </div>
              <div v-if="message.showActions">
                <template>
                  <v-tooltip bottom v-if="!isLatestMessage(message.id)">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="actions-toggle"
                        @click="toggleActionsVisibility(message)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="#A9A9A9" class="icon-with-bg">
                          {{ message.actionsExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </div>
                    </template>
                    <span>{{ message.actionsExpanded ? 'Hide Actions' : 'Show Actions' }}</span>
                  </v-tooltip>
                </template>
                <div
                  v-show="isLatestMessage(message.id) || message.actionsExpanded"
                  class="additional-actions"
                  :class="{ 'latest-message': isLatestMessage(message.id) }"
                >
                  <div class="button-container">
                    <div class="left-group">
                      <v-tooltip up max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            small
                            icon
                            v-if="message.finalAnswer"
                            @click="openSettingsDialog(message)"
                            class="mr-2 tune-answer"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small left color="primary">mdi-database-settings-outline</v-icon>
                            Tune Answer
                          </v-chip>
                        </template>
                        <div>
                          <strong>Tune: Customize Your Answer</strong>
                          <br>
                          Clicking this will allow you to adjust:
                          <ul style="padding-left: 20px; margin: 5px 0;">
                            <li>Language Model (LLM) settings</li>
                            <li>Retrieval-Augmented Generation (RAG) strategy</li>
                            <li>Interactive Active Objects (IAO)</li>
                            <li>Search settings</li>
                          </ul>
                          <em>These adjustments will be applied before re-submitting your question for an enhanced response.</em>
                        </div>
                      </v-tooltip>
                    </div>
                    <div class="right-group">
                      <div v-if="message.isButton" class="feedback-buttons">
                        <v-tooltip left max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="knowledge-button-wrapper">
                              <v-chip
                                small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                :class="buttonClasses(message)"
                                class="knowledge-chip"
                                :disabled="isButtonDisabled(message)"
                                @click="onButtonClicked('like', message)"
                                label
                              >
                                <v-icon small left color="success">
                                  {{ buttonIcon(message) }}
                                </v-icon>
                                {{ buttonChipText(message) }}

                                <!-- Pro badge -->
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      x-small
                                      class="ml-1 pro-badge"
                                      v-on="on"
                                      color="grey lighten-1"
                                    >
                                      mdi-clipboard-account-outline
                                    </v-icon>
                                  </template>
                                  <span>Pro Team Feature</span>
                                </v-tooltip>
                              </v-chip>
                              <div style="display: flex; align-items: center;" @click="!currentUser.loggedIn && $event.preventDefault()">
                                <router-link
                                  v-if="message.userFeedback === 'like' && !message.isProcessing"
                                  :to="{ name: 'entities' }"
                                  style="text-decoration: none; margin-left: 8px;"
                                  target="_blank"
                                  :event="currentUser.loggedIn ? 'click' : ''"
                                >
                                  <v-chip
                                    small
                                    outlined
                                    class="view-entities-chip"
                                    :color="currentUser.loggedIn ? 'success' : 'grey'"
                                    style="cursor: pointer;"
                                    :disabled="!currentUser.loggedIn"
                                  >
                                    <v-icon small left>
                                      mdi-database-search
                                    </v-icon>
                                    View
                                  </v-chip>
                                </router-link>

                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      small
                                      v-bind="attrs"
                                      v-on="on"
                                      color="grey"
                                      style="margin-left: 4px;"
                                    >
                                      mdi-information
                                    </v-icon>
                                  </template>
                                  <span>
                                    {{ currentUser.loggedIn ? 'View entities extracted from this response' : 'Please log in to view entities' }}
                                  </span>
                                </v-tooltip>
                              </div>
                            </div>
                          </template>
                          <div>
                            <strong>Save to Knowledge Base</strong>
                            <br>
                            This will:
                            <ul style="padding-left: 20px; margin: 5px 0;">
                              <li>Extract key information</li>
                              <li>Index for future responses</li>
                              <li>Enhance context understanding</li>
                            </ul>
                          </div>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <template>
                    <div v-if="(message.relatedQueries && message.relatedQueries.length) || (message.newRelatedQueries && message.newRelatedQueries.length)"
                        class="related-queries"
                        :data-message-id="message.id">
                      <div class="queries-header d-flex align-center justify-space-between">
                        <!-- Mode Switch -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div class="mode-selector" v-bind="attrs" v-on="on">
                              <v-btn
                                :class="['mode-btn', !message.showNewRelatedQueries && 'active']"
                                text
                                x-small
                                @click="() => { message.showNewRelatedQueries = false; toggleRelatedQueries(message); }"
                                :ripple="false"
                              >
                                <v-icon left x-small>mdi-earth</v-icon>
                                Broader
                              </v-btn>
                              <v-btn
                                :class="['mode-btn', message.showNewRelatedQueries && 'active']"
                                text
                                x-small
                                @click="() => { message.showNewRelatedQueries = true; toggleRelatedQueries(message); }"
                                :ripple="false"
                              >
                                <v-icon left x-small>mdi-bullseye-arrow</v-icon>
                                Specific
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ message.showNewRelatedQueries ? 'Specific questions related to this response' : 'Broader questions related to your query' }}</span>
                        </v-tooltip>

                        <!-- Expand/Collapse -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              icon
                              @click="toggleQuestionsVisibility(message)"
                              v-bind="attrs"
                              v-on="on"
                              class="expand-btn"
                            >
                              <v-icon small color="grey" class="icon-with-bg">
                                {{ message.questionsExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ message.questionsExpanded ? 'Hide Related Questions' : 'Show Related Questions' }}
                          </span>
                        </v-tooltip>
                      </div>

                      <!-- Questions Display -->
                      <v-expand-transition>
                        <div v-if="message.questionsExpanded" class="queries-content">
                          <!-- Empty States -->
                          <p v-if="!message.showNewRelatedQueries && (!message.relatedQueries || message.relatedQueries.length === 0)"
                            class="empty-state">
                            <v-icon small class="mr-2">mdi-information</v-icon>
                            Broader questions are not available for this query. Switch to see specific questions.
                          </p>
                          <p v-else-if="message.showNewRelatedQueries && (!message.newRelatedQueries || message.newRelatedQueries.length === 0)"
                            class="empty-state">
                            <v-icon small class="mr-2">mdi-information</v-icon>
                            Specific questions are not available for this query. Switch to see broader questions.
                          </p>
                          <!-- Questions Grid -->
                          <div v-else-if="!message.showNewRelatedQueries" class="queries-grid">
                            <div
                              v-for="(query, index) in message.relatedQueries"
                              :key="index"
                              class="query-card"
                              @click.self="openSettingsDialog(message, query)"
                            >
                              <div class="query-content">
                                <span class="query-number">#{{ index + 1 }}</span>
                                <p
                                  class="query-text"
                                  @click.stop="$event.target.closest('.query-card') && openSettingsDialog(message, query)"
                                >
                                  <citation-renderer
                                    :html="sanitizeHTML(query)"
                                    @citation-click="handleCitationClick"
                                  />
                                </p>
                              </div>
                              <v-icon
                                small
                                color="success"
                                class="action-icon"
                                @click.stop="openSettingsDialog(message, query)"
                              >
                                mdi-arrow-right-circle
                              </v-icon>
                            </div>
                          </div>

                          <!-- New Questions Grid -->
                          <div v-else class="queries-grid">
                            <div
                              v-for="(query, index) in message.newRelatedQueries"
                              :key="index"
                              class="query-card"
                              @click.self="openSettingsDialog(message, query)"
                            >
                              <div class="query-content">
                                <span class="query-number">#{{ index + 1 }}</span>
                                <p
                                  class="query-text"
                                  @click.stop="$event.target.closest('.query-card') && openSettingsDialog(message, query)"
                                >
                                  <citation-renderer
                                    :html="sanitizeHTML(query)"
                                    @citation-click="handleCitationClick"
                                  />
                                </p>
                              </div>
                              <v-icon
                                small
                                color="success"
                                class="action-icon"
                                @click.stop="openSettingsDialog(message, query)"
                              >
                                mdi-arrow-right-circle
                              </v-icon>
                            </div>
                          </div>
                        </div>
                      </v-expand-transition>
                    </div>
                  </template>
                </div>
              </div>
              <!-- Additional actions End -->
            </div> <!-- Message content End -->
          </div> <!-- Bot messages End -->
          <template>
            <!-- User Messages -->
            <div v-if="message.user === 'user'" class="user-message-container">
              <div class="user-message">
                <div class="user-message-content">
                  <div class="message-body">
                    <div v-if="message.iaoReference" class="iao-reference">
                      <v-chip
                        class="iao-chip"
                        small
                        @click="handleIAOClick(message.iaoReference)"
                      >
                        <v-icon left small color="success">mdi-image-filter-center-focus</v-icon>
                        {{ message.iaoReference.type }}: {{ message.iaoReference.id }}
                      </v-chip>
                    </div>
                    <p>
                      <citation-renderer
                        :html="sanitizeHTML(message.displayText)"
                        @citation-click="handleCitationClick"
                      />
                    </p>
                    <div v-if="message.chatSettings" class="chat-settings">
                      <small>
                        <v-icon x-small color="grey">mdi-molecule</v-icon>
                        {{ message.chatSettings.llm_model_name }} |
                        <v-icon x-small color="grey">{{ ragStrategyIcon }}</v-icon>
                        {{ message.chatSettings.rag_strategy }} |
                        <v-icon x-small color="grey">mdi-clock-time-four-outline</v-icon>
                        {{  message.timestamp | formatRelativeDate }}
                        <v-btn
                          x-small
                          text
                          color="success"
                          @click="openSettingsPopup(message.chatSettings)"
                        >
                          More Settings
                        </v-btn>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template> <!-- User Messages End -->
        </div>  <!-- All Chat Messages End -->

        <!-- Scroll to bottom-->
        <div class="scroll-button-container">
          <v-btn
            v-show="showScrollButton"
            @click="manualScrollToBottom"
            fab
            small
            color="secondary"
            class="scroll-button"
          >
            <v-icon small>mdi-arrow-down</v-icon>
          </v-btn>
        </div>
      </div>       <!-- End Messages Container -->

      <!-- Chat Input -->
      <template>
        <div class="chat-input-wrapper" :class="messagesContainerClasses">
          <div class="rag-icon-container">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="isResponseInProgress ? 'success' : 'grey'"
                  :class="{ 'spin-animation': isResponseInProgress }"
                  @click="cycleRagStrategy"
                >
                  {{ getCurrentStrategyIcon }}
                </v-icon>
              </template>
              <span>RAG Strategy: <em>{{ getCurrentStrategyText }}</em></span>
            </v-tooltip>
            <v-btn
              icon
              @click="openFileUploadDialog"
              color="grey"
              class="ml-2"
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>

          <!-- Added label/placeholder section -->
          <div class="input-label" :class="{ 'input-has-content': userInput.length > 0 }">
            <transition name="fade">
              <div v-if="!userInput.length" class="placeholder-content">
                <div class="placeholder-text">Ask a question or paste:</div>
                <div class="source-tags">
                  <span class="source-tag">
                    <v-icon x-small class="tag-icon">mdi-link</v-icon>
                    links
                  </span>
                  <span class="source-tag">
                    <v-icon x-small class="tag-icon">mdi-youtube</v-icon>
                    videos
                  </span>
                </div>
              </div>
            </transition>
          </div>

          <floating-action-box
            :selected-text="selectedFloatingText"
            @cancel="clearSelection"
            @use-selection="handleChatInputAction"
          />

          <textarea
            ref="chatInput"
            :value="userInput"
            @input="handleInput"
            @keydown="handleKeyDown"
            @focus="onInputFocus"
            @blur="onInputBlur"
            class="chat-input"
            :class="{
              'typing': isBotTyping,
              'input-focused': inputFocused
            }"
          ></textarea>

          <div class="llm-model-info-container">
            <v-fade-transition>
              <div v-if="!showScrollButton" class="llm-model-info">
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="model-text">
                      {{ currentModelName }}
                    </span>
                  </template>
                  <template v-slot:default>
                    <div v-if="currentModel" class="model-tooltip-content">
                      <div class="model-header">
                        <strong>{{ currentModel.name }}</strong>
                        <span v-if="currentModel.type" class="model-type">{{ currentModel.type }}</span>
                      </div>
                      <p class="model-description">{{ currentModel.description }}</p>
                      <div class="model-details">
                        <strong>Capabilities:</strong>
                        <ul>
                          <li v-for="(capability, index) in currentModel.capabilities" :key="'cap-' + index">
                            {{ capability }}
                          </li>
                        </ul>
                      </div>
                      <div class="model-details">
                        <strong>Cons:</strong>
                        <ul>
                          <li v-for="(con, index) in currentModel.cons" :key="'con-' + index">
                            {{ con }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <span v-else>Information about this model is not available.</span>
                  </template>
                </v-tooltip>
              </div>
            </v-fade-transition>
          </div>

          <div class="send-icon-container">
            <v-icon
              :class="{
                'send-icon': true,
                'send-icon-active': userInput.trim().length > 0 || isResponseInProgress,
                'send-icon-hover': (userInput.trim().length > 0 || isResponseInProgress) && isHovered
              }"
              @mouseenter="isHovered = true"
              @mouseleave="isHovered = false"
              @click="isResponseInProgress ? stopResponse() : sendMessage()"
            >
              {{ isResponseInProgress ? 'mdi-stop-circle-outline' : 'mdi-navigation-outline' }}
            </v-icon>
          </div>
        </div>
      </template> <!-- Chat Input End -->
    </div>     <!-- End Chat Container -->

    <!-- Dialogs -->
    <template>
      <v-dialog v-model="isYouTubeSubmitDialogOpen" max-width="800px">
        <v-card class="youtube-submit-dialog" elevation="8">
          <v-card-title class="headline">
            <v-icon left color="error">mdi-youtube</v-icon>
            Link YouTube Video Transcripts to Planet
          </v-card-title>
          <v-card-text class="pt-4">
            <p class="mb-4">
              Planets are spaces where you can organize and interact with your content. Upload videos to be processed and mapped to your selected planet. Selecting a planet to interact with will select all videos mapped to that planet and its children.
            </p>
            <v-form ref="submitYouTubeForm" v-model="submitYouTubeValid" lazy-validation>
              <v-textarea
                v-model="youtubeUrls"
                label="YouTube Video URLs (one per line)"
                :rules="[v => !!v || 'At least one URL is required', validateYoutubeUrls]"
                outlined
                required
                rows="4"
              ></v-textarea>
              <div class="d-flex align-center">
                <v-autocomplete
                  v-model="selectedProject"
                  :items="treeViewProjects"
                  item-text="name"
                  item-value="id"
                  label="Select Planet"
                  :rules="[v => !!v && !!v.id || 'Planet is required']"
                  outlined
                  required
                  return-object
                  :filter="customFilter"
                  @change="validateSelectedProject"
                  class="flex-grow-1 mr-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <template v-for="(pathPart, index) in item.fullPath">
                            <v-icon v-if="index > 0" small class="mr-1" :key="`icon-${index}`">mdi-chevron-right</v-icon>
                            <span :key="`path-${index}`">{{ pathPart }}</span>
                          </template>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.name }}</span>
                  </template>
                </v-autocomplete>
                <v-btn
                  icon
                  color="red"
                  @click="openCreateProjectDialog"
                  class="ml-2 mb-8"
                  :title="'Create a new planet'"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
              <v-select
                v-model="sensitivityLevel"
                :items="sensitivityLevels"
                label="Sensitivity Level"
                :disabled="!user.is_pro_team"
                :rules="[v => !!v || 'Sensitivity level is required']"
                outlined
                required
                class="mt-10"
                :value="defaultSensitivityLevel"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                        small
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      Set the sensitivity level for this transcript. Sensitivity levels control who can view the content.
                      <br><br>
                      Internal: Visible to team members and admins
                      <br>
                      Confidential: Visible to admins and owner only
                    </span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="isYouTubeSubmitDialogOpen = false" color="grey">
              Cancel
            </v-btn>
            <v-btn
              @click="submitYouTubeTranscriptsToServer"
              :disabled="!submitYouTubeValid || isSubmittingYoutube"
              color="red"
              class="px-4"
              :loading="isSubmittingYoutube"
            >
              <v-icon left>mdi-send</v-icon>
              {{ isSubmittingYoutube ? 'Submitting...' : 'Submit' }}
            </v-btn>
            <v-checkbox
              class="ml-4"
              :disabled="!user.is_pro"
              v-model="buildIndex"
              label="Build Index"
              hint="Build custom ECRAG index for your video. Consistently beats most AI search/answer engines -- especially when text size exceeds LLM context window."
              persistent-hint
            >
              <template v-slot:label>
                <div class="d-flex align-center">
                  Build Index
                  <v-chip
                    v-if="!user.is_pro_team"
                    x-small
                    color="primary"
                    class="ml-2 pro-chip"
                  >
                    PRO
                  </v-chip>
                </div>
              </template>
            </v-checkbox>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

   <!-- Submit text dialog -->
   <template>
      <v-dialog v-model="isSubmitTextDialogOpen" max-width="800px">
        <v-card class="submit-text-dialog" elevation="8">
          <v-card-title class="headline">
            <v-icon left color="#FF9800">mdi-text-box-plus-outline</v-icon>
            Paste Text to Planet
          </v-card-title>
          <v-card-text class="pt-4">
            <p class="mb-4">
              Planets are spaces to organize and interact with your content. Upload text to be processed and mapped to your selected planet. Selecting a planet to interact with will select the text mapped to that planet and its children.
            </p>
            <v-form ref="submitTextForm" v-model="submitTextValid" lazy-validation>
              <v-text-field
                v-model="submittedTextName"
                label="Name for this text submission"
                :rules="[v => !!v || 'Name is required']"
                outlined
                required
              ></v-text-field>
              <v-textarea
                v-model="submittedText"
                label="Enter your text"
                :rules="[
                  v => !!v || 'Text is required',
                  v => v.length <= MAX_TEXT_CHARACTERS || `Text should be less than ${MAX_TEXT_CHARACTERS} characters`
                ]"
                rows="5"
                outlined
                counter
                required
                @input="handleTextSelection"
                :maxlength="MAX_TEXT_CHARACTERS"
              ></v-textarea>
              <div class="d-flex align-center">
                <v-autocomplete
                  v-model="selectedProject"
                  :items="treeViewProjects"
                  item-text="name"
                  item-value="id"
                  label="Select Planet"
                  :rules="[v => !!v && !!v.id || 'Planet is required']"
                  outlined
                  required
                  return-object
                  :filter="customFilter"
                  @change="validateSelectedProject"
                  class="flex-grow-1 mr-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <template v-for="(pathPart, index) in item.fullPath">
                            <v-icon v-if="index > 0" small class="mr-1" :key="`icon-${index}`">mdi-chevron-right</v-icon>
                            <span :key="`path-${index}`">{{ pathPart }}</span>
                          </template>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.name }}</span>
                  </template>
                </v-autocomplete>
                <v-btn
                  icon
                  color="success"
                  @click="openCreateProjectDialog"
                  class="ml-2 mb-8"
                  :title="'Create a new planet'"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
              <v-select
                v-model="sensitivityLevel"
                :items="sensitivityLevels"
                label="Sensitivity Level"
                :disabled="!user.is_pro_team"
                :rules="[v => !!v || 'Sensitivity level is required']"
                outlined
                required
                class="mt-3"
                :value="defaultSensitivityLevel"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                        small
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      Set the sensitivity level for this text submission. This affects who can access/interact and view the content.
                      <br><br>
                      Internal: Visible to team members and admins
                      <br>
                      Confidential: Visible to admins and owner only
                    </span>
                  </v-tooltip>
                </template>
              </v-select>
              <v-checkbox
                class="mb-4"
                :disabled="!user.is_pro_team"
                v-model="buildIndex"
                label="Build Index"
                hint="Build custom ECRAG index for your text. Consistently beats most AI search/answer engines -- especially when text size exceeds LLM context window."
                persistent-hint
              >
                <template v-slot:label>
                  <div class="d-flex align-center">
                    Build Index
                    <v-chip
                      v-if="!user.is_pro_team"
                      x-small
                      color="success"
                      text-color="black"
                      class="ml-2 pro-chip"
                    >
                      PRO
                    </v-chip>
                  </div>
                </template>
              </v-checkbox>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="isSubmitTextDialogOpen = false" color="grey">
              Cancel
            </v-btn>
            <v-btn
              @click="submitTextToServer"
              :disabled="!submitTextValid"
              color="success"
              class="px-4"
            >
              <v-icon left>mdi-send</v-icon>
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <!-- Image upload dialog -->
    <template>
      <v-dialog v-model="isImageUploadDialogOpen" max-width="800px">
        <v-card class="image-upload-dialog" elevation="8">
          <v-card-title class="headline">
            <v-icon left color="#FFC107">mdi-folder-multiple-image</v-icon>
            Upload Images to Planet
          </v-card-title>
          <v-card-text class="pa-4">
            <p class="mb-4">
              Planets are spaces to organize and interact with your content. Upload images to be processed and mapped to your selected planet. Selecting a planet to interact with will select all images mapped to that planet and its children.
            </p>
            <v-form ref="imageUploadForm" v-model="imageUploadValid" lazy-validation>
              <div class="d-flex align-center">
                <v-autocomplete
                  v-model="selectedProject"
                  :items="treeViewProjects"
                  item-text="name"
                  item-value="id"
                  label="Select Planet"
                  :rules="[v => !!v && !!v.id || 'Planet is required']"
                  outlined
                  required
                  return-object
                  :filter="customFilter"
                  @change="validateSelectedProject"
                  class="flex-grow-1 mr-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <template v-for="(pathPart, index) in item.fullPath">
                            <v-icon v-if="index > 0" small class="mr-1" :key="`icon-${index}`">mdi-chevron-right</v-icon>
                            <span :key="`path-${index}`">{{ pathPart }}</span>
                          </template>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.name }}</span>
                  </template>
                </v-autocomplete>
                <v-btn
                  icon
                  color="success"
                  @click="openCreateProjectDialog"
                  class="ml-2 mb-8"
                  :title="'Create a new planet'"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
              <v-file-input
                v-model="selectedFiles"
                label="Select images"
                :rules="[
                  v => v && v.length > 0 || 'At least one image is required',
                  v => v && v.length <= maxSimultaneousUploads || `Maximum ${maxSimultaneousUploads} files allowed`
                ]"
                required
                @change="handleFileSelection"
                outlined
                prepend-icon="mdi-image"
                :show-size="true"
                accept=".jpeg,.jpg,.png,.gif,.bmp,.tiff,.tif"
                multiple
                chips
              ></v-file-input>
              <small class="text-caption">
                Supported formats: JPEG, PNG, GIF, BMP, TIFF<br>
                Max size: 20MB<br>
                Max files: <span class="success--text font-weight-medium">{{ maxSimultaneousUploads }} files {{ upgradeNote }}</span>
              </small>
              <v-select
                v-model="sensitivityLevel"
                :items="sensitivityLevels"
                label="Sensitivity Level"
                :disabled="!user.is_pro_team"
                :rules="[v => !!v || 'Sensitivity level is required']"
                outlined
                required
                class="mt-10"
                :value="defaultSensitivityLevel"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                        small
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      Set the sensitivity level for this image. This affects who can access/interact and view the content.
                      <br><br>
                      Internal: Visible to team members and admins
                      <br>
                      Confidential: Visible to admins and owner only
                    </span>
                  </v-tooltip>
                </template>
              </v-select>
              <v-checkbox
                class="mt-3"
                :disabled="!user.is_pro"
                v-model="buildIndex"
                label="Build Index"
                hint="Build custom ECRAG index for your image. Consistently beats most AI search/answer engines -- especially when text size exceeds LLM context window."
                persistent-hint
              >
                <template v-slot:label>
                  <div class="d-flex align-center">
                    Build Index
                    <v-chip
                      v-if="!user.is_pro_team"
                      x-small
                      color="success"
                      text-color="black"
                      class="ml-2 pro-chip"
                    >
                      PRO
                    </v-chip>
                  </div>
                </template>
              </v-checkbox>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="isImageUploadDialogOpen = false" color="grey">
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="uploadImages"
              :disabled="!fileUploadValid || !selectedProject || selectedFiles.length === 0 || isUploading"
              class="px-4 position-relative"
              :loading="isUploading"
            >
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  size="20"
                  width="2"
                  color="#10B981"
                ></v-progress-circular>
              </template>
              <v-icon left v-if="!isUploading">mdi-cloud-upload</v-icon>
              <span>{{ isUploading ? 'Uploading...' : 'Upload' }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <!-- File upload dialog -->
    <template>
      <v-dialog v-model="isFileUploadDialogOpen" max-width="800px">
        <v-card class="file-upload-dialog" elevation="8">
          <v-card-title class="headline">
            <v-icon left color="success">mdi-file-document-multiple-outline</v-icon>
            Upload Files to your Planet
          </v-card-title>
          <v-card-text class="pt-4">
            <p class="mb-4">
              Planets are spaces to organize and interact with your content. Upload files to be processed and mapped to your selected planet. Selecting a planet to interact with will select all files mapped to that planet and its children.
            </p>
            <v-form ref="fileUploadForm" v-model="fileUploadValid" lazy-validation>
              <div class="d-flex align-center">
                <v-autocomplete
                  v-model="selectedProject"
                  :items="treeViewProjects"
                  item-text="name"
                  item-value="id"
                  label="Select Planet"
                  :rules="[v => !!v && !!v.id || 'Planet is required']"
                  outlined
                  required
                  return-object
                  :filter="customFilter"
                  @change="validateSelectedProject"
                  class="flex-grow-1 mr-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <template v-for="(pathPart, index) in item.fullPath">
                            <v-icon v-if="index > 0" small class="mr-1" :key="`icon-${index}`">mdi-chevron-right</v-icon>
                            <span :key="`path-${index}`">{{ pathPart }}</span>
                          </template>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.name }}</span>
                  </template>
                </v-autocomplete>
                <v-btn
                  icon
                  color="success"
                  @click="openCreateProjectDialog"
                  class="ml-2 mb-8"
                  :title="'Create a new planet'"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
              <v-file-input
                v-model="selectedFiles"
                label="Select files"
                :rules="[
                  v => v && v.length > 0 || 'At least one image is required',
                  v => v && v.length <= maxSimultaneousUploads || `Maximum ${maxSimultaneousUploads} files allowed`
                ]"
                required
                @change="handleFileSelection"
                outlined
                prepend-icon="mdi-file-document-outline"
                :show-size="true"
                accept=".txt,.eml,.msg,.xml,.html,.md,.rst,.json,.rtf,.doc,.docx,.ppt,.pptx,.pdf,.odt,.epub,.csv,.tsv,.xlsx,.gz .jpeg,.jpg,.png,.gif,.bmp,.tiff,.tif"
                multiple
                chips
              ></v-file-input>
              <small class="text-caption">
                Supported formats: TXT, EML, MSG, XML, HTML, MD, RST, JSON, RTF, DOC, DOCX, PPT, PPTX, PDF, ODT, EPUB, CSV, TSV, XLSX, GZ<br>
                Images: JPEG, PNG, GIF, BMP, TIFF<br>
                Max size: 20MB<br>
                Max files: <span class="success--text font-weight-medium">{{ maxSimultaneousUploads }} files {{ upgradeNote }}</span>
              </small>
              <v-select
                v-model="sensitivityLevel"
                :items="sensitivityLevels"
                label="Sensitivity Level"
                :disabled="!user.is_pro_team"
                :rules="[v => !!v || 'Sensitivity level is required']"
                outlined
                required
                class="mt-10"
                :value="defaultSensitivityLevel"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="grey lighten-1"
                        small
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      Set the sensitivity level for this document. This affects who can access/interact and view the document.
                      <br><br>
                      Internal: Visible to team members and admins
                      <br>
                      Confidential: Visible to admins and owner only
                    </span>
                  </v-tooltip>
                </template>
              </v-select>
              <v-checkbox
                class="mt-3"
                :disabled="!user.is_pro"
                v-model="buildIndex"
                label="Build Index"
                hint="Build custom ECRAG index for your document. Consistently beats most AI search/answer engines -- especially when text size exceeds LLM context window."
                persistent-hint
              >
                <template v-slot:label>
                  <div class="d-flex align-center">
                    Build Index
                    <v-chip
                      v-if="!user.is_pro_team"
                      x-small
                      color="primary"
                      class="ml-2 pro-chip"
                    >
                      PRO
                    </v-chip>
                  </div>
                </template>
              </v-checkbox>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="isFileUploadDialogOpen = false" color="grey">
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="uploadFiles"
              :disabled="!fileUploadValid || !selectedProject || selectedFiles.length === 0 || isUploading"
              class="px-4"
              :loading="isUploading"
            >
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  size="20"
                  width="2"
                  color="white"
                ></v-progress-circular>
              </template>
              <v-icon left v-if="!isUploading">mdi-cloud-upload</v-icon>
              <span>{{ isUploading ? 'Uploading...' : 'Upload' }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <!-- Create Project Dialog -->
    <v-dialog v-model="isCreateProjectDialogOpen" max-width="600px">
      <v-card class="project-dialog" elevation="8">
        <v-card-title class="headline">
          <v-icon left color="success">mdi-plus-circle</v-icon>
          Create a new planet
        </v-card-title>
        <v-card-text class="pt-4">
          <p class="mb-4">
            Create a new planet to organize your submitted texts and files. Planets can be nested, allowing for hierarchical organization. When interacting, all context from objects mapped to the selected planet, and all its children will be included, providing comprehensive results.
          </p>
          <v-form ref="form" v-model="createProjectValid" lazy-validation>
            <v-text-field
              v-model="newProjectName"
              label="Planet Name"
              :rules="[v => !!v || 'Planet name is required']"
              prepend-icon="mdi-format-title"
              required
            ></v-text-field>
            <v-autocomplete
              v-model="newProjectParentId"
              :items="parentProjectOptions"
              label="Parent Planet (Optional)"
              prepend-icon="mdi-folder-multiple-outline"
              item-text="name"
              item-value="id"
              clearable
            >
              <template v-slot:selection="data">
                {{ data.item.name }}
              </template>
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ 'ID: ' + data.item.id }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="isCreateProjectDialogOpen = false" color="grey">
            Cancel
          </v-btn>
          <v-btn
            @click="createProject"
            :disabled="!createProjectValid"
            color="success"
            class="px-4"
          >
            <v-icon left>mdi-check</v-icon>
            Create Planet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLimits" max-width="900px">
      <UserLimitsDisplay
        :is-pro="user.is_pro"
        :is-team="user.is_pro_team"
      />
    </v-dialog>
    <v-dialog v-model="showLikedMessagesProcessingDialog" max-width="1000px">
      <LikedMessagesProcessingStatus @close-dialog="closeLikedMessagesProcessingDialog" />
    </v-dialog>
    <!-- Projects/Planets dialog -->
    <v-dialog v-model="isProjectsDialogOpen" max-width="800px">
      <v-card class="project-dialog">
        <v-card-title class="headline">
          <v-icon left color="success">mdi-earth</v-icon>
          Planets
          <v-spacer></v-spacer>
          <v-btn icon @click="isProjectsDialogOpen = false" dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                color="success"
                v-bind="attrs"
                v-on="on"
                class="mb-4"
              >
                <v-icon left>mdi-information-outline</v-icon>
                About Planets
              </v-btn>
            </template>
            <v-card class="pa-4" max-width="350">
              <h3 class="headline mb-2">Planets: Focused Data Realms</h3>
              <v-divider class="mb-3"></v-divider>
              <p class="subtitle-1 mb-2">Planets are the most granular level in our cosmic data hierarchy:</p>
              <v-chip color="success" small class="mb-3">Universe > Galaxy > Planet > Moon > Object</v-chip>
              <p class="body-2 mb-3">
                <strong>Key Features:</strong>
              </p>
              <ul class="body-2 pl-4 mb-3">
                <li><strong>Multiple Planets:</strong> Each Galaxy can contain numerous Planets</li>
                <li><strong>Nested Structure:</strong> Planets can be hierarchically organized</li>
                <li><strong>Content Organization:</strong> Houses your submitted texts and files</li>
              </ul>
              <v-divider class="mb-3"></v-divider>
              <p class="body-2 mb-2"><strong>When you select a Planet:</strong></p>
              <ul class="body-2 pl-4">
                <li>Searches and answers focus on the Planet's specific context</li>
                <li>Queries are enhanced with a prepended activity object</li>
                <li>Information from the Planet and its sub-Planets is considered</li>
                <li>Responses become more accurate and relevant</li>
              </ul>
              <v-divider class="my-3"></v-divider>
              <p class="caption font-italic">
                Navigate through your cosmic data structure to pinpoint the exact information realm you need!
              </p>
            </v-card>
          </v-tooltip>
          <v-text-field
            v-model="projectSearch"
            label="Search planets"
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            dense
          ></v-text-field>
          <v-treeview
          :items="treeViewProjects"
          :search="projectSearch"
          :open="openedNodes"
          item-key="id"
          activatable
          open-on-click
          transition
          hoverable
          dense
        >
          <template v-slot:prepend="{ item }">
            <v-icon :color="item.children ? 'success' : 'secondary'">
              {{ item.children ? 'mdi-earth' : 'mdi-moon-full' }}
            </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <div class="d-flex align-center project-item" @click="handleProjectClick(item)">
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                </template>
                <span>
                  {{ item.description || 'No description available' }}
                  <br>
                  <em>Note:</em>
                  Selecting this Planet will focus your RAG context on its content. All submissions (including the ones from its children) will be considered for RAG sent to the model.
                </span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-btn
                small
                color="success"
                outlined
                @click.stop="selectProject(item.id)"
              >
                Select
              </v-btn>
              <v-icon small color="primary" class="ml-2 chat-hint">mdi-chat-processing-outline</v-icon>
            </div>
          </template>
        </v-treeview>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="openCreateProjectDialog"
            class="px-4"
          >
            <v-icon left>mdi-plus</v-icon>
            Create a new planet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Submissins dialog -->
    <v-dialog v-model="showSubmissionsDialog" max-width="1000px">
       <SubmissionsStatus
         :user="user"
         @close-dialog="closeSubmissionsDialog"
         :project-id="selectedGalaxy"
       />
     </v-dialog>

  <!-- Settings dialog -->
  <v-dialog v-model="isSettingsDialogOpen" max-width="800px">
    <transition name="fade-transition">
      <v-card class="settings-dialog">
        <div class="settings-header">
          <div class="header-content">
            <div class="title-container">
              <v-icon class="header-icon" color="primary">mdi-database-settings-outline</v-icon>
              <h2 class="header-title">Customize Your Answer</h2>
            </div>
            <div class="universe-container">
              <span class="universe-label">Universe:</span>
              <span class="universe-name">{{ selectedUniverse }}</span>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="info-icon"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <v-card class="pa-3">
                  <p class="body-2">
                    These settings are specific to the selected Universe.
                    Each Universe maintains its own configuration, allowing for
                    tailored knowledge empowerment across different organizational contexts.
                  </p>
                </v-card>
              </v-tooltip>
            </div>
          </div>
        </div>
        <v-card-text>
          <template>
            <div class="d-flex align-center">
              <rag-strategy-selector
                :rag_strategy="rag_strategy"
                @update:rag_strategy="rag_strategy = $event"
                :user="user"
                @change="updateRAGStrategy"
              />
              <v-spacer></v-spacer>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div
                      class="chip bao-chip"
                      :class="{ 'disabled': !hasActiveObject }"
                    >
                      <v-icon left color="primary">{{ chipIcon }}</v-icon>
                      <span class="chip-text">{{ chipText }}</span>
                    </div>
                  </div>
                </template>
                <div>
                  <strong>Interactive Active Object (IAO)</strong>
                  <br>
                  An Interactive Active Object is a specific item or concept that becomes the focus of the conversation. When enabled:
                  <ul>
                    <li>RAG context narrows focus on this object</li>
                    <li>Includes relevant information about child objects (if any)</li>
                    <li>Enhances conversation specificity and relevance</li>
                  </ul>
                  <em>This allows for more precise and context-aware interactions.</em>
                </div>
              </v-tooltip>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    @click="handleToggleBAOPrepend"
                    :color="shouldPrependBAO ? 'primary' : 'primary'"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                  >
                    <v-icon>{{ shouldPrependBAO ? 'mdi-image-filter-center-focus' : 'mdi-focus-auto' }}</v-icon>
                  </v-btn>
                </template>
                <div>
                  <strong>{{ shouldPrependBAO ? 'Disable' : 'Enable' }} IAO Prepend</strong>
                  <br>
                  {{ shouldPrependBAO
                    ? 'Currently enabled: Context is narrowed to the selected object'
                    : 'Currently disabled: Context includes broader information'
                  }}
                </div>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    @click="handleResetBAO"
                    color="error"
                    :disabled="!activeObject"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 custom-disabled-btn"
                  >
                    <v-icon>mdi-image-filter-center-focus-weak</v-icon>
                  </v-btn>
                </template>
                <span>Turn Focus Off</span>
              </v-tooltip>
            </div>
          </template>
          <v-tabs v-model="activeTab" grow @change="handleTabChange">
            <v-tab key="web_search">
              <v-icon small left>mdi-web</v-icon>
              Web Search Setting
            </v-tab>
            <v-tab key="ecrag">
              <v-icon small left>mdi-atom-variant</v-icon>
              ECRAG Settings
            </v-tab>
            <v-tab key="llm">
              <v-icon small left>mdi-atom</v-icon>
              LLM Settings
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item key="web_search">
              <v-card flat>
                <v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Search Configuration</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-subheader>Number of Search Results</v-subheader>
                        <v-slider
                          v-model="google_search_results_count"
                          min="10"
                          max="40"
                          step="10"
                          thumb-label="always"
                          @input="updateGoogleSearchResultsCount"
                          color="success"
                          thumb-color="tips"
                        >
                          <template v-slot:append>
                            <v-tooltip left max-width="300">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                              </template>
                              <v-card class="pa-4">
                                <h3 class="headline mb-2">Web Search Results Count</h3>
                                <v-divider class="mb-3"></v-divider>
                                <p class="body-2 mb-2">Adjust the number of search results returned:</p>
                                <ul class="body-2 pl-4 mb-3">
                                  <li><strong>Higher values:</strong> Broader coverage, longer processing</li>
                                  <li><strong>Lower values:</strong> Faster, but potentially limited scope</li>
                                </ul>
                                <p class="body-2 mb-2"><strong>Impact:</strong></p>
                                <ul class="body-2 pl-4">
                                  <li>Affects the diversity of information retrieved</li>
                                  <li>Influences processing time and resource usage</li>
                                </ul>
                                <v-divider class="my-3"></v-divider>
                                <p class="caption font-italic">
                                  Balance between comprehensive search and efficiency based on your needs. Please note we respect robots.txt.
                                </p>
                              </v-card>
                            </v-tooltip>
                          </template>
                        </v-slider>

                        <v-switch
                          v-model="google_search_skip_top_results"
                          @change="updateGoogleSearchSkipTopResults"
                          color="success"
                        >
                          <template v-slot:label>
                            <div>
                              <v-icon small left color="tips">mdi-skip-forward-outline</v-icon>
                              Skip top search results
                              <v-tooltip right max-width="300">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                              </template>
                              <v-card class="pa-4">
                                <h3 class="headline mb-2">Skip Top Results</h3>
                                <v-divider class="mb-3"></v-divider>
                                <p class="body-2 mb-2">Option to skip the most popular search results:</p>
                                <ul class="body-2 pl-4 mb-3">
                                  <li><strong>Enabled:</strong> May find more diverse or niche information</li>
                                  <li><strong>Disabled:</strong> Includes popular, potentially more relevant results</li>
                                </ul>
                                <p class="body-2 mb-2"><strong>Use cases:</strong></p>
                                <ul class="body-2 pl-4">
                                  <li>Research on less common topics</li>
                                  <li>Exploring alternative viewpoints</li>
                                  <li>Avoiding oversaturated or biased top results</li>
                                </ul>
                                <v-divider class="my-3"></v-divider>
                                <p class="caption font-italic">
                                  Enable when seeking diverse or less common information. Please note we respect robots.txt.
                                </p>
                              </v-card>
                            </v-tooltip>
                            </div>
                          </template>
                        </v-switch>

                        <v-subheader>
                          <v-icon left small color="primary">mdi-skip-forward-outline</v-icon>
                          Number of Top Results to Skip
                        </v-subheader>
                        <v-slider
                          v-model="google_search_skip_top_num"
                          min="0"
                          max="100"
                          step="10"
                          thumb-label="always"
                          @input="updateGoogleSearchSkipTopNum"
                          color="success"
                          thumb-color="tips"
                          :disabled="!google_search_skip_top_results"
                        >
                          <template v-slot:append>
                            <v-tooltip left max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                            </template>
                            <v-card class="pa-4">
                              <h3 class="headline mb-2">Number of Top Results to Skip</h3>
                              <v-divider class="mb-3"></v-divider>
                              <p class="body-2 mb-2">Set the number of top search results to bypass:</p>
                              <ul class="body-2 pl-4 mb-3">
                                <li><strong>Higher values:</strong> More niche results, potential info loss</li>
                                <li><strong>Lower values:</strong> Less filtering, more mainstream info</li>
                              </ul>
                              <p class="body-2 mb-2"><strong>Considerations:</strong></p>
                              <ul class="body-2 pl-4">
                                <li>Topic popularity and information availability</li>
                                <li>Desired balance between common and rare insights</li>
                                <li>Risk of missing crucial information</li>
                              </ul>
                              <v-divider class="my-3"></v-divider>
                              <p class="caption font-italic">
                                Adjust carefully to balance between niche findings and important general information.
                              </p>
                              </v-card>
                            </v-tooltip>
                          </template>
                        </v-slider>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>Site-Specific Search</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card-subtitle>
                          <v-icon left small color="primary">mdi-perspective-more</v-icon>
                          Limit search results to specific websites
                        </v-card-subtitle>
                        <v-alert
                          type="warning"
                          dense
                          text
                          outlined
                        >
                          <strong>Note:</strong> If you include a site, it takes precedence over the excluded sites. Also, try to include only one site at a time for the best results.
                          You can add as many excluded sites as needed. The search will exclude results from these sites.
                        </v-alert>
                        <v-list>
                          <v-list-item
                            v-for="(site, index) in site_searches"
                            :key="index"
                            class="neutral-hover"
                          >
                            <v-text-field
                              v-model="site_searches[index].site"
                              label="Site"
                              @input="updateSiteSearches"
                            ></v-text-field>
                            <v-select
                              v-model="site_searches[index].filter"
                              :items="[
                                { text: 'Include', value: 'i' },
                                { text: 'Exclude', value: 'e' }
                              ]"
                              label="Filter"
                              @change="updateSiteSearches"
                            ></v-select>
                            <v-btn icon @click="removeSiteSearch(index)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-list-item>
                        </v-list>
                        <v-btn text color="success" @click="addSiteSearch">
                          <v-icon left>mdi-plus</v-icon>
                          Add Site
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>Date Range</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-icon left small color="primary">mdi-calendar-range-outline</v-icon>
                        <p>Set a date range to limit your Web Search results. This can help focus your search on specific time periods.</p>
                        <v-alert
                          type="warning"
                          dense
                          text
                          outlined
                        >
                          <strong>Note:</strong> Setting a date range may significantly reduce the number of search results. Leave both fields empty for the most comprehensive search.
                        </v-alert>
                        <v-row class="mt-2">
                          <v-col cols="5">
                            <v-menu
                              v-model="lowRangeMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="google_search_low_range"
                                  label="From (Optional)"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  hint="Start date for search results"
                                  persistent-hint
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="google_search_low_range"
                                @input="updateGoogleSearchLowRange"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="1">
                            <v-btn icon @click="resetLowRange" :disabled="!google_search_low_range" title="Reset start date">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="5">
                            <v-menu
                              v-model="highRangeMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="google_search_high_range"
                                  label="To (Optional)"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  hint="End date for search results"
                                  persistent-hint
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="google_search_high_range"
                                @input="updateGoogleSearchHighRange"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="1">
                            <v-btn icon @click="resetHighRange" :disabled="!google_search_high_range" title="Reset end date">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item key="ecrag">
              <v-card flat>
                <v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Entity Matching</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-subheader>
                          <v-icon small left>mdi-format-letter-matches</v-icon>
                          Entities Relationship Match Threshold
                          <v-tooltip right max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                          </template>
                          <v-card class="pa-4">
                            <h3 class="headline mb-2">Relationship Match Threshold</h3>
                            <v-divider class="mb-3"></v-divider>
                            <p class="body-2 mb-2">This setting controls the minimum similarity score for success entity relationships:</p>
                            <ul class="body-2 pl-4 mb-3">
                              <li><strong>Higher values:</strong> Stricter matching, more precise relationships</li>
                              <li><strong>Lower values:</strong> More inclusive matching, potentially more relationships</li>
                            </ul>
                            <p class="body-2 mb-2"><strong>Impact:</strong></p>
                            <ul class="body-2 pl-4">
                              <li>Affects the breadth of related information in responses</li>
                              <li>Influences the specificity of entity connections</li>
                            </ul>
                            <v-divider class="my-3"></v-divider>
                            <p class="caption font-italic">
                              Adjust based on your need for precision vs. inclusivity in relationships.
                            </p>
                          </v-card>
                        </v-tooltip>
                        </v-subheader>
                        <v-slider
                          v-model="relationshipMatchThreshold"
                          min="0"
                          max="1"
                          step="0.01"
                          thumb-label="always"
                          @input="updateThreshold"
                          color="success"
                          thumb-color="tips"
                        ></v-slider>

                        <v-subheader>
                          <v-icon small left>mdi-relation-one-or-many-to-one-or-many</v-icon>
                          Related Entities Relationship Match Threshold
                          <v-tooltip right max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                          </template>
                          <v-card class="pa-4">
                            <h3 class="headline mb-2">Related Entities Match Threshold</h3>
                            <v-divider class="mb-3"></v-divider>
                            <p class="body-2 mb-2">Controls the minimum similarity score for related entity relationships:</p>
                            <ul class="body-2 pl-4 mb-3">
                              <li><strong>Higher values:</strong> Stricter matching for secondary connections</li>
                              <li><strong>Lower values:</strong> More inclusive related entity matching</li>
                            </ul>
                            <p class="body-2 mb-2"><strong>Impact:</strong></p>
                            <ul class="body-2 pl-4">
                              <li>Affects the breadth of indirectly related information</li>
                              <li>Influences the network of entity connections</li>
                            </ul>
                            <v-divider class="my-3"></v-divider>
                            <p class="caption font-italic">
                              Adjust to balance between focused and broad related entity networks.
                            </p>
                          </v-card>
                        </v-tooltip>
                        </v-subheader>
                        <v-slider
                          v-model="relatedEntitiesMatchThreshold"
                          min="0"
                          max="1"
                          step="0.01"
                          thumb-label="always"
                          @input="updateRelatedEntitiesThreshold"
                          color="success"
                          thumb-color="tips"
                        ></v-slider>

                        <v-subheader>
                          <v-icon small left>mdi-ruler-square-compass</v-icon>
                          Matched Entities Length Factor
                          <v-tooltip top max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                          </template>
                          <v-card class="pa-4">
                            <h3 class="headline mb-2">Length Factor</h3>
                            <v-divider class="mb-3"></v-divider>
                            <p class="body-2 mb-2">Influences how the length of matched entities affects their relevance score:</p>
                            <ul class="body-2 pl-4 mb-3">
                              <li><strong>Higher values:</strong> More weight to longer entity matches</li>
                              <li><strong>Lower values:</strong> Less emphasis on entity length</li>
                            </ul>
                            <p class="body-2 mb-2"><strong>Impact:</strong></p>
                            <ul class="body-2 pl-4">
                              <li>Affects the prioritization of longer vs. shorter entity matches</li>
                              <li>Influences the balance between specificity and generality in responses</li>
                            </ul>
                            <v-divider class="my-3"></v-divider>
                            <p class="caption font-italic">
                              Adjust based on whether you prefer more specific (longer) or more general (shorter) entity matches.
                            </p>
                          </v-card>
                        </v-tooltip>
                        </v-subheader>
                        <v-slider
                          v-model="lengthFactor"
                          min="0.01"
                          max="0.05"
                          step="0.01"
                          thumb-label="always"
                          @input="updateLengthFactor"
                          color="success"
                          thumb-color="tips"
                        ></v-slider>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>RAG Configuration</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-subheader>
                          <v-icon small left>mdi-all-inclusive</v-icon>
                          Top Entities Inclusion Threshold
                          <v-tooltip right max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                          </template>
                          <v-card class="pa-4">
                            <h3 class="headline mb-2">Top Entities Threshold</h3>
                            <v-divider class="mb-3"></v-divider>
                            <p class="body-2 mb-2">Sets the maximum number of top-ranked entities to include in the analysis:</p>
                            <ul class="body-2 pl-4 mb-3">
                              <li><strong>Higher values:</strong> More comprehensive results</li>
                              <li><strong>Lower values:</strong> Faster processing, more focused results</li>
                            </ul>
                            <p class="body-2 mb-2"><strong>Impact:</strong></p>
                            <ul class="body-2 pl-4">
                              <li>Affects the breadth of information considered</li>
                              <li>Influences processing time and resource usage</li>
                            </ul>
                            <v-divider class="my-3"></v-divider>
                            <p class="caption font-italic">
                              Balance between comprehensive analysis and performance based on your needs.
                            </p>
                          </v-card>
                        </v-tooltip>
                        </v-subheader>
                        <v-slider
                          v-model="topEntitiesThreshold"
                          min="100"
                          max="2000"
                          step="100"
                          thumb-label="always"
                          @input="updateTopEntitiesThreshold"
                          color="success"
                          thumb-color="tips"
                        ></v-slider>

                        <v-subheader>
                          <v-icon small left>mdi-alphabet-aurebesh</v-icon>
                          Entities RAG Token Threshold
                          <v-tooltip right max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                          </template>
                          <v-card class="pa-4">
                            <h3 class="headline mb-2">RAG Token Threshold</h3>
                            <v-divider class="mb-3"></v-divider>
                            <p class="body-2 mb-2">Limits the number of tokens used for Retrieval-Augmented Generation (RAG) for success entities:</p>
                            <ul class="body-2 pl-4 mb-3">
                              <li><strong>Higher values:</strong> Potentially improved accuracy</li>
                              <li><strong>Lower values:</strong> Faster processing, less resource usage</li>
                            </ul>
                            <p class="body-2 mb-2"><strong>Impact:</strong></p>
                            <ul class="body-2 pl-4">
                              <li>Affects the amount of context provided to the AI</li>
                              <li>Influences response quality and generation time</li>
                            </ul>
                            <v-divider class="my-3"></v-divider>
                            <p class="caption font-italic">
                              Adjust to balance between context richness and system performance.
                            </p>
                          </v-card>
                        </v-tooltip>
                        </v-subheader>
                        <v-slider
                          v-model="ragTokenThreshold"
                          min="1000"
                          max="100000"
                          step="1000"
                          thumb-label="always"
                          @input="updateRagTokenThreshold"
                          color="success"
                          thumb-color="tips"
                        ></v-slider>

                        <v-subheader>
                          <v-icon small left>mdi-opacity</v-icon>
                          Relationships Display Limit
                          <v-tooltip top max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                          </template>
                          <v-card class="pa-4">
                            <h3 class="headline mb-2">Relationships Display Limit</h3>
                            <v-divider class="mb-3"></v-divider>
                            <p class="body-2 mb-2">Sets the maximum number of relationships to display for each entity:</p>
                            <ul class="body-2 pl-4 mb-3">
                              <li><strong>Higher values:</strong> Show more connections</li>
                              <li><strong>Lower values:</strong> More focused view, less cluttered</li>
                            </ul>
                            <p class="body-2 mb-2"><strong>Impact:</strong></p>
                            <ul class="body-2 pl-4">
                              <li>Affects the complexity of displayed entity networks</li>
                              <li>Influences interface clarity and information density</li>
                            </ul>
                            <v-divider class="my-3"></v-divider>
                            <p class="caption font-italic">
                              Balance between comprehensive relationship display and interface usability.
                            </p>
                          </v-card>
                        </v-tooltip>
                        </v-subheader>
                        <v-slider
                          v-model="relationshipsDisplayLimit"
                          min="0"
                          max="20"
                          step="1"
                          thumb-label="always"
                          @input="updateRelationshipsDisplayLimit"
                          color="success"
                          thumb-color="tips"
                        ></v-slider>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>LLM Settings</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="llm-model-selection">
                          <v-subheader>
                            <v-icon left small>mdi-molecule</v-icon>
                            LLM Model Selection
                            <v-tooltip right max-width="300">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                                </template>
                                <v-card class="pa-4">
                                  <h3 class="headline mb-2">
                                    <v-icon left>mdi-atom</v-icon>
                                    Language Model Selection</h3>
                                    <v-divider class="mb-3"></v-divider>
                                    <p class="body-2 mb-2">Choose the language model to use for generating responses:</p>
                                    <ul class="body-2 pl-4 mb-3">
                                      <li><strong>Larger models:</strong> More capable, potentially slower</li>
                                      <li><strong>Smaller models:</strong> Faster, may have limited capabilities</li>
                                    </ul>
                                    <p class="body-2 mb-2"><strong>Considerations:</strong></p>
                                    <ul class="body-2 pl-4">
                                      <li>Context Window Size (e.g. 128k tokens for OpenAI, 200k tokens for Claude, Etc.)</li>
                                      <li>Task complexity and required expertise</li>
                                      <li>Response time requirements</li>
                                      <li>Resource availability and costs</li>
                                    </ul>
                                    <v-divider class="my-3"></v-divider>
                                    <p class="caption font-italic">
                                      Select based on your specific needs for performance, capability, and efficiency.
                                    </p>
                                </v-card>
                            </v-tooltip>
                          </v-subheader>
                          <v-expansion-panels>
                            <v-expansion-panel
                              v-for="model in llmModels"
                              :key="model.name"
                              :class="{ 'selected': model.name === llmModelName, 'pro-model': model.type === 'Pro' }"
                            >
                            <v-expansion-panel-header
                                @click="handleModelSelection(model)"
                                :disabled="!canSelectModel(model)"
                                :class="{'pro-disabled': !canSelectModel(model)}"
                              >
                                <v-row no-gutters align="center">
                                  <v-col cols="auto" class="mr-3">
                                    <v-icon v-if="model.name === llmModelName" color="success" small>
                                      mdi-check-circle
                                    </v-icon>
                                    <v-icon v-else :color="canSelectModel(model) ? 'grey lighten-1' : 'grey darken-2'" small>
                                      mdi-checkbox-blank-circle-outline
                                    </v-icon>
                                  </v-col>
                                  <v-col>
                                    <v-row no-gutters align="center">
                                      <v-col cols="12" sm="4">
                                        <v-card-title class="text-h6 d-flex align-center">
                                          {{ model.name }}
                                          <v-chip
                                            v-if="model.type === 'Pro'"
                                            x-small
                                            :color="canSelectModel(model) ? 'primary' : 'grey'"
                                            class="ml-2"
                                          >
                                            Premium
                                          </v-chip>
                                          <v-tooltip v-if="!canSelectModel(model)" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                small
                                                color="warning"
                                                class="ml-2"
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                mdi-lock
                                              </v-icon>
                                            </template>
                                            <span>Please login to access this model</span>
                                          </v-tooltip>
                                        </v-card-title>
                                      </v-col>
                                      <v-col cols="12" sm="8" class="d-flex align-center">
                                        <v-chip
                                          v-for="(capability, index) in model.capabilities.slice(0, 2)"
                                          :key="'cap-' + index"
                                          class="mr-2"
                                          small
                                        >
                                          {{ capability }}
                                        </v-chip>
                                        <v-chip v-if="model.capabilities.length > 2" small>
                                          +{{ model.capabilities.length - 2 }}
                                        </v-chip>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-card-text>
                                  <p>{{ model.description }}</p>
                                  <v-chip
                                    v-for="(capability, index) in model.capabilities"
                                    :key="'cap-' + index"
                                    class="mr-2 mb-2"
                                    small
                                  >
                                    {{ capability }}
                                  </v-chip>
                                  <p class="mt-3 font-weight-bold">Cons:</p>
                                  <ul>
                                    <li v-for="(con, index) in model.cons" :key="'con-' + index">
                                      {{ con }}
                                    </li>
                                  </ul>
                                </v-card-text>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>
                        <v-subheader>
                          <v-icon left small>mdi-white-balance-iridescent</v-icon>
                          LLM Temperature
                          <v-tooltip right max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                          </template>
                          <v-card class="pa-4">
                            <h3 class="headline mb-2">
                              <v-icon left>mdi-white-balance-iridescent</v-icon>
                              LLM Temperature</h3>
                            <v-divider class="mb-3"></v-divider>
                            <p class="body-2 mb-2">Controls the randomness in the model's output:</p>
                            <ul class="body-2 pl-4 mb-3">
                              <li><strong>Lower values (0-0.5):</strong> More focused, deterministic responses</li>
                              <li><strong>Higher values (0.5-1):</strong> Increased creativity and variability</li>
                            </ul>
                            <p class="body-2 mb-2"><strong>Impact:</strong></p>
                            <ul class="body-2 pl-4">
                              <li>Affects the diversity and predictability of responses</li>
                              <li>Influences the balance between accuracy and creativity</li>
                            </ul>
                            <v-divider class="my-3"></v-divider>
                            <p class="caption font-italic">
                              Adjust based on whether you need consistent, factual responses or more creative, varied outputs.
                            </p>
                          </v-card>
                        </v-tooltip>
                        </v-subheader>
                        <v-slider
                          v-model="llmTemperature"
                          min="0"
                          max="1"
                          step="0.01"
                          thumb-label="always"
                          @input="updateTemperature"
                          color="success"
                          thumb-color="tips"
                        ></v-slider>
                        <!-- LLM Prompt Section -->
                        <v-subheader>
                          <v-icon left small>mdi-arrow-decision-outline</v-icon>
                          LLM Custom Prompt
                          <v-tooltip right max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                            </template>
                            <v-card class="pa-4">
                              <h3 class="headline mb-2">
                                <v-icon left>mdi-arrow-decision-outline</v-icon>
                                LLM Custom Prompt</h3>
                              <v-divider class="mb-3"></v-divider>
                              <p class="body-2 mb-2">Customize the initial/System prompt given to the language model:</p>
                              <ul class="body-2 pl-4 mb-3">
                                <li><strong>Purpose:</strong> Sets the context and behavior for the AI</li>
                                <li><strong>Impact:</strong> Influences the style, tone, and focus of responses</li>
                              </ul>
                              <p class="body-2 mb-2"><strong>Tips:</strong></p>
                              <ul class="body-2 pl-4">
                                <li>Be clear and specific about the desired output</li>
                                <li>Include any necessary context or constraints</li>
                                <li>Consider the model's capabilities and limitations</li>
                              </ul>
                              <v-divider class="my-3"></v-divider>
                              <p class="caption font-italic">
                                Choose between the default UnStruct prompt or customize your own to tailor the AI's responses.
                              </p>
                            </v-card>
                          </v-tooltip>
                        </v-subheader>

                        <!-- Prompt Selection Switch -->
                        <v-switch
                          v-model="useDefaultPrompt"
                          :label="`Use ${useDefaultPrompt ? 'Default' : 'Custom'} Prompt`"
                          @change="updateUseDefaultPrompt"
                        ></v-switch>

                        <!-- Only show PersonalitySelector when custom prompt is selected -->
                        <personality-selector
                          v-if="!useDefaultPrompt"
                          @prompt-set="updatePrompt"
                        ></personality-selector>

                        <!-- Default Prompt (non-editable) -->
                        <v-textarea
                          v-if="useDefaultPrompt"
                          :value="defaultPrompt"
                          outlined
                          rows="3"
                          readonly
                          label="Default Prompt"
                        ></v-textarea>

                        <!-- Custom Prompt (editable) -->
                        <v-textarea
                          v-else
                          v-model="customPrompt"
                          outlined
                          rows="3"
                          label="Custom Prompt"
                          placeholder="Enter custom prompt for the LLM"
                        ></v-textarea>

                        <!-- Apply Prompt Button -->
                        <v-btn
                          @click="applyPrompt"
                          color="primary"
                          class="mt-3"
                          :disabled="useDefaultPrompt"
                        >
                          Apply Prompt
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item key="llm">
              <v-card flat>
                <v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>LLM Configuration</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="llm-model-selection">
                          <v-subheader>
                            <v-icon left small>mdi-molecule</v-icon>
                            LLM Model Selection
                            <v-tooltip right max-width="300">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                                </template>
                                <v-card class="pa-4">
                                  <h3 class="headline mb-2">
                                    <v-icon left>mdi-atom</v-icon>
                                    Language Model Selection</h3>
                                    <v-divider class="mb-3"></v-divider>
                                    <p class="body-2 mb-2">Choose the language model to use for generating responses:</p>
                                    <ul class="body-2 pl-4 mb-3">
                                      <li><strong>Larger models:</strong> More capable, potentially slower</li>
                                      <li><strong>Smaller models:</strong> Faster, may have limited capabilities</li>
                                    </ul>
                                    <p class="body-2 mb-2"><strong>Considerations:</strong></p>
                                    <ul class="body-2 pl-4">
                                      <li>Context Window Size (e.g. 128k tokens for OpenAI, 200k tokens for Claude, Etc.)</li>
                                      <li>Task complexity and required expertise</li>
                                      <li>Response time requirements</li>
                                      <li>Resource availability and costs</li>
                                    </ul>
                                    <v-divider class="my-3"></v-divider>
                                    <p class="caption font-italic">
                                      Select based on your specific needs for performance, capability, and efficiency.
                                    </p>
                                </v-card>
                            </v-tooltip>
                          </v-subheader>
                          <v-expansion-panels>
                            <v-expansion-panel
                              v-for="model in llmModels"
                              :key="model.name"
                              :class="{ 'selected': model.name === llmModelName, 'pro-model': model.type === 'Pro' }"
                            >
                            <v-expansion-panel-header
                                @click="handleModelSelection(model)"
                                :disabled="!canSelectModel(model)"
                                :class="{'pro-disabled': !canSelectModel(model)}"
                              >
                                <v-row no-gutters align="center">
                                  <v-col cols="auto" class="mr-3">
                                    <v-icon v-if="model.name === llmModelName" color="success" small>
                                      mdi-check-circle
                                    </v-icon>
                                    <v-icon v-else :color="canSelectModel(model) ? 'grey lighten-1' : 'grey darken-2'" small>
                                      mdi-checkbox-blank-circle-outline
                                    </v-icon>
                                  </v-col>
                                  <v-col>
                                    <v-row no-gutters align="center">
                                      <v-col cols="12" sm="4">
                                        <v-card-title class="text-h6 d-flex align-center">
                                          {{ model.name }}
                                          <v-chip
                                            v-if="model.type === 'Pro'"
                                            x-small
                                            :color="canSelectModel(model) ? 'primary' : 'grey'"
                                            class="ml-2"
                                          >
                                            Premium
                                          </v-chip>
                                          <v-tooltip v-if="!canSelectModel(model)" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                small
                                                color="warning"
                                                class="ml-2"
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                mdi-lock
                                              </v-icon>
                                            </template>
                                            <span>Please login to access this model</span>
                                          </v-tooltip>
                                        </v-card-title>
                                      </v-col>
                                      <v-col cols="12" sm="8" class="d-flex align-center">
                                        <v-chip
                                          v-for="(capability, index) in model.capabilities.slice(0, 2)"
                                          :key="'cap-' + index"
                                          class="mr-2"
                                          small
                                        >
                                          {{ capability }}
                                        </v-chip>
                                        <v-chip v-if="model.capabilities.length > 2" small>
                                          +{{ model.capabilities.length - 2 }}
                                        </v-chip>
                                      </v-col>

                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-card-text>
                                  <p>{{ model.description }}</p>
                                  <v-chip
                                    v-for="(capability, index) in model.capabilities"
                                    :key="'cap-' + index"
                                    class="mr-2 mb-2"
                                    small
                                  >
                                    {{ capability }}
                                  </v-chip>
                                  <p class="mt-3 font-weight-bold">Cons:</p>
                                  <ul>
                                    <li v-for="(con, index) in model.cons" :key="'con-' + index">
                                      {{ con }}
                                    </li>
                                  </ul>
                                </v-card-text>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>
                        <v-subheader>
                          <v-icon left small>mdi-white-balance-iridescent</v-icon>
                          LLM Temperature
                          <v-tooltip right max-width="300">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                          </template>
                          <v-card class="pa-4">
                            <h3 class="headline mb-2">
                              <v-icon left>mdi-white-balance-iridescent</v-icon>
                              LLM Temperature</h3>
                            <v-divider class="mb-3"></v-divider>
                            <p class="body-2 mb-2">Controls the randomness in the model's output:</p>
                            <ul class="body-2 pl-4 mb-3">
                              <li><strong>Lower values (0-0.5):</strong> More focused, deterministic responses</li>
                              <li><strong>Higher values (0.5-1):</strong> Increased creativity and variability</li>
                            </ul>
                            <p class="body-2 mb-2"><strong>Impact:</strong></p>
                            <ul class="body-2 pl-4">
                              <li>Affects the diversity and predictability of responses</li>
                              <li>Influences the balance between accuracy and creativity</li>
                            </ul>
                            <v-divider class="my-3"></v-divider>
                            <p class="caption font-italic">
                              Adjust based on whether you need consistent, factual responses or more creative, varied outputs.
                            </p>
                          </v-card>
                        </v-tooltip>
                        </v-subheader>
                        <v-slider
                          v-model="llmTemperature"
                          min="0"
                          max="1"
                          step="0.01"
                          thumb-label="always"
                          @input="updateTemperature"
                          color="success"
                          thumb-color="tips"
                        ></v-slider>
                        <!-- LLM Prompt Section -->
                        <!-- LLM Prompt Section -->
                        <v-subheader>
                          <v-icon left small>mdi-arrow-decision-outline</v-icon>
                          LLM Custom Prompt
                          <v-tooltip right max-width="300">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon small v-bind="attrs" v-on="on" class="ml-1">mdi-information-outline</v-icon>
                            </template>
                            <v-card class="pa-4">
                              <h3 class="headline mb-2">
                                <v-icon left>mdi-arrow-decision-outline</v-icon>
                                LLM Custom Prompt</h3>
                              <v-divider class="mb-3"></v-divider>
                              <p class="body-2 mb-2">Customize the initial/System prompt given to the language model:</p>
                              <ul class="body-2 pl-4 mb-3">
                                <li><strong>Purpose:</strong> Sets the context and behavior for the AI</li>
                                <li><strong>Impact:</strong> Influences the style, tone, and focus of responses</li>
                              </ul>
                              <p class="body-2 mb-2"><strong>Tips:</strong></p>
                              <ul class="body-2 pl-4">
                                <li>Be clear and specific about the desired output</li>
                                <li>Include any necessary context or constraints</li>
                                <li>Consider the model's capabilities and limitations</li>
                              </ul>
                              <v-divider class="my-3"></v-divider>
                              <p class="caption font-italic">
                                Choose between the default UnStruct prompt or customize your own to tailor the AI's responses.
                              </p>
                            </v-card>
                          </v-tooltip>
                        </v-subheader>

                        <!-- Prompt Selection Switch -->
                        <v-switch
                          v-model="useDefaultPrompt"
                          :label="`Use ${useDefaultPrompt ? 'Default' : 'Custom'} Prompt`"
                          @change="updateUseDefaultPrompt"
                        ></v-switch>

                        <!-- Only show PersonalitySelector when custom prompt is selected -->
                        <personality-selector
                          v-if="!useDefaultPrompt"
                          @prompt-set="updatePrompt"
                        ></personality-selector>

                        <!-- Default Prompt (non-editable) -->
                        <v-textarea
                          v-if="useDefaultPrompt"
                          :value="defaultPrompt"
                          outlined
                          rows="3"
                          readonly
                          label="Default Prompt"
                        ></v-textarea>

                        <!-- Custom Prompt (editable). -->
                        <v-textarea
                          v-else
                          v-model="customPrompt"
                          outlined
                          rows="3"
                          label="Custom Prompt"
                          placeholder="Enter custom prompt for the LLM"
                        ></v-textarea>

                        <!-- Apply Prompt Button -->
                        <v-btn
                          @click="applyPrompt"
                          color="primary"
                          class="mt-3"
                          :disabled="useDefaultPrompt"
                        >
                          Apply Prompt
                        </v-btn>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="tune-answer-btn"
            @click="handleRefineAnswer"
            :disabled="!isValidRefineState"
            color="primary"
          >
          <v-icon left>
            {{ isRelatedQuestion ? 'mdi-connection' : 'mdi-database-settings-outline' }}
          </v-icon>
            <span>{{ isRelatedQuestion ? 'Ask Related' : 'Tune Answer' }}</span>
          </v-btn>
          <v-btn color="success" text @click="closeSettingsDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </transition>
    </v-dialog>

    <!-- Objects Dialog -->
    <template>
      <v-dialog v-model="isObjectsDialogOpen" max-width="90%" max-height="90%">
        <v-card>
          <v-card-title class="d-flex justify-space-between align-center">
            <span class="headline">
              <v-icon large left color="success">mdi-image-filter-center-focus</v-icon>
              Select Object to Chat
            </span>
            <v-btn icon @click="closeObjectsDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p class="mb-4">
              Search for and select Interactive Active Data Objects (IAOs) to engage in chat interactions.
              IAOs are dynamic data entities that you can converse with, query, and interact in real-time.
            </p>
            <v-alert
              type="info"
              colored-border
              border="left"
              class="mb-4"
            >
              <v-icon left>mdi-information-outline</v-icon>
              <strong>Note:</strong> Some objects require a pro team plan to activate.
            </v-alert>
            <OldSchoolSearchEntryPage @object-selected="handleObjectSelected" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <!-- Search Results -->
    <v-dialog v-model="searchResultsDialog" max-width="1000px">
      <v-card class="search-results-dialog entity-container">
        <v-card-title class="headline">
          <v-icon left color="primary">mdi-image-filter-center-focus</v-icon>
          Additional Sources
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col v-for="result in dialogSearchResults" :key="result.id" cols="12" sm="6" md="4" lg="3">
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 8 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="openSearchResultLink(result.url)"
                >
                  <v-card-text class="d-flex align-center">
                    <v-avatar :color="getRandomColor()" size="40" class="mr-3">
                      <v-icon dark>{{ getRandomIcon().name }}</v-icon>
                    </v-avatar>
                    <span class="source-text">{{ result.name }}</span>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#004bfb" text @click="searchResultsDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="1000px">
      <v-card class="entity-dialog entity-container">
        <v-card-title class="headline">
          <v-icon left color="primary">mdi-image-filter-center-focus</v-icon>
          Additional Sources
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col v-for="entity in dialogEntities" :key="entity.id" cols="12" sm="6" md="4" lg="3">
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 8 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="openEntityLink(entity.url)"
                >
                  <v-card-text class="d-flex align-center">
                    <v-avatar :color="getRandomColor()" size="40" class="mr-3">
                      <v-icon dark>{{ getRandomIcon().name }}</v-icon>
                    </v-avatar>
                    <div class="flex-grow-1">
                      <div class="entity-name">{{ entity.name }}</div>
                      <div class="entity-count">
                        {{ entity.count }}
                        {{ parseInt(entity.count) === 1 ? 'occurrence' : 'occurrences' }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <organization-create-edit-dialog />
    <galaxy-create-dialog @dialog-closed="onGalaxyDialogClosed" />
    <SettingsPopup v-model="showSettingsPopup" :settings="selectedSettings" />
  </div>
  <!-- End of div id chat-->
</template>


<script>
const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB
const MAX_TEXT_CHARACTERS = 4000000; // 4 million characters (Gemini LLM limit)
const MAX_TOKEN_FOR_RAG_VIEW = 150000;
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import ConceptMap from './ConceptMap.vue';

import { debounce } from "lodash";

import OldSchoolSearchEntryPage from "@/incident/OldSchoolSearchEntryPage.vue";
import AnswerGenerationProcess from './AnswerGenerationProcess.vue';
import UserLimitsDisplay from '@/user_limits/UserLimitsDisplay.vue';
import SubmissionsStatus from '@/user_submissions/SubmissionsStatus.vue';
import LikedMessagesProcessingStatus from '@/user_interactions/LikedMessagesProcessingStatus.vue';
import AnswerSettingsBox from './AnswerSettingsBox.vue';
import JobUpdatesFeed from './JobUpdatesFeed.vue';
import UniverseApi from "@/organization/api"
import CitationRenderer from './CitationRenderer.vue';
import OrganizationCreateEditDialog from "@/organization/CreateEditDialog.vue"
import GalaxyCreateDialog from "@/project/GalaxyCreateDialog.vue"
import RagStrategyExplainer from './RagStrategyExplainer.vue'
import PersonalitySelector from './PersonalitySelector.vue';
import IaoObjects from './IaoObjects.vue';
import CombinedSettingsDialog from './CombinedSettingsDialog.vue';
import RagTokensContainer from './RagTokensContainer.vue';
import HelpMenu from './HelpMenu.vue';
import SettingsPopup from './SettingsPopup.vue'
import FloatingActionBox from './FloatingActionBox.vue';
import SearchResultsAnimation from './SearchResultsAnimation.vue'
import RagStrategySelector from './RagStrategySelector.vue'
import ModernLanding from './ModernLanding.vue';
import FeatureGrid from './FeatureGrid.vue';
import ResourcesManagerIcon from './custom_icons/ResourcesManagerIcon.vue';
import NewGalaxyIcon from './custom_icons/NewGalaxyIcon.vue';
import DOMPurify from 'dompurify';
import EntityResultsAnimation from './EntityResultsAnimation.vue';


const allowedTags = ['p', 'br', 'strong', 'em', 'u', 'ol', 'ul', 'li', 'a', 'code', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'sup', 'sub', 'blockquote', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'div', 'span', 'hr'];

const allowedAttributes = {
  'a': ['href', 'target'],
  'code': ['class'],
  'pre': ['class'],
  'span': [
    'class',
    'data-citation',
    'data-metadata',
    'data-source-text',
    'role',
    'tabindex'
  ]
};

const sanitizeConfig = {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: allowedAttributes,
    ADD_ATTR: ['class'],
    ADD_TAGS: ['citation-renderer'],
    RETURN_DOM: false,
    WHOLE_DOCUMENT: false,
    FORCE_BODY: false,
    KEEP_CONTENT: true,
};

// Optimize the hook
DOMPurify.addHook('uponSanitizeAttribute', (node, data) => {
    if (data.attrName === 'class' && data.attrValue?.includes('citation-link')) {
        data.forceKeep = true;
    }
});

export default {
  name: 'Chat',
  inject: ['updateTitle'],
  components: {
    ConceptMap,
    OldSchoolSearchEntryPage,
    AnswerGenerationProcess,
    UserLimitsDisplay,
    SubmissionsStatus,
    LikedMessagesProcessingStatus,
    AnswerSettingsBox,
    JobUpdatesFeed,
    CitationRenderer,
    OrganizationCreateEditDialog,
    GalaxyCreateDialog,
    RagStrategyExplainer,
    PersonalitySelector,
    IaoObjects,
    CombinedSettingsDialog,
    RagTokensContainer,
    HelpMenu,
    SettingsPopup,
    FloatingActionBox,
    SearchResultsAnimation,
    RagStrategySelector,
    ModernLanding,
    FeatureGrid,
    ResourcesManagerIcon,
    NewGalaxyIcon,
    EntityResultsAnimation,
  },
  data() {
    return {
      processingLikes: [],
      features: [
        {
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M21 11c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12V5l9-4 9 4v6z"/></svg>',
          title: 'Fact Verification',
          description: 'Real-time AI hallucination detection'
        },
        {
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="M12 16v-4M12 8h.01"/></svg>',
          title: 'Smart Research',
          description: 'Context-aware information gathering'
        },
        {
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M22 12h-4l-3 9L9 3l-3 9H2"/></svg>',
          title: 'Deep Analysis',
          description: 'Advanced pattern recognition'
        },
        {
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/></svg>',
          title: 'Data Integration',
          description: 'Seamless knowledge connection'
        }
      ],
      isFeedMini: true,
      timerSeconds: 0,
      timerInterval: null,
      isWaitingForResponse: false,
      jobUpdatesFeedVisible: false,
      sensitivityLevels: [
        { text: 'Internal', value: 'Internal' },
        { text: 'Confidential', value: 'Confidential' },
      ],
      sensitivityLevel: 'Internal',
      defaultSensitivityLevel: 'Internal',
      showSettingsPopup: false,
      selectedSettings: null,
      justSpin: true,
      cancelLoading: false,
      showPromptMenu: false,
      cosmicHierarchy: [
        {
          text: this.currentUniverseName,
          icon: 'mdi-star-four-points-outline',
          description: 'A Universe is a collection of Galaxies and Planets.'
        },
        {
          text: this.currentGalaxyName,
          icon: 'mdi-creation',
          description: 'A Galaxy is a category or topic within your Universe.'
        },
        {
          text: this.currentProjectName,
          icon: 'mdi-earth',
          description: 'A Planet represents a specific project or task.'
        }
      ],
      statusMessage: "Thinking...",
      hasProcessingJobs: false,
      processingJobCount: 0,
      inputFocused: true,
      menuOpen: false,
      offsetX: -100,
      offsetY: 25,
      jobCount: 0,
      previousJobCount: 0,
      isExpanded: false,
      isRagExpanded: false,
      expandedSelector: null,
      missionText: "Verify AI accuracy, customize searches, explore diverse sources, organize large-scale insights, and optimize context handling - all tailored to your specific needs.",
      missionDisplayedText: "",
      showMissionStatement: true,
      textWidth: 0,
      missionTypingComplete: false,
      tutorialParagraphs: [
        { text: "💡 Ask a question (or paste any link).", displayedText: "" },
        { text: "🔧 Fine tune your answer using a wide range of customizable settings.", displayedText: "" },
        { text: "🔍 Pay attention to the context (or Active Object) you're interacting with.", displayedText: "" },
        { text: "🚀 Take your time and enjoy the journey!", displayedText: "" },
      ],
      typingSpeed: 20,
      currentParagraph: 0,
      tutorialTypingComplete: false,
      showCancelDialog: false,
      iconOptions: [
        { name: 'mdi-web', color: '#004bfb' },
        { name: 'mdi-newspaper', color: '#ff6b6b' },
        { name: 'mdi-book-open-page-variant', color: '#feca57' },
        { name: 'mdi-file-document-outline', color: '#5f27cd' },
        { name: 'mdi-chart-bar', color: '#54a0ff' },
        { name: 'mdi-video', color: '#ff9ff3' },
        { name: 'mdi-image', color: '#5ed4f3' },
        { name: 'mdi-format-list-bulleted', color: '#ff9f43' },
        { name: 'mdi-atom-variant', color: '#ff9f43' },
        { name: 'mdi-atom', color: '#ff9f43' }
      ],
      isScrollLocked: false,
      minTempSpaceHeight: 0,
      maxTempSpaceHeight: 600,
      isUploading: false,
      cosmicMode: this.getCosmicModeFromStorage(),
      darkMode: this.getDarkModeFromStorage(),
      rag_strategy: 'web_search',
      previous_rag_strategy: 'web_search',
      ragStrategies: [
        { value: 'ecrag', text: 'ECRAG', icon: 'mdi-atom-variant' },
        { value: 'llm_passthrough', text: 'LLM Passthrough', icon: 'mdi-atom' },
        { value: 'web_search', text: 'Web Search', icon: 'mdi-web' },
      ],
      currentMessage: null,
      currentQuery: null,
      universes: [],
      ragContent: '',
      showRagContent: false,
      selectedText: '',
      selectedFloatingText: '',
      lastProcessedUserInput: '',
      latestUserInput: '',
      ragTokenCount: 0,
      ragType: 'ECRAG',
      showSparks: false,
      previousRagTokenCount: 0,
      MAX_TEXT_CHARACTERS: MAX_TEXT_CHARACTERS,
      showLimits: false,
      isConnected: false,
      reconnectAttempts: 0,
      maxReconnectAttempts: 3,
      reconnectDelay: 60000, // 1 minute
      showScrollButton: false,
      projectSearch: '',
      createProjectValid: true,
      submitTextValid: true,
      buildIndex: false,
      fileUploadValid: true,
      isYouTubeSubmitDialogOpen: false,
      youtubeUrls: '',
      isSubmittingYoutube: false,
      submitYouTubeValid: false,
      projects: [],
      openedNodes: [],
      searchResultsDialog: false,
      dialogSearchResults: [],
      isProjectsDialogOpen: false,
      newProjectParentId: null,
      selectedProject: null,
      selectedFile: null,
      selectedFiles: [],
      imageUploadValid: false,
      isFileUploadDialogOpen: false,
      isImageUploadDialogOpen: false,
      isCreateProjectDialogOpen: false,
      newProjectName: '',
      isHovered: false,
      isResponseInProgress: false,
      lastScrollPosition: 0,
      isObjectsDialogOpen: false,
      isSubmitTextDialogOpen: false,
      showSubmissionsDialog: false,
      showLikedMessagesProcessingDialog: false,
      submittedText: "",
      submittedTextName: "",
      objects: [
        { name: 'Incident' },
        { name: 'Commit' },
        { name: 'Case' },
        { name: 'Signal' },
        { name: 'Ticket' },
        { name: 'Document' },
        { name: 'Conversation' },
        { name: 'Conference' },
        { name: 'Task' },
        { name: 'Source' },
        { name: 'Individual' },
        { name: 'Website' },
        { name: 'Other' },
      ],
      messages: [],
      currentPage: 1,
      isLoadingMoreMessages: false,
      hasMoreMessages: true,
      scrollThreshold: 100,
      lastLoadedScrollHeight: 0,
      userInput: "",
      isBotTyping: false,
      socket: null,
      typingInterval: null,
      isScrollingEnabled: true,
      isAutoScrolling: true,
      isFinalAnswer: false,
      isSettingsDialogOpen: false,
      isRelatedQuestion: false,
      activeTab: 'ecrag',
      dialog: false,
      dialogEntities: [],
      parsedRelationships: [],
      llmModels: [
        {
          name: "gpt-4o",
          type: "Pro",
          description: "Latest GPT-4 model with enhanced capabilities.",
          capabilities: ["128k Context", "Versatile", "High Performance"],
          cons: ["Expensive", "Slower than simpler models", "May be overkill for basic tasks"]
        },
        {
          name: "gpt-4o-mini",
          description: "Compact version of GPT-4 for faster processing.",
          capabilities: ["128k Context", "Fast", "Efficient", "Good for simpler tasks"],
          cons: ["Less capable than full GPT-4", "May struggle with complex queries", "Limited performance"]
        },
        {
          name: "gpt-4o-2024-08-06",
          type: "Pro",
          description: "Specific version of GPT-4 from August 2024.",
          capabilities: ["128k Context", "Stable", "Consistent", "Well-documented"],
          cons: ["Potentially outdated", "Fixed capabilities", "May lack latest improvements"]
        },
        {
          name: "claude-3-haiku-20240307",
          description: "Lightweight Claude model for quick responses.",
          capabilities: ["200k Context", "Very Fast", "Low Resource Usage", "Good for short queries"],
          cons: ["Limited complexity handling", "Formatting can be flaky", "Struggles with out-of-context RAG"]
        },
        {
          name: "claude-3-5-sonnet-20240620",
          type: "Pro",
          description: "Balanced Claude model with good all-round performance.",
          capabilities: ["200k Context", "Balanced", "Versatile", "Good for most tasks"],
          cons: ["Expensive", "Jack of all trades; master of none", "May underperform specialized models in specific tasks"]
        },
        {
          name: "claude-3-sonnet-20240229",
          type: "Pro",
          description: "Earlier version of Claude 3 Sonnet.",
          capabilities: ["200k Context", "Robust", "Well-tested", "Consistent performance"],
          cons: ["Older version", "May lack latest improvements", "Potentially less capable than newer models"]
        },
        {
          name: "claude-3-opus-20240229",
          type: "Pro",
          description: "Most powerful Claude model for complex tasks.",
          capabilities: ["200k Context", "High Capability", "Best for complex queries"],
          cons: ["Resource-intensive", "Slower response times", "Overkill for simple tasks", "Potentially more expensive"]
        },
        {
          name: "gemini-1.5-flash",
          description: "Fast and versatile performance across a diverse variety of tasks.",
          capabilities: ["1M Context", "Very Fast", "Low Resource Usage", "High-frequency tasks"],
          cons: ["Limited complexity handling", "May provide superficial answers", "May not be suitable for in-depth analysis"]
        },
        {
          name: "gemini-1.5-pro",
          type: "Pro",
          description: "Complex reasoning tasks such as code and text generation, text editing, problem solving, data extraction and generation.",
          capabilities: ["2M Context", "Boost in performance", "Good for most tasks"],
          cons: ["Jack of all trades, master of none", "May underperform specialized models in specific tasks", "Expensive"]
        },
      ],
      llmModelNames: [
        "gpt-4o",
        "gpt-4o-mini",
        "gpt-4o-2024-08-06",
        "claude-3-haiku-20240307",
        "claude-3-5-sonnet-20240620",
        "claude-3-sonnet-20240229",
        "claude-3-opus-20240229",
        "gemini-1.5-flash",
        "gemini-1.5-pro",
      ],
      tempMessageTimeout: null,
      relationshipMatchThreshold: 0.4,
      relatedEntitiesMatchThreshold: 0.4,
      llmModelName: "gemini-1.5-flash",
      llmTemperature: 0.5,
      lengthFactor: 0.03,
      useDefaultPrompt: true,
      defaultPrompt: "...[UNSTRUCT AI DEFAULT PROMPT]...",
      customPrompt: "You are a helpful AI assistant.",
      topEntitiesThreshold: 500,
      ragTokenThreshold: 10000,
      relationshipsDisplayLimit: 0,
      build_personal_index_on_every_query: true,
      google_search_results_count: 10,
      google_search_skip_top_results: false,
      google_search_skip_top_num: 10,
      google_search_low_range: null,
      google_search_high_range: null,
      use_google_search_for_rag: false,
      site_searches: [],
      lowRangeMenu: false,
      highRangeMenu: false,
    };
  },

  watch: {
    jobCount: {
      handler(newValue, oldValue) {
        if (newValue > oldValue) {
          this.jobCount = newValue;
          this.menuOpen = true;
          this.setAutoCloseTimer();
        }
      }
    },
    currentGalaxyName: {
      immediate: true,
      handler(newValue) {
        const newTitle = `${this.currentUniverseName} / ${newValue}`;
        this.updateTitle(newTitle);
        this.updateCurrentUniverse(this.currentUniverseName);
        this.updateCurrentGalaxy(newValue);
        this.focusInput();
      }
    },
    currentUniverseName: {
      immediate: true,
      handler(newValue) {
        const newTitle = `${newValue} / ${this.currentGalaxyName}`;
        this.updateTitle(newTitle);
        this.updateCurrentUniverse(newValue);
        this.updateCurrentGalaxy(this.currentGalaxyName);
        this.focusInput();
      }
    },
    rag_strategy(newStrategy, oldStrategy) {
      if (newStrategy === 'web_search' && this.hasLiveObject) {
        this.rag_strategy = 'web_search';
        this.previous_rag_strategy = oldStrategy;
        this.$set(this, 'rag_strategy', 'web_search');
        this.updateRAGStrategy();
        // Disable IAO prepend
        this.$store.commit('websocket/RESET_ACTIVE_OBJECT');
      }
    },

    hasLiveObject(newValue) {
      if (newValue && this.rag_strategy === 'web_search') {
        this.previous_rag_strategy = this.rag_strategy;
        // Switch to llm_pass if Web was selected and an object becomes active
        this.rag_strategy = 'llm_passthrough';
        this.$set(this, 'rag_strategy', 'llm_passthrough');
        this.updateRAGStrategy();
      }  else if (!newValue && this.rag_strategy === 'llm_passthrough') {
        // Switch back to previous strategy if object is deactivated
        this.rag_strategy = this.previous_rag_strategy;
        this.updateRAGStrategy();
      }
    },
    messages: {
      handler() {
        this.$nextTick(() => {
          this.checkScrollPosition();
        });
      },
      deep: true
    },
    userinput() {
      this.debouncedAdjustTextareaHeight();
      this.debouncedScrollToBottom();
    }
  },

  computed: {
    ...mapState("auth", ["currentUser", "currentProject", "currentOrganization", 'loading', 'oauthLoading']),
    ...mapState('user_limits', ['limits']),
    ...mapState('websocket', ['activeObject', 'objectId', 'shouldPrependBAO', 'tempMessage', 'showTempMessage', 'user','subscriptionLoading', 'activeObjectContent']),
    ...mapState('project', ['galaxies']),
    filteredMessages() {
      return this.messages.filter(msg => this.hasContent(msg));
    },
    availableStrategies() {
      // Filter strategies based on user status. Return all for all for now.
      return this.ragStrategies;
    },
    ...mapFields('websocket', [
            'showTempMessage',
            'tempMessage',
            'shouldPrependBAO',
            'activeObject',
            'objectId',
            'user',
        ]),
    ...mapFields("project", [
      "selected.name",
      "selected.description",
      "selected.color",
    ]),
    messagesContainerClasses() {
      return {
        'feed-mini': this.isFeedMini,
        'rag-content-visible': this.showRagContent,
      };
    },
    upgradeNote() {
      if (this.user.is_pro_team) {
        return ''; // No upgrade available
      } else if (this.user.is_pro) {
        return ' (Upgrade to Pro Team for up to 50)';
      } else {
        return ' (Upgrade to Pro for up to 10, or Pro Team for up to 50)';
      }
    },
    maxSimultaneousUploads() {
      if (this.user.is_pro_team) {
        return 50;
      } else if (this.user.is_pro) {
        return 10;
      } else {
        return 2; // Default limit for non-pro users
      }
    },
    currentModelName() {
      return this.llmModelName;
    },
    currentModel() {
      return this.llmModels.find(m => m.name === this.llmModelName);
    },
    jobsTooltipText() {
      if (this.hasProcessingJobs) {
        return `Processing ${this.processingJobCount} job${this.processingJobCount !== 1 ? 's' : ''}`;
      } else {
        return 'No jobs currently processing';
      }
    },
    getCurrentStrategyIcon() {
      const strategy = this.ragStrategies.find(s => s.value === this.rag_strategy);
      return strategy ? strategy.icon : 'mdi-help-circle-outline';
    },
    getCurrentStrategyText() {
      const strategy = this.ragStrategies.find(s => s.value === this.rag_strategy);
      return strategy ? strategy.text : 'Select RAG Strategy';
    },
    currentProjectName() {
      const project = this.projects.find(p => p.id === this.selectedProject)
      return project ? project.name : 'Select a Planet'
    },
    currentStrategy() {
      return this.ragStrategies.find(s => s.value === this.rag_strategy).text;
    },
    ragStrategyIcon() {
      return this.ragStrategies.find(s => s.value === this.rag_strategy).icon;
    },
    strategyColor() {
      const colors = {
        ecrag: 'primary',
        web_search: 'success',
        llm_passthrough: 'tips'
      };
      return colors[this.rag_strategy];
    },
    strategyTextColor() {
      return this.rag_strategy === 'llm_passthrough' ? 'black' : 'white';
    },
    isValidRefineState() {
      // For related queries
      if (this.currentQuery) {
        return true;
      }

      // For regular messages
      if (this.currentMessage && this.currentMessage.id) {
        // Check if there's a user message before this bot message
        const index = this.messages.findIndex(msg => msg.id === this.currentMessage.id);
        if (index > 0) {
          return this.messages.slice(0, index).some(msg => msg.user !== 'bot');
        }
      }

      return false;
    },
    // both must be met
    hasActiveObject() {
      return this.activeObject && this.objectId;
    },
    // all must be met w/prepend enabled
    hasLiveObject() {
      return this.activeObject && this.objectId && this.shouldPrependBAO;
    },
    chipIcon() {
      if (!this.hasActiveObject) return 'mdi-image-filter-center-focus-weak';
      return this.shouldPrependBAO ? 'mdi-image-filter-center-focus' : 'mdi-focus-auto';
    },
    chipText() {
      if (!this.hasActiveObject) {
        return 'No Focus Object';
      }

      const displayNames = {
        'ECCSProject': 'Planet',
        'YouTubeTranscriptSubmission': 'YouTube',
        'FileSubmission': 'File',
        'TextSubmission': 'Text',
        'WebCrawlSubmission': 'Web',
        'ChatMessage': 'Chat',
      };

      const displayType = displayNames[this.activeObject] || this.activeObject;
      return `${displayType}: ${this.objectId}`;
    },
    currentUniverseName() {
      const universe = this.universes.find(u => u.slug === this.selectedUniverse);
      return universe ? universe.name : 'universe';
    },
    currentGalaxyName() {
      const galaxy = this.galaxies.find(g => g.id === this.selectedGalaxy);
      return galaxy ? galaxy.name : 'galaxy';
    },
    currentPlanetName() {
      const project = this.projects.find(p => p.id === this.selectedProject);
      return project ? project.name : 'planet';
    },
    selectedUniverse: {
      get() {
        return this.currentOrganization;
      },
      set(value) {
        this.SET_CURRENT_ORGANIZATION(value);
      }
    },
    selectedGalaxy: {
      get() {
        return this.currentProject;
      },
      set(value) {
        this.SET_CURRENT_PROJECT(value);
      }
    },
    currentSettings() {
      return {
        relationshipMatchThreshold: this.relationshipMatchThreshold,
        relatedEntitiesMatchThreshold: this.relatedEntitiesMatchThreshold,
        llmModelName: this.llmModelName,
        llmTemperature: this.llmTemperature,
        lengthFactor: this.lengthFactor,
        topEntitiesThreshold: this.topEntitiesThreshold,
        ragTokenThreshold: this.ragTokenThreshold,
        relationshipsDisplayLimit: this.relationshipsDisplayLimit,
        google_search_results_count: this.google_search_results_count,
        google_search_skip_top_results: this.google_search_skip_top_results,
        google_search_skip_top_num: this.google_search_skip_top_num,
        google_search_low_range: this.google_search_low_range,
        google_search_high_range: this.google_search_high_range,
        rag_strategy: this.rag_strategy,
        site_searches: this.site_searches,
        useDefaultPrompt: this.useDefaultPrompt,
        customPrompt: this.customPrompt,
      };
    },
    ragTypeText() {
      return this.ragType
    },
    iconName() {
      const icons = {
        error: 'mdi-alert-circle',
        warning: 'mdi-alert',
        info: 'mdi-information',
        success: 'mdi-check-circle'
      };
      return icons[this.tempMessage.type] || icons.info;
    },
    iconColor() {
      const colors = {
        error: 'red',
        warning: 'orange',
        info: 'blue',
        success: 'green'
      };
      return colors[this.tempMessage.type] || colors.info;
    },
    parentProjectOptions() {
      return this.projects.map(project => ({
        id: project.id,
        name: project.name
      }));
    },
    treeViewProjects() {
      // Convert flat list of projects to a tree structure with full path
      const projectMap = new Map();
      this.projects.forEach(project => {
        projectMap.set(project.id, { ...project, children: [], level: 0, fullPath: [project.name] });
      });

      const rootProjects = [];
      projectMap.forEach(project => {
        if (project.parent_id) {
          const parent = projectMap.get(project.parent_id);
          if (parent) {
            parent.children.push(project);
            project.level = parent.level + 1;
            project.fullPath = [...parent.fullPath, project.name];
          }
        } else {
          rootProjects.push(project);
        }
      });

      // Flatten the tree for v-autocomplete, preserving hierarchy information
      const flattenTree = (nodes, result = []) => {
        nodes.forEach(node => {
          result.push(node);
          if (node.children.length) {
            flattenTree(node.children, result);
          }
        });
        return result;
      };

      return flattenTree(rootProjects);
    },
    filteredProjects() {
      if (!this.projectSearch) {
        return this.projects;
      }
      const search = this.projectSearch.toLowerCase();
      return this.projects.filter(project =>
        project.name.toLowerCase().includes(search) ||
        project.id.toString().includes(search)
      );
    },
  },

  methods: {
    ...mapActions('websocket', ['setActiveObject', 'resetActiveObject', 'resetBAOState', 'toggleBAOPrepend', 'showTempMessageFn', 'fetchUserInfo','updateCurrentGalaxy', 'updateCurrentUniverse', 'upgradePlan']),
    ...mapActions('user_limits', ['fetchLimits', 'receiveLimits']),
    ...mapActions('user_submissions', ['fetchSubmissions', 'receiveSubmissions', 'uploadFile']),
    ...mapActions('user_interactions', ['fetchLikedMessagesProcessing']),
    ...mapActions("organization", ["showCreateEditDialog"]),
    ...mapActions("project", ["createEditShow", 'fetchGalaxies', 'save']),
    ...mapActions("auth", ["logout", "oauthLogin"]),
    ...mapMutations('auth', ['SET_CURRENT_PROJECT', 'SET_CURRENT_ORGANIZATION']),
    ...mapMutations('project', ['SET_SELECTED']),
    hasContent(message) {
      if (!message) return false;

      // Handle user messages
      if (message.user === 'user') {
        return (
          (message.displayText && message.displayText.trim()) ||
          message.iaoReference ||
          (message.content && message.content.trim())
        );
      }

      // Handle bot messages
      return (
        message.typing ||
        (message.content && message.content.trim()) ||
        message.finalAnswer ||
        message.loading ||
        message.error
      );
    },
    isButtonDisabled(message) {
      return this.processingLikes.includes(message.id);
    },
    cycleRagStrategy() {
      const currentIndex = this.availableStrategies.findIndex(s => s.value === this.rag_strategy)
      const nextIndex = (currentIndex + 1) % this.availableStrategies.length
      this.rag_strategy = this.availableStrategies[nextIndex].value
      // Update the RAG strategy
      this.updateRAGStrategy()
    },
    handleFeatureInputAction(text) {
      if (text) {
        this.userInput = text;
        this.sendMessage();
      }
    },
    fetchUserInfoAnonymous() {
      this.socket.send(JSON.stringify({ type: "fetch_user_info" }));
    },
    fetchGalaxiesAnonymous() {
      this.socket.send(JSON.stringify({ type: "fetch_galaxies" }));
    },
    fetchUserSettingsAnonymous() {
      this.socket.send(JSON.stringify({ type: "fetch_user_settings" }));
    },
    handleCitationClick({ citation, sourceText }) {
      if (sourceText) {
        // Use existing selection mechanism
        this.selectedText = sourceText;
      }
    },
    // Add method to highlight clicked citation
    highlightCitation(citation) {
      // Remove previous highlights
      document.querySelectorAll('.citation-marker-active').forEach(el => {
        el.classList.remove('citation-marker-active');
      });

      // Add highlight to clicked citation
      const citationElements = document.querySelectorAll(`[data-citations*="${citation}"]`);
      citationElements.forEach(el => {
        el.classList.add('citation-marker-active');
      });
    },
    handleMiniChange(isMini) {
      this.isFeedMini = isMini;
    },
    clearSelection() {
        this.selectedFloatingText = '';
        this.userInput = '';
    },
    handleChatInputAction(text) {
      if (text) {
        this.userInput = text;
        this.sendMessage();
      }
    },
    handleChatInputUpdate(text) {
      this.selectedFloatingText = text;

      this.$nextTick(() => {
        this.adjustTextareaHeight();
        this.scrollToBottom();

        if (this.$refs.chatInput) {
          this.$refs.chatInput.focus();
          this.$refs.chatInput.dispatchEvent(new Event('input'));
        }
      });
    },
    startResponseTimer() {
      this.timerSeconds = 0;
      this.isWaitingForResponse = true;

      // Clear any existing interval first
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }

      // Start new interval
      this.timerInterval = setInterval(() => {
        this.timerSeconds++;
      }, 1000);
    },

    stopResponseTimer() {
      this.isWaitingForResponse = false;
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },
    toggleSelector(selector) {
      if (this.expandedSelector === selector && this.isExpanded) {
        this.isExpanded = false;
        this.expandedSelector = null;
      } else {
        this.isExpanded = true;
        this.expandedSelector = selector;
      }
    },
    toggleJobUpdatesFeed() {
      this.jobUpdatesFeedVisible = !this.jobUpdatesFeedVisible;
    },
    navigateToMembers() {
      this.$router.push({ name: 'OrganizationMemberTable' });
    },
    navigateToEmailLogin() {
      // Navigate to the login page
      this.$router.push({ name: 'BasicLogin' });
    },
    openSettingsPopup(settings) {
      this.selectedSettings = settings
      this.showSettingsPopup = true
    },
    updateProcessingJobsStatus(status) {
      this.hasProcessingJobs = status;
    },
    updateProcessingJobCount(count) {
      this.processingJobCount = count;
    },
    handleVisibilityChange() {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        this.connect(false); // Don't refetch chat messages
      }
    },
    handleOnline() {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        this.connect(false); // Don't refetch chat messages
      }
    },
    startHeartbeat() {
      this.heartbeatInterval = setInterval(() => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(JSON.stringify({ type: "heartbeat" }));
        }
      }, 5000);
    },
    stopHeartbeat() {
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }
    },
    toggleDarkTheme() {
      this.darkMode = !this.darkMode;
      const newTheme = !this.$vuetify.theme.dark
      this.$vuetify.theme.dark = newTheme
      localStorage.setItem("dark_theme", JSON.stringify(newTheme))

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    triggerMenuOpenAndClose() {
      this.menuOpen = true;
      setTimeout(() => {
        this.menuOpen = false;
      }, 120000);
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen) {
        this.setAutoCloseTimer();
      } else {
        this.clearAutoCloseTimer();
      }
    },
    setAutoCloseTimer() {
      // Clear any existing timer
      if (this.autoCloseTimer) {
        clearTimeout(this.autoCloseTimer);
      }

      // Set a new timer
      this.autoCloseTimer = setTimeout(() => {
        this.menuOpen = false;
      }, 300000); // Close after 5 minutes
    },
    clearAutoCloseTimer() {
      if (this.autoCloseTimer) {
        clearTimeout(this.autoCloseTimer);
      }
    },
    updateJobCount(count) {
      this.previousJobCount = this.jobCount;
      this.jobCount = count;
      if (this.jobCount > this.previousJobCount) {
        this.menuOpen = true;
        this.setAutoCloseTimer();
      }
    },
    shortenText(text) {
      return text.length > 10 ? text.substring(0, 7) + '...' : text;
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.expandedSelector = null;
      }
    },
    toggleRagExpand() {
      this.isRagExpanded = !this.isRagExpanded;
    },
    expandSelector(selector) {
      this.isExpanded = true;
      this.expandedSelector = selector;
    },
    getSubscriptionStatusColor(status) {
      const statusColors = {
        active: 'green',
        canceled: 'orange',
        expired: 'red',
        team_pro: 'green',
      };
      return statusColors[status] || 'grey';
    },
    getSubscriptionStatusText(status) {
      const statusTexts = {
        active: 'Active',
        team_pro: 'Active',
        canceled: 'Canceled',
        expired: 'Expired',
      };
      return statusTexts[status] || 'Unknown';
    },
    showProFeatureHint(feature) {
      this.showTempMessageFn(`Creating a new ${feature} is a pro feature. Explore your knowledge universe with pro!`, 'info');
      this.showTempMessageFn({
        message: `Creating a new ${feature} is a pro feature. Explore your knowledge universe with pro!`,
        type: 'info',
        timeout: 5000
      });
    },
    formatDate(date) {
      if (!date) return 'N/A';
      return new Date(date).toLocaleDateString();
    },
    showCancelConfirmation() {
      this.showCancelDialog = true;
    },
    confirmCancelSubscription() {
      this.cancelLoading = true;
      // Implement the actual cancellation logic here
      this.cancelSubscription().then(() => {
        // Update user info after successful cancellation
        this.fetchUserInfo();
        this.showCancelDialog = false;
      }).catch(error => {
        console.error('Error canceling subscription:', error);
        // Handle error (show error message to user)
      }).finally(() => {
        this.cancelLoading = false;
      });
    },
    async cancelSubscription() {
        try {
            this.cancelLoading = true;
            const host = window.location.host;
            const organization = this.currentOrganization;

            let url;
            let headers = {
                'Content-Type': 'application/json'
            };

            if (this.currentUser?.token) {
                url = `https://${host}/api/v1/${organization}/stripe/cancel-subscription`;
                headers['Authorization'] = `Bearer ${this.currentUser.token}`;
            } else {
                const anonymousKey = localStorage.getItem('unstruct_anonymous_key');
                if (!anonymousKey) {
                    throw new Error('No authentication method available');
                }
                url = `https://${host}/api/v1/${organization}/stripe/anonymous/cancel-subscription/${anonymousKey}`;
            }

            const response = await fetch(url, {
                method: 'POST',
                headers
            });

            if (!response.ok) {
                throw new Error('Failed to cancel subscription. Please try again.');
            }

            const data = await response.json();
            this.fetchUserInfo();
            this.showTempMessageFn({
                message: 'Subscription cancelled successfully.',
                type: 'success',
                timeout: 999999
            });

        } catch (error) {
            console.error('Error cancelling subscription:', error);
            this.showTempMessageFn({
                message: 'Failed to cancel subscription. Please try again. Or contact support@unstruct.ai for assistance.',
                type: 'error',
                timeout: 999999
            });
        } finally {
            this.cancelLoading = false;
        }
    },
    isValidYouTubeUrl(url) {
      const patterns = [
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
        /^(https?:\/\/)?(www\.)?youtu\.be\/[a-zA-Z0-9_-]{11}(\?.*)?$/
      ];
      return patterns.some(pattern => pattern.test(url.trim()));
    },

    extractVideoId(url) {
      const fullUrlMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/);
      if (fullUrlMatch) {
        const videoId = fullUrlMatch[1].split(/[^0-9a-z_-]/i)[0];
        return videoId;
      }
      const shortUrlMatch = url.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})/);
      if (shortUrlMatch) {
        return shortUrlMatch[1];
      }
      return null;
    },

    validateYoutubeUrls(v) {
      const urls = v.split('\n').filter(url => url.trim() !== '');
      const invalidUrls = urls.filter(url => !this.isValidYouTubeUrl(url.trim()));
      if (invalidUrls.length > 0) {
        return `The following URLs are invalid: ${invalidUrls.join(', ')}`;
      }
      return true;
    },
    async submitYouTubeTranscriptsToServer() {
      if (!this.$refs.submitYouTubeForm.validate()) {
        return;
      }
      // Check limits based on user type
      let limitExceeded = false;
      let limitType = 'standard';

      if (this.user.is_pro_team) {
        limitType = 'pro_team';
        limitExceeded = this.limits?.pro_team.youtube_transcript_submissions.used >= this.limits?.pro_team.youtube_transcript_submissions.max;
      } else if (this.user.is_pro) {
        limitType = 'pro';
        limitExceeded = this.limits?.pro.youtube_transcript_submissions.used >= this.limits?.pro.youtube_transcript_submissions.max;
      } else {
        limitExceeded = this.limits?.regular.youtube_transcript_submissions.used >= this.limits?.regular.youtube_transcript_submissions.max;
      }

      if (limitExceeded) {
        this.showTempMessageFn({
          message: `You have reached the maximum number of YouTube transcript submissions allowed for your ${limitType} plan.`,
          type: "error",
          timeout: 5000
        });
        return;
      }
      if (!this.selectedProject || !this.selectedProject.id) {
        this.showTempMessageFn({
          message: "Please select a project.",
          type: "error",
          timeout: 2000
        });
        return;
      }

      const urls = this.youtubeUrls.split('\n').filter(url => url.trim() !== '');
      const validUrls = urls.filter(url => this.isValidYouTubeUrl(url.trim()));

      if (validUrls.length === 0) {
        this.showTempMessageFn({
          message: "No valid YouTube URLs found. Please check your input and try again.",
          type: "error",
          timeout: 5000
        });
        return;
      }

      this.isSubmittingYoutube = true;

      for (const url of validUrls) {
        const videoId = this.extractVideoId(url.trim());
        if (!videoId) {
          this.showTempMessageFn({
            message: `Could not extract video ID from ${url}. Skipping this URL.`,
            type: "warning",
            timeout: 3000
          });
          continue;
        }

        try {
          await this.submitSingleTranscript(videoId);
        } catch (error) {
          console.error(`Error submitting transcript for ${url}:`, error);
          this.showTempMessageFn({
            message: `Failed to submit transcript for ${url}: ${error.message}`,
            type: "error",
            timeout: 5000
          });
        }
      }

      this.youtubeUrls = "";
      this.$refs.submitYouTubeForm.resetValidation();
      this.isYouTubeSubmitDialogOpen = false;
      this.$refs.jobUpdatesFeed.onNewJobSubmitted();
      this.isSubmittingYoutube = false;
      // set active object to the eccs project
      this.setActiveObject({
        type: 'ECCSProject',
        id: this.selectedProject.id
      });
    },

    async submitSingleTranscript(videoId) {
      return new Promise((resolve, reject) => {
        const data = {
          type: 'youtube_transcript_upload',
          videoId: videoId,
          eccs_project_id: this.selectedProject.id,
          project_id: this.selectedGalaxy,
          buildIndex: this.buildIndex,
          sensitivityLevel: this.sensitivityLevel,
        };
        this.socket.send(JSON.stringify(data));

        const handleResponse = (event) => {
          const response = JSON.parse(event.data);
          if (response.type === 'submit_youtube_transcript_response') {
            this.socket.removeEventListener('message', handleResponse);
            if (response.status === 'success') {
              this.showTempMessageFn({
                message: `Transcript for video ${videoId} submitted successfully.`,
                type: "success",
                timeout: 2000
              });
              this.setActiveObject({
                type: 'YouTubeTranscriptSubmission',
                id: response.submission_id
              });
              resolve(response);
            } else {
              reject(new Error(response.message || `Failed to submit transcript for video ${videoId}`));
            }
          }
        };

        this.socket.addEventListener('message', handleResponse);
      });
    },
    showStrategyChangeNotification(oldStrategy, newStrategy) {
      const message = `RAG strategy changed from ${oldStrategy} to ${newStrategy} due to active object selection.`;
      this.showTempMessageFn({
        message: message,
        type: 'info',
        timeout: 5000
      });
    },
    processUserMessage(message) {
      const iaoMatch = message.match(/^\[IAO-(\w+): (\d+)\] (.*)/);
      if (iaoMatch) {
        return {
          displayText: iaoMatch[3],
          iaoReference: {
            type: iaoMatch[1],
            id: iaoMatch[2]
          }
        };
      }
      return {
        displayText: message,
        iaoReference: null
      };
    },
    truncatePrompt(prompt) {
      if (!prompt) return '';
      return prompt.length > 20 ? prompt.substring(0, 17) + '...' : prompt;
    },
    truncateText(text, maxLength) {
      if (!text) return '';
      return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
    },
    getRandomIcon() {
      return this.iconOptions[Math.floor(Math.random() * this.iconOptions.length)];
    },
    getRandomColor() {
      const colors = [
        'indigo darken-2',
        'deep-purple darken-2',
        'blue darken-3',
        'teal darken-2',
        'green darken-3',
        'orange darken-3',
        'deep-orange darken-2',
        'brown darken-2',
        'blue-grey darken-2',
        'red darken-2',
        'pink darken-2'
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    updatePrompt(newPrompt) {
      this.customPrompt = newPrompt;
    },
    applyPrompt() {
      this.updateCustomPrompt();
    },
    getCosmicModeFromStorage() {
      const storedValue = localStorage.getItem('cosmicMode');
      return storedValue !== null ? JSON.parse(storedValue) : true;
    },
    getDarkModeFromStorage() {
      const storedValue = localStorage.getItem('dark_theme');
      return storedValue !== null ? JSON.parse(storedValue) : null;
    },
    applyDarkMode(value) {
      this.darkMode = value;
      localStorage.setItem('dark_theme', JSON.stringify(this.darkMode));
      this.$vuetify.theme.dark = this.darkMode;
    },
    initializeDarkMode() {
      if (this.darkMode === null) {
        // If no value is set, default to dark mode
        this.applyDarkMode(true);
      } else {
        // If a value is set, apply it
        this.$vuetify.theme.dark = this.darkMode;
      }
    },
    toggleCosmicMode() {
      this.cosmicMode = !this.cosmicMode;
      localStorage.setItem('cosmicMode', JSON.stringify(this.cosmicMode));
      this.applyCosmicMode();
    },
    applyCosmicMode() {
      const root = document.documentElement;
      if (this.cosmicMode) {
        root.style.setProperty('--bot-message-bg', '#0a192f');
        root.style.setProperty('--additional-actions-bg', '#0a192f');
      } else {
        root.style.setProperty('--bot-message-bg', '#262626');
        root.style.setProperty('--additional-actions-bg', '#262626');
      }
    },
    typeWriterEffect(message, callback) {
        // Disable animation completely - set full text immediately
        this.$set(message, 'text', message.fullText);
        this.$set(message, 'showActions', true);
        if (callback) callback();
        return;
    },
    getTypeDelay() {
      return Math.random() * 15 + 30; // Random delay between 30ms and 45ms
    },

    scrollToNewMessage() {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        if (container && this.isScrollingEnabled) {
          container.scrollTop = container.scrollHeight - container.clientHeight;
        }
      });
    },
    scrollForUserMessage() {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        const inputBox = this.$refs.chatInput;
        if (container && inputBox) {
          // Calculate the height of the input box plus any margin/padding
          const inputBoxHeight = inputBox.offsetHeight;

          // Calculate the position to scroll to
          const scrollPosition = container.scrollHeight - container.clientHeight + inputBoxHeight;

          // Smooth scroll to the calculated position
          container.scrollTo({
            top: scrollPosition,
            behavior: 'smooth'
          });
        }
      });
    },
    // This method should be called in your typewriter effect
    scrollDuringTyping() {
      const container = this.$refs.messagesContainer;
      const inputBox = this.$refs.chatInput;
      if (container && inputBox) {
        const inputBoxHeight = inputBox.offsetHeight + 20;
        const scrollPosition = container.scrollHeight - container.clientHeight + inputBoxHeight;

        // Scroll only if we're already near the bottom
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - inputBoxHeight - 50) {
          container.scrollTop = scrollPosition;
        }
      }
    },
    handleScroll() {
      const container = this.$refs.messagesContainer;
      if (container) {
        // Check if scrolled to top (for loading more messages)
        if (container.scrollTop <= this.scrollThreshold && !this.isLoadingMoreMessages && this.hasMoreMessages) {
          this.loadMoreMessages();
        }

        // Update auto-scrolling state
        const atBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
        this.isAutoScrolling = atBottom;
      }
    },
    toggleScrolling() {
      this.isScrollingEnabled = !this.isScrollingEnabled;
      if (this.isScrollingEnabled) {
        this.scrollToNewMessage();
      }
    },
    isScrolledToBottom() {
      const container = this.$refs.messagesContainer;
      if (!container) return false;
      return container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
    },
    updateRAGStrategy() {
      // New call for updated backend
      this.socket.send(JSON.stringify({ type: 'update_rag_strategy', value: this.rag_strategy }));

      // Old call for backward compatibility
      this.use_google_search_for_rag = this.rag_strategy === 'web_search';
      const use_google_search_for_rag = this.rag_strategy === 'web_search';
      this.socket.send(JSON.stringify({ type: 'update_use_google_search_for_rag', value: use_google_search_for_rag }));
    },
    toggleConceptMapVisibility(message) {
      this.$set(message, 'conceptMapExpanded', !message.conceptMapExpanded);
    },

    toggleActionsVisibility(message) {
      this.$set(message, 'actionsExpanded', !message.actionsExpanded);
    },
    toggleQuestionsVisibility(message) {
      this.$set(message, 'questionsExpanded', !message.questionsExpanded);

      if (message.questionsExpanded) { // If expanding
        // Wait for the expand transition to complete
        this.$nextTick(() => {
          // Small delay to account for the v-expand-transition animation
          setTimeout(() => {
            const messageElement = document.querySelector(`[data-message-id="${message.id}"]`);
            if (messageElement) {
              messageElement.scrollIntoView({
                behavior: 'smooth',
                block: 'end'  // Align the bottom of the element with the bottom of the viewport
              });
            }
          }, 300); // Adjust timing based on your transition duration
        });
      }
    },
    copyMessageText(message) {
      let textToCopy = message.text || '';

      // Convert HTML to formatted plain text
      textToCopy = this.convertHtmlToFormattedText(textToCopy);

      navigator.clipboard.writeText(textToCopy).then(() => {
        // Set the copied flag to true
        this.$set(message, 'copied', true);

        // Reset the icon after 2 seconds
        setTimeout(() => {
          this.$set(message, 'copied', false);
        }, 2000);

      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
    convertHtmlToFormattedText(html) {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      let output = '';
      let listIndex = 0;
      let lastNodeType = null;

      function traverse(node, depth = 0) {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent.trim();
          if (text) {
            if (lastNodeType === 'heading' && node.parentNode.tagName.toLowerCase() !== 'li') {
              output += text + '\n\n';
            } else {
              output += text + ' ';
            }
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const tagName = node.tagName.toLowerCase();

          if (tagName === 'p') {
            if (lastNodeType && lastNodeType !== 'heading') {
              output += '\n';
            }
            output += '  '.repeat(depth);
          } else if (tagName === 'br') {
            output += '\n' + '  '.repeat(depth);
          } else if (tagName === 'ol' || tagName === 'ul') {
            output += '\n';
            listIndex = 0;
          } else if (tagName === 'li') {
            listIndex++;
            output += '  '.repeat(depth) + (node.parentNode.tagName === 'OL' ? `${listIndex}. ` : '• ');
          } else if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tagName)) {
            if (lastNodeType) {
              output += '\n\n';
            }
            output += '#'.repeat(parseInt(tagName[1])) + ' ';
            lastNodeType = 'heading';
          }

          for (let child of node.childNodes) {
            traverse(child, depth + 1);
          }

          if (['p', 'ol', 'ul'].includes(tagName)) {
            output += '\n';
            lastNodeType = tagName;
          } else if (tagName === 'li') {
            output += '\n';
          }
        }
      }

      traverse(doc.body);
      return output.trim().replace(/\n{3,}/g, '\n\n');
    },
    parseRelatedQuestions(response) {
      if (!response || typeof response !== 'string') {
        console.error("Invalid response in parseRelatedQuestions:", response);
        return { questions: [], remainingContent: response };
      }

      const MIN_QUESTION_LENGTH = 15; // Minimum characters for a valid question

      try {
        let questions = [];
        let remainingContent = response;

        // Remove trailing </p> if present
        remainingContent = remainingContent.replace(/<\/p>\s*$/, '');

        // Check for various formats of related questions
        const formats = [
          /<RELATED_QUESTIONS>([\s\S]*?)<\/RELATED_QUESTIONS>/i,
          /<h2>RELATED_QUESTIONS?:?<\/h2>([\s\S]*?)(?=<h2>|$)/i,
          /<h3>RELATED_QUESTIONS?:?<\/h3>([\s\S]*?)(?=<h3>|$)/i,
          /<h2>Related\s*Questions?:?<\/h2>([\s\S]*?)(?=<h2>|$)/i,
          /RELATED_QUESTIONS?:\s*([\s\S]*)/i,
          /RELATED QUESTIONS?:\s*([\s\S]*)/i,
          /Related\s*Questions?:?\s*([\s\S]*)/i,
          /<h2>Related\s*Questions?<\/h2>\s*<ol>([\s\S]*?)<\/ol>/i,
          /<h3>Related\s*Questions?<\/h3>\s*<ul>([\s\S]*?)<\/ul>/i,
          /RELATED_QUESTIONS?:\s*<ul>([\s\S]*?)<\/ul>/i,
          /RELATED QUESTIONS?:\s*<ul>([\s\S]*?)<\/ul>/i,
          /\*\*RELATED_QUESTIONS?:\*\*\s*([\s\S]*)/i,
          /\*\*Related Questions?\*\*\s*([\s\S]*)/i,
          /\*\*Related Questions?:\*\*\s*([\s\S]*)/i,
          /\*\*RELATED QUESTIONS?:\*\*\s*([\s\S]*)/i,
          /\*\*RELATED QUESTIONS?\*\*\s*([\s\S]*)/i
        ];

        for (const format of formats) {
          const match = remainingContent.match(format);
          if (match) {
            // Get the matched content
            const matchedContent = match[1].trim();

            // Extract and filter questions based on length
            const extractedQuestions = this.extractQuestions(matchedContent)
              .filter(question => {
                // Remove HTML tags and trim for length check
                const plainQuestion = question.replace(/<[^>]+>/g, '').trim();
                return plainQuestion.length >= MIN_QUESTION_LENGTH;
              });

            // Only set questions if we have valid ones
            if (extractedQuestions.length > 0) {
              questions = extractedQuestions;
            }

            // Remove the matched section from remaining content
            remainingContent = remainingContent.replace(match[0], '').trim();
            break;
          }
        }

        return {
          questions,
          remainingContent,
          // Optionally add metadata about filtered questions
          totalExtracted: questions.length
        };
      } catch (error) {
        console.error("Error in parseRelatedQuestions:", error);
        return { questions: [], remainingContent: response };
      }
    },
    extractQuestions(content) {
      // Check for list tags in the content
    if (content.includes('<ol>') || content.includes('<ul>')) {
          // Extract list items while preserving HTML and citations
          const items = content.match(/<li>[\s\S]*?<\/li>/g) || [];
          return items
              .map(item => {
                  // Remove only the li tags but keep other HTML (citations)
                  return item
                      .replace(/<li>/g, '')
                      .replace(/<\/li>/g, '')
                      .trim();
              })
              .filter(q => {
                  const plainText = q.replace(/<[^>]+>/g, '').trim();
                  return plainText.length > 15 && plainText.includes('?');
              });
      }
      // fallback to split by newlines if no list markers found
      let normalizedContent = content
          .replace(/<\/?(?:ul|ol|li)[^>]*>/g, '') // Remove list tags
          .replace(/^\s*(?:\d+\.|[-•*])\s*/gm, '___QUESTION___') // Mark question starts
          .replace(/\n+/g, ' ') // Replace newlines with spaces
          .trim();

      return normalizedContent
          .split('___QUESTION___')
          .map(q => this.cleanQuestion(q))
          .filter(q => {
              // Remove empty or too short questions
              const plainText = q.replace(/<[^>]+>/g, '').trim();
              return plainText.length > 15 &&
                    plainText.includes('?'); // Ensure it's actually a question
          });
    },

    cleanQuestion(question) {
        if (!question) return '';

        let cleaned = question.trim();

        // Don't remove citation spans
        cleaned = cleaned
            .replace(/^[-•*]\s+/g, '') // Remove bullet points
            .replace(/^\d+\.\s+/g, '') // Remove numeric markers
            .replace(/\s+/g, ' ') // Normalize spaces
            .trim();

        // If the question doesn't end with ?, add it
        if (cleaned && !cleaned.endsWith('?') &&
            cleaned.toLowerCase().includes('what') ||
            cleaned.toLowerCase().includes('how') ||
            cleaned.toLowerCase().includes('why') ||
            cleaned.toLowerCase().includes('when') ||
            cleaned.toLowerCase().includes('where') ||
            cleaned.toLowerCase().includes('which')) {
            cleaned += '?';
        }

        return cleaned;
    },
    stripHtmlTags(str) {
      return str.replace(/<\/?[^>]+(>|$)/g, "").trim();
    },
    stripHtmlTagsDom(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    },
    toggleRelatedQueriesType(message) {
      this.$set(message, 'showNewRelatedQueries', !message.showNewRelatedQueries);
      // ... other logic for switching between Earth and Mars questions ...
    },
    toggleRelatedQueries(messageId) {
      const message = this.messages.find(msg => msg.id === messageId);
      if (message) {
        message.showNewRelatedQueries = !message.showNewRelatedQueries;
      }
    },
    initializeLoggedInUser() {
      this.connect();
      this.fetchUniverses();
      this.fetchLimits();
      this.setupScrollListener();

      this.$nextTick(() => {
        this.focusInput();
      });
    },
    textToHtml(text) {
      if (typeof text !== 'string' || text.trim() === '') {
        return '';
      }
      const lines = text.split('\n');
      let html = '';
      let inList = false;
      let listType = '';

      lines.forEach((line, index) => {
        line = line.trim();
        if (line === '') {
          return; // Skip further processing for empty lines
        }

        if (line.match(/^\d+\./)) {
          // Ordered list item (unchanged)
          if (!inList || listType !== 'ol') {
            if (inList) html += `</${listType}>`;
            html += '<ol>';
            inList = true;
            listType = 'ol';
          }
          const colonIndex = line.indexOf(':');
          if (colonIndex !== -1) {
            const mainPoint = line.substring(0, colonIndex).replace(/^\d+\./, '').trim();
            const rest = line.substring(colonIndex + 1).trim();
            html += `<li><strong>${mainPoint}:</strong> ${rest}</li>`;
          } else {
            html += `<li>${line.replace(/^\d+\./, '')}</li>`;
          }
        } else if (line.match(/^[-*]/)) {
          // Unordered list item
          if (!inList || listType !== 'ul') {
            if (inList) html += `</${listType}>`;
            html += '<ul>';
            inList = true;
            listType = 'ul';
          }
          const colonIndex = line.indexOf(':');
          if (colonIndex !== -1) {
            const mainPoint = line.substring(0, colonIndex).replace(/^[-*]/, '').trim();
            const rest = line.substring(colonIndex + 1).trim();
            html += `<li><strong>${mainPoint}:</strong> ${rest}</li>`;
          } else {
            html += `<li>${line.replace(/^[-*]/, '')}</li>`;
          }
        } else if (line.match(/^#{1,6}/)) {
          // Heading (now with bold formatting)
          if (inList) {
            html += `</${listType}>`;
            inList = false;
          }
          const level = line.match(/^#{1,6}/)[0].length;
          const headingText = line.replace(/^#{1,6}/, '').trim();
          html += `<h${level}><strong>${headingText}</strong></h${level}>`;
        } else {
          // Regular paragraph or end of list
          if (inList) {
            html += `</${listType}>`;
            inList = false;
          }
          html += `<p>${line}</p>`;
        }
      });

      // Remove any remaining empty paragraphs
      html = html.replace(/<p>\s*<\/p>/g, '');

      return html;
    },
    showCreateEditUniverse() {
      if (!this.user.is_pro_team) {
        this.showTempMessageFn({
          message: 'Creating a new universe needs a Pro Team plan. Please upgrade to create a new universe.',
          type: 'info',
          timeout: 5000
        });
        return;
      }
      this.showCreateEditDialog();
    },
    showCreateEditGalaxy() {
      this.createEditShow()
    },
    onGalaxyDialogClosed(newGalaxyId) {
      this.fetchGalaxiesFn();
      this.onGalaxyChange(newGalaxyId);
      this.isExpanded = false;
      this.expandedSelector = false;
    },
    modifySheetLabels() {
      const sheet = this.$refs.planetSheet
      if (sheet) {
        sheet.$el.querySelector('.v-list-item-subtitle').textContent = 'Planet'
        const hints = sheet.$el.querySelectorAll('.v-messages__message')
        hints.forEach(hint => {
          hint.textContent = hint.textContent.replace('project', 'planet')
        })
      }
    },
    cleanLLMText(content) {
      if (!content) return '';

      let cleaned = content;

      // Remove double asterisks around sentences/phrases
      cleaned = cleaned.replace(/\*\*(.*?):\*\*/g, '$1:');

      // Also handle case where there's no colon
      cleaned = cleaned.replace(/\*\*(.*?)\*\*/g, '$1');

      return this.sanitizeHTML(cleaned);
    },
    sanitizeHTML(content) {
        if (!content) return '';

        let cleaned = content;

         // Simple string replacement for **text:** pattern.
        cleaned = cleaned.split('**').map((part, i, arr) => {
            if (part.endsWith(':') && i % 2 === 1) {
                return part;
            }
            return part;
        }).join('');

        // Handle remaining **text** pattern
        cleaned = cleaned.split('**').join('');

        // Handle single *text* pattern
        cleaned = cleaned.split('*').join('');

        return DOMPurify.sanitize(cleaned, sanitizeConfig);
    },
    handleCitation(type, id) {
      this.setActiveObject({
        type: type,
        id: id,
      });
    },
    async fetchGalaxiesFn() {
      try {
        // Wait for galaxies to be fetched
        await this.fetchGalaxies();

        // Now that we have galaxies, get the saved project ID
        const savedProject = localStorage.getItem("project");

        // Check if we have a saved project that exists in the galaxies list
        if (savedProject) {
          const savedProjectId = parseInt(savedProject);
          // Check if this galaxy exists in our list
          if (this.galaxies.some(p => p.id === savedProjectId)) {
            this.selectedGalaxy = savedProjectId;
            return; // Exit early if we found and set the saved galaxy
          }
        }

        // If we don't have a saved project or it wasn't found in the list,
        // fall back to the first galaxy if available
        if (this.galaxies && this.galaxies.length > 0) {
          this.selectedGalaxy = this.galaxies[0].id;
        }
      } catch (error) {
        console.error('Failed to fetch galaxies:', error);
        // Optionally show a user-friendly error message
        this.showTempMessageFn({
          message: "Failed to load galaxies. Please refresh the page.",
          type: "error",
          timeout: 5000
        });
      }
    },
    fetchUniverses() {
      UniverseApi.getAll()
        .then(response => {
          this.universes = response.data.items;
          const savedUniverse = localStorage.getItem("organization");
          if (savedUniverse && this.universes.some(u => u.slug === savedUniverse)) {
            this.selectedUniverse = savedUniverse;
            this.$store.commit("auth/SET_CURRENT_ORGANIZATION", savedUniverse);
          } else if (this.universes.length > 0) {
            this.selectedUniverse = this.universes[0].slug;
            this.$store.commit("auth/SET_CURRENT_ORGANIZATION", this.selectedUniverse);
          }
        })
        .catch(error => {
          console.error("Error fetching universes:", error);
        });
    },
    onUniverseChange(slug) {
      localStorage.setItem("organization", slug);
      this.$store.commit("auth/SET_CURRENT_ORGANIZATION", slug);
      let currentUrl = new URL(window.location.href);
      let pathParts = currentUrl.pathname.split("/").filter(Boolean);

      // reset the project in local storage
      localStorage.removeItem("project");

      if (pathParts.length > 0) {
        pathParts[0] = slug;
      } else {
        pathParts.unshift(slug);
      }

      currentUrl.pathname = "/" + pathParts.join("/");

      // delete any query parameters
      currentUrl.searchParams.delete('q');
      currentUrl.search = "";


      window.location.href = currentUrl.href;

      this.fetchGalaxiesFn();
      this.fetchProjects();
      this.fetchChatHistory();
    },
    onGalaxyChange(id) {
      // Set the new project in localStorage
      localStorage.setItem("project", id);
      this.$store.commit("auth/SET_CURRENT_PROJECT", id);
      this.selectedGalaxy = id;
      this.fetchLikedMessagesProcessing(this.selectedGalaxy);
      this.fetchChatHistory();
      this.fetchProjects();
      // reset active object to avoid confusion
      this.resetBAOState();
      this.rag_strategy = 'web_search';
      this.updateRAGStrategy();
      // reset RAG content
      this.resetRAGContent();
    },
    openImageUploadDialog() {
      this.isImageUploadDialogOpen = true;
      this.createDefaultProject();
      this.fetchProjects(); // Ensure the projects list is up to date
    },
    handleRagTextSelection() {
      const selectedText = window.getSelection().toString().trim();
      if (selectedText) {
        this.selectedText = selectedText;
      }
    },
    toggleRAGStrategy() {
      this.use_google_search_for_rag = !this.use_google_search_for_rag;
      this.updateUseGoogleSearchForRag();
    },
    updateRagTokenCount(messageData) {
      // TBD
    },
    updateRagTokenMetadata(messageData) {
      this.$store.commit('websocket/SET_ACTIVE_OBJECT_CONTENT', messageData);
    },
    resetRAGContent() {
      this.ragContent = "";
      this.ragTokenCount = 0;
      this.ragType = "";
      this.showRagContent = false;
      this.isRagExpanded = false;
    },
    triggerSparkAnimation() {
      this.showSparks = true;
      setTimeout(() => {
        this.showSparks = false;
      }, 5000);
    },
    handleTabChange(tab) {
      this.activeTab = tab;
    },
    limitedSearchResults(searchResults) {
      return searchResults.slice(0, 4); // Show only the first 4 search results
    },

    remainingSearchResultsCount(searchResults) {
      return searchResults.length > 4 ? searchResults.length - 4 : 0;
    },

    openSearchResultLink(url) {
      window.open(url, '_blank');
    },

    openSearchResultsDialog(searchResults) {
      this.dialogSearchResults = searchResults.slice(4);
      this.searchResultsDialog = true;
    },
    updateInputHeight() {
      const chatInput = this.$refs.chatInput;
      if (chatInput) {
        const scrollHeight = chatInput.scrollHeight;
        const maxHeight = 400; // This should match the max-height in CSS
        const newHeight = Math.min(scrollHeight, maxHeight);
        document.documentElement.style.setProperty('--input-height', `${newHeight + 75}px`);
      }
    },
    resetInputHeight() {
      document.documentElement.style.setProperty('--input-height', '125px'); // set it back to the .latest-message
      if (this.$refs.chatInput) {
        this.$refs.chatInput.style.height = 'auto';
      }
    },
    isLatestMessage(messageId) {
      return messageId === this.messages[this.messages.length - 1].id;
    },
    isFirstMessage(messageId) {
      return this.messages.length > 0 && messageId === this.messages[0].id;
    },
    isLatestBotMessage(messageId) {
      const botMessages = this.messages.filter(m => m.user === 'bot' && m.conceptMappingAndLateralThinkingHtml);
      return botMessages.length > 0 && botMessages[botMessages.length - 1].id === messageId;
    },
    handleLimitsIconClick() {
      this.showLimits = true;
      this.fetchLimits();
    },
    handleSubmissionsIconClick() {
      this.showSubmissionsDialog = true
    },
    closeSubmissionsDialog() {
      this.showSubmissionsDialog = false;
    },
    handleLikedMessagesProcessingIconClick() {
      this.showLikedMessagesProcessingDialog = true;
      this.fetchLikedMessagesProcessing(this.selectedGalaxy);
    },
    closeLikedMessagesProcessingDialog() {
      this.showLikedMessagesProcessingDialog = false;
    },
    validateSelectedProject() {
      if (!this.selectedProject || !this.selectedProject.id) {
        this.selectedProject = null;
        this.showTempMessageFn({
          message: "Invalid planet selected. Please choose a valid planet.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    showReconnectionMessage() {
      this.showTempMessageFn({
        message: "Reconnecting to the server...",
        type: "warning",
        timeout: 999999
      });
    },
    hideReconnectionMessage() {
      this.showTempMessage = false;
        this.tempMessage = "";
    },
    setupScrollListener() {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        if (container) {
          container.addEventListener('scroll', this.checkScrollPosition);
          this.checkScrollPosition(); // Initial check
        } else {
          console.warn('Messages container not found');
        }
      });
    },
    manualScrollToBottom() {
      const container = this.$refs.messagesContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    checkScrollPosition() {
      const container = this.$refs.messagesContainer;
      if (container) {
        const scrollHeight = container.scrollHeight;
        const height = container.clientHeight;
        const maxScrollTop = scrollHeight - height;
        const scrolledUp = maxScrollTop - container.scrollTop;

        this.showScrollButton = scrolledUp > 100; // Show scroll button if scrolled up by more than 100px
      }
    },
    loadMoreMessages() {
      if (!this.isLoadingMoreMessages && this.hasMoreMessages) {
        this.isLoadingMoreMessages = true;
        this.lastLoadedScrollHeight = this.$refs.messagesContainer.scrollHeight;
        this.fetchChatHistory(this.currentPage + 1);
      }
    },
    handleChatHistory(messageData) {
      const isNewPage = this.currentPage < messageData.history.current_page;
      this.processChatHistory(messageData.history, isNewPage);
      this.isLoadingMoreMessages = false;

      if (isNewPage) {
        // Maintain scroll position when new messages are prepended
        this.$nextTick(() => {
          const container = this.$refs.messagesContainer;
          const scrollDifference = container.scrollHeight - this.lastScrollPosition;
          container.scrollTop = scrollDifference;
          this.lastScrollPosition = container.scrollHeight;
        });
      }
    },
    processChatHistory(history, isNewPage = false) {
      const oldMessages = [...this.messages];
      const newMessages = this.processMessages(history.messages);

      if (isNewPage) {
        // Prepend new messages to existing ones
        this.messages = [...newMessages, ...oldMessages];
      } else {
        // Replace existing messages (for initial load or refresh)
        this.messages = newMessages;
      }

      // Update pagination info
      this.currentPage = history.current_page;
      this.hasMoreMessages = history.has_more;

      this.$nextTick(() => {
        this.$forceUpdate();

        // Apply typewriter effect to relationships and final answers
        this.messages.forEach(message => {
          if (message.relationshipsHtml) {
            this.typeWriterEffectForRelationships(message);
          }
          if (message.finalAnswer && message.text) {
            this.typeWriterEffect(message);
          }
        });

        if (isNewPage) {
          // Maintain scroll position when new messages are prepended
          const container = this.$refs.messagesContainer;
          const newScrollHeight = container.scrollHeight;
          const scrollDifference = newScrollHeight - this.lastLoadedScrollHeight;
          container.scrollTop = scrollDifference + this.scrollThreshold;
        } else {
          // Scroll to bottom after loading initial history
          this.scrollToBottom(true, true);
        }

        this.isLoadingMoreMessages = false;
      });
    },
    processMessages(history) {
      let processedMessages = [];
      history.forEach(msg => {
        // Add user message
        const userMessage = this.processUserMessage(msg.user_message);
        processedMessages.push({
          id: `${msg.id}-user`,
          user: 'user',
          text: msg.user_message, // Full text with IAO prepended (for server)
          displayText: userMessage.displayText, // Clean text for display
          iaoReference: userMessage.iaoReference,
          timestamp: new Date(msg.created_at),
          chatSettings: msg.chat_settings,
        });

        // Add bot message(s)
        if (msg.additional_data) {
          // Add intermediate bot messages (query attributes, concept mapping, entities, relationships)
          if (msg.additional_data.query_attributes) {
            processedMessages.push({
              id: `${msg.id}-query-attributes`,
              user: 'bot',
              queryAttributesHtml: msg.additional_data.query_attributes,
              typing: false,
            });
          }

          if (msg.additional_data.concept_mapping_and_lateral_thinking) {
            processedMessages.push({
              id: `${msg.id}-concept-mapping`,
              user: 'bot',
              conceptMappingAndLateralThinkingHtml: msg.additional_data.concept_mapping_and_lateral_thinking,
              typing: false,
            });
          }

          // Add search results
          if (msg.additional_data.search_results && msg.additional_data.search_results.length > 0) {
            const searchResults = msg.additional_data.search_results.map((url, index) => ({
              id: index,
              url: url,
              name: new URL(url).hostname
            }));

            processedMessages.push({
              id: `${msg.id}-search`,
              user: 'bot',
              component: 'search-results-animation',
              componentProps: {
                results: searchResults,
                isSearching: false,  // Since this is history
                finalAnswerReceived: true  // Since this is history
              },
              typing: false,
              finalAnswer: true,  // Since this is history
            });
          }

          ///Add IAO objects
          if (msg.additional_data.iao_objects && msg.additional_data.iao_objects.length > 0) {
            processedMessages.push({
              id: `${msg.id}-iao-objects`,
              user: 'bot',
              iaoObjects: msg.additional_data.iao_objects,
              typing: false,
            });
          }

          // Add entities and relationships
          if (msg.additional_data.entities_formatted) {
            processedMessages.push({
              user: "bot",
              component: 'entity-results-animation',
              componentProps: {
                entities: msg.additional_data.entities_formatted,
                isSearching: false, // Since this is history
                finalAnswerReceived: true // Since this is history
              },
              typing: false,
              finalAnswer: true, // Since this is history
              id: `entities-${Date.now()}`
            });
          }

          if (msg.additional_data.relationships_html) {
            const relationshipsMessage = {
              id: `${msg.id}-relationships`,
              user: 'bot',
              relationshipsHtml: msg.additional_data.relationships_html,
              parsedRelationships: this.parseRelationships(msg.additional_data.relationships_html),
              typing: false,
              finalAnswer: false,
            };
            processedMessages.push(relationshipsMessage);
          }
        }

        // Add final bot response
        const botResponse = msg.bot_response;
        const { questions: newRelatedQueries, remainingContent } = this.parseRelatedQuestions(botResponse);
        const botMessage = {
          id: msg.id,
          user: 'bot',
          text: this.sanitizeHTML(this.textToHtml(remainingContent)),
          fullText: this.sanitizeHTML(this.textToHtml(remainingContent)),
          timestamp: new Date(msg.created_at),
          finalAnswer: true,
          showActions: true,
          userFeedback: msg.user_feedback === true ? 'like' : msg.user_feedback === false ? 'dislike' : 'none',
          typing: false,
          buttons: msg.buttons,
          isButton: msg.buttons && msg.buttons.length > 0,
          newRelatedQueries: newRelatedQueries,
          showNewRelatedQueries: true,
        };

        if (msg.additional_data && msg.additional_data.related_queries) {
          botMessage.relatedQueries = msg.additional_data.related_queries;
        }

        processedMessages.push(botMessage);
      });

      return processedMessages;
    },
    fetchChatHistory(page = 1) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.isLoadingMoreMessages = true;
        this.socket.send(JSON.stringify({
          type: 'fetch_chat_history',
          project_id: this.selectedGalaxy,
          page: page
        }));
      } else {
        console.error('WebSocket is not open');
      }
    },
    typeMissionText() {
      const typeNextChar = () => {
        if (this.missionDisplayedText.length < this.missionText.length) {
          this.missionDisplayedText += this.missionText.charAt(this.missionDisplayedText.length);
          setTimeout(typeNextChar, this.typingSpeed);
        } else {
          this.missionTypingComplete = true;
        }
      };
      typeNextChar();
    },
    customFilter(item, queryText, itemText) {
      const searchText = item.fullPath.join(' ').toLowerCase();
      return searchText.indexOf(queryText.toLowerCase()) > -1;
    },
    onInputFocus() {
      this.inputFocused = true;
    },

    onInputBlur() {
      // Only remove focus if we're actually losing focus to another element
      if (document.activeElement !== this.$refs.chatInput) {
        this.inputFocused = false;
      }
    },

    // Method to programmatically focus input
    focusInput() {
      if (this.$refs.chatInput) {
        this.$refs.chatInput.focus();
        this.inputFocused = true;
      }
    },
    cleanCitations(text) {
      // First remove the HTML spans
      let cleaned = text.replace(/<span class="citation-link"[^>]*>.*?<\/span>/g, '');

      // Then remove the remaining citation numbers (e.g., c17c32c33)
      cleaned = cleaned.replace(/\s*c\d+(?:c\d+)*/g, '');

      // Remove double question marks that might occur from citation removal
      cleaned = cleaned.replace(/\?\s*\?/g, '?');

      // Remove any double spaces and trim
      cleaned = cleaned.replace(/\s+/g, ' ').trim();

      return cleaned;
    },
    rewriteAnswer(message, query = null) {
      let userMessage;

      if (query) {
          // If a query is provided (for related queries), use it directly
          userMessage = query;
      } else {
          // For bot messages, search for the corresponding user message
          let currentIndex = this.messages.findIndex(msg => msg === message);
          if (currentIndex === -1) {
              console.error('Could not find the current message in the conversation');
              return;
          }

          // Search backwards for the last user message
          for (let i = currentIndex - 1; i >= 0; i--) {
              if (this.messages[i].user !== 'bot') {
                  userMessage = this.messages[i].text;
                  break;
              }
          }

          if (!userMessage) {
              console.error('Could not find corresponding user message');
              return;
          }
      }

      // Clean up the message: remove IAO prepending and citations
      userMessage = this.cleanCitations(userMessage).replace(/^\[IAO-[^\]]+\]\s*/g, '');

      // Set the userInput without IAO prepending
      this.userInput = userMessage;

      // Call sendMessage, which will handle IAO prepending if needed
      this.sendMessage();
    },
    handleObjectSelected(selectedObject) {
      this.selectedProject = selectedObject.id;
      this.setActiveObject({
        type: selectedObject.type,
        id: selectedObject.id
      });
      this.isObjectsDialogOpen = false;

    },
    handleToggleBAOPrepend() {
      this.toggleBAOPrepend();
      if (this.previous_rag_strategy) {
        this.rag_strategy = this.previous_rag_strategy;
        this.updateRAGStrategy();
      }
    },

    handleResetBAO() {
      this.resetBAOState();
    },
    calculateFontSize(text) {
      const baseSize = 16;
      const maxSize = 24;
      const minSize = 16;
      const textLength = text.length;

      if (textLength <= 50) {
        return `${maxSize}px`;
      } else if (textLength <= 100) {
        // Linear interpolation between maxSize and baseSize
        const scale = (100 - textLength) / 50;
        return `${Math.max(baseSize, maxSize - (maxSize - baseSize) * (1 - scale))}px`;
      } else if (textLength <= 200) {
        // Gradual decrease from baseSize to minSize
        const scale = (200 - textLength) / 100;
        return `${Math.max(minSize, baseSize - (baseSize - minSize) * (1 - scale))}px`;
      } else {
        return `${minSize}px`;
      }
    },
    stopResponse() {
      // Close the websocket connection
      if (this.socket) {
        this.socket.close();
      }

      if (this.animationFrame) {
        cancelAnimationFrame(this.animationFrame);
        this.animationFrame = null;
      }

      // Clear any typing intervals
      this.clearTypingInterval();

      // Complete the current message immediately
      if (this.messages.length > 0) {
        const lastMessage = this.messages[this.messages.length - 1];

        // If it's a typing indicator, remove it
        if (lastMessage.typing && !lastMessage.fullText) {
          this.messages.pop();
        } else {
          // If it's a partial message, complete it as best we can
          this.$set(lastMessage, 'text', lastMessage.fullText || lastMessage.text || 'Response interrupted');
          this.$set(lastMessage, 'showActions', true);
          this.$set(lastMessage, 'typing', false);
          this.$set(lastMessage, 'finalAnswer', true);

          // If we have partial data for related queries, include it
          if (lastMessage.newRelatedQueries) {
            this.$set(lastMessage, 'relatedQueries', lastMessage.newRelatedQueries);
            this.$set(lastMessage, 'showNewRelatedQueries', true);
          }

          // If we have partial data for buttons, include it
          if (lastMessage.buttons) {
            this.$set(lastMessage, 'isButton', lastMessage.buttons.length > 0);
          }
        }
      }

      // Add a system message indicating the response was stopped
      this.messages.push({
        user: "bot",
        text: "Response stopped by user.",
        showActions: true,
        finalAnswer: true
      });

      this.isResponseInProgress = false;
      this.isBotTyping = false;
      this.isFinalAnswer = true;

      setTimeout(() => {
        this.connect(false); // Attempt to reconnect
      }, 1000);
    },
    restoreScrollPosition() {
      const container = this.$refs.messagesContainer;
      if (container) {
        container.scrollTop = this.lastScrollPosition;
      }
    },
    openEntityLink(url) {
      window.open(url, '_blank');
    },
    closeTempMessage() {
      this.showTempMessage = false;
      if (this.tempMessageTimeout) {
        clearTimeout(this.tempMessageTimeout);
      }
    },
    updateLikeDislikeState(messageId, action) {
      const message = this.messages.find(m => m.id === messageId);
      if (message) {
        this.$set(message, 'buttonsHidden', true);
        this.$set(message, 'userFeedback', action);
      }

      if (action === 'like') {
        this.showTempMessageFn({
          message: "Got it. I will search the web for relevant context and build your personal index.",
          type: "success",
          timeout: 2000
        });
      } else if (action === 'dislike') {
        this.showTempMessageFn({
          message: "Got it. I will not build your personal index based on this context.",
          type: "success",
          timeout: 2000
        });
      }
    },
    openObjectsDialog() {
      this.isObjectsDialogOpen = true;
    },
    closeObjectsDialog() {
      this.isObjectsDialogOpen = false;
    },
    openSettingsDialog(message = null, query = null) {
      // Check if message exists before checking for id
      this.currentMessage = message?.id ? message : null;
      this.currentQuery = query;
      this.isRelatedQuestion = !!query; // Convert to boolean
      this.isSettingsDialogOpen = true;
    },
    handleRefineAnswer() {
        this.refineAnswer(this.currentMessage, this.currentQuery);
    },
    refineAnswer(message, query = null) {
      if (message) {
          this.rewriteAnswer(message, query);
      } else {
          this.showTempMessageFn({
            message: "No message to refine. Please select a message to refine.",
            type: "warning",
            timeout: 2000
          });
      }
      this.closeSettingsDialog();
    },
    closeSettingsDialog() {
      this.currentMessage = null;
      this.currentQuery = null;
      setTimeout(() => {
        this.isSettingsDialogOpen = false;
      }, 300); // smoothly close the dialog
    },
    updateThreshold() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_threshold', value: this.relationshipMatchThreshold }));
      } else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateRelatedEntitiesThreshold() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_related_entities_match_threshold', value: this.relatedEntitiesMatchThreshold }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateTemperature() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_llm_temperature', value: this.llmTemperature }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    canSelectModel(model) {
      // Any user can select as we have limits in place
      // return this.user.is_pro_team || this.user.is_pro || model.type !== 'Pro';
      return true;
    },

    handleModelSelection(model) {
      if (!this.canSelectModel(model)) {
        this.showTempMessageFn({
          message: "Please login to select a Pro model.",
          type: "warning",
          timeout: 3000
        });
        return;
      }
      this.updateLlmModelName(model.name);
    },
    updateLlmModelName(modelName) {
      this.llmModelName = modelName;
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_llm_model_name', value: this.llmModelName }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateUseDefaultPrompt() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_use_default_prompt', value: this.useDefaultPrompt }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateCustomPrompt() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_custom_prompt', value: this.customPrompt }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    getCurrentPrompt() {
      return this.useDefaultPrompt ? this.defaultPrompt : this.customPrompt;
    },
    updateLengthFactor() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_length_factor', value: this.lengthFactor }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateTopEntitiesThreshold() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_top_entities_threshold', value: this.topEntitiesThreshold }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateRagTokenThreshold() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_rag_token_threshold', value: this.ragTokenThreshold }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateRelationshipsDisplayLimit() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_relationships_display_limit', value: this.relationshipsDisplayLimit }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateBuildPersonalIndex() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_build_personal_index', value: this.build_personal_index_on_every_query }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateGoogleSearchResultsCount() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_google_search_results_count', value: this.google_search_results_count }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateGoogleSearchSkipTopNum() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_google_search_skip_top_num', value: this.google_search_skip_top_num }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateGoogleSearchSkipTopResults() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_google_search_skip_top_results', value: this.google_search_skip_top_results }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateGoogleSearchLowRange(date) {
      this.google_search_low_range = date
      this.lowRangeMenu = false
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_google_search_low_range', value: date }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateGoogleSearchHighRange(date) {
      this.google_search_high_range = date
      this.highRangeMenu = false
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_google_search_high_range', value: date }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateUseGoogleSearchForRag() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_use_google_search_for_rag', value: this.use_google_search_for_rag }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    resetLowRange() {
      this.google_search_low_range = null
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_google_search_low_range', value: null }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },

    resetHighRange() {
      this.google_search_high_range = null
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ type: 'update_google_search_high_range', value: null }));
      }
       else {
        this.showTempMessageFn({
          message: "Server connection lost. Please refresh the page to reconnect.",
          type: "warning",
          timeout: 2000
        });
      }
    },
    updateSiteSearches() {
        const sites = this.site_searches.map(s => s.site);
        const filters = this.site_searches.map(s => s.filter);
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify({
                type: 'update_site_searches',
                sites: sites,
                filters: filters
            }));
        }
         else {
            this.showTempMessageFn({
              message: "Server connection lost. Please refresh the page to reconnect.",
              type: "warning",
              timeout: 2000
            });
        }
    },

    addSiteSearch() {
        this.site_searches.push({ site: '', filter: 'i' });
        this.updateSiteSearches();
    },

    removeSiteSearch(index) {
        this.site_searches.splice(index, 1);
        this.updateSiteSearches();
    },
    openSubmitTextDialog() {
      this.isSubmitTextDialogOpen = true;
      this.createDefaultProject();
      this.fetchProjects(); // Ensure the projects list is up to date
    },
    closeSubmitTextDialog() {
      this.isSubmitTextDialogOpen = false;
    },
    async createDefaultProject() {
      try {
        await new Promise((resolve, reject) => {
          this.socket.send(JSON.stringify({
            type: 'create_default_eccs_project',
            project_id: this.selectedGalaxy
          }));

          const handleResponse = (event) => {
            const response = JSON.parse(event.data);
            if (response.type === 'eccs_project_created') {
              this.socket.removeEventListener('message', handleResponse);
              resolve(response);
            }
          };

          this.socket.addEventListener('message', handleResponse);
        });

        // Fetch updated project list
        await this.fetchProjects();
      } catch (error) {
        // FOO_BAR
      }
    },
    async createProject() {
      // Validate the form
      if (!this.$refs.form.validate()) {
        // If validation fails, don't proceed
        return;
      }

      // At this point, we know the form is valid, so we can proceed
      try {
        await new Promise((resolve, reject) => {
          this.socket.send(JSON.stringify({
            type: 'create_eccs_project',
            name: this.newProjectName.trim(),
            project_id: this.selectedGalaxy,
            parent_id: this.newProjectParentId
          }));

          // Set up a one-time event listener for the response
          const handleResponse = (event) => {
            const response = JSON.parse(event.data);
            if (response.type === 'eccs_project_created') {
              const newProject = {
                id: response.id,
                name: response.name,
                description: response.description,
              }
              this.projects.push(newProject);
              this.selectedProject = newProject;
              this.socket.removeEventListener('message', handleResponse);
              resolve(response);
            }
          };

          this.socket.addEventListener('message', handleResponse);
        });

        // Clear the form
        this.newProjectName = '';
        this.newProjectParentId = null; // Add this line
        this.$refs.form.resetValidation();
        this.isCreateProjectDialogOpen = false;
        this.isExpanded = false;
        this.expandSelector = false;


        // Fetch updated project list
        await this.fetchProjects();
      } catch (error) {
        this.showTempMessageFn({
          message: "Failed to create planet. Please try again.",
          type: "error",
          timeout: 2000
        });
        this.isCreateProjectDialogOpen = false;
      }
    },
    openCreateProjectDialog() {
      this.isCreateProjectDialogOpen = true;
    },
    openProjectsDialog() {
      this.isProjectsDialogOpen = true;
      this.createDefaultProject();
      this.fetchProjects();
    },
    handleProjectClick(item) {
      this.setActiveObject({
        type: 'ECCSProject',
        id: item.id,
      });
      this.isProjectsDialogOpen = false;
    },
    selectProject(projectId) {
      const selectedProject = this.projects.find(project => project.id === projectId);
      if (selectedProject) {
        this.selectedProject = projectId;
        this.setActiveObject({
          type: 'ECCSProject',
          id: projectId
        });
        this.isProjectsDialogOpen = false;
      } else {
        console.error(`Project with ID ${projectId} not found`);
        this.showTempMessageFn({
          message: `Error: Project not found`,
          type: "error",
          timeout: 5000
        });
      }
    },
    fetchProjects() {
      this.socket.send(JSON.stringify({ type: 'fetch_eccs_projects', project_id: this.selectedGalaxy }));
    },
    async submitTextToServer() {
      if (!this.$refs.submitTextForm.validate()) {
        return;
      }

       // Check limits based on user type
      let limitExceeded = false;
      let limitType = 'standard';

      if (this.user.is_pro_team) {
        limitType = 'pro_team';
        limitExceeded = this.limits?.pro_team.text_submissions.used >= this.limits?.pro_team.text_submissions.max;
      } else if (this.user.is_pro) {
        limitType = 'pro';
        limitExceeded = this.limits?.pro.text_submissions.used >= this.limits?.pro.text_submissions.max;
      } else {
        limitExceeded = this.limits?.regular.text_submissions.used >= this.limits?.regular.text_submissions.max;
      }

      if (limitExceeded) {
        this.showTempMessageFn({
          message: `You have reached the maximum number of text submissions allowed for your ${limitType} plan.`,
          type: "warning",
          timeout: 5000
        });
        return;
      }

      if (!this.selectedProject || !this.selectedProject.id) {
        this.showTempMessageFn({
          message: "Please select a planet.",
          type: "warning",
          timeout: 2000
        });
        return;
      }

      const trimmedText = this.submittedText.trim();
      const trimmedName = this.submittedTextName.trim();
      if (trimmedText === "" || trimmedName === "") {
        this.showTempMessageFn({
          message: "Please enter a name and text to submit.",
          type: "warning",
          timeout: 2000
        });
        return;
      }

      try {
        await new Promise((resolve, reject) => {
          const data = {
            type: 'submit_text',
            value: trimmedText,
            name: trimmedName,
            eccs_project_id: this.selectedProject.id,
            project_id: this.selectedGalaxy,
            buildIndex: this.buildIndex,
          };
          this.socket.send(JSON.stringify(data));

          const handleResponse = (event) => {
            const response = JSON.parse(event.data);
            if (response.type === 'submit_text_response') {
              this.socket.removeEventListener('message', handleResponse);
              if (response.status === 'success') {
                resolve(response);
                 // Set the active object to the TextSubmission object
                this.setActiveObject({
                  type: 'TextSubmission',
                  id: response.submission_id
                });
                this.showTempMessageFn({
                  message: response.message || "Text submitted successfully.",
                  type: "success",
                  timeout: 2000
                });

              } else {
                reject(new Error(response.message || 'Failed to submit text'));
              }
            }
          };

          this.socket.addEventListener('message', handleResponse);
        });

        // Text submitted successfully
        this.submittedText = "";
        this.submittedTextName = "";
        this.$refs.submitTextForm.resetValidation();
        this.isSubmitTextDialogOpen = false;
        // Trigger job polling in JobUpdatesFeed
        this.$refs.jobUpdatesFeed.onNewJobSubmitted();
      } catch (error) {
        console.error("Error submitting text:", error);
        this.showTempMessageFn({
          message: error.message || "Failed to submit text. Please try again.",
          type: "error",
          timeout: 5000
        });
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileSelection(files) {
      if (!files) {
        this.selectedFiles = [];
        return;
      }

      const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes
      const validFiles = [];
      const invalidSizeFiles = [];
      const exceededLimitFiles = [];

      files.forEach((file, index) => {
        if (index < this.maxSimultaneousUploads) {
          if (file.size <= MAX_FILE_SIZE) {
            validFiles.push(file);
          } else {
            invalidSizeFiles.push(file.name);
          }
        } else {
          exceededLimitFiles.push(file.name);
        }
      });

      this.selectedFiles = validFiles;

      if (invalidSizeFiles.length > 0) {
        const message = invalidSizeFiles.length === 1
          ? `File "${invalidSizeFiles[0]}" exceeds the maximum size of ${MAX_FILE_SIZE / 1024 / 1024} MB and was not added.`
          : `${invalidSizeFiles.length} files exceed the maximum size of ${MAX_FILE_SIZE / 1024 / 1024} MB and were not added.`;

        this.showTempMessageFn({
          message: message,
          type: "warning",
          timeout: 5000
        });
      }

      if (exceededLimitFiles.length > 0) {
        const message = `${exceededLimitFiles.length} file(s) exceeded the upload limit of ${this.maxSimultaneousUploads} and were not added.`;
        this.showTempMessageFn({
          message: message,
          type: "warning",
          timeout: 5000
        });
      }

      if (validFiles.length > 0) {
        const message = validFiles.length === 1
          ? `1 file selected for upload.`
          : `${validFiles.length} files selected for upload.`;

        this.showTempMessageFn({
          message: message,
          type: "info",
          timeout: 3000
        });
      }
    },
    handleTextSelection() {
      const charCount = this.submittedText.length;
      if (charCount > MAX_TEXT_CHARACTERS) {
        this.submittedText = this.submittedText.slice(0, MAX_TEXT_CHARACTERS);
        this.showTempMessageFn({
          message: `Text should be less than ${MAX_TEXT_CHARACTERS} characters`,
          type: "warning",
          timeout: 5000
        });
      }
    },
    openFileUploadDialog() {
      this.isFileUploadDialogOpen = true;
      this.createDefaultProject();
      this.fetchProjects(); // Ensure the projects list is up to date
    },
    openYoutubeSubmitDialog() {
      this.isYouTubeSubmitDialogOpen = true;
      this.createDefaultProject();
      this.fetchProjects(); // Ensure the projects list is up to date
    },
    async uploadImages() {
      // Check limits based on user type
      let limitExceeded = false;
      let limitType = 'standard';

      if (this.user.is_pro_team) {
        limitType = 'pro_team';
        limitExceeded = this.limits?.pro_team.file_submissions.used >= this.limits?.pro_team.file_submissions.max;
      } else if (this.user.is_pro) {
        limitType = 'pro';
        limitExceeded = this.limits?.pro.file_submissions.used >= this.limits?.pro.file_submissions.max;
      } else {
        limitExceeded = this.limits?.regular.file_submissions.used >= this.limits?.regular.file_submissions.max;
      }

      if (limitExceeded) {
        this.showTempMessageFn({
          message: `You have reached the maximum number of file uploads allowed for your ${limitType} plan.`,
          type: "warning",
          timeout: 5000
        });
        return;
      }
      if (!this.$refs.imageUploadForm.validate()) {
        return;
      }

      if (!this.selectedProject || !this.selectedProject.id || this.selectedFiles.length === 0) {
        this.showTempMessageFn({
          message: "Please select both a planet and at least one image.",
          type: "warning",
          timeout: 5000
        });
        return;
      }

      this.isUploading = true;

      const uploadPromises = this.selectedFiles.map(file =>
        this.uploadImageWithMessage(file)
      );

      try {
        await Promise.all(uploadPromises);
        this.selectedFiles = [];
        this.$refs.imageUploadForm.resetValidation();
        this.isImageUploadDialogOpen = false;
        // Trigger job polling in JobUpdatesFeed
        this.$refs.jobUpdatesFeed.onNewJobSubmitted();
        // Set the active object to the ECCSProject
        this.setActiveObject({
          type: 'ECCSProject',
          id: this.selectedProject.id
        });
        this.isUploading = false;

        this.showTempMessageFn({
          message: "All images uploaded successfully.",
          type: "success",
          timeout: 2000
        });
      } catch (error) {
        this.isUploading = false;
        this.isImageUploadDialogOpen = false;
        console.error('Error uploading images:', error);
        this.showTempMessageFn({
          message: `Failed to upload one or more images. ${error.message}`,
          type: "error",
          timeout: 10000
        });
      }
    },

    async uploadImageWithMessage(file) {
      this.showTempMessageFn({
        message: `Uploading ${file.name}...`,
        type: "info",
        timeout: 2000
      });

      try {
        const response = await this.uploadFile({
          file,
          projectId: this.selectedGalaxy,
          eccsProjectId: this.selectedProject.id,
          buildIndex: this.buildIndex,
          sensitivityLevel: this.sensitivityLevel,
        });

        this.showTempMessageFn({
          message: `${file.name} uploaded successfully.`,
          type: "success",
          timeout: 2000
        });

        return response;
      } catch (error) {
        console.error('Error uploading image:', file.name, error);
        this.showTempMessageFn({
          message: `Failed to upload ${file.name}. ${error.message}`,
          type: "error",
          timeout: 5000
        });
        throw error;
      }
    },
    async uploadFiles() {
      // Check limits based on user type
      let limitExceeded = false;
      let limitType = 'standard';

      if (this.user.is_pro_team) {
        limitType = 'pro_team';
        limitExceeded = this.limits?.pro_team.file_submissions.used >= this.limits?.pro_team.file_submissions.max;
      } else if (this.user.is_pro) {
        limitType = 'pro';
        limitExceeded = this.limits?.pro.file_submissions.used >= this.limits?.pro.file_submissions.max;
      } else {
        limitExceeded = this.limits?.regular.file_submissions.used >= this.limits?.regular.file_submissions.max;
      }

      if (limitExceeded) {
        this.showTempMessageFn({
          message: `You have reached the maximum number of file uploads allowed for your ${limitType} plan.`,
          type: "warning",
          timeout: 5000
        });
        return;
      }

      if (!this.$refs.fileUploadForm.validate()) {
        return;
      }

      if (!this.selectedProject || !this.selectedProject.id || !this.selectedFiles || this.selectedFiles.length === 0) {
        this.showTempMessageFn({
          message: "Please select both a planet and at least one file.",
          type: "warning",
          timeout: 5000
        });
        return;
      }

      this.isUploading = true;

      const uploadPromises = this.selectedFiles.map(file =>
        this.uploadFileWithMessage(file)
      );

      try {
        await Promise.all(uploadPromises);
        this.selectedFiles = [];
        this.$refs.fileUploadForm.resetValidation();
        this.isFileUploadDialogOpen = false;
        // Trigger job polling in JobUpdatesFeed
        this.$refs.jobUpdatesFeed.onNewJobSubmitted();
        // Set the active object to the ECCSProject
        this.setActiveObject({
          type: 'ECCSProject',
          id: this.selectedProject.id
        });
        this.isUploading = false;

        this.showTempMessageFn({
          message: "All files uploaded successfully.",
          type: "success",
          timeout: 2000
        });
      } catch (error) {
        this.isUploading = false;
        this.isFileUploadDialogOpen = false;
        console.error('Error uploading files:', error);
        this.showTempMessageFn({
          message: `Failed to upload one or more files. ${error.message}`,
          type: "error",
          timeout: 5000
        });
      }
    },

    async uploadFileWithMessage(file) {
      this.showTempMessageFn({
        message: `Uploading ${file.name}...`,
        type: "info",
        timeout: 2000
      });

      try {
        await this.uploadFile({
          file,
          projectId: this.selectedGalaxy,
          eccsProjectId: this.selectedProject.id,
          buildIndex: this.buildIndex,
          sensitivityLevel: this.sensitivityLevel,
        });

        this.showTempMessageFn({
          message: `${file.name} uploaded successfully.`,
          type: "success",
          timeout: 2000
        });
      } catch (error) {
        console.error('Error uploading file:', file.name, error);
        this.showTempMessageFn({
          message: `Failed to upload ${file.name}. ${error.message}`,
          type: "error",
          timeout: 5000
        });
        throw error; // Re-throw the error to be caught in the main try-catch block
      }
    },
    askRelatedQuery(query) {
      this.userInput = query;
      this.sendMessage();
    },
    limitedEntities(entitiesHtml) {
      const entities = this.parseEntities(entitiesHtml);
      return entities.slice(0, 4); // Show only the first 4 entities/IAOs
    },
    parseRelationships(html) {
      const relationships = [];
      const relLines = html.split('<br>');
      relLines.forEach(line => {
        if (line.trim()) {
          const parts = line.split(' -> ');
          if (parts.length === 3) {
            relationships.push({
              entity1: parts[0].trim(),
              type: parts[1].trim(),
              entity2: parts[2].trim()
            });
          }
        }
      });
      return relationships;
    },
    remainingEntitiesCount(entitiesHtml) {
      const entities = this.parseEntities(entitiesHtml);
      return entities.length > 5 ? entities.length - 5 : 0;
    },
    openDialog(entitiesHtml) {
      this.dialogEntities = this.parseEntities(entitiesHtml).slice(5); // Show remaining entities in dialog
      this.dialog = true;
    },
    parseEntities(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const entities = [];
      doc.querySelectorAll('div').forEach(div => {
        const count = div.dataset.count;
        const a = div.querySelector('a');
        if (a) {
          entities.push({
            id: a.href.split('eid:')[1],
            url: a.href,
            name: a.textContent,
            count: count,
          });
        }
      });
      return entities;
    },
    handleInput(event) {
      this.debouncedUpdateInput(event.target.value);
      this.debouncedAdjustTextareaHeight();
      this.debouncedUpdateInputHeight();
      this.debouncedScrollToBottom();
    },
    updateInput(value) {
      this.userInput = value;
    },
    handleKeyDown(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      } else if (event.key === 'Enter' && event.shiftKey) {
        // Allow default behavior for Shift+Enter (new line)
        this.debouncedAdjustTextareaHeight();
      }
    },
    maintainFocusAppearance() {
      // Keep the focused appearance even when the textarea loses focus
      this.inputFocused = true;
    },
    setupWebSocketListeners(fetchData) {
      this.socket.onopen = () => {
        this.isConnected = true;
        console.log('WebSocket connected');
        this.reconnectAttempts = 0;
        this.hideReconnectionMessage();

        // Add a small delay before fetching data
        setTimeout(() => {
          if (fetchData) {
            this.fetchChatHistory();
            this.fetchProjects();
            this.fetchLimits();
            this.fetchUniverses();
            this.fetchUserSettingsAnonymous();
            this.fetchUserInfoAnonymous();
            this.fetchGalaxiesAnonymous();
          }
          this.startHeartbeat();
        }, 100);

        setTimeout(() => {
          this.fetchGalaxiesFn();
        }, 1000); // refetch w/1s delay for new accounts.
      };

      this.socket.onclose = this.socket.onerror = (event) => {
        if (event instanceof CloseEvent) {
          console.log('WebSocket disconnected:', event);
        } else {
          console.error('WebSocket error:', event);
        }
      };
    },
    connect(fetchData = true) {
      try {
        const token = localStorage.getItem("token");
        const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");
        let baseUrl = window.location.host;
        let protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
        let organization = window.location.pathname.split("/")[1];
        try {
          if (token) {
            this.socket = new WebSocket(`${protocol}//${baseUrl}/api/v1/${organization}/ws?token=${token}`);
          } else if (unstruct_anonymous_key) {
            this.socket = new WebSocket(`${protocol}//${baseUrl}/api/v1/${organization}/ws?unstruct_anonymous_key=${unstruct_anonymous_key}`);
          } else {
            this.socket = new WebSocket(`${protocol}//${baseUrl}/api/v1/${organization}/ws`);
          }
        } catch (error) {
          console.error('Error connecting to WebSocket:', error);
          this.showReconnectionMessage();
          return;
        }

        this.setupWebSocketListeners(fetchData);

        this.$store.commit('SET_SOCKET', this.socket);

        this.socket.onmessage = event => {
          const messageData = JSON.parse(event.data);
          // process user_settings data
          if (messageData.type === "user_settings") {
            this.relationshipMatchThreshold = messageData.relationship_match_threshold;
            this.relatedEntitiesMatchThreshold = messageData.related_entities_match_threshold;
            this.llmModelName = messageData.llm_model_name;
            this.llmTemperature = messageData.llm_temperature;
            this.useDefaultPrompt = messageData.use_default_prompt;
            this.customPrompt = messageData.custom_prompt;
            this.lengthFactor = messageData.length_factor;
            this.topEntitiesThreshold = messageData.top_entities_count;
            this.ragTokenThreshold = messageData.original_entities_RAG_token_threshold;
            this.relationshipsDisplayLimit = messageData.relationships_display_limit;
            this.google_search_results_count = messageData.google_search_results_count;
            this.build_personal_index_on_every_query = messageData.build_personal_index_on_every_query;
            this.google_search_skip_top_results = messageData.google_search_skip_top_results;
            this.google_search_skip_top_num = messageData.google_search_skip_top_num;
            this.google_search_low_range = messageData.google_search_low_range;
            this.google_search_high_range = messageData.google_search_high_range;
            this.use_google_search_for_rag = messageData.use_google_search_for_rag;
            this.rag_strategy = messageData.rag_strategy;
            this.site_searches = messageData.site_searches.map(item => ({
                site: item.site,
                filter: item.filter
            }));
          }

          else if (messageData.type === "anonymous_session") {
            localStorage.setItem("unstruct_anonymous_key", messageData.unstruct_anonymous_key);
            this.fetchUniverses();
          }

          else if (messageData.type === "galaxies") {
            this.$store.commit('project/SET_GALAXIES', messageData.data.items);
          }

          else if (messageData.type === "user_info") {
            this.$store.commit('websocket/SET_USER', messageData.data);
          }

          else if (messageData.type === "heartbeat_ack") {
            // console.log('Received heartbeat ack');
          }

          else if (messageData.type === 'eccs_projects_list') {
            this.projects = messageData.projects;
            if (!this.selectedProject) {
              this.selectedProject = this.projects[0];
            }
          }

          else if (messageData.type === 'object_content_response') {
            this.updateRagTokenMetadata(messageData);
          }

          else if (messageData.type === 'eccs_project_created') {
            const newProject = {
              id: messageData.id,
              name: messageData.name,
              description: messageData.description,
            }
            this.projects.push(newProject);
            this.selectedProject = newProject;
          }
          // if query_attributes data
          else if (messageData.type === 'query_attributes') {
            // Stop typing indicator
            this.isBotTyping = false;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1] && this.messages[this.messages.length - 1].typing) {
              this.messages.pop();
            }

            // add query_attributes to the message
            this.messages.push({
              user: "bot",
              queryAttributesHtml: messageData.query_attributes_html,
              typing: false,
            });

            // Start typing indicator again
            this.isBotTyping = true;
            this.messages.push({
              user: "bot",
              text: "",
              typing: true,
            });
          }

          else if (messageData.type === 'rag_view_limit_response') {
            this.$emit('rag-view-limit-response', messageData.data);
          }

          // if chat history data
          else if (messageData.type === 'chat_history') {
            this.handleChatHistory(messageData);
          }

          else if (messageData.type === "user_limits") {
           this.receiveLimits(messageData.limits);
          }

          //
          else if (messageData.type === "submissions_update") {
              this.$store.dispatch('user_submissions/receiveSubmissions', messageData);
          }

          else if (messageData.type === "liked_messages_processing_status") {
            this.$store.dispatch('user_interactions/receiveLikedMessagesProcessing', messageData);
          }

          // Status message for the user
          else if (messageData.type === "status_message") {
            this.statusMessage = messageData.message;
          }

          // concept_mapping_and lateral_thinking
          else if (messageData.type === 'concept_mapping_and_lateral_thinking') {
            this.isBotTyping = false;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1] && this.messages[this.messages.length - 1].typing) {
              this.messages.pop();
            }

            // add concept_mapping_and_lateral_thinking to the message
            this.messages.push({
              id: Date.now(),
              user: "bot",
              conceptMappingAndLateralThinkingHtml: messageData.concept_mapping_and_lateral_thinking,
              typing: false,
            });

            // Start typing indicator again
            this.isBotTyping = true;
            this.messages.push({
              user: "bot",
              text: "",
              typing: true,
            });
          }

          // If entities data
          else if (messageData.type === 'entities') {
            // Stop typing indicator
            this.isBotTyping = false;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1] && this.messages[this.messages.length - 1].typing) {
              this.messages.pop();
            }

            // add entities to the messages
            this.messages.push({
              user: "bot",
              component: 'entity-results-animation',
              componentProps: {
                entities: messageData.entities_formatted,
                isSearching: true,
                finalAnswerReceived: false
              },
              typing: false,
              finalAnswer: false,
              id: `entities-${Date.now()}`
            });

            // Start typing indicator again
            this.isBotTyping = true;
            this.messages.push({
              user: "bot",
              text: "",
              typing: true,
            });

          }
          // If IAO objects data
          else if (messageData.type === 'iao_objects') {
            // Stop typing indicator
            this.isBotTyping = false;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1] && this.messages[this.messages.length - 1].typing) {
              this.messages.pop();
            }
            // add IAO objects to the messages
            this.messages.push({
              user: "bot",
              iaoObjects: messageData.iao_objects,
              typing: false,
            });
            // Start typing indicator again
            this.isBotTyping = true;
            this.messages.push({
              user: "bot",
              text: "",
              typing: true,
            });
          }
          else if (messageData.type === "search_results") {
            this.isBotTyping = false;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1]?.typing) {
              this.messages.pop();
            }

            if (messageData.search_results.length === 0) {
              this.showTempMessageFn({
                message: "No search results found",
                type: "warning",
                timeout: 2000
              });
            } else {
              const searchResults = messageData.search_results.map((url, index) => ({
                id: index,
                url: url,
                name: new URL(url).hostname
              }));

              // Add message with search results animation
              const searchResultsMessage = {
                user: "bot",
                component: 'search-results-animation',
                componentProps: {
                  results: searchResults,
                  isSearching: true,
                  finalAnswerReceived: false
                },
                typing: false,
                finalAnswer: false,
                id: `search-${Date.now()}`
              };

              this.messages.push(searchResultsMessage);

              // Continue with typing indicator
              this.isBotTyping = true;
              this.messages.push({
                user: "bot",
                text: "",
                typing: true
              });
            }
          }

          // if relationships
          else if (messageData.type === 'relationships') {
            // Stop typing indicator
            this.isBotTyping = false;
            if (this.messages.length > 0 && this.messages[this.messages.length - 1] && this.messages[this.messages.length - 1].typing) {
              this.messages.pop();
            }

            // Add relationships to the messages
            const message = {
              user: "bot",
              relationshipsHtml: messageData.relationships_html,
              parsedRelationships: [],
              typing: false,
              finalAnswer: false,
            };
            this.messages.push(message);
            this.typeWriterEffectForRelationships(message);

            // Start typing indicator again
            this.isBotTyping = true;
            this.messages.push({
              user: "bot",
              text: "",
              typing: true,
            });
          }

          // Handle like/dislike and entity extraction messages
          else if (messageData.type === 'like_dislike') {
            // Update the message feedback status
            const message = this.messages.find(m => m.id === messageData.messageId);
            if (message) {
              message.userFeedback = messageData.action;
            }

            // If this is just a dislike action, we're done
            if (messageData.action === 'dislike') {
              return;
            }

            // For likes, we continue processing unless status is 'complete'
            if (messageData.status !== 'processing') {
              this.processingLikes = this.processingLikes.filter(id => id !== messageData.messageId);
            }
          }

          else if (messageData.type === 'entity_extraction') {
            const message = this.messages.find(m => m.id === messageData.messageId);
            if (message) {
              if (messageData.status === 'complete') {
                this.$set(message, 'isProcessing', false);
                this.showTempMessageFn({
                  message: `Entities extracted successfully`,
                  type: "success",
                  timeout: 2000
                });
              } else if (messageData.status === 'error') {
                this.$set(message, 'isProcessing', false);
                this.showTempMessageFn({
                  message: `Failed to extract entities: ${messageData.message}`,
                  type: "error",
                  timeout: 3000
                });
              }
            } else {
              console.error('Message not found for entity extraction:', messageData);
            }
          }

          // Handle submit_text data
          else if (messageData.type == 'submit_text_response') {
            if (messageData.status === "success") {
              this.showTempMessageFn(messageData.message);
              this.showTempMessageFn({
                message: messageData.message,
                type: "success",
                timeout: 2000
              });
            } else if (messageData.status === "error") {
              this.showTempMessageFn({
                message: messageData.message,
                type: "error",
                timeout: 5000
              });
            } else {
              this.showTempMessageFn({
                message: "Unknown error occurred",
                type: "error",
                timeout: 5000
              });
            }
          }

          // User settings confirmation
          else if (messageData.type == 'user_settings_confirmation') {
            this.showTempMessageFn({
              message: messageData.message,
              type: "success",
              timeout: 2000
            });
          }

          // Handle file_upload data
          else if (messageData.type == 'submit_file_response') {
            if (messageData.status === "success") {
              this.showTempMessageFn({
                message: messageData.message,
                type: "success",
                timeout: 2000
              });
            } else if (messageData.status === "error") { // TODO: Handle error case
              this.showTempMessageFn({
                message: messageData.message,
                type: "error",
                timeout: 5000
              });
            } else {
              this.showTempMessageFn({
                message: "Unknown error occurred",
                type: "error",
                timeout: 5000
              });
            }

          }
          // Update the final answer handler
          else if (messageData.type === 'answer') {
            // Stop typing indicator
            if (this.messages.length > 0 && this.messages[this.messages.length - 1]?.typing) {
              this.messages.pop();
            }

            this.isBotTyping = false;
            this.isFinalAnswer = true;
            this.clearTypingInterval();

            // Find and update search results component
            const searchResultsMessage = this.messages.find(msg =>
              msg.component === 'search-results-animation' && !msg.finalAnswer
            );

            if (searchResultsMessage) {
              searchResultsMessage.componentProps.finalAnswerReceived = true;
              searchResultsMessage.finalAnswer = true;
            }

            // Find and update entity results component
            const entityResultsMessage = this.messages.find(msg =>
              msg.component === 'entity-results-animation' && !msg.finalAnswer
            );

            if (entityResultsMessage) {
              entityResultsMessage.componentProps.finalAnswerReceived = true;
              entityResultsMessage.finalAnswer = true;
            }

            const botResponse = messageData.answer_html;
            const { questions: newRelatedQueries, remainingContent } = this.parseRelatedQuestions(botResponse);

            const newMessage = {
              user: "bot",
              text: "",
              fullText: this.sanitizeHTML(this.textToHtml(remainingContent)),
              id: messageData.id,
              buttons: messageData.buttons,
              isButton: messageData.buttons && messageData.buttons.length > 0,
              relatedQueries: messageData.related_queries,
              typing: true,
              finalAnswer: true,
              showActions: false,
              userFeedback: null,
              newRelatedQueries: newRelatedQueries,
              showNewRelatedQueries: true,
            };

            // Mark all previous bot messages as final answer
            this.messages.forEach(msg => {
              if (msg.user === "bot") {
                msg.finalAnswer = true;
                // Also update any search results components
                if (msg.component === 'search-results-animation') {
                  msg.componentProps.finalAnswerReceived = true;
                }
              }
            });

            this.messages.push(newMessage);

            setTimeout(() => {
              newMessage.typing = false;
              this.isBotTyping = false;
            }, 50);

            // Start type writer effect
            this.$nextTick(() => {
              this.typeWriterEffect(newMessage, () => {
                this.isResponseInProgress = false;
              });
            });

            // trigger job polling in JobUpdatesFeed
            this.$refs.jobUpdatesFeed.onNewJobSubmitted();
          }

          else if (messageData.type === "RAG_tokens") {
            this.updateRagTokenCount(messageData);
          }

          else if (messageData.type === "RAG_tokens_metadata") {
            this.updateRagTokenMetadata(messageData);
          }

          else if (messageData.type === "error") {
            this.showTempMessageFn({
              message: messageData.message,
              type: "error",
              timeout: 10000
            });
          }

          else if (messageData.type === "submit_youtube_transcript_response") {
            // do nothing, handled in the child component
          }

          else {
            console.log("Unknown message type", messageData);
            this.showTempMessageFn({
              message: "Unknown message type",
              type: "error",
              timeout: 5000
            });
          }
          // end of if/else if

        }; // end of onmessage

      } catch (error) {
        console.error('Failed to connect to WebSocket:', error);
      }
    },

    detectURLs(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.match(urlRegex);
    },
    sendMessage(event) {
      if (event && event.shiftKey) return;
      if (this.userInput.trim() === "" || this.isResponseInProgress) return;
      if (this.isResponseInProgress) {
        this.showTempMessageFn({
          message: "Please wait for the current response to complete",
          type: "info",
          timeout: 3000
        });
        return;
      }

      let messageToSend = this.userInput.trim();
      let displayText = messageToSend;

      this.latestUserInput = messageToSend;

      const sendMessageAttempt = () => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {

          // Check for URLs in the message
          const containsURLs = this.detectURLs(messageToSend);

          // Disable IAO prepend if URLs are found
          const shouldPrependBAO = this.shouldPrependBAO && !containsURLs;

          let iaoReference = null;
          if (shouldPrependBAO && this.activeObject && this.objectId) {
            iaoReference = {
              type: this.activeObject,
              id: this.objectId
            };
            messageToSend = `[IAO-${this.activeObject}: ${this.objectId}] ${messageToSend}`;
          }

          // Create a message object that includes the project info and message type
          const chatSettings = {
            relationship_match_threshold: this.relationshipMatchThreshold,
            related_entities_match_threshold: this.relatedEntitiesMatchThreshold,
            llm_temperature: this.llmTemperature,
            llm_model_name: this.llmModelName,
            custom_prompt: this.customPrompt,
            use_default_prompt: this.useDefaultPrompt,
            length_factor: this.lengthFactor,
            top_entities_count: this.topEntitiesThreshold,
            original_entities_RAG_token_threshold: this.ragTokenThreshold,
            relationships_display_limit: this.relationshipsDisplayLimit,
            build_personal_index_on_every_query: this.build_personal_index_on_every_query,
            google_search_results_count: this.google_search_results_count,
            google_search_skip_top_results: this.google_search_skip_top_results,
            google_search_skip_top_num: this.google_search_skip_top_num,
            google_search_low_range: this.google_search_low_range,
            google_search_high_range: this.google_search_high_range,
            use_google_search_for_rag: this.use_google_search_for_rag,
            rag_strategy: this.rag_strategy,
            site_searches: this.site_searches.map(item => ({
              site: item.site,
              filter: item.filter
            }))
          };
          const messageObject = {
            type: "user_query",
            content: messageToSend,
            project_id: this.selectedGalaxy,
            chat_settings: chatSettings,
          };

          // Clear the input only after successful connection
          this.userInput = "";
          this.resetInputHeight();

          this.$nextTick(() => {
            if (this.$refs.chatInput) {
              this.$refs.chatInput.value = '';
              this.$refs.chatInput.blur();
            }
            this.debouncedAdjustTextareaHeight();
            this.scrollToBottom();
          });

          // Add user message with IAO reference if present
          this.messages.push({
            user: "user",
            text: messageToSend,
            displayText: displayText,
            iaoReference: iaoReference,
            timestamp: new Date().toISOString(),
            chatSettings: messageObject.chat_settings,
          });

          // Add typing indicator
          this.messages.push({
            user: "bot",
            text: "",
            typing: true,
          });

          // Send the message to the server
          this.socket.send(JSON.stringify(messageObject));

          // Start timer
          this.startResponseTimer();

          this.isFinalAnswer = false;
          this.isBotTyping = true;
          this.isResponseInProgress = true;

          // refetch the currentGalaxy if it doesn't exist
          if (!this.selectedGalaxy) {
            this.fetchGalaxiesFn();
          }

          // Add typing indicator for the bot
          this.addTypingIndicator();
        } else {
          // Attempt to reconnect
          this.connect(false);

          // Show reconnecting message
          this.showTempMessageFn({
            message: "Reconnecting to the server...",
            type: "info",
            timeout: 3000
          });

          // Retry after a short delay
          setTimeout(() => {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
              sendMessageAttempt();
            } else {
              this.showTempMessageFn({
                message: "Unable to connect. Please try again.",
                type: "error",
                timeout: 5000
              });
            }
          }, 3000); // 3 seconds delay, adjust as needed
        }
      };

      sendMessageAttempt();
    },

    addTypingIndicator() {
      if (this.messages[this.messages.length - 1].user !== "bot" || !this.messages[this.messages.length - 1].typing) {
        this.messages.push({
          user: "bot",
          text: "",
          typing: true,
        });
      }
    },
    handleIAOClick(iaoReference) {
      this.setActiveObject({
        type: iaoReference.type,
        id: iaoReference.id
      });
      this.selectedProject = iaoReference.id;
      // You might want to add any additional logic here, such as highlighting the selected IAO
    },
    adjustTextareaHeight() {
      const textarea = this.$refs.chatInput;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
      this.scrollToBottom();
    },
    onButtonClicked(action, message) {
      if (message.isProcessing) return;

      // Set processing state on the message
      this.$set(message, 'isProcessing', true);

      this.socket.send(JSON.stringify({
        action: action,
        messageId: message.id,
        messageText: message.fullText
      }));

      this.$refs.jobUpdatesFeed.onNewJobSubmitted();
    },
    // Check if the like/dislike button is disabled
    buttonClasses(message) {
      return {
        'knowledge-chip': true,
        'processing': message.isProcessing,
        'liked': message.userFeedback === 'like'
      };
    },

    buttonIcon(message) {
      if (message.isProcessing) {
        return 'mdi-loading';
      }
      return message.userFeedback === 'like' ? 'mdi-check' : 'mdi-database-plus-outline';
    },

    buttonChipText(message) {
      if (message.isProcessing) {
        return 'Saving...';
      }
      return message.userFeedback === 'like' ? 'Saved' : 'Save to Knowledge';
    },
    typeWriterEffectForRelationships(message) {
      if (message.parsedRelationships.length === this.parseRelationships(message.relationshipsHtml).length) {
        // Relationships are already fully displayed (historical message)
        return;
      }

      const relationships = this.parseRelationships(message.relationshipsHtml);
      message.parsedRelationships = [];

      let index = 0;
      const intervalDuration = 100;
      const itemsPerInterval = 2;

      const addRelationships = () => {
        for (let i = 0; i < itemsPerInterval && index < relationships.length; i++) {
          this.$set(message.parsedRelationships, index, relationships[index]);
          index++;
        }

        if (index >= relationships.length) {
          cancelAnimationFrame(this.animationFrame);
          this.animationFrame = null;
        } else {
          this.animationFrame = setTimeout(addRelationships, intervalDuration);
        }
      };

      this.animationFrame = setTimeout(addRelationships, intervalDuration);
    },
    clearTypingInterval() {
      if (this.typingInterval) {
        clearInterval(this.typingInterval);
        this.typingInterval = null;
      }
    },
    scrollToBottom(force = false, smooth = false) {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        if (container) {
          if (smooth) {
            container.scrollTo({
              top: container.scrollHeight,
              behavior: 'smooth'
            });
          } else {
            container.scrollTop = container.scrollHeight;
          }
        }
      });
    },
    scrollToTop(smooth = false) {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer;
        if (container) {
          container.scrollTo({
            top: 0,
            behavior: smooth ? 'smooth' : 'auto'
          });
        }
      });
    },
    insertCitations(text) {
      const currentPath = window.location.pathname;
      const org_slug = currentPath.split('/')[1];
      const pathname = `/${org_slug}/ados`;
      const location = window.location.origin + pathname;

     // Add click handler for citation numbers with a data attribute
      text = text.replace(/\[(\d+(?:,\s*\d+)*(?::\w+)?)\]/g, (match, citation) => {
        return `<span
          class="citation-link"
          data-citation="${citation}"
        >${match}</span>`;
      });

      return text;
    },
  },
  created() {
    // don't connect if not logged in
    this.debouncedUpdateInput = debounce(this.updateInput, 100);
    this.debouncedAdjustTextareaHeight = debounce(this.adjustTextareaHeight, 100);
    this.debouncedUpdateInputHeight = debounce(this.updateInputHeight, 100);
    this.debouncedScrollToBottom = debounce(this.scrollToBottom, 100);
  },
  async mounted() {
    this.initializeLoggedInUser();
    this.focusInput();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('online', this.handleOnline);
    setTimeout(() => {
      this.triggerMenuOpenAndClose();
    }, 3000);
    this.typeMissionText();
    const container = this.$refs.messagesContainer;
    if (container) {
      container.addEventListener('scroll', this.handleScroll);
    }
    this.fetchUserInfo();
    this.initializeDarkMode();
    await this.fetchGalaxies();
    this.scrollForUserMessage();
    this.updateCurrentGalaxy();
    this.$root.$on('open-limits-page', () => {
      this.showLimits = true;
    });
    document.addEventListener('click', this.handleCitationClick);
  },
  beforeDestroy() {
    this.updateTitle();
    this.clearAutoCloseTimer();
    const container = this.$refs.messagesContainer;
    if (container) {
      container.removeEventListener('scroll', this.checkScrollPosition);
    }
    this.stopHeartbeat();
    if (this.socket) {
      this.socket.close();
    }
    this.$root.$off('open-limits-page');
    this.stopResponseTimer();
    document.removeEventListener('click', this.handleCitationClick);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700;900&display=swap');
#chat {
  position: relative;
  transition: padding-right 0.3s ease;
}

#chat.with-feed-open {
  padding-right: 250px; /* Adjust based on your JobUpdatesFeed width */
}

.chat-content {
  transition: width 0.3s ease;
}

#chat.with-feed-open .chat-content {
  width: calc(100% - 250px); /* Adjust based on your JobUpdatesFeed width */
}

.branding-icon {
  margin-right: 10px;
}

.user-account-section {
  display: flex;
  align-items: center;
}
.account-button-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.pro-label {
  position: absolute;
  top: 1px;
  right: -19px;
  background-color: gold;
  color: black;
  font-size: 10px;
  font-weight: bold;
  padding: 1px 3px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .pro-label {
    right: -10px;
  }
}

@media (max-width: 900px) {
  .pro-label {
    right: -10px;
  }
}

.branding-text {
  font-size: 18px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  color: gray;
  margin-right: 16px;
}
.llm-model-selection {
  margin-bottom: 20px;
}

.mission-text {
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0 5em 0;
}

.secondary-mission-color {
  color: #FF1493;
  margin-bottom: 40px;
}

.tutorial-text {
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
}

.typewriter-text {
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.35rem;
}

.typewriter-text strong, .typewriter-text b {
    font-weight: 550;
    letter-spacing: 0.01em;
}

.typewriter-cursor {
  display: inline-block;
  width: 3px;
  height: 1em;
  margin-left: 2px;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from, to { opacity: 0; }
  50% { opacity: 1; }
}

.star {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  animation: twinkle 4s infinite;
}

.star:nth-child(3n) {
  animation-delay: 1s;
}

.star:nth-child(3n+1) {
  animation-delay: 2s;
}

.planet {
  position: absolute;
  border-radius: 50%;
  animation: float 20s infinite ease-in-out;
}

.planet-1 {
  width: 100px;
  height: 100px;
  background: linear-gradient(45deg, #ff6b6b, #4ecdc4);
  top: 20%;
  right: 10%;
}

.planet-2 {
  width: 60px;
  height: 60px;
  background: linear-gradient(45deg, #f7d794, #786fa6);
  bottom: 15%;
  left: 5%;
  animation-delay: -10s;
}

.comet {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  top: 20%;
  left: -10%;
  animation: cometMove 10s linear infinite;
}

.comet::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 2px;
  background: linear-gradient(to right, #fff, transparent);
}

.satellite {
  position: absolute;
  width: 40px;
  height: 20px;
  background: linear-gradient(90deg, #ff9ff3, #feca57);
  border-radius: 10px;
  top: 70%;
  right: 15%;
  animation: orbit 30s linear infinite;
}

@keyframes twinkle {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes cometMove {
  0% { transform: translateX(0) translateY(0); }
  100% { transform: translateX(120vw) translateY(80vh); }
}

@keyframes orbit {
  0% { transform: rotate(0deg) translateX(100px) rotate(0deg); }
  100% { transform: rotate(360deg) translateX(100px) rotate(-360deg); }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.submit-textarea {
  min-height: 150px;
  resize: none;
}
.submit-text-dialog .v-card__title {
  font-size: 24px;
}

.submit-text-dialog .v-text-field__details,
.submit-text-dialog .v-messages {
  font-size: 12px;
}

.submit-text-dialog .v-btn {
  text-transform: none;
}

.submit-text-dialog .v-input--selection-controls {
  margin-top: 16px;
}

.panel-title {
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: bold;
}
.object-id-input {
  margin-top: 15px;
}
.v-list-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.slider-container {
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.slider-title {
  font-size: 1.2em;
  color: #004bfb;
  margin-bottom: 10px;
}

.bao-floating-container {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.panel-title {
  font-size: 1.2em;
  color: #004bfb;
  margin-bottom: 10px;
}

.active-bao {
  background-color: #004bfb;
  color: #000;
  transform: scale(1.05);
}

.rotating {
  animation: rotate-job-updates 1s linear infinite;
}

@keyframes rotate-job-updates {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.beta {
  position: relative;
  top: -5px;
}
.actions-toggle {
  cursor: pointer;
  color: darkgray;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.icon-with-bg {
  background: #f1f1f1;
  border-radius: 50%;
  padding: 5px;
  transition: background 0.2s ease;
}
.icon-with-bg:hover {
  background: #e1e1e1;
  cursor: pointer;
}
.theme--dark .icon-with-bg {
  background:  #404142;
}
.there--dark .icon-with-bg:hover {
  background: #333435;
}
.additional-actions {
  padding: 10px 10px 0 10px;
}

.theme--dark .additional-actions {
  background-color: #333435;
}

.latest-message {
  margin-top: 15px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.left-group {
  display: flex;
  align-items: center;
}

.right-group {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.custom-feedback-button {
  font-size: 12px;
  height: 32px !important;
  padding: 0 12px !important;
  border: none;
}

.feedback-buttons {
  display: flex;
}

.rewrite-button {
  margin-right: auto; /* This pushes the rewrite button to the left */
}

.rewrite-button:hover {
  color: grey;
  transition: color 0.1s ease;
}

.chat-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  background: linear-gradient(to right, rgba(230, 230, 230, 0.125) 0%, rgba(230, 230, 230, 0.1) 50%, rgba(230, 230, 230, 0.175) 100%);
}
.chat-header {
  position: sticky;
  top: 0;
  padding: 10px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

/* Hide chat header on mobile */
@media screen and (max-width: 768px) {
  .chat-header {
    display: none;
  }
}

.theme--dark .chat-header {
    background-color: rgba(30, 34, 39, 0.95) !important;
  }

.header-left, .header-center, .header-right {
  display: flex;
  align-items: center;
}
.right-controls-navigator {
  height: 100%;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.iao-status {
  position: relative;
  padding: 16px 12px;
  border-radius: 8px;
  background: #10B981;
  transition: all 0.3s ease;
}

.iao-status:not(.disabled):hover {
  background: #047857;
  transform: translateY(-1px);
}

.status-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-icon {
  font-size: 1.2rem;
}

.status-text-iao {
  font-size: 0.875rem;
  font-weight: 500; /* Semi-bold */
}

.status-indicator-iao {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 0 8px rgba(0, 198, 255, 0.5);
}

.control-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
}

.control-btn {
  position: relative;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.03) !important;
  border-radius: 8px !important;
}

.control-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0)
  );
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: 8px;
}

.control-btn:hover:not(:disabled) {
  transform: translateY(-1px);
  background: rgba(255, 255, 255, 0.05) !important;
}

.control-btn:hover::before {
  opacity: 1;
}

.control-btn.active {
  background: rgba(0, 198, 255, 0.15) !important;
}

.control-btn:disabled {
  opacity: 0.5 !important;
  background: rgba(255, 255, 255, 0.02) !important;
}

.modern-tooltip {
  background: rgba(30, 34, 39, 0.95) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  backdrop-filter: blur(10px) !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2) !important;
}

.tooltip-content {
  padding: 12px;
}

.tooltip-header {
  font-weight: 600;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.9);
}

.tooltip-description {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  line-height: 1.4;
}

.tooltip-description ul {
  margin: 8px 0 8px 16px;
}

.tooltip-description li {
  margin: 4px 0;
}

.tooltip-description em {
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
}

/* Theme-specific styles */
:deep(.theme--dark) .status-text-chat {
  color: rgba(255, 255, 255, 0.9);
}

:deep(.theme--light) .status-text-chat {
  color: rgba(0, 0, 0, 0.9);
}

.header-left {
  flex: 1;
  gap: 10px;
}

.left-controls-navigator {
  height: 100%;
  padding: 8px;
  border-radius: 12px;
  background: linear-gradient(
    to right,
    rgba(230,240,255,0.03) 0%,
    rgba(230,240,255,0.05) 50%,
    rgba(230,240,255,0.03) 100%
  );
  display: flex;
  align-items: center;
  gap: 16px;
}

.control-group {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  position: relative;
}

.control-group:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -8px;
  height: 24px;
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.control-btn {
  position: relative;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px !important;
}

.control-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0)
  );
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: 8px;
}

.control-btn:hover {
  transform: translateY(-1px);
  background: rgba(255, 255, 255, 0.05);
}

.control-btn:hover::before {
  opacity: 1;
}

.account-control {
  position: relative;
  display: flex;
  align-items: center;
}

.status-badges {
  position: absolute;
  top: -12px;
  right: -10px;
  display: flex;
  gap: 4px;
}

.status-badge {
  font-size: 0.625rem;
  font-weight: 600;
  padding: 2px 4px;
  border-radius: 4px;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.status-indicator-jobs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.03);
  transition: all 0.3s ease;
}

.status-indicator-jobs:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-1px);
}

.modern-menu, .modern-tooltip {
  background: rgba(30, 34, 39, 0.95) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  backdrop-filter: blur(10px) !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2) !important;
}

/* Ensure help-menu and combined-settings-dialog buttons follow the same style */
:deep(.control-item .v-btn) {
  background: rgba(255, 255, 255, 0.03) !important;
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
}

:deep(.control-item .v-btn:hover) {
  background: rgba(255, 255, 255, 0.05) !important;
  transform: translateY(-1px);
}

.header-center {
  justify-content: center;
}

.cosmic-navigator {
  position: relative;
  padding: 8px;
  border-radius: 12px;
}

.navigation-breadcrumbs {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 8px;
}

.nav-item {
  position: relative;
  padding: 16px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  gap: 8px;
}

.nav-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.nav-item.active {
  background: rgba(0, 198, 255, 0.1);
}

.nav-item.active .nav-icon {
  color: #10B981 !important;
  transform: scale(1.1);
}

.nav-label {
  font-size: 0.875rem;
  transition: color 0.3s ease;
}

.nav-icon {
  transition: all 0.3s ease;
}

.nav-dot {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%) scale(0);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #10B981;
  opacity: 0;
  transition: all 0.3s ease;
}

.nav-item.active .nav-dot {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.nav-separator {
  opacity: 0.5;
}

.selector-panel {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 8px;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 16px;
  z-index: 100;
}

.theme--dark .selector-panel {
  background: rgba(30, 34, 39, 0.95);
}

.selector-container {
  width: 100%;
}

.modern-select {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.theme--dark .modern-select {
  background: rgba(230,240,255,0.03);
}

.theme--dark .modern-select:hover {
  background: rgba(255, 255, 255, 0.05);
}

.theme--dark .modern-select-menu {
  background: rgba(30, 34, 39, 0.95) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.selector-icon {
  margin-right: 8px;
}

.create-btn {
  opacity: 0.7;
  transition: all 0.3s ease;
}

.create-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

/* Animation for panel */
.v-expand-transition-enter-active,
.v-expand-transition-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.v-expand-transition-enter,
.v-expand-transition-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/* Modern scrollbar styles */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}


.v-chip {
  margin-right: 4px !important;
}

.cosmic-highlight-text {
  color: #004bfb;
}

.tech-details {
  margin-left: 20px;
}

.settings-navigator {
  height: 100%;
  padding: 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.settings-chips {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
}

.settings-chip {
  background: rgba(255, 255, 255, 0.03) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  transition: all 0.2s ease-in-out !important;
}

/* Theme-specific styles */
:deep(.theme--dark) .settings-chip:hover {
  background: rgba(255, 255, 255, 0.08) !important; /* Slightly lighter but still dark */
  border-color: rgba(255, 255, 255, 0.2) !important;
}

:deep(.theme--light) .settings-chip:hover {
  background: rgba(0, 0, 0, 0.08) !important; /* Slightly darker but still light */
  border-color: rgba(0, 0, 0, 0.2) !important;
}

/* Override Vuetify's default hover behavior */
.settings-chip::before,
.settings-chip::after {
  display: none !important;
}

/* Ensure text contrast */
:deep(.theme--dark) .chip-text {
  color: rgba(255, 255, 255, 0.9) !important;
}

:deep(.theme--light) .chip-text {
  color: rgba(0, 0, 0, 0.9) !important;
}

/* For the primary strategy chip */
:deep(.theme--dark) .settings-chip.primary-strategy {
  background: linear-gradient(
    45deg,
    rgba(0, 198, 255, 0.15),
    rgba(0, 114, 255, 0.15)
  ) !important;
}

:deep(.theme--light) .settings-chip.primary-strategy {
  background: linear-gradient(
    45deg,
    rgba(0, 198, 255, 0.25),
    rgba(0, 114, 255, 0.25)
  ) !important;
}

.settings-chip:hover .edit-icon {
  opacity: 1;
  transform: translateX(0);
}

.theme--dark .settings-chip.primary-strategy {
  background: linear-gradient(
    45deg,
    rgba(0, 198, 255, 0.15),
    rgba(0, 114, 255, 0.15)
  ) !important;
}

.chip-text {
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0 4px;
}

.edit-icon {
  opacity: 0;
  transform: translateX(-4px);
  transition: all 0.2s ease;
}

.strategy-icon, .model-icon, .prompt-icon {
  font-size: 16px !important;
}

/* Tooltip Styles */
.settings-tooltip {
  background: rgba(30, 34, 39, 0.95) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px !important;
  backdrop-filter: blur(10px) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
}

.tooltip-content {
  padding: 8px 12px;
}

.tooltip-title {
  font-size: 0.75rem;
  margin-bottom: 4px;
}

.tooltip-value {
  font-size: 0.875rem;
  font-weight: 500;
}

/* Animation for edit icon */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-4px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.header-right {
  flex: 1;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.branding-text {
  font-weight: bold;
  margin-right: 10px;
}

.chat-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.menu-card {
  max-height: 100vh;
  overflow-y: auto;
}

.settings-box-container,
.rag-strategy-container-help,
.rag-strategy-container,
.cosmic-selectors {
  position: static;
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  max-height: none;
  border: none;
  box-shadow: none;
  background: transparent;
  padding: 0;
  margin: 8px 0;
}


.hierarchy-display {
  font-size: 0.875rem;
  color: #888;
}

@media (max-width: 768px) {
  .chat-header {
    flex-direction: column;
    align-items: stretch;
  }

  .header-left, .header-center, .header-right {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .chat-controls {
    flex-wrap: wrap;
    justify-content: center;
  }

  .hierarchy-display {
    text-align: center;
  }
}

.prompt-chip {
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 0.875rem;
}

.prompt-text, .rag-strategy-text {
  font-size: 0.875rem;
  color: #999;
}

.logo-container {
  margin-right: 10px;
}
.logo {
  width: 50px;
  height: auto;
  margin-bottom: 5px;
}

.chat-title {
  margin: 0;
  font-size: 1.2em;
}

.chat-subtitle {
  margin-top: 15px;
  font-size: 0.9em;
  clear: both;
  display: block;
  position: relative;
  top: 7px;
}

.chat-icon-container {
  position: fixed;
  bottom: 40px;
  right: 15px;
}
.chat-icon {
  width: 20px;
  height: 20px;
  transition: 0.3s all ease;
  padding: 5px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #004bfb;
  cursor: pointer;
}

.chat-icon-container:hover .chat-icon {
  border-color: #004bfb;
}

.chat-icon-container-alt {
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.chat-icon-alt {
  transition: color 0.3s ease;
}

.chat-container-alt:hover .chat-icon-alt {
  color: lightskyblue;
}

.minimize-icon {
  cursor: pointer;
}

.messages {
  padding: 0px;
}

.bot-message {
  overflow-wrap: break-word;
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  line-height: 1.35rem;
  transition: all 0.6s ease;
  padding: 0px 10px;
  margin-bottom: 20px;
}

.bot-message strong, .bot-message b {
    letter-spacing: 0.01em;
}

.bot-message p {
  border-radius: 20px;
  margin-bottom: 10px;
}

.bot-message p:empty {
  display: none;
  margin: 0;
}

.bot-message p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .bot-message {
    padding: 12px 16px;
  }
}

.bot-message h2, .bot-message h3 {
  font-size: 1rem;
  font-weight: 600;
}

.bot-message h1 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.75rem !important;
  margin-top: 1.5rem !important;
}


.bot-answer {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.bot-answer.fade-in {
  opacity: 1;
}

.bot-message ul, .bot-message ol {
  margin-bottom: 5px;
}

.bot-message ul li, .bot-message ol li {
  margin-bottom: 5px;
  line-height: 1.35rem;
}

.bot-message ul li:last-child {
  margin-bottom: 0;
}

.bot-message ul li::before {
  font-weight: bold;
  display: inline-block;
  width: 1em;
  color: inherit; /* This ensures the bullet inherits the text color */
}

.bot-message li:last-child {
  margin-bottom: 0;
}

.message-content {
  position: relative;
  padding: 1px 25px 0px 25px;
  border-radius: 16px 2px 16px 16px;
}

.theme--dark .message-content {
  background: #333435;
}

.user-message-container {
  margin: 0px;
  padding-top: 30px; /* Add space for the floating icon */
}

.user-message {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 900px) {
  .user-message {
    justify-content: flex-start;
  }
}

.user-message-content {
  padding: 20px 25px 20px 60px;
  border-radius: 2px 16px 16px 16px;
  font-size: 0.92rem;
  line-height: 1.35rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  letter-spacing: 0.01em;
  overflow-wrap: break-word;
  position: relative;
  max-width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}

.user-message-icon {
  position: absolute;
  left: -8px;
  top: -12px;
  font-size: 28px;
  background: linear-gradient(45deg, #2c3e50, #34495e); /* Darker, more subdued gradient */
  border-radius: 50%;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #3a4a5c; /* Slightly lighter than the background for subtle definition */
}

.user-message-icon:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.chat-settings {
  font-size: 0.9em;
  text-align: right;
  margin-top: 2px;
}

.message-body {
  display: flex;
  flex-direction: column;
}

.iao-reference {
  margin-bottom: 8px;
}

.iao-chip {
  cursor: pointer;
  font-weight: bold;
  font-size: 0.75rem;
  padding: 24px;
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%) !important;
}

.user-message p {
  margin: 0;
  color: inherit;
}

.user-message:hover .user-message-content {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .user-message-content {
    font-size: 16px;
    padding: 15px 20px 15px 50px;
  }

  .user-message-icon {
    font-size: 32px;
    left: -15px;
    top: -15px;
  }

  .iao-chip {
    font-size: 0.7rem;
  }
}
.messages-container {
  overflow-x: hidden;
  border: none;
  height: calc(100vh - 75px);
  max-height: none;
  border-radius: 5px;
  flex-grow: 1;
  overflow-y: auto;
  justify-content: center;
  scrollbar-width: none;
  -ms-overflow-style: none;


  /* Default state: width 52%, margin-right 22% */
  /* Total space left = 26% on each side for centered content */
  transition: padding 0.3s ease;
  padding: 0 18% 75px 15%;
  margin-top: 0;
  box-sizing: border-box;

  /* Keep same max-width as before */
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  /* Minimum width maintained */
  min-width: 300px;
}

/* Hide scrollbar */
.messages-container::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

/* RAG visible: width 45%, margin-right 40% */
/* Left space = 15%, Right space = 40% */
.messages-container.rag-content-visible {
  padding-right: 30%;
  padding-left: 10%;
}

/* Feed mini: width 52%, margin-right 28% */
/* Left space = 20%, Right space = 28% */
.messages-container.feed-mini {
  padding-right: 18%;
  padding-left: 15%;
}

/* Both states: width 52%, margin-right 43% */
/* Left space = 5%, Right space = 43% */
.messages-container.rag-content-visible.feed-mini {
  padding-right: 36%;
  padding-left: 0%;
}

/* RAG visible not mini: width 47%, margin-right 38% */
/* Left space = 15%, Right space = 38% */
.messages-container.rag-content-visible:not(.feed-mini) {
  padding-right: 30%;
  padding-left: 10%;
}

/* Chat input wrapper adjustments to match container widths */
.chat-input-wrapper {
  position: fixed;
  left: 52%;
  transform: translateX(-50%);
  width: 52%; /* Match default container width */
  max-width: 1000px;
  min-height: 75px;
  border-radius: 20px 20px 0 0;
  transition: all 0.3s ease;
  bottom: 0px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Theme-specific wrapper styles */
.theme--dark .chat-input-wrapper {
  background: rgba(30, 34, 39, 0.95); /* Dark theme background */
  border-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.theme--light .chat-input-wrapper {
  background: rgba(255, 255, 255, 0.95); /* Light theme background */
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* When RAG content is visible */
.chat-input-wrapper.rag-content-visible {
  width: 45%; /* Match RAG visible width */
  left: 45%;
  transform: translateX(-65%);
}

.chat-input-wrapper.feed-mini {
  width: 52%; /* Match feed mini width */
  left: 50%;
  transform: translateX(-58%);
}

.chat-input-wrapper.rag-content-visible.feed-mini {
  width: 45%; /* Match both states width */
  left: 41%;
  transform: translateX(-72%);
}

@media (max-width: 900px) {
  .messages-container,
  .messages-container.rag-content-visible,
  .messages-container.feed-mini,
  .messages-container.rag-content-visible.feed-mini {
    padding: 0 16px 75px 0px;
  }

  .chat-input-wrapper,
  .chat-input-wrapper.rag-content-visible,
  .chat-input-wrapper.feed-mini,
  .chat-input-wrapper.rag-content-visible.feed-mini {
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
    right: 50%;
    min-height: 60px;
  }
  .queries-header {
    margin-bottom: 10px;
  }
}


/* Hover effects */
.theme--dark .chat-input-wrapper:hover {
  background: rgba(35, 37, 42, 0.95);
  border-color: rgba(255, 255, 255, 0.15);
}

.theme--light .chat-input-wrapper:hover {
  background: rgba(250, 250, 250, 0.95);
  border-color: rgba(0, 0, 0, 0.15);
}

.input-label {
  position: absolute;
  top: 14px;
  left: 30px;
  right: 48px;
  pointer-events: none;
  z-index: 1;
  transition: opacity 0.2s ease;
}

@media (max-width: 768px) {
  .messages-container {
    height: calc(100vh - 60px);
    padding-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .input-label {
    left: 90px;
  }
}

@media (max-width: 900px) {
  .input-label {
    left: 90px;
  }
}

.input-has-content {
  opacity: 0;
}

.placeholder-content {
  display: flex;
  align-items: center;
  gap: 8px;
  color: gray;
  font-size: 0.875rem;
}

.source-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.source-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 12px;
  background: rgba(96, 165, 250, 0.1);
  color: #004bfb;
  font-size: 0.75rem;
  white-space: nowrap;
}

.theme--dark .source-tag {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.tag-icon {
  opacity: 0.8;
}

.placeholder-text {
  white-space: nowrap;
}

.chat-input {
  width: 100%;
  background-color: transparent;
  border-radius: 50px;
  padding: 10px 120px 10px 30px;
  outline: none;
  border: none;
  resize: none;
  overflow-y: auto;
  min-height: 40px;
  max-height: 400px;
  font-size: 0.875rem;
  line-height: 1.5;
  z-index: 2;
  transition: all 0.3s ease;
  color: rgba(255, 255, 255, 0.87);
}

.chat-input:focus,
.chat-input.input-focused {
  outline: none;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.03); /* Subtle background on focus */
}

.chat-input::placeholder {
  color: rgba(255, 255, 255, 0.4); /* Lighter placeholder text */
}

/* Theme-aware styles */
.theme--light .chat-input-wrapper .chat-input {
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: transparent;
}

.theme--dark .chat-input-wrapper .chat-input {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: transparent;
}

/* Scrollbar styling for the input */
.chat-input::-webkit-scrollbar {
  width: 4px;
}

.chat-input::-webkit-scrollbar-track {
  background: transparent;
}

.chat-input::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}

/* Ensure Vuetify doesn't override our styles */
.v-application .theme--light.v-input input,
.v-application .theme--light.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}

.v-application .theme--dark.v-input input,
.v-application .theme--dark.v-input textarea {
  color: rgba(255, 255, 255, 0.87);
}

@media (max-width: 900px) {
  .chat-input {
    padding: 10px 60px 10px 90px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .chat-input {
    padding: 10px 60px 10px 90px;
    width: 100%;
  }
}

.bao-controls {
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.strategy-chip {
  cursor: pointer;
  transition: opacity 0.3s;
}
.strategy-chip:hover {
  opacity: 0.8;
}

.rag-strategy-container {
  width: 100%;
}

@media (max-width: 768px) {
    .rag-strategy-container {
      top: 70%;
    }
  }

  @media (max-width: 900px) {
    .rag-strategy-container {
      top: 70%;
    }
  }

.rag-strategy-container.expanded {
  width: 250px;
}

.toggle-btn-rag {
  position: absolute;
  top: 2px;
  right: 2px;
}

.strategy-icon {
  font-size: 20px;
  cursor: pointer;
}

.rag-strategy-header {
  display: flex;
  align-items: center;
  padding: 8px;
}
.collapsed-header {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.rag-strategy-title-small {
  font-size: 12px;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.strategy-expanded {
  margin-top: 10px;
}

.rag-strategy-select {
  width: 50%;
  font-size: 12px;
}

.rag-strategy-select-mini {
  width: 80%;
  font-size: 12px;
}

.rag-strategy-select ::v-deep .v-input__slot {
  min-height: 30px !important;
}

.rag-strategy-select ::v-deep .v-label {
  top: 6px;
  font-size: 12px;
}

.strategy-text {
  font-size: 12px;
}

.rag-explanation-icon {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.rag-explanation-icon:hover {
  opacity: 1;
}

/* Add styles for small buttons */
.v-btn.v-size--small {
  height: 28px;
  font-size: 0.8rem;
}

/* Style for the chip */
.v-chip.v-size--small {
  height: 24px;
  font-size: 0.75rem;
}

.send-icon-container {
  position: absolute;
  top: 35%;
  right: 5px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .send-icon-container {
    right: 10px;
  }
}

@media (max-width: 900px) {
  .send-icon-container {
    right: 10px;
  }
}

.send-icon {
  color: gray;
  cursor: pointer;
  transition: color 0.3s;
}

.llm-model-name {
  font-size: .875rem;
  color: #999;
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.send-icon-active {
  color: #004bfb;
}

.send-icon:hover {
  color: #004bfb;
}

.stop-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: gray;
  cursor: pointer;
  transition: color 0.3s;
}

.rag-icon-container {
  position: absolute;
  right: 40px;
  top: 35%;
  transform: translateY(-50%);
  z-index: 1;
}

@media (max-width: 768px) {
  .rag-icon-container {
    left: 10px;
  }
}

@media (max-width: 900px) {
  .rag-icon-container {
    left: 10px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 1s linear infinite !important;
  transform-origin: center center;
  display: inline-block; /* Ensures the icon takes up its own block for spinning */
}

.chat-input:focus {
  box-shadow: none;
}

.typing-indicator {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  margin-bottom: 20px;
}

dot-container {
  width: 40px; /* Give container explicit width */
  height: 20px; /* Give container explicit height */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure dot doesn't get cut off during animation */
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #10B981;
  border-radius: 50%;
  animation: slide 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  position: relative;
  display: block; /* Ensure dot is displayed as block element */
}

.timer {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-message {
  font-family: 'Inter', sans-serif;
  font-size: 0.75rem;
  opacity: 0.8;
  animation: fadeInOut 2s infinite;
  margin-bottom: 10px;
}

@keyframes slide {
  0%, 100% {
    transform: translateX(-10px);
    opacity: 0.3;
  }
  50% {
    transform: translateX(10px);
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.6; }
  50% { opacity: 1; }
}

.entity-container {
  border-radius: 0px;
  padding: 10px !important;
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0; /* Start with 0 opacity */
}

/* Apply background color only in dark theme */
.theme--dark .entity-container {
  background-color: #0a0f0d;
}


@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.entity-container > * {
  animation: fadeIn 0.5s ease-out forwards;
  animation-delay: calc(var(--child-index, 0) * 0.3s); /* Increased delay for smoother effect */
  opacity: 0;
}


.entity-container .v-card__text {
  padding: 10px 5px !important;
  min-width:0
}

.entity-container .v-card__title {
  padding: 10px 5px !important;
}

.entity-container .v-card__subtitle {
  padding: 10px 5px !important;
}

.entity-name {
  font-weight: bold;
  line-height: 1.2;
}
.entity-count {
  font-size: 0.8em;
  opacity: 0.7;
}
.source-text {
  font-weight: bold;
  line-height: 1.2;
}
.v-card {
  transition: all 0.3s ease-in-out;
}

.v-card.on-hover {
    box-shadow: 0 0 15px rgba(0, 198, 255, 0.4) !important;
  }

.v-card__text {
  padding: 15px !important;
  min-width:0
}

.v-card__title {
  padding: 15px !important;
}

.v-card__subtitle {
  padding: 15px !important;
}

.source-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.search-results-dialog .v-card {
  transition: all 0.3s ease-in-out;
}

.search-results-dialog .source-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.headline {
  padding: 10px 20px !important;
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
}

.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #004bfb;
  color: #fff;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
}

@keyframes button-clicked-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.related-queries-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.query-text {
  flex-grow: 1;
  margin-right: 16px; /* Adjust this value to control space between text and icon */
}

.query-icon {
  flex-shrink: 0;
}

.related-queries ul {
  list-style-type: none;
}

.related-query-item {
  padding: 5px;
  transition: all 0.1s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Hover effect */
.related-query-item:hover {
  color: #004bfb;
}

.related-query-item span {
  transition: color 0.1s ease;
}

.relationships-container {
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
}

.relationships-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.entities-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.related-queries-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.related-queries {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 12px;
}

.queries-header {
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 10px 0px;
  background: linear-gradient(to right, rgba(230, 240, 255, 0.1) 0%, rgba(230, 240, 255, 0.3) 50%, rgba(230, 240, 255, 0.1) 100%);
}

.mode-selector {
  display: flex;
  gap: 4px;
  padding: 2px;
  border-radius: 8px;
}

.mode-btn {
  opacity: 0.5;
  transition: all 0.3s ease !important;
  min-width: unset !important;
  letter-spacing: 0 !important;
}

.mode-btn.active {
  opacity: 1;
}


.expand-btn {
  transition: transform 0.3s ease;
}

.expand-btn:hover {
  transform: translateY(-1px);
}

.queries-content {
  margin-top: 1rem;
}

.empty-state {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  font-size: 0.85rem;
}

.queries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
}
.query-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word; /* Allows breaking of long words. */
  min-width: 0; /* Ensures flex items can shrink below content size */
}

.query-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

/* Dark theme */
.theme--dark .query-card {
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  border-color: rgba(255, 255, 255, 0.1);
}

.theme--dark .query-card:hover {
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.2) 50%, rgba(230,240,255,0.1) 100%);
  border-color: rgba(255, 255, 255, 0.1);
}

/* Light theme */
.theme--light .query-card {
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(226, 232, 240, 0.8);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
}

.theme--light .query-card:hover {
  background: rgba(255, 255, 255, 1);
  border-color: rgba(203, 213, 225, 1);
  box-shadow: 0 4px 12px rgba(148, 163, 184, 0.1);
}

.query-content {
  flex: 1;
  margin-right: 1rem;
}

.query-number {
  font-size: 0.75rem;
  opacity: 0.5;
  margin-bottom: 0.25rem;
  display: block;
}

.query-text {
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.4;
}

.action-icon {
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.query-card:hover .action-icon {
  opacity: 1;
  transform: translateX(0);
}

.attributes-container {
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.attributes-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.attributes-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.v-chip {
  transition: all 0.3s ease;
}

.v-chip:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.attribute-chip {
  margin: 5px;
  padding: 10px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.attribute-chip:hover {
  background-color: #004bfb;
}

.concept-mapping-toggle {
  cursor: pointer;
  border-radius: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.concept-mapping-container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0px !important;
}

.concept-map-label {
  font-size: 0.8rem;
  color: #A9A9A9;
}

.concept-mapping-title {
  font-size: 1.2em;
}

.entities-title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.relationship-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.relationship-item:last-child {
  border-bottom: none;
}

.relationship-entity {
  font-weight: bold;
  flex: 1;
}

.relationship-type {
  margin: 0 10px;
  font-style: italic;
}

.relationship-icon {
  color: #004bfb;
  margin: 0 5px;
  width: 24px;
  flex-shrink: 0;
  text-align: center;
}

.relationships-container p {
  margin: 0 0 10px 0;
  font-size: 1.1em;
}

.relationship-items {
  line-height: 1.35rem;
}

.build-index-checkbox {
  margin-top: 16px;
}

.pro-chip {
  font-size: 0.625rem;
  font-weight: bold;
}

.temp-message-container {
  position: fixed;
  top: env(safe-area-inset-top, 20px);
  left: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 10000;
}

.temp-message {
  pointer-events: auto;
  width: auto;
  max-width: 90%;
  min-width: 300px;
  padding: 12px 40px 12px 20px; /* Increased right padding for close button */
  position: relative;
  border: 1px solid #b8c2cc !important;
  text-align: left; /* Changed to left align for better readability */
  border-radius: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05), 0 1px 3px rgba(0,0,0,0.1);
  display: flex;
  align-items: flex-start; /* Changed to flex-start for better alignment with multi-line text */
}

.temp-message-content {
  flex-grow: 1;
  padding-right: 20px; /* Added padding to prevent text from touching close button */
  word-break: break-word; /* Allows long words to break and wrap */
}

.close-btn {
  position: absolute !important;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  transition: opacity 0.4s ease, background-color 0.2s ease !important;
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.05) !important;
  margin-left: 8px; /* Added margin to separate from text */
}

/* Media query for very small screens */
@media screen and (max-width: 320px) {
  .temp-message {
    min-width: unset; /* Remove min-width for very small screens */
    max-width: 100%; /* Allow full width on very small screens */
  }
}

@media screen and (max-width: 768px) {
  .temp-message {
    min-width: 300px; /* Adjust min-width for smaller screens */
  }
}

@media screen and (max-width: 900px) {
  .temp-message {
    min-width: 300px; /* Adjust min-width for smaller screens */
  }
}

.temp-message--error {
  background-color: #FFEBEE;
  border-left: 4px solid #F44336;
  color: #D32F2F;
}

.temp-message--warning {
  background-color: #FFF3E0;
  border-left: 4px solid #FF9800;
  color: #F57C00;
}

.temp-message--info {
  background-color: #E3F2FD;
  border-left: 4px solid #2196F3;
  color: #1976D2;
}

.temp-message--success {
  background-color: #E8F5E9;
  border-left: 4px solid #4CAF50;
  color: #388E3C;
}

.temp-message .v-icon {
  margin-right: 12px;
  flex-shrink: 0;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-20px) translateX(-50%);
  opacity: 0;
}
.close-btn {
  position: absolute !important;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  transition: opacity 0.4s ease, background-color 0.2s ease !important;
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.close-btn:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.close-btn .v-icon {
  font-size: 16px !important;
}

/* Adjust the icon color for better visibility */
.temp-message--error .close-btn {
  color: #D32F2F !important;
}

.temp-message--warning .close-btn {
  color: #F57C00 !important;
}

.temp-message--info .close-btn {
  color: #1976D2 !important;
}

.temp-message--success .close-btn {
  color: #388E3C !important;
}

.file-input {
  display: none;
}

.rewrite-button {
  padding: 0 8px !important;
  border: none !important;
}

.rewrite-button .v-icon {
  font-size: 24px !important;
}

.project-dialog .v-card__title {
  font-size: 24px;
}

.project-dialog .v-list-item {
  transition: background-color 0.3s;
}

.project-dialog .v-list-item:hover {
  background-color: #282828;
}

.project-dialog .v-list-item__avatar {
  margin-right: 16px;
}
.project-dialog .v-messages {
  font-size: 12px;
}

.project-dialog .v-btn {
  text-transform: none;
}

.file-upload-dialog .v-card__title {
  font-size: 24px;
}

.file-upload-dialog .v-text-field__details,
.file-upload-dialog .v-messages {
  font-size: 12px;
}

.file-upload-dialog .v-btn {
  text-transform: none;
}

.file-upload-dialog .v-input--selection-controls {
  margin-top: 16px;
}

.fade-transition-enter-active,
.fade-transition-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-transition-enter,
.fade-transition-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.settings-header {
  padding: 12px;
  position: relative;
  overflow: hidden;
}

.settings-header::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
  animation: ripple 15s infinite linear;
  opacity: 0.3;
}

@keyframes ripple {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.header-icon {
  font-size: 32px;
  margin-right: 16px;
}

.header-title {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.universe-container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.universe-label {
  margin-right: 8px;
}

.universe-name {
  font-weight: 600;
  margin-right: 8px;
}

.info-icon {
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.info-icon:hover {
  opacity: 0.8;
}

.settings-dialog {
  transition: all 0.5s ease;
}

.settings-dialog .v-input__control {
  margin-bottom: 20px;
}

.settings-dialog .v-subheader {
  height: 24px;
  padding: 0;
}

.settings-dialog .v-input {
  margin-top: 40px !important;
}

/* Optional: Add a subtle scale effect */
.settings-dialog.v-card {
  transform-origin: top center;
}

.fade-transition-enter-active .settings-dialog.v-card {
  animation: scaleIn 0.5s ease;
}

.fade-transition-leave-active .settings-dialog.v-card {
  animation: scaleOut 0.5s ease;
}

.tune-answer-btn {
  transition: opacity 0.3s ease;
  background-color: #004bfb;
}

.tune-answer-btn:hover {
  opacity: 0.9;
}

.tune-answer {
  padding: 24px;
}

.theme--dark .tune-answer {
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
}

@keyframes scaleIn {
  from { transform: scale(0.95); }
  to { transform: scale(1); }
}

@keyframes scaleOut {
  from { transform: scale(1); }
  to { transform: scale(0.95); }
}

.generating-answer {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.generating-answer p {
  margin-bottom: 20px;
}

.llm-model-info-container {
  position: absolute;
  right: 0px;
  top: 60%;
  z-index: 1000;
}

.scroll-button-container {
  position: sticky;
  bottom: 7px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.scroll-button {
  opacity: 0.7;
  transition: opacity 0.3s;
}

.scroll-button:hover {
  opacity: 1;
}

.v-tooltip {
  display: inline-block !important;
}

.neutral-hover {
  transition: background-color 0.3s ease;
}

::v-deep .custom-disabled-btn.v-btn--disabled {
  opacity: 0.5;
}

::v-deep .custom-disabled-btn.v-btn--disabled .v-icon {
  color: rgba(255, 255, 255, 0.3) !important;
}

.ai-disclaimer {
  text-align: center;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  color: #666;
  cursor: help;
}
.disclaimer-text {
  border-bottom: 1px dotted #666;
}

.llm-model-info {
  text-align: center;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  color: #666;
}

@media (max-width: 768px) {
  .llm-model-info {
    display: none;
  }
}

@media (max-width: 900px) {
  .llm-model-info {
    display: none;
  }
}

.model-text {
  border-bottom: 1px dotted #666;
  cursor: help;
}

.toggle-btn {
  position: absolute;
  top: 0px;
  right: 0px;
}

.selector-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}
.icon-text-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}

.tiny-text {
  font-size: 14px;
  margin-left: 4px;
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectors-expanded {
  position: absolute;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.selector-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 5px;
  backdrop-filter: blur(8px);
}

.cosmic-select {
  font-size: 16px;
  min-width: 200px;
}

.selector {
  width: 220px;
}

.cosmic-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cosmic-info-text {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 4px;
}

.help-icon {
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease;
}

.create-icon {
  color: grey;
  cursor: pointer;
  transition: color 0.3s ease;
}

.create-icon:hover {
  color: #0072ff;
}

.help-icon:hover {
  color: #004bfb;
}

/* Styling for locked pro features */
.pro-locked {
  cursor: not-allowed;
}

.pro-locked {
  animation: gentle-pulse 2s infinite;
}

/* Hover effect for pro-locked icons */
.pro-locked:hover {
  color: #64B5F6; /* A light blue color, adjust as needed */
}

.project-item {
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.project-item:hover {
  background-color: rgba(33, 150, 243, 0.1) !important;
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.project-item:hover .chat-hint {
  opacity: 1;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.button-pro-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.pro-icon {
  position: absolute;
  top: -8px;
  right: 8px;
  font-size: 12px;
  background: rgba(0,0,0,0.6);
  border-radius: 50%;
  padding: 2px;
}

.futuristic-button-wrapper {
  position: relative;
  display: inline-block;
}

.futuristic-button {
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.3s ease;
}

.glow-effect {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: linear-gradient(45deg, #004bfb, #0072ff);
  background-size: 200% 200%;
  border-radius: 4px;
  filter: blur(3px);
  opacity: 0;
  z-index: 0;
  transition: opacity 0.3s ease;
}

.futuristic-button-wrapper:hover .glow-effect {
  opacity: 0.2;
  animation: subtleGlowAnimation 4s ease-in-out infinite;
}

.futuristic-button-wrapper:hover .futuristic-button {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 198, 255, 0.2);
}

@keyframes subtleGlowAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.rag-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.v-tooltip__content {
  z-index: 9999 !important;
  opacity: 1 !important;
  padding: 6px 10px !important;
  border-radius: 4px !important;
  font-size: 0.9em !important;
  transition: all 0.3s ease !important;
}

.chip {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;
}

.chip-icon {
  margin-right: 6px;
  font-size: 14px;
}

.chip-text {
  position: relative;
  font-size: 1em;
  color: grey;
}

.toggle-button {
  border: none;
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  outline: none;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.toggle-button:active {
  transform: scale(0.95);
}

.toggle-button__content {
  display: flex;
  align-items: center;
}

.toggle-button__icon {
  margin-right: 4px;
}

.toggle-button__text {
  line-height: 1;
}
.copy-button {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.copy-button:hover {
  opacity: 1;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.v-icon {
  animation: fadeIn 0.3s;
}
.custom-switch {
  margin-right: -25px !important; /* Adjust this value as needed */
}

.toggle-label {
  font-size: 1rem;
}

.earth-mars-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.earth-mars-switch ::v-deep .v-input__slot {
  margin-bottom: 0;
}
.earth-mars-switch ::v-deep .v-input--switch__track {
  background-color: #4682B4 !important;
  opacity: 0.5;
}
.earth-mars-switch ::v-deep .v-input--switch__thumb {
  color:  #4682B4 !important;
}
.earth-mars-switch ::v-deep .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
  color:  #004bfb !important;
}
.earth-mars-switch ::v-deep .v-input--switch--inset.v-input--is-dirty .v-input--switch__track {
  background-color:  #004bfb !important;
  opacity: 0.5;
}
.earth--text {
  color: #4682B4 !important;
}
.mars--text {
  color: #B07D56 !important;
}
.custom-switch {
  /* Adjust the transform scale to make the switch smaller */
  transform: scale(0.75);
  transform-origin: left center;
}

.custom-switch ::v-deep .v-input__slot {
  margin-bottom: 0 !important;
}

.custom-switch ::v-deep .v-messages {
  display: none;
}

/* Adjust the label spacing */
.custom-switch ::v-deep .v-label {
  font-size: 0.75rem !important;
  margin-left: 4px;
}

.custom-hover {
  position: relative;
  transition: all 0.3s ease;
}

.custom-hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: inherit;
}

.custom-hover:hover::before {
  opacity: 1;
}

.custom-hover:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-hover:hover .v-icon {
  color: #004bfb !important;
}

.custom-hover .v-icon {
  position: relative;
  z-index: 1;
}
.custom-hover .v-badge__badge {
  z-index: 2;
}

.custom-hover:hover .v-badge__badge {
  transform: scale(0.91);  /* Counteract the button scaling */
}

/* Ensure proper alignment within the button */
.custom-hover.v-btn--icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-hover.v-btn--icon .v-icon {
  margin: 0;
}

.header-content {
  text-align: center;
}

.feedback-button.processing {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.knowledge-button-wrapper {
  display: inline-flex;
  align-items: center;
}

.knowledge-chip {
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 24px;
}

.theme--dark .knowledge-chip {
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
}

.knowledge-chip.processing {
  pointer-events: none;
}

.knowledge-chip.processing .v-icon {
  animation: spin 1s linear infinite;
}

.knowledge-chip.knowledge-chip-saved {
  border-color: #4CAF50;
}

.pro-badge {
  opacity: 0.7;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Ensure disabled state is enforced */
.knowledge-chip.v-chip--disabled {
  pointer-events: none !important;
  opacity: 0.7;
}

.messages:empty {
  display: none;
}

.bot-message:empty {
  display: none;
}

.message-content:empty {
  display: none;
}

.view-entities-chip {
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 24px;
}

.view-entities-chip:hover {
  background-color: var(--v-primary-lighten5);
}
</style>
