import { render, staticRenderFns } from "./LikedMessagesProcessingStatus.vue?vue&type=template&id=30bd5df9&scoped=true"
import script from "./LikedMessagesProcessingStatus.vue?vue&type=script&lang=js"
export * from "./LikedMessagesProcessingStatus.vue?vue&type=script&lang=js"
import style0 from "./LikedMessagesProcessingStatus.vue?vue&type=style&index=0&id=30bd5df9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30bd5df9",
  null
  
)

export default component.exports