<template>
    <div>
      <v-tooltip right content-class="modern-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click="openSettingsDialog"
          class="settings-icon custom-hover"
          v-bind="attrs"
          v-on="on"
        >
          <resources-manager-icon
            :size="28"
          />
        </v-btn>
      </template>
      <span>Upload Images, Documents, Links, Youtube videos, Text, Search Artifacts, Limits & Plans</span>
    </v-tooltip>

      <v-dialog v-model="settingsDialogOpen" max-width="1000px">
        <v-card class="settings-dialog">
          <v-card-title class="headline text-center">Uploads, Artifacts & Limits</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col v-for="(item, index) in settingsItems" :key="index" cols="12" sm="6" md="4">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      @click="item.action"
                      :elevation="hover ? 8 : 2"
                      :class="{ 'on-hover': hover }"
                      class="d-flex flex-column align-center pa-4 settings-card"
                    >
                      <v-icon size="48" :color="item.color">{{ item.icon }}</v-icon>
                      <v-card-title class="text-center">{{ item.title }}</v-card-title>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue lighten-1" text @click="settingsDialogOpen = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>

  <script>
import ResourcesManagerIcon from './custom_icons/ResourcesManagerIcon.vue'
  export default {
  components: { ResourcesManagerIcon },
    name: 'CombinedSettingsDialog',
    data() {
      return {
        settingsDialogOpen: false,
        settingsItems: [
          { title: 'Upload Documents', icon: 'mdi-file-document-multiple-outline', color: '#10B981', action: this.openFileUploadDialog },
          { title: 'Upload Images', icon: 'mdi-folder-multiple-image', color: '#FFC107', action: this.openImageUploadDialog },
          { title: 'Link Youtube Videos', icon: 'mdi-youtube', color: '#F43F5E', action: this.openYoutubeSubmitDialog },
          { title: 'Paste Text', icon: 'mdi-text-recognition', color: '#FF9800', action: this.openSubmitTextDialog },
          { title: 'My Planets', icon: 'mdi-earth', color: '#64748B', action: this.openProjectsDialog },
          { title: 'My Uploads / Sources', icon: 'mdi-file-table-box-multiple-outline', color: '#8B5CF6', action: this.handleSubmissionsIconClick },
          { title: 'Search Interactive Objects', icon: 'mdi-image-filter-center-focus', color: '#00BCD4', action: this.openObjectsDialog },
          { title: 'Web Search Artifacts', icon: 'mdi-web', color: '#004bfb', action: this.handleLikedMessagesProcessingIconClick },
          { title: 'Usage, Limits & Plans', icon: 'mdi-chart-bar-stacked', color: '#8BC34A', action: this.handleLimitsIconClick }
        ]
      }
    },
    methods: {
      openSettingsDialog() {
        this.settingsDialogOpen = true
      },
      openKnowledgeEmpowermentSettings() {
        this.$emit('open-knowledge-empowerment-settings')
        this.settingsDialogOpen = false
      },
      openProjectsDialog() {
        this.$emit('open-projects-dialog')
        this.settingsDialogOpen = false
      },
      openFileUploadDialog() {
        this.$emit('open-file-upload-dialog')
        this.settingsDialogOpen = false
      },
      openYoutubeSubmitDialog() {
        this.$emit('open-youtube-submit-dialog')
        this.settingsDialogOpen = false
      },
      openImageUploadDialog() {
        this.$emit('open-image-upload-dialog')
        this.settingsDialogOpen = false
      },
      handleSubmissionsIconClick() {
        this.$emit('handle-submissions-icon-click')
        this.settingsDialogOpen = false
      },
      openObjectsDialog() {
        this.$emit('open-objects-dialog')
        this.settingsDialogOpen = false
      },
      openSubmitTextDialog() {
        this.$emit('open-submit-text-dialog')
        this.settingsDialogOpen = false
      },
      handleLikedMessagesProcessingIconClick() {
        this.$emit('handle-liked-messages-processing-icon-click')
        this.settingsDialogOpen = false
      },
      handleLimitsIconClick() {
        this.$emit('handle-limits-icon-click')
        this.settingsDialogOpen = false
      }
    }
  }
  </script>

  <style scoped>
  .settings-dialog {
    border-radius: 8px;

  }
  .settings-icon {
    cursor: pointer;
  }


  .custom-hover {
    transition: all 0.3s ease;
  }

  .custom-hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: inherit;
  }

  .custom-hover:hover::before {
    opacity: 1;
  }

  .custom-hover:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .custom-hover:hover .v-icon {
    color: #004bfb !important;
  }

  .custom-hover .v-icon {
    font-size: 25px;
  }

  .settings-icon:hover::before {
    opacity: 1;
  }

  .settings-icon:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .settings-icon:hover .v-icon {
    color: #004bfb !important;
  }

  .settings-icon .v-icon {
    position: relative;
    z-index: 1;
  }

  .settings-card {
    transition: all 0.3s;
    cursor: pointer;
    height: 100%;
  }

  .settings-card.on-hover {
    transform: translateY(-5px);
  }

  .v-card__title {
    font-size: 1rem;
    font-weight: 500;
  }

  .headline {
    background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
    padding: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .modern-tooltip {
    background: rgba(30, 34, 39, 0.95) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 12px !important;
    backdrop-filter: blur(10px) !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2) !important;
  }
  </style>
