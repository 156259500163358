<!-- ResourcesManagerIcon.vue -->
<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :width="size"
      :height="size"
      :class="classes"
    >
      <!-- Base container shape - modernized -->
      <path
        d="M7 3h10l4 4v10l-4 4H7l-4-4V7l4-4z"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />

      <!-- Single elegant plus symbol -->
      <path
        d="M12 8v8M8 12h8"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
      />

      <!-- Subtle corner accents -->
      <path
        d="M7 3l-4 4m14-4l4 4"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        opacity="0.6"
      />
    </svg>
  </template>

  <script>
  export default {
    name: 'ResourcesManagerIcon',
    props: {
      size: {
        type: [Number, String],
        default: 24
      },
      classes: {
        type: [String, Object, Array],
        default: ''
      }
    }
  }
  </script>
