<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
    :class="classes"
  >
    <!-- Neural network base -->
    <path
      d="M12 3c-4.4 0-8 3.6-8 8s3.6 8 8 8c1.8 0 3.5-0.6 4.9-1.7l3.4 3.4c0.4 0.4 1 0.4 1.4 0s0.4-1 0-1.4l-3.4-3.4c1-1.4 1.7-3.1 1.7-4.9 0-4.4-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z"
      :fill="color"
    />

    <!-- AI Matrix pattern -->
    <path
      d="M9 8h6M9 12h6M9 16h6"
      :stroke="color"
      stroke-width="0.8"
      stroke-dasharray="1,2"
      opacity="0.6"
    />

    <!-- Verification shield -->
    <path
      d="M12 7l2 2-2 2-2-2z"
      fill="none"
      :stroke="color"
      stroke-width="1.2"
      stroke-linejoin="round"
    />

    <!-- Warning/glitch elements -->
    <path
      d="M14 13l-4-0.5M14 11l-4 0.5"
      :stroke="color"
      stroke-width="1"
      stroke-linecap="round"
      opacity="0.8"
    />
  </svg>
</template>

<script>
export default {
  name: 'HallucinationIcon',
  props: {
    size: {
      type: [Number, String],
      default: 24
    },
    color: {
      type: String,
      default: 'currentColor'
    },
    classes: {
      type: [String, Object, Array],
      default: ''
    }
  }
}
</script>
