<!-- SearchResultsAnimation.vue -->
<template>
    <div class="search-results-visualization" :class="{ 'collapsed': isCollapsed }">
      <!-- Search Progress Bar -->
      <div class="search-status-bar" @click="toggleCollapse">
        <div class="status-icon">
          <i :class="statusIcon"></i>
        </div>
        <div class="status-text">{{ statusMessage }}</div>
        <div class="results-counter" v-if="!isCollapsed">
          {{ processedResults.length }}/{{ results.length }}
        </div>
        <div class="collapse-icon">
          <i :class="isCollapsed ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'"></i>
        </div>
      </div>

      <!-- Animated Results Container -->
      <transition-group
        name="result-transition"
        tag="div"
        class="results-container"
        v-if="!isCollapsed"
      >
        <div
          v-for="(result) in processedResults"
          :key="result.id"
          class="result-item"
        >
          <div class="result-connector">
            <div class="connector-line"></div>
            <div class="connector-dot"></div>
          </div>
          <div class="result-content">
            <a
              :href="result.url"
              target="_blank"
              rel="noopener noreferrer"
              class="result-url"
            >
              {{ result.url }}
            </a>
            <div class="result-name">{{ result.name }}</div>
          </div>
        </div>
      </transition-group>
    </div>
  </template>

  <script>
  export default {
    name: 'SearchResultsAnimation',
    props: {
      results: {
        type: Array,
        required: true
      },
      isSearching: {
        type: Boolean,
        default: false
      },
      finalAnswerReceived: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        processedResults: [],
        statusMessages: [
          'Scanning knowledge base...',
          'Processing results...',
          'Analyzing relevance...',
          'Preparing response...'
        ],
        completedMessage: 'Search completed - Click to expand/collapse',
        currentStatusIndex: 0,
        isCollapsed: false
      }
    },
    computed: {
      statusMessage() {
        return this.finalAnswerReceived ? this.completedMessage : this.statusMessages[this.currentStatusIndex]
      },
      statusIcon() {
        return {
          'mdi mdi-magnify pulse': !this.finalAnswerReceived,
          'mdi mdi-check-circle': this.finalAnswerReceived
        }
      }
    },
    watch: {
    results: {
        immediate: true,
        handler(newResults) {
        this.processedResults = []
        if (newResults.length > 0) {
            this.animateResults(newResults)
        }
        }
    },
    finalAnswerReceived: {
        immediate: true,
        handler(newVal) {
        if (newVal) {
            // Mark this component as final
            this.$emit('update:finalAnswer', true)

            // Collapse after a short delay when final answer is received
            setTimeout(() => {
            this.isCollapsed = true
            this.$emit('collapsed', true)
            }, 300) // Matching your transition timing
        }
        }
    }
    },
    methods: {
      animateResults(results) {
        results.forEach((result, index) => {
          setTimeout(() => {
            this.processedResults.push(result)
            // Cycle through status messages
            this.currentStatusIndex = (index % this.statusMessages.length)
          }, index * 800)
        })
      },
      toggleCollapse() {
        this.isCollapsed = !this.isCollapsed
        this.$emit('collapsed', this.isCollapsed)
      }
    }
  }
  </script>

  <style scoped>
  .search-results-visualization {
    padding: 1rem;
    background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
    border-radius: 8px;
    margin: 1rem 0;
    transition: all 0.3s ease;
  }

  .search-results-visualization.collapsed {
    padding: 0.5rem;
  }

  .search-status-bar {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .collapsed .search-status-bar {
    margin-bottom: 0;
  }

  .status-icon {
    margin-right: 1rem;
  }

  .status-icon i {
    color: #10B981;
    font-size: 1.25rem;
  }

  .status-icon i.mdi-check-circle {
    color: #10B981;
  }

  .pulse {
    animation: pulse 2s infinite;
  }

  .status-text {
    flex-grow: 1;
    font-size: 0.9rem;
  }

  .results-counter {
    font-size: 0.8rem;
    color: #999;
    margin-right: 1rem;
  }

  .collapse-icon {
    color: #666;
    transition: transform 0.3s ease;
  }

  .collapsed .collapse-icon i {
    transform: rotate(180deg);
  }

  .results-container {
    position: relative;
    transition: all 0.3s ease;
  }

  .result-item {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
    position: relative;
  }

  .result-connector {
    width: 24px;
    margin-right: 1rem;
    position: relative;
  }

  .connector-line {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #e0e0e0;
    transform: translateX(-50%);
  }

  .connector-dot {
    width: 8px;
    height: 8px;
    background: #004bfb;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .result-content {
    flex-grow: 1;
    background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .result-url {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
    text-decoration: none;
    display: block;
    word-break: break-all;
  }

  .theme--dark .result-url {
    color: #fff;
  }

  .theme--light .result-url {
    color: #333;
  }

  .result-url:hover {
    text-decoration: underline;
    color: #004bfb;
  }

  .theme--dark .result-url:hover {
    color: #2196F3;
  }

  .result-name {
    font-size: 0.8rem;
    color: grey;
  }

  /* Transitions */
  .result-transition-enter-active,
  .result-transition-leave-active {
    transition: all 0.5s ease;
  }

  .result-transition-enter,
  .result-transition-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  </style>
