<template>
  <div class="rag-strategy-pills">
    <div class="pills-container">
      <div
        v-for="strategy in strategies"
        :key="strategy.value"
        class="strategy-pill"
        :class="{
          'active': rag_strategy === strategy.value,
        }"
        @click="selectStrategy(strategy)"
      >
        <div class="d-flex align-center">
          <v-icon small class="mr-1">{{ strategy.icon }}</v-icon>
          <span>{{ strategy.text }}</span>

          <!-- Info Icon with Tooltip -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                x-small
                class="info-icon ml-1"
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div class="tooltip-content">
              <h4>{{ strategy.text }}</h4>
              <p>{{ strategy.description }}</p>
              <ul v-if="strategy.points">
                <li v-for="point in strategy.points" :key="point">{{ point }}</li>
              </ul>
              <div v-if="strategy.value === 'ecrag' && !user.is_pro_team" class="mt-2">
                <strong>✨ Upgrade to Pro Team to unlock this feature</strong>
              </div>
            </div>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RagStrategySelector',
  props: {
    rag_strategy: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      strategies: [
        {
          value: 'ecrag',
          text: 'ECRAG',
          icon: 'mdi-database-plus-outline',
          description: 'Entity-Centric Retrieval-Augmented Generation',
          points: [
            'Uses your knowledge base',
            'Object-specific context',
            'Entity-driven insights'
          ]
        },
        {
          value: 'web_search',
          text: 'Web Search',
          icon: 'mdi-web',
          description: 'Real-time web information retrieval',
          points: [
            'Up-to-date information',
            'Global context',
            'Current events'
          ]
        },
        {
          value: 'llm_passthrough',
          text: 'LLM Direct',
          icon: 'mdi-arrow-right-bold',
          description: 'Direct language model interaction',
          points: [
            'Raw model output',
            'No knowledge base filtering',
            'Direct responses'
          ]
        }
      ]
    }
  },
  methods: {
    selectStrategy(strategy) {
      this.$emit('update:rag_strategy', strategy.value);
      this.$emit('change', strategy.value);
    }
  }
}
</script>

<style scoped>
.strategy-pill {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 20px;
  margin: 0 4px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.strategy-pill.active {
  background-color: #10B981;
  color: white;
}

.strategy-pill.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.pro-badge {
  font-size: 10px;
  height: 16px !important;
}

.tooltip-content {
  max-width: 300px;
}

.tooltip-content h4 {
  margin-bottom: 8px;
}

.tooltip-content ul {
  padding-left: 16px;
  margin-top: 8px;
}
</style>
