<template>
    <div class="modern-landing">
      <!-- Background gradients -->
      <div class="gradient-bg">
        <div class="gradient-orb gradient-orb-1"></div>
        <div class="gradient-orb gradient-orb-2"></div>
      </div>

      <!-- Main content -->
      <div class="content-container">
        <transition appear name="fade-up">
          <div class="glass-card">
            <!-- Header -->
            <div class="header">
              <h1 class="title">UnStruct.AI</h1>
              <h2 class="subtitle">Research companion with a hallucination checker to verify AI accuracy</h2>
            </div>

            <!-- Feature grid -->
            <div class="features-grid">
              <transition-group name="fade-stagger" tag="div">
                <div v-for="(feature, index) in features"
                     :key="feature.id"
                     class="feature-card"
                     :style="{ transitionDelay: `${index * 100}ms` }">
                  <div class="feature-icon" v-html="feature.icon"></div>
                  <h3 class="feature-title">{{ feature.title }}</h3>
                  <p class="feature-description">{{ feature.description }}</p>
                </div>
              </transition-group>
            </div>

            <!-- Mission text -->
            <div class="mission-text">
              <span class="typewriter">{{ displayedText }}</span>
              <span class="cursor">|</span>
            </div>

            <!-- Floating elements -->
            <div class="floating-elements">
              <div v-for="n in 20"
                   :key="n"
                   class="floating-particle"
                   :style="getRandomParticleStyle(n)">
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'ModernLanding',

    data() {
      return {
        displayedText: '',
        missionText: 'Empowering research with AI-driven insights and accuracy verification.',
        features: [
          {
            id: 1,
            icon: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M21 11c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12V5l9-4 9 4v6z"></path></svg>',
            title: 'AI-Powered Research',
            description: 'Advanced AI algorithms to enhance your research capabilities'
          },
          {
            id: 2,
            icon: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><circle cx="12" cy="12" r="10"></circle><path d="M9 12l2 2 4-4"></path></svg>',
            title: 'Accuracy Verification',
            description: 'Built-in hallucination checker ensures reliable results'
          },
          {
            id: 3,
            icon: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M22 12h-4l-3 9L9 3l-3 9H2"></path></svg>',
            title: 'Comprehensive Database',
            description: 'Access vast knowledge repositories seamlessly'
          },
          {
            id: 4,
            icon: '<svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M12 3v18M3 12h18"></path></svg>',
            title: 'Smart Insights',
            description: 'Get intelligent suggestions and connections'
          }
        ]
      }
    },

    mounted() {
      this.typewriterEffect();
    },

    methods: {
      typewriterEffect() {
        let index = 0;
        const timer = setInterval(() => {
          if (index <= this.missionText.length) {
            this.displayedText = this.missionText.slice(0, index);
            index++;
          } else {
            clearInterval(timer);
          }
        }, 50);
      },

      getRandomParticleStyle(index) {
        const random = (min, max) => Math.random() * (max - min) + min;
        return {
          '--x': `${random(-50, 50)}%`,
          '--y': `${random(-50, 50)}%`,
          '--duration': `${random(5, 10)}s`,
          '--delay': `${random(0, 2)}s`,
          '--size': `${random(2, 6)}px`,
          '--opacity': random(0.2, 0.5)
        }
      }
    }
  }
  </script>

  <style scoped>
  .modern-landing {
    min-height: 100vh;
    background: linear-gradient(135deg, #0f172a 0%, #1e3a8a 50%, #0f172a 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: relative;
    overflow: hidden;
  }

  .gradient-bg {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }

  .gradient-orb {
    position: absolute;
    border-radius: 50%;
    filter: blur(100px);
  }

  .gradient-orb-1 {
    background: rgba(59, 130, 246, 0.1);
    width: 50%;
    height: 50%;
    top: -25%;
    left: -25%;
  }

  .gradient-orb-2 {
    background: rgba(147, 51, 234, 0.1);
    width: 50%;
    height: 50%;
    bottom: -25%;
    right: -25%;
  }

  .content-container {
    width: 100%;
    max-width: 64rem;
    position: relative;
    z-index: 1;
  }

  .glass-card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(20px);
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 2rem;
    position: relative;
    overflow: hidden;
  }

  .header {
    text-align: center;
    margin-bottom: 3rem;
  }

  .title {
    font-size: 3rem;
    font-weight: bold;
    background: linear-gradient(to right, #004bfb, #a78bfa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
  }

  .subtitle {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.25rem;
  }

  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
    margin-bottom: 3rem;
  }

  .feature-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.75rem;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
  }

  .feature-card:hover {
    transform: translateY(-2px);
    border-color: rgba(255, 255, 255, 0.2);
  }

  .feature-icon {
    color: #004bfb;
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
  }

  .feature-title {
    color: white;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .feature-description {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.875rem;
  }

  .mission-text {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.125rem;
    font-weight: 300;
  }

  .cursor {
    animation: blink 1s step-end infinite;
  }

  .floating-particle {
    position: absolute;
    width: var(--size);
    height: var(--size);
    background: rgba(255, 255, 255, var(--opacity));
    border-radius: 50%;
    transform: translate(var(--x), var(--y));
    animation: float var(--duration) infinite ease-in-out;
    animation-delay: var(--delay);
  }

  /* Animations */
  @keyframes blink {
    from, to { opacity: 1; }
    50% { opacity: 0; }
  }

  @keyframes float {
    0%, 100% { transform: translate(var(--x), calc(var(--y) - 10%)); }
    50% { transform: translate(var(--x), calc(var(--y) + 10%)); }
  }

  .fade-up-enter-active, .fade-up-leave-active {
    transition: all 0.5s ease;
  }

  .fade-up-enter, .fade-up-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

  .fade-stagger-enter-active {
    transition: all 0.3s ease;
  }

  .fade-stagger-enter {
    opacity: 0;
    transform: translateY(20px);
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .title {
      font-size: 2.5rem;
    }

    .subtitle {
      font-size: 1.125rem;
    }

    .features-grid {
      grid-template-columns: 1fr;
    }
  }
  </style>
