<template>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
            <v-icon left>mdi-texture</v-icon>Chat Settings</v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(value, key) in settings" :key="key">
              <v-list-item-content>
                <v-list-item-title>{{ formatKey(key) }}</v-list-item-title>
                <v-list-item-subtitle>
                  <template v-if="key === 'site_searches'">
                    <div v-for="(site, index) in value" :key="index">
                      Site: {{ site.site }}, Filter: {{ site.filter }}
                    </div>
                  </template>
                  <template v-else>
                    {{ formatValue(value) }}
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
  export default {
    props: {
      value: Boolean,
      settings: Object
    },
    data() {
      return {
        dialog: this.value
      }
    },
    watch: {
      value(val) {
        this.dialog = val
      },
      dialog(val) {
        this.$emit('input', val)
      }
    },
    methods: {
      formatKey(key) {
        return key
          .split('_')
          .map(word => {
            if (word === 'google') {
              return 'Web';
            }
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(' ');
      },
      formatValue(value) {
        if (Array.isArray(value)) {
          return value.join(', ')
        }
        if (typeof value === 'boolean') {
          return value ? 'Yes' : 'No'
        }
        return value
      }
    },
  }
  </script>


<style scoped>
.theme--dark .v-card, .theme--dark .v-dialog, .theme--dark .v-card__text, .theme--dark .v-list-item, .theme--dark .v-list-item__title, .theme--dark .v-list-item__subtitle {
    background-color: rgba(30, 34, 39, 0.95) !important;
  }
</style>
