<template>
    <div :id="containerId" class="concept-map-container"></div>
  </template>

<script>
import cytoscape from 'cytoscape'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    messageId: {
      type: [String, Number],
      required: true,
    },
    shouldAnimate: Boolean,
  },
    data() {
      return {
      cy: null,
      containerId: `cy-${this._uid}-${this.messageId}`,
      };
    },
    watch: {
      shouldAnimate(newValue) {
        if (newValue) {
          this.initCytoscape();
        }
      }
    },
  mounted() {
    this.$nextTick(() => {
      this.initCytoscape();
    });
  },
  methods: {
    initCytoscape() {
      const relationships = this.data.concept_mapping_and_lateral_thinking;

      const relationshipEntities = new Set(relationships.flatMap(rel => rel.split(' -> ')));
      const nodes = [...new Set([
        ...this.data.extracted_entities_from_query,
        ...this.data.related_entities,
        ...relationshipEntities,
      ])];

      const elements = {
        nodes: nodes.map(node => ({ data: { id: node, label: node } })),
        edges: relationships.flatMap(rel => {
          const entities = rel.split(' -> ');
          return entities.slice(0, -1).map((entity, index) => ({
            data: {
              source: entity,
              target: entities[index + 1],
              label: `${entity} -> ${entities[index + 1]}`
            }
          }));
        })
      };

      const cy = cytoscape({
        container: document.getElementById(this.containerId),
        elements: elements,
        style: [
          {
            selector: 'node',
            style: {
              'background-color': '#78909C',
              'label': 'data(label)',
              'color': '#fff',
              'text-valign': 'center',
              'text-halign': 'center',
              'font-size': '12px'
            }
          },
          {
            selector: 'edge',
            style: {
              'width': 2,
              'line-color': '#004bfb',
              'target-arrow-color': '#bbb',
              'target-arrow-shape': 'triangle',
              'curve-style': 'bezier',
              //'label': 'data(label)',
              'font-size': '10px',
              'text-rotation': 'autorotate',
              'line-style': 'dashed',
            }
          }
        ],
        layout: {
          name: 'cose'
        },
        // Disable auto-zoom on scroll
        //wheelSensitivity: 0,
        // Optional: Enable manual zooming with buttons or key presses
        zoomingEnabled: true,
        userZoomingEnabled: false,
        minZoom: 0.5,
        maxZoom: 3,
      });

      // Optional: Add custom zoom controls
      this.addZoomControls(cy);
    },
    // Optional: Method to add custom zoom controls
    addZoomControls(cy) {
      const zoomIn = () => {
        cy.zoom({
          level: cy.zoom() * 1.1,
          renderedPosition: { x: cy.width() / 2, y: cy.height() / 2 }
        });
      };

      const zoomOut = () => {
        cy.zoom({
          level: cy.zoom() * 0.9,
          renderedPosition: { x: cy.width() / 2, y: cy.height() / 2 }
        });
      };

      // You can add buttons to your template and call these methods
      // or bind them to key presses
      document.addEventListener('keydown', (event) => {
        if (event.key === '+') zoomIn();
        if (event.key === '-') zoomOut();
      });
    }
  }
};
</script>

<style scoped>
.concept-map-container {
  width: 100%;
  height: 450px;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(10, 25, 47, 0.7);
  color: #ffffff !important;
}
</style>
