// src/utils/textUtils.js

// context_map
export const CONTEXT_MAP = {
    // Error Handling & Debugging
    'error': [
        'exception', 'failed', 'crash', 'bug', 'issue', 'fault', 'failure', 'invalid',
        'problem', 'defect', 'mistake', 'malfunction', 'corruption', 'stack trace',
        'debug', 'log', 'breakpoint', 'panic', 'abort', 'timeout', 'deadlock'
    ],

    // Performance & Optimization
    'performance': [
        'speed', 'slow', 'fast', 'optimization', 'memory', 'cpu', 'latency',
        'throughput', 'bottleneck', 'responsive', 'lag', 'efficient', 'cache',
        'benchmark', 'profiling', 'tuning', 'load', 'resource', 'utilization',
        'metrics', 'overhead', 'concurrent', 'parallel', 'scalable', 'intensive'
    ],

    // Security & Authentication
    'security': [
        'authentication', 'permission', 'access', 'vulnerability', 'encryption',
        'authorization', 'credential', 'token', 'oauth', 'jwt', 'certificate',
        'firewall', 'breach', 'exploit', 'threat', 'attack', 'secure', 'ssl',
        'tls', 'cors', 'xss', 'injection', 'sanitize', 'validation', 'role'
    ],

    // Database & Storage
    'database': [
        'query', 'sql', 'nosql', 'table', 'index', 'schema', 'migration',
        'record', 'transaction', 'backup', 'restore', 'replication', 'shard',
        'partition', 'cluster', 'consistency', 'acid', 'orm', 'persistence',
        'column', 'row', 'join', 'foreign key', 'primary key', 'constraint'
    ],

    // Network & Communication
    'network': [
        'http', 'tcp', 'ip', 'dns', 'api', 'endpoint', 'request', 'response',
        'protocol', 'socket', 'connection', 'bandwidth', 'packet', 'route',
        'gateway', 'proxy', 'firewall', 'load balancer', 'cdn', 'ssl', 'tls',
        'websocket', 'ping', 'timeout', 'retry'
    ],

    // Architecture & Design
    'architecture': [
        'pattern', 'design', 'structure', 'component', 'module', 'service',
        'interface', 'abstraction', 'layer', 'tier', 'mvc', 'mvvm', 'solid',
        'dependency', 'coupling', 'cohesion', 'scalable', 'maintainable',
        'microservice', 'monolith', 'serverless', 'event-driven'
    ],

    // Configuration & Environment
    'configuration': [
        'setting', 'parameter', 'environment', 'variable', 'config', 'setup',
        'initialization', 'property', 'option', 'preference', 'profile',
        'development', 'production', 'staging', 'testing', 'debug', 'deploy'
    ],

    // Testing & Quality
    'testing': [
        'test', 'unit', 'integration', 'e2e', 'coverage', 'assertion', 'mock',
        'stub', 'fixture', 'scenario', 'case', 'suite', 'regression', 'smoke',
        'acceptance', 'validation', 'verification', 'quality', 'qa', 'tdd', 'bdd'
    ],

    // User Interface & Experience
    'interface': [
        'ui', 'ux', 'user', 'interface', 'frontend', 'layout', 'design',
        'responsive', 'mobile', 'desktop', 'component', 'widget', 'screen',
        'view', 'form', 'input', 'validation', 'accessibility', 'a11y'
    ],

    // Authentication & Users
    'user': [
        'login', 'logout', 'signup', 'register', 'profile', 'account',
        'password', 'session', 'role', 'permission', 'group', 'admin',
        'authentication', 'authorization', 'identity', 'credential'
    ],

    // Data Processing
    'data': [
        'format', 'parse', 'validate', 'transform', 'convert', 'serialize',
        'deserialize', 'encode', 'decode', 'compress', 'encrypt', 'stream',
        'buffer', 'batch', 'pipeline', 'etl', 'import', 'export'
    ],

    // Monitoring & Logging
    'monitoring': [
        'log', 'trace', 'debug', 'info', 'warn', 'error', 'metric', 'alert',
        'notification', 'health', 'status', 'dashboard', 'report', 'analytics',
        'telemetry', 'audit', 'monitor', 'observe', 'track'
    ],

    // Version Control
    'version': [
        'git', 'commit', 'branch', 'merge', 'conflict', 'repository', 'tag',
        'release', 'changelog', 'version', 'revision', 'source', 'code',
        'diff', 'patch', 'update', 'history'
    ],

    // API & Integration
    'api': [
        'rest', 'graphql', 'soap', 'endpoint', 'request', 'response', 'header',
        'parameter', 'payload', 'status', 'method', 'get', 'post', 'put',
        'delete', 'patch', 'integration', 'webhook', 'callback'
    ],

    // Infrastructure & Deployment
    'infrastructure': [
        'server', 'cloud', 'container', 'docker', 'kubernetes', 'cluster',
        'node', 'instance', 'deploy', 'provision', 'scale', 'infrastructure',
        'platform', 'service', 'host', 'vm', 'virtual', 'network'
    ],

    // Concurrency & Threading
    'concurrency': [
        'thread', 'process', 'async', 'await', 'parallel', 'concurrent',
        'synchronous', 'asynchronous', 'block', 'lock', 'mutex', 'semaphore',
        'race', 'deadlock', 'starvation', 'queue', 'worker'
    ]
};

// synonyms
export const SYNONYMS = {
    // Size/Dimension
    'big': ['large', 'huge', 'massive', 'enormous', 'gigantic', 'vast', 'substantial', 'extensive'],
    'small': ['tiny', 'little', 'mini', 'miniature', 'compact', 'slight', 'petite', 'modest'],
    'wide': ['broad', 'extensive', 'spacious', 'expansive', 'large'],
    'narrow': ['slim', 'thin', 'slender', 'confined', 'restricted'],

    // Speed/Time
    'quick': ['fast', 'rapid', 'swift', 'speedy', 'prompt', 'immediate', 'instant'],
    'slow': ['gradual', 'unhurried', 'leisurely', 'sluggish', 'plodding', 'laggy', 'unresponsive', 'bottlenecked'],
    'immediate': ['instant', 'instantaneous', 'prompt', 'direct', 'instant'],
    'delayed': ['postponed', 'deferred', 'suspended', 'withheld', 'late'],

    // Quality
    'good': ['great', 'excellent', 'wonderful', 'superb', 'outstanding', 'exceptional', 'favorable'],
    'bad': ['poor', 'inferior', 'substandard', 'deficient', 'inadequate', 'unacceptable'],
    'perfect': ['flawless', 'ideal', 'excellent', 'optimal', 'prime', 'impeccable'],
    'broken': ['damaged', 'defective', 'faulty', 'malfunctioning', 'inoperative'],

    // Performance
    'efficient': ['effective', 'productive', 'capable', 'competent', 'proficient'],
    'inefficient': ['ineffective', 'unproductive', 'wasteful', 'incompetent'],
    'optimized': ['improved', 'enhanced', 'refined', 'streamlined', 'upgraded'],

    // State/Status
    'active': ['running', 'operational', 'functioning', 'working', 'live'],
    'inactive': ['idle', 'dormant', 'suspended', 'disabled', 'stopped'],
    'enabled': ['activated', 'on', 'functioning', 'operational'],
    'disabled': ['deactivated', 'off', 'unavailable', 'inactive'],

    // Change/Modification
    'changed': ['modified', 'altered', 'adjusted', 'revised', 'updated'],
    'unchanged': ['constant', 'fixed', 'stable', 'unmodified', 'static'],
    'improved': ['enhanced', 'upgraded', 'refined', 'better', 'advanced'],
    'degraded': ['deteriorated', 'worsened', 'declined', 'diminished'],

    // Errors/Issues
    'error': ['problem', 'issue', 'bug', 'fault', 'defect', 'flaw'],
    'critical': ['severe', 'serious', 'grave', 'crucial', 'vital'],
    'minor': ['trivial', 'slight', 'insignificant', 'negligible'],
    'fixed': ['resolved', 'corrected', 'remedied', 'repaired', 'patched'],

    // Security
    'secure': ['protected', 'safe', 'guarded', 'encrypted', 'safeguarded'],
    'vulnerable': ['unprotected', 'exposed', 'at risk', 'compromised'],
    'authenticated': ['verified', 'validated', 'confirmed', 'authorized'],
    'unauthorized': ['forbidden', 'restricted', 'blocked', 'denied'],

    // Data
    'valid': ['correct', 'accurate', 'proper', 'right', 'legitimate'],
    'invalid': ['incorrect', 'wrong', 'improper', 'erroneous', 'faulty'],
    'complete': ['full', 'entire', 'whole', 'comprehensive', 'total'],
    'incomplete': ['partial', 'unfinished', 'lacking', 'missing'],

    // User Interface
    'visible': ['shown', 'displayed', 'apparent', 'noticeable', 'evident'],
    'hidden': ['invisible', 'concealed', 'obscured', 'masked', 'undisplayed'],
    'interactive': ['responsive', 'dynamic', 'active', 'reactive'],
    'static': ['fixed', 'stationary', 'unchanging', 'stable'],

    // Resource Usage
    'heavy': ['intensive', 'demanding', 'consuming', 'taxing', 'resource-heavy'],
    'light': ['minimal', 'efficient', 'economical', 'conservative'],
    'overloaded': ['overwhelmed', 'strained', 'overtaxed', 'saturated'],
    'available': ['accessible', 'free', 'unused', 'spare', 'ready'],

    // Documentation/Communication
    'clear': ['obvious', 'evident', 'plain', 'understandable', 'comprehensible'],
    'unclear': ['ambiguous', 'vague', 'confusing', 'obscure', 'cryptic'],
    'detailed': ['comprehensive', 'thorough', 'complete', 'in-depth', 'exhaustive'],
    'brief': ['short', 'concise', 'succinct', 'summarized', 'condensed'],

    // Development
    'deprecated': ['outdated', 'obsolete', 'legacy', 'discontinued'],
    'current': ['latest', 'up-to-date', 'modern', 'recent', 'newest'],
    'stable': ['reliable', 'steady', 'consistent', 'dependable'],
    'experimental': ['beta', 'testing', 'trial', 'prototype', 'developmental'],

    // Results/Outcomes
    'successful': ['successful', 'accomplished', 'achieved', 'completed', 'fulfilled'],
    'failed': ['unsuccessful', 'failed', 'unaccomplished', 'incomplete'],
    'partial': ['incomplete', 'fragmentary', 'limited', 'partial', 'unfinished'],
    'verified': ['confirmed', 'validated', 'checked', 'proven', 'authenticated']
};

// Common stop words
export const STOP_WORDS = new Set([
    // Articles
    'a', 'an', 'the',

    // Pronouns
    'i', 'me', 'my', 'mine', 'myself',
    'you', 'your', 'yours', 'yourself', 'yourselves',
    'he', 'him', 'his', 'himself',
    'she', 'her', 'hers', 'herself',
    'it', 'its', 'itself',
    'we', 'us', 'our', 'ours', 'ourselves',
    'they', 'them', 'their', 'theirs', 'themselves',
    'this', 'that', 'these', 'those',
    'who', 'whom', 'whose', 'which', 'what',
    'whatever', 'whoever', 'whomever',

    // Prepositions
    'about', 'above', 'across', 'after', 'against', 'along',
    'amid', 'among', 'around', 'at', 'before', 'behind',
    'below', 'beneath', 'beside', 'besides', 'between',
    'beyond', 'but', 'by', 'concerning', 'despite',
    'down', 'during', 'except', 'for', 'from', 'in',
    'inside', 'into', 'like', 'near', 'of', 'off',
    'on', 'onto', 'out', 'outside', 'over', 'past',
    'regarding', 'round', 'since', 'through', 'throughout',
    'to', 'toward', 'towards', 'under', 'underneath',
    'until', 'unto', 'up', 'upon', 'with', 'within', 'without',

    // Conjunctions
    'and', 'but', 'or', 'nor', 'for', 'yet', 'so',
    'although', 'because', 'before', 'unless', 'whether',
    'while', 'where',

    // Auxiliary verbs and common verb forms
    'am', 'are', 'is', 'was', 'were', 'be', 'been', 'being',
    'have', 'has', 'had', 'having',
    'do', 'does', 'did', 'doing',
    'will', 'would', 'shall', 'should',
    'can', 'could', 'may', 'might', 'must',
    'ought',

    // Numbers
    'one', 'two', 'three', 'four', 'five', 'six', 'seven',
    'eight', 'nine', 'ten', 'first', 'second', 'third',
    'fourth', 'fifth',

    // Time-related
    'now', 'then', 'today', 'tomorrow', 'yesterday',
    'day', 'week', 'month', 'year', 'time',

    // Common adverbs
    'again', 'almost', 'already', 'always', 'anywhere',
    'else', 'even', 'ever', 'everywhere', 'far',
    'here', 'how', 'however', 'just', 'mostly',
    'never', 'not', 'often', 'only', 'quite',
    'rather', 'sometimes', 'somewhere', 'still',
    'there', 'too', 'usually', 'very', 'when',
    'where', 'why',

    // Common verbs
    'get', 'got', 'gotten', 'getting',
    'go', 'going', 'gone', 'went',
    'know', 'known', 'knew', 'knowing',
    'make', 'made', 'making',
    'say', 'said', 'saying',
    'see', 'saw', 'seen', 'seeing',
    'take', 'took', 'taken', 'taking',
    'think', 'thought', 'thinking',
    'use', 'used', 'using',
    'want', 'wanted', 'wanting',
    'work', 'worked', 'working',

    // Other common words
    'also', 'any', 'each', 'few', 'if', 'into',
    'many', 'more', 'most', 'much', 'no', 'nor',
    'other', 'same', 'some', 'such', 'than',
    'well', 'what', 'whatever', 'when', 'where',
    'whether', 'which', 'while', 'who', 'whom',
    'whose', 'yes', 'yet',

    // Common contractions (though you might want to handle these with preprocessing)
    "ain't", "aren't", "can't", "couldn't", "didn't",
    "doesn't", "don't", "hadn't", "hasn't", "haven't",
    "he'd", "he'll", "he's", "i'd", "i'll", "i'm",
    "i've", "isn't", "it's", "let's", "mightn't",
    "mustn't", "shan't", "she'd", "she'll", "she's",
    "shouldn't", "that's", "there's", "they'd", "they'll",
    "they're", "they've", "we'd", "we'll", "we're",
    "we've", "weren't", "what's", "when's", "where's",
    "who's", "won't", "wouldn't", "you'd", "you'll",
    "you're", "you've"
]);

// Helper functions
export const isStopWord = (word) => {
    return STOP_WORDS.has(word.toLowerCase());
};

export const getKeywordsFromQuery = (query) => {
    return query
        .toLowerCase()
        .split(/\s+/)
        .filter(word =>
            word.length > 2 &&
            !isStopWord(word) &&
            !word.match(/[0-9]+/)
        );
};

export const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
