// This file contains the API calls for the job updates
import API from "@/api"

const resource = '/job_updates';

export default {
    getJobUpdates(params) {
        const token = localStorage.getItem("token");
        const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");

        if (token) {
            return API.get(`${resource}`, { params });
        } else if (unstruct_anonymous_key) {
            return API.get(`${resource}/anonymous/${unstruct_anonymous_key}`, { params });
        } else {
            return Promise.reject(new Error("No authentication or temporary session found"));
        }
    },
};
