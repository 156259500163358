<template>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          small
          v-bind="attrs"
          v-on="on"
          class="help-icon-btn"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                Help & Support
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            @click="handleItemClick(item)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </template>

  <script>
  import { mapActions } from 'vuex';
  export default {
    name: 'HelpMenu',
    data: () => ({
      menu: false,
      menuItems: [
        { title: 'Contact Support', icon: 'mdi-email-outline', action: 'support' },
        { title: 'FAQ', icon: 'mdi-beaker-question-outline', action: 'faq' },
        { title: 'UnStruct Pro', icon: 'mdi-star-three-points-outline', action: 'pro' },
        { title: 'Terms of Service', icon: 'mdi-file-document-outline', action: 'terms' },
        { title: 'Privacy Policy', icon: 'mdi-shield-outline', action: 'privacy' },
        { title: 'Blog', icon: 'mdi-post-outline', action: 'blog' },
        { title: 'LinkedIn', icon: 'mdi-linkedin', action: 'about' }
      ]
    }),
    methods: {
      ...mapActions('websocket', ['showTempMessageFn']),
      handleItemClick(item) {
        this.menu = false;

        switch(item.action) {
          case 'pro':
            this.$root.$emit('open-limits-page');
            break;
          case 'faq':
            this.$router.push({ name: 'FAQ' });
            break;
          case 'support':
            window.location.href = 'mailto:support@unstruct.ai';
            break;
          case 'terms':
            this.$router.push({ name: 'TermsOfService' });
            break;
          case 'privacy':
            this.$router.push({ name: 'PrivacyPolicy' });
            break;
          case 'blog':
            this.$router.push({ name: 'Blog' });
            break;
          case 'about':
            window.open('https://www.linkedin.com/in/kishore-jalleda-bb403b4/', '_blank');
            break;
        }
      }
    }
  };
  </script>

  <style scoped>
  .help-icon-btn {
    position: relative;
    margin-left: 12px;
  }
  </style>
