<template>
  <v-card class="answer-generation-process" flat>
    <v-card-text>
      <v-stepper v-model="currentStep" vertical>
        <template v-for="(step, index) in steps">
          <v-stepper-step
            :key="`step-${index}`"
            :step="index + 1"
            :complete="currentStep > index + 1"
          >
            {{ step.title }}
          </v-stepper-step>

          <v-stepper-content :key="`content-${index}`" :step="index + 1">
            <v-card class="mb-4" outlined>
              <v-card-text>
                <p>{{ step.description }}</p>
                <v-skeleton-loader
                  :type="step.skeletonType"
                  :height="step.skeletonHeight"
                ></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </template>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AnswerGenerationProcess',
  data() {
    return {
      currentStep: 1,
      steps: [
        {
          title: "Contextual Information Retrieval",
          description: "Searching and retrieving relevant information from our knowledge base...",
          skeletonType: "text, image",
          skeletonHeight: 100
        },
        {
          title: "Entity Recognition and Mapping",
          description: "Identifying and mapping key entities in the context...",
          skeletonType: "list-item-three-line, image",
          skeletonHeight: 150
        },
        {
          title: "Relationship Analysis",
          description: "Analyzing relationships between identified entities...",
          skeletonType: "table",
          skeletonHeight: 120
        },
        {
          title: "AI-Powered Synthesis",
          description: "Synthesizing information and generating insights...",
          skeletonType: "article, image",
          skeletonHeight: 200
        },
        {
          title: "Response Formulation",
          description: "Formulating a comprehensive and contextual response...",
          skeletonType: "paragraph, actions",
          skeletonHeight: 150
        }
      ],
      animationDuration: 3000, // 3 seconds
    };
  },
  mounted() {
    this.animateSteps();
  },
  methods: {
    animateSteps() {
      const stepDuration = this.animationDuration / this.steps.length;
      let step = 1;
      const animate = () => {
        if (step <= this.steps.length) {
          this.currentStep = step;
          step++;
          setTimeout(animate, stepDuration);
        } else {
          this.$emit('animation-complete');
        }
      };
      animate();
    },
  }
};
</script>

<style scoped>
.answer-generation-process {
  max-width: 600px;
  margin: 0 auto;
}
</style>
