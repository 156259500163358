<template>
  <div
    ref="ragTokensContainer"
    class="rag-tokens-container"
    :class="{
      'collapsed': !isExpanded,
      'mini': isFeedMini,
      'expanded': isExpanded
    }"
    >
    <div v-if="isLoading" class="loading-container">
      <div class="loading-spinner"></div>
      <span>Loading...</span>
    </div>
    <v-tooltip left v-if="!isExpanded">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        @click="toggleExpand"
        v-bind="attrs"
        class="custom-hover"
        v-on="on"
      >
      <v-badge
        v-if="showBadge"
        :content="badgeContent"
        :value="badgeContent"
        color="primary"
        overlap
        offset-x="-10"
        offset-y="25"
        class="iao-badge"
      >
        <hallucination-icon
          :size="24"
        />
      </v-badge>
      <hallucination-icon
        v-else
        :size="24"
      />
      </v-btn>
    </template>
    <span>Open Hallucination Checker (RAG)</span>
  </v-tooltip>

    <v-expand-transition>
      <div v-if="isExpanded" class="rag-content">
        <div class="rag-header">
          <v-btn icon @click="previousHighlight" :disabled="getCurrentHighlightCount() === 0">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon @click="nextHighlight" :disabled="getCurrentHighlightCount() === 0">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="toggleUserQueryHighlights"
                :color="showUserQueryHighlights ? 'primary' : 'grey'"
                v-bind="attrs"
                v-on="on"
                :disabled="isUserQueryHighlightDisabled"
              >
               mdi-keyboard-outline
              </v-icon>
            </template>
            <span>
              {{ showUserQueryHighlights ? 'Hide Query Highlights' : 'Show Query Highlights' }}
            </span>
          </v-tooltip>
          <span class="rag-tokens-title">{{ ragTypeText }} Tokens</span>
          <v-tooltip left max-width="350">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" class="help-icon">
                mdi-help-circle-outline
              </v-icon>
            </template>
            <v-card class="pa-4">
              <h3 class="headline mb-2">Understanding RAG and LLMs</h3>
              <v-divider class="mb-3"></v-divider>
              <p class="body-2 mb-2"><strong>RAG (Retrieval-Augmented Generation):</strong></p>
              <ul class="body-2 pl-4 mb-3">
                <li>Enhances AI responses with relevant context</li>
                <li>Improves accuracy and relevance of answers (if you feed relevant data)</li>
                <li>Allows AI to access up-to-date information</li>
              </ul>
              <p class="body-2 mb-2"><strong>LLM (Large Language Model):</strong></p>
              <ul class="body-2 pl-4 mb-3">
                <li>AI trained on vast amounts of text data</li>
                <li>Generates human-like text based on input</li>
                <li>Powers chatbots and AI assistants</li>
              </ul>
              <v-divider class="mb-3"></v-divider>
              <p class="body-2">
                <strong>Pro Tip:</strong> In UnStruct, you can view the exact RAG context sent to LLMs for generating answers.
                This transparency helps you understand how context influences AI responses.
              </p>
            </v-card>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                @click="toggleRagContent"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  {{ showRagContent ? 'mdi-close' : 'mdi-eye-check-outline' }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ showRagContent ? 'Done checking' : 'Check for Hallucinations' }}</span>
          </v-tooltip>
        </div>
        <div class="rag-tokens-display-wrapper">
          <div class="rag-tokens-display">
            <div class="rag-token-digit" v-for="(digit, index) in ragTokenDigits" :key="index">
              <div class="rag-token-digit-column">
                <span v-for="n in 10" :key="n">{{ n - 1 }}</span>
              </div>
            </div>
          </div>
        </div>
        <v-expand-transition>
          <div v-if="showRagContent" class="rag-content-container">
            <v-card flat>
              <v-card-text class="text--primary">
                <v-text-field
                  v-model="searchQuery"
                  label="Search | Select Text from Answer"
                  prepend-icon="mdi-magnify"
                  clearable
                  @input="debouncedHandleSearchQuery"
                  dense
                  :disabled="isSearchDisabled"
                  :hint="searchDisabledHint"
                >
                  <template v-slot:append>
                    <v-tooltip bottom v-if="isSearchDisabled">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="warning"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>Search is disabled for large documents to maintain performance.</span>
                    </v-tooltip>
                    <v-chip
                      v-if="getCurrentHighlightCount() > 0"
                      color="grey"
                      small
                      class="ml-2"
                    >
                      {{ currentHighlightType === 'relevantSection'
                          ? `${Math.min(highlightIndex + 1, relevantSections.length)} / ${relevantSections.length}`
                          : `${highlightIndex + 1} / ${getCurrentHighlightCount()}`
                      }}
                      <span class="ml-1 text-caption">
                        {{ getHighlightTypeLabel() }}
                      </span>
                    </v-chip>
                  </template>
                </v-text-field>
                <div
                  ref="ragContentText"
                  class="rag-content-text"
                  v-html="highlightedContent.content"
                ></div>
              </v-card-text>
            </v-card>
          </div>
        </v-expand-transition>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import { debounce } from 'lodash';
import { isStopWord, getKeywordsFromQuery, escapeRegExp, STOP_WORDS, SYNONYMS, CONTEXT_MAP } from './utils/textUtils';
import HallucinationIcon from './custom_icons/HallucinationIcon.vue';

const MAX_HIGHLIGHTS = 200;
const MAX_TOKEN_FOR_RAG_SEARCH = 150000;
const MINIMUM_RELEVANCE_THRESHOLD = 0.45;
const MAX_RELEVANT_HIGHLIGHTS = 5;

export default {
  name: 'RagTokens',
  emits: ['update:selected-text', 'update-chat-input'],  // Declare emits for parent components
  components: {
    HallucinationIcon,
  },
  props: {
    ragTokens: {
      type: Number,
      required: true,
    },
    ragType: {
      type: String,
      default: 'llm_passthrough'
    },
    isFeedMini: {
      type: Boolean,
      default: true
    },
    selectedText: {
    type: String,
    default: ''
    },
    latestUserInput: {
      type: String,
      default: ''
    },
    showSparks: {
      type: Boolean,
      default: false
    },
    socket: WebSocket,
  },
  data() {
    return {
      isLoading: true,
      relevantSections: [], // Will store analyzed sections
      showRelevantHighlights: true,
      sectionHighlightsVisible: false,
      isExpanded: false,
      showRagContent: false,
      searchQuery: '',
      lastSearchQuery: '',
      isUserQueryHighlightDisabled: false,
      isSearchDisabled: false,
      searchDisabledHint: '',
      maxHighlights: 100,
      isSearching: false,
      ragTokenDigits: [0, 0, 0, 0, 0],
      highlightIndex: -1,
      highlightCountSearch: 0,
      highlightCountSelected: 0,
      highlightCountUserQuery: 0,
      highlightCountRelevant: 0,
      currentHighlightType: null,
      showUserQueryHighlights: false,
      localRagTokens: this.ragTokens,
      localRagContent: '',
      localRagType: this.ragType,
      contentSource: 'activeObject',
    }
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState('websocket', ['user', 'showTempMessage', 'activeObject', 'activeObjectContent', 'objectId']),
    ...mapFields('websocket', [
          'showTempMessage',
          'user',
      ]),
    badgeContent() {
      if (!this.activeObject || !this.activeObjectContent) return null;

      const typeMap = {
        'FileSubmission': 'F',
        'TextSubmission': 'T',
        'ChatMessage': 'C',
        'YouTubeTranscriptSubmission': 'YT',
        'IndividualContact': 'IC',
      };

      const prefix = typeMap[this.activeObject] || '';
      return `${prefix}#${this.objectId}`;
    },

    showBadge() {
      return !this.isExpanded && this.activeObject && this.activeObjectContent;
    },
    effectiveRagTokens() {
      if (this.contentSource === 'activeObject' && this.activeObjectContent) {
        return this.activeObjectContent.original_tokens || 0;
      }
      return this.localRagTokens || 0;
    },

    effectiveRagContent() {
      if (this.contentSource === 'activeObject' && this.activeObjectContent) {
        return this.activeObjectContent.rag_content || '';
      }
      return this.localRagContent || '';
    },

    effectiveRagType() {
      if (this.contentSource === 'activeObject' && this.activeObjectContent) {
        return this.activeObjectContent.rag_strategy || 'llm_passthrough';
      }
      return this.localRagType || 'llm_passthrough';
    },
    tokenCount() {
      return this.ragTokens;
    },
    ragTypeText() {
      const type = this.effectiveRagType || '';
      return type.toString().toUpperCase();
    },

    highlightedContent() {
      let content = this.replaceURLsAndEntities(this.effectiveRagContent);
      let userQueryCount = 0, searchCount = 0, selectedCount = 0;
      const stopWords = STOP_WORDS;

      // Highlight user query words only if showUserQueryHighlights is true
      if (this.showUserQueryHighlights && !this.isUserQueryHighlightDisabled) {
        const userWords = this.latestUserInput.toLowerCase().split(/\s+/);
        userWords.forEach(word => {
          if (word.length > 2 && !stopWords.has(word)) {
            const regex = new RegExp(`\\b${this.escapeRegExp(word)}\\b`, 'gi');
            content = content.replace(regex, (match, index) => {
              userQueryCount++;
              return `<span class="highlight-user-query" data-word="${word}" data-index="${index}">${match}</span>`;
            });
          }
        });
      }

      // Highlight search query
      if (this.searchQuery) {
        const searchRegex = new RegExp(this.escapeRegExp(this.searchQuery), 'gi');
        content = content.replace(searchRegex, (match, index) => {
          searchCount++;
          return `<span class="highlight-search" data-word="${this.searchQuery}" data-index="${index}">${match}</span>`;
        });
      }

      // Highlight selected text
      if (this.selectedText) {
        const selectedRegex = new RegExp(this.escapeRegExp(this.selectedText), 'gi');
        content = content.replace(selectedRegex, (match, index) => {
          selectedCount++;
          return `<span class="highlight-selected" data-word="${this.selectedText}" data-index="${index}">${match}</span>`;
        });
      }

      return {
        content,
        userQueryCount,
        searchCount,
        selectedCount
      };
    },
  },
  created() {
    this.debouncedHandleSearchQuery = debounce(this.handleSearchQuery, 500);
  },
  watch: {
    activeObjectContent: {
      handler(newContent) {
        this.$nextTick(() => {
          this.setupClickHandlers();
        });
      },
    },
    ragTokens(newTokens) {
      if (newTokens > MAX_TOKEN_FOR_RAG_SEARCH) {
        this.isSearchDisabled = true;
        this.searchDisabledHint = `Search is disabled for large documents to maintain performance.`;
      }
    },
    searchQuery() {
      this.debouncedHandleSearchQuery();
    },
    selectedText(newText) {
      this.$nextTick(() => {
        this.isExpanded = true;
        this.showRagContent = true;
        this.$emit('update:showRagContent', true);
        this.updateHighlightCount();
        this.currentHighlightType = 'selected';
        this.contentSource = 'activeObject';
        this.localRagContent = this.effectiveRagContent;
        this.localRagTokens = this.effectiveRagTokens;
        this.updateLocalData();
        this.updateRagContentVisibility(true);
        this.highlightIndex = 0;
        this.$nextTick(() => {
          setTimeout(() => {
            this.scrollToHighlightByIndex();
          }, 300);
        });
    });
    },
  },
  mounted() {
  // Listen for rag view limit response
  this.$parent.$on('rag-view-limit-response', this.handleRagViewLimitResponse);
  this.setupSelectionListener();
  // Initial setup attempt
  this.setupClickHandlers();
  this.isLoading = false;
},

updated() {
  // Other update logic
  this.isLoading = false;
},

beforeDestroy() {
  if (this.$refs.ragContentText) {
    this.$refs.ragContentText.removeEventListener('click', this.handleCombinedClick);
  }
  this.$parent.$off('rag-view-limit-response', this.handleRagViewLimitResponse);
  this.cleanupSelectionListener();
},
  beforeUnmount() {
    // Remove the listener when the component is destroyed
    this.$parent.$off('rag-view-limit-response', this.handleRagViewLimitResponse);
  },
  methods: {
    ...mapActions('websocket', ['showTempMessageFn']),
    isStopWord,
    getKeywordsFromQuery,
    escapeRegExp,
    handleCombinedClick(event) {
      // First check if it's a metadata block click.
      const metadataBlock = event.target.closest('.metadata-block');
      if (metadataBlock && metadataBlock.dataset.metadata) {
        try {
          const metadata = JSON.parse(metadataBlock.dataset.metadata);
          let type;
          let id;

          switch(metadata.type) {
            case 'file':
              type = 'FileSubmission';
              id = metadata.id;
              break;
            case 'text':
              type = 'TextSubmission';
              id = metadata.id;
              break;
            case 'url':
              type = 'ChatMessage';
              id = metadata.id || metadata.source;
              break;
            case 'youtube':
              type = 'YouTubeTranscriptSubmission';
              id = metadata.id;
              break;
            case 'ecrag':
              type = 'ChatMessage';
              id = metadata.id;
              break;
            default:
              console.warn('Unknown metadata type:', metadata.type);
              return;
          }

          this.$store.dispatch('websocket/setActiveObject', {
            type,
            id,
            rag_strategy: metadata.rag_strategy || 'llm_passthrough'
          });

          // Don't proceed to scrollToNextHighlight if we handled a metadata click
          return;
        } catch (error) {
          console.error('Error handling metadata click:', error);
        }
      }

      // If not a metadata click, proceed with highlight scrolling
      this.scrollToNextHighlight(event);
    },
    setupClickHandlers() {
    if (this.$refs.ragContentText) {
        // Remove existing handlers first to prevent duplicates
        this.$refs.ragContentText.removeEventListener('click', this.handleCombinedClick);
        this.$refs.ragContentText.addEventListener('click', this.handleCombinedClick);
      }
    },
    handleToggle() {
      this.isExpanded = !this.isExpanded;
    },
    clearCitationHighlights() {
      const contentElement = this.$refs.ragContentText;
      if (!contentElement) return;

      const highlights = contentElement.querySelectorAll('.citation-highlight');
      highlights.forEach(highlight => {
        const parent = highlight.parentNode;
        if (parent) {
          parent.replaceChild(
            document.createTextNode(highlight.textContent),
            highlight
          );
        }
      });
    },
    getHighlightTypeLabel() {
      switch(this.currentHighlightType) {
        case 'relevantSection':
          return 'Relevant';
        case 'userQuery':
          return 'Query';
        case 'search':
          return 'Search';
        case 'selected':
          return 'Selected';
        default:
          return '';
      }
    },
    analyzeContent() {
      if (!this.effectiveRagContent || !this.latestUserInput) return;

      // Split content into paragraphs/sections
      const sections = this.effectiveRagContent
        .split(/\n\n+/)
        .filter(section => section.trim().length > 0);

      // Get all relevant sections first
      const relevantSections = sections
        .map(section => ({
          text: section,
          score: this.calculateRelevance(section, this.latestUserInput),
          index: this.effectiveRagContent.indexOf(section)
        }))
        .filter(section => section.score > MINIMUM_RELEVANCE_THRESHOLD)
        .sort((a, b) => b.score - a.score);

      // Take up to 5 most relevant sections
      this.relevantSections = relevantSections.length > 5 ?
        relevantSections.slice(0, MAX_RELEVANT_HIGHLIGHTS) :
        relevantSections;

      // Update highlight count immediately
      this.highlightCountRelevant = this.relevantSections.length;

      if (this.relevantSections.length > 0) {
        this.highlightRelevantSections();
        this.scrollToMostRelevantSection();
      }
    },

    scrollToMostRelevantSection() {
      this.$nextTick(() => {
        const contentElement = this.$refs.ragContentText;
        const mostRelevant = this.relevantSections[0];
        if (contentElement && mostRelevant) {
          const element = contentElement.querySelector(
            `[data-score="${mostRelevant.score}"]`
          );
          if (element) {
            this.scrollToElement(element);
            element.classList.add('highlight-relevant');
          }
        }
      });
    },

    calculateRelevance(section, query) {
      // Updated weights with higher semantic emphasis
      const weights = {
        keywordMatch: 0.25,      // Reduced from 0.3
        semanticSimilarity: 0.25, // Increased from 0.1
        proximity: 0.2,          // Kept same
        keywordDensity: 0.15,    // Kept same
        position: 0.1,           // Reduced from 0.15
        sectionLength: 0.05      // Reduced from 0.1
      };

      let score = 0;
      const sectionLower = section.toLowerCase();
      const queryLower = query.toLowerCase();

      // 1. Keyword Matching (25%)
      const keywords = this.getKeywordsFromQuery(queryLower);
      const keywordScores = keywords.map(keyword => {
        const regex = new RegExp(this.escapeRegExp(keyword), 'gi');
        const matches = (sectionLower.match(regex) || []).length;
        return matches > 0 ? (matches * weights.keywordMatch) : 0;
      });
      score += Math.min(1, keywordScores.reduce((a, b) => a + b, 0));

      // 2. Semantic Similarity (25%) - Now with higher weight
      const semanticScore = this.calculateSemanticScore(section, query);
      score += semanticScore * weights.semanticSimilarity;

      // 3. Keyword Proximity (20%) - Same logic as before
      if (keywords.length > 1) {
        let proximityScore = 0;
        for (let i = 0; i < keywords.length - 1; i++) {
          for (let j = i + 1; j < keywords.length; j++) {
            const firstIndex = sectionLower.indexOf(keywords[i]);
            const secondIndex = sectionLower.indexOf(keywords[j]);
            if (firstIndex >= 0 && secondIndex >= 0) {
              const distance = Math.abs(secondIndex - firstIndex);
              proximityScore += 1 / (1 + distance / 30);
            }
          }
        }
        score += (proximityScore / (keywords.length * (keywords.length - 1) / 2)) * weights.proximity;
      }

      // 4. Keyword Density (15%) - Same as before
      const wordCount = sectionLower.split(/\s+/).length;
      const keywordDensity = keywordScores.length > 0 ?
        keywordScores.reduce((a, b) => a + b, 0) / wordCount : 0;
      score += keywordDensity * weights.keywordDensity;

      // 5. Position (10%) - Reduced importance
      const positionInDocument = this.effectiveRagContent.indexOf(section);
      const positionScore = 1 - (positionInDocument / this.effectiveRagContent.length);
      score += positionScore * weights.position;

      // 6. Section Length (5%) - Reduced importance
      const lengthScore = this.calculateLengthScore(section.length);
      score += lengthScore * weights.sectionLength;

      // Add bonus for multiple context matches
      const contextResult = this.checkContextWords(section, query);
      if (contextResult > 0.5) { // If significant context matches found
        score *= 1.2; // 20% bonus
      }

      return Math.min(1, score);
    },
    calculateLengthScore(length) {
      const idealMin = 100;
      const idealMax = 500;
      if (length < idealMin) {
        return length / idealMin;
      } else if (length > idealMax) {
        return idealMax / length;
      }
      return 1;
    },

    calculateSemanticScore(section, query) {
      let score = 0;
      const sectionLower = section.toLowerCase();

      // 1. Check for phrase matches
      const phrases = this.extractPhrases(query);
      phrases.forEach(phrase => {
        if (sectionLower.includes(phrase.toLowerCase())) {
          score += 0.5;
        }
      });

      // 2. Check for common synonyms
      const synonymMatches = this.checkSynonyms(section, query);
      score += synonymMatches * 0.3;

      // 3. Check for context words
      const contextScore = this.checkContextWords(section, query);
      score += contextScore * 0.2;

      return Math.min(1, score);
    },

    extractPhrases(text) {
      // Extract 2-3 word phrases
      const words = text.split(/\s+/);
      const phrases = [];

      for (let i = 0; i < words.length - 1; i++) {
        phrases.push(words[i] + ' ' + words[i + 1]);
        if (i < words.length - 2) {
          phrases.push(words[i] + ' ' + words[i + 1] + ' ' + words[i + 2]);
        }
      }

      return phrases;
    },

    checkSynonyms(section, query) {
      // Simple synonym dictionary
      const synonyms = SYNONYMS

      let matches = 0;
      const queryWords = query.toLowerCase().split(/\s+/);

      queryWords.forEach(word => {
        if (synonyms[word]) {
          synonyms[word].forEach(synonym => {
            if (section.toLowerCase().includes(synonym)) {
              matches++;
            }
          });
        }
      });

      return matches;
    },

    checkContextWords(section, query) {
      // Define context words for common topics
      const contextMap = CONTEXT_MAP;

      let score = 0;
      const queryWords = query.toLowerCase().split(/\s+/);
      const sectionLower = section.toLowerCase();

      queryWords.forEach(word => {
        if (contextMap[word]) {
          contextMap[word].forEach(contextWord => {
            if (sectionLower.includes(contextWord)) {
              score += 0.2;
            }
          });
        }
      });

      return Math.min(1, score);
    },

    getScoreColor(score) {
      // Option 3: Dual color - amber for high scores, blue for lower
      if (score > 0.9) {
        const alpha = 0.15 + ((score - 0.7) * 0.25); // 0.15 to 0.25 opacity
        return `rgba(255, 193, 7, ${alpha})`; // Amber for high relevance
      } else {
        const alpha = 0.1 + (score * 0.15); // 0.1 to 0.2 opacity
        return `rgba(25, 118, 210, ${alpha})`; // Blue for moderate relevance
      }
    },
    // Add method to handle content updates, disbale search for large documents, and highlight relevant sections
    handleContentUpdate() {
      if (this.showRagContent) {
        this.$nextTick(() => {
          this.updateHighlightCount();

          // Re-apply highlights in correct order
          if (this.showRelevantHighlights) {
            this.highlightRelevantSections();
          }
          if (this.showUserQueryHighlights) {
            // User query highlights will be applied via the computed property
            this.$forceUpdate();  // Force re-render to ensure highlights are applied
          }
        });
      }
    },

    handleTextSelection() {
    try {
      const selection = window.getSelection();
      const selectedText = selection?.toString()?.trim();

      if (!selectedText) return;

      // Emit both events - one for selected text tracking, one for chat input
      this.$emit('update:selected-text', selectedText);
      this.$emit('update-chat-input', selectedText);
    } catch (error) {
      console.warn('Error handling text selection:', error);
    }
  },

    // Add this to your existing template's div
    setupSelectionListener() {
      if (this.$refs.ragTokensContainer) {
        this.$refs.ragTokensContainer.addEventListener('mouseup', this.handleTextSelection);
        this.$refs.ragTokensContainer.addEventListener('touchend', this.handleTextSelection);
      }
    },

    cleanupSelectionListener() {
      if (this.$refs.ragTokensContainer) {
        this.$refs.ragTokensContainer.removeEventListener('mouseup', this.handleTextSelection);
        this.$refs.ragTokensContainer.removeEventListener('touchend', this.handleTextSelection);
      }
    },
    updateLocalData() {
      this.localRagTokens = this.ragTokens;
      this.localRagContent = this.activeObjectContent;
      this.localRagType = this.ragType;
      this.updateTokenDisplay(this.localRagTokens);
      this.showRagContent = true;
      this.updateRagContentVisibility(true);
    },
    showUserQueryHighlightWarning() {
      this.showTempMessageFn({
        message: `User query highlighting is disabled for large documents to maintain performance.`,
        type: 'warning',
        timeout: 5000,
      });
    },
    toggleRelevantHighlights() {
      this.showRelevantHighlights = !this.showRelevantHighlights;

      this.$nextTick(() => {
        if (this.showRelevantHighlights) {
          // Add relevant highlights without removing others
          this.highlightRelevantSections();
          if (this.relevantSections.length > 0) {
            this.currentHighlightType = 'relevantSection';
            this.highlightIndex = 0;
            this.scrollToHighlightByIndex();
          }
        } else {
          // Just hide relevant section highlights by removing their styling
          const contentElement = this.$refs.ragContentText;
          if (contentElement) {
            contentElement.querySelectorAll('.highlight-relevant-section').forEach(el => {
              el.style.backgroundColor = 'transparent';
              el.classList.remove('highlight-current');
            });
          }

          if (this.currentHighlightType === 'relevantSection') {
            const nextType = this.findNextAvailableHighlightType('relevantSection');
            if (nextType) {
              this.currentHighlightType = nextType;
              this.highlightIndex = 0;
              this.scrollToHighlightByIndex();
            }
          }
        }
      });
    },
    highlightRelevantSections() {
        if (!this.$refs.ragContentText || !this.showRelevantHighlights) return;

        const contentElement = this.$refs.ragContentText;
        let content = contentElement.innerHTML;

        // Sort sections by index in reverse to maintain positions
        const sortedSections = [...this.relevantSections]
            .sort((a, b) => b.index - a.index);

        // Split content into segments based on existing highlights
        function highlightText(text, sections) {
            // Split content into chunks: highlighted and non-highlighted
            const chunks = text.split(/(<span class="highlight-relevant-section.*?<\/span>)/);

            return chunks.map(chunk => {
                // If chunk is already highlighted, leave it as is
                if (chunk.startsWith('<span class="highlight-relevant-section')) {
                    return chunk;
                }

                // Process non-highlighted chunks
                let processedChunk = chunk;
                sections.forEach(section => {
                    const escapedText = section.text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                    const backgroundColor = this.getScoreColor(section.score);

                    processedChunk = processedChunk.replace(
                        new RegExp(escapedText, 'g'),
                        `<span class="highlight-relevant-section highlight-semantic-match"
                              data-score="${section.score}"
                              title="Relevance: ${Math.round(section.score * 100)}%">$&</span>`
                    );
                });

                return processedChunk;
            }).join('');
        }

        // Process content
        contentElement.innerHTML = highlightText.call(this, content, sortedSections);
    },
    toggleUserQueryHighlights() {
      if (this.isUserQueryHighlightDisabled) {
        this.showUserQueryHighlightWarning();
        return;
      }
      this.showUserQueryHighlights = !this.showUserQueryHighlights;

      this.$nextTick(() => {
        // Refresh all highlights
        const contentElement = this.$refs.ragContentText;
        if (!contentElement) return;

        // Start with base content
        let content = this.replaceURLsAndEntities(this.effectiveRagContent);

        // Apply relevant section highlights first (if enabled)
        if (this.showRelevantHighlights && this.relevantSections.length > 0) {
          const sortedSections = [...this.relevantSections].sort((a, b) => b.index - a.index);
          sortedSections.forEach(section => {
            const escapedText = this.escapeRegExp(section.text);
            const backgroundColor = this.getScoreColor(section.score);
            content = content.replace(
              new RegExp(`(${escapedText})`, 'g'),
              `<span class="highlight-relevant-section"
                    data-score="${section.score}"
                    title="Relevance: ${Math.round(section.score * 100)}%">$1</span>`
            );
          });
        }

        // Apply user query highlights
        if (this.showUserQueryHighlights) {
          const userWords = this.latestUserInput.toLowerCase().split(/\s+/);
          userWords.forEach(word => {
            if (word.length > 2 && !this.isStopWord(word)) {
              const regex = new RegExp(`\\b${this.escapeRegExp(word)}\\b`, 'gi');
              content = content.replace(regex, match =>
                `<span class="highlight-user-query" data-word="${word}">${match}</span>`
              );
            }
          });
        }

        // Apply search highlights if any
        if (this.searchQuery) {
          const searchRegex = new RegExp(this.escapeRegExp(this.searchQuery), 'gi');
          content = content.replace(searchRegex, match =>
            `<span class="highlight-search" data-word="${this.searchQuery}">${match}</span>`
          );
        }

        // Apply selected text highlights if any
        if (this.selectedText) {
          const selectedRegex = new RegExp(this.escapeRegExp(this.selectedText), 'gi');
          content = content.replace(selectedRegex, match =>
            `<span class="highlight-selected" data-word="${this.selectedText}">${match}</span>`
          );
        }

        // Update content
        contentElement.innerHTML = content;

        // Update counts and scroll position
        this.updateHighlightCount();
        if (this.showUserQueryHighlights && this.highlightCountUserQuery > 0) {
          this.currentHighlightType = 'userQuery';
          this.highlightIndex = 0;
          this.scrollToHighlightByIndex();
        } else if (!this.showUserQueryHighlights && this.currentHighlightType === 'userQuery') {
          const nextType = this.findNextAvailableHighlightType('userQuery');
          if (nextType) {
            this.currentHighlightType = nextType;
            this.highlightIndex = 0;
            this.scrollToHighlightByIndex();
          }
        }
      });
    },
    // Helper method to find next available highlight type
    findNextAvailableHighlightType(currentType) {
      const types = [
        { type: 'relevantSection', condition: () => this.relevantSections.length > 0 && this.showRelevantHighlights },
        { type: 'userQuery', condition: () => this.highlightCountUserQuery > 0 && this.showUserQueryHighlights },
        { type: 'search', condition: () => this.highlightCountSearch > 0 },
        { type: 'selected', condition: () => this.highlightCountSelected > 0 }
      ];

      // Find the index of current type
      const currentIndex = types.findIndex(t => t.type === currentType);

      // Check all other types in order
      for (let i = 1; i <= types.length; i++) {
        const index = (currentIndex + i) % types.length;
        if (types[index].condition()) {
          return types[index].type;
        }
      }

      return null;
    },
    nextHighlight() {
      const count = this.getCurrentHighlightCount();
      if (count > 0) {
        this.highlightIndex = (this.highlightIndex + 1) % count;
        this.scrollToHighlightByIndex();
      } else {
        // If current type has no highlights, try switching to next type
        const nextType = this.findNextAvailableHighlightType(this.currentHighlightType);
        if (nextType) {
          this.currentHighlightType = nextType;
          this.highlightIndex = 0;
          this.scrollToHighlightByIndex();
        }
      }
    },
    previousHighlight() {
      const count = this.getCurrentHighlightCount();
      if (count > 0) {
        this.highlightIndex = (this.highlightIndex - 1 + count) % count;
        this.scrollToHighlightByIndex();
      } else {
        // If current type has no highlights, try switching to next type
        const nextType = this.findNextAvailableHighlightType(this.currentHighlightType);
        if (nextType) {
          this.currentHighlightType = nextType;
          this.highlightIndex = 0;
          this.scrollToHighlightByIndex();
        }
      }
    },
    getCurrentHighlightCount() {
      if (!this.$refs.ragContentText) return 0;

      switch(this.currentHighlightType) {
        case 'search':
          return this.highlightCountSearch;
        case 'selected':
          return this.highlightCountSelected;
        case 'userQuery':
          return this.highlightCountUserQuery;
        case 'relevantSection':
          return this.relevantSections.length; // Use array length directly
        default:
          return Math.max(
            this.highlightCountSearch,
            this.highlightCountSelected,
            this.highlightCountUserQuery,
            this.relevantSections.length  // Include relevant sections in max count
          );
      }
    },
    scrollToHighlightByIndex() {
      if (!this.$refs.ragContentText) return;
      const contentElement = this.$refs.ragContentText;
      const highlightClass = this.getHighlightClassFromType(this.currentHighlightType);
      const highlights = contentElement.querySelectorAll(`.${highlightClass}`);

      if (this.highlightIndex >= 0 && this.highlightIndex < highlights.length) {
        const targetHighlight = highlights[this.highlightIndex];
        this.scrollToElement(targetHighlight);
        this.updateCurrentHighlight(targetHighlight);
      }
    },
    getHighlightClassFromType(type) {
      switch(type) {
        case 'search': return 'highlight-search';
        case 'selected': return 'highlight-selected';
        case 'userQuery': return 'highlight-user-query';
        case 'relevantSection': return 'highlight-relevant-section';
        default: return '';
      }
    },
    scrollToElement(element) {
      if (!element) return;
      const contentElement = this.$refs.ragContentText;
      const containerRect = contentElement.getBoundingClientRect();
      const highlightRect = element.getBoundingClientRect();

      let scrollOffset = contentElement.scrollTop + (highlightRect.top - containerRect.top) - 50;
      const maxScroll = contentElement.scrollHeight - contentElement.clientHeight;
      scrollOffset = Math.min(scrollOffset, maxScroll);

      contentElement.scrollTo({
        top: scrollOffset,
        behavior: 'smooth'
      });
    },
    updateCurrentHighlight(element) {
      if (!element) return;
      const contentElement = this.$refs.ragContentText;
      contentElement.querySelectorAll('.highlight-current').forEach(el => el.classList.remove('highlight-current'));
      element.classList.add('highlight-current');
    },
    updateHighlightCount() {
      const contentElement = this.$refs.ragContentText;
      if (!contentElement) return;

      this.highlightCountUserQuery = contentElement.querySelectorAll('.highlight-user-query').length;
      this.highlightCountSearch = contentElement.querySelectorAll('.highlight-search').length;
      this.highlightCountSelected = contentElement.querySelectorAll('.highlight-selected').length;
      // Add this line to track relevant sections count
      this.highlightCountRelevant = this.relevantSections.length;

      // Set the current highlight type if not set
      if (!this.currentHighlightType) {
        if (this.relevantSections.length > 0 && this.showRelevantHighlights) {
          this.currentHighlightType = 'relevantSection';
        } else if (this.highlightCountUserQuery > 0) {
          this.currentHighlightType = 'userQuery';
        } else if (this.highlightCountSearch > 0) {
          this.currentHighlightType = 'search';
        } else if (this.highlightCountSelected > 0) {
          this.currentHighlightType = 'selected';
        }
      }

      // Update chip count display
      if (this.currentHighlightType === 'relevantSection') {
        this.highlightIndex = Math.min(this.highlightIndex, this.relevantSections.length - 1);
      }
    },
    updateHighlightWarnings() {
      const totalHighlights = this.highlightCountUserQuery + this.highlightCountSearch + this.highlightCountSelected;
      if (totalHighlights > 1000) {  // Adjust this threshold as needed
        this.showTempMessageFn({
          message: `Large number of highlights (${totalHighlights}) may affect performance.`,
          type: 'info',
          timeout: 5000,
        });
      }
    },
    resetHighlightCount() {
      this.highlightCountSearch = 0;
      this.highlightCountSelected = 0;
      this.highlightCountUserQuery = 0;
      this.highlightCountRelevant = 0;
      this.currentHighlightType = null;
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.showRagContent = false;
        this.$emit('update:showRagContent', false);
        this.updateRagContentVisibility(false);
      } else {
        this.showRagContent = true;
        this.$emit('update:showRagContent', true);
        this.updateRagContentVisibility(true);
      }
    },
    toggleRagContent() {
      if (this.showRagContent) {
        this.showRagContent = false;
        this.isExpanded = false;
        this.$emit('update:showRagContent', false);
        this.updateRagContentVisibility(false);
        return;
      } else {
        this.showRagContent = true;
        this.isExpanded = true;
        this.$emit('update:showRagContent', true);
        this.updateRagContentVisibility(true);
      }

      if (!this.user.is_pro) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(JSON.stringify({
            type: 'rag_view_limit',
          }));
        } else {
          console.error('WebSocket is not connected');
        }
        return;
      }

      // Pro user logic
      this.showRagContent = true;
      this.$emit('update:showRagContent', true);
      this.updateRagContentVisibility(true);

      this.$nextTick(async () => {
        try {
          // First scroll to existing highlights
          this.scrollToAllHighlights();

          // Then analyze and highlight relevant sections
          if (this.effectiveRagContent && this.latestUserInput && !this.isSearchDisabled) {
            const sections = this.effectiveRagContent
              .split(/\n\n+/)
              .filter(section => section.trim().length > 0);

            // Get all relevant sections first
            const relevantSections = sections
              .map(section => ({
                text: section,
                score: this.calculateRelevance(section, this.latestUserInput),
                index: this.effectiveRagContent.indexOf(section)
              }))
              .filter(section => section.score > MINIMUM_RELEVANCE_THRESHOLD)
              .sort((a, b) => b.score - a.score);

            // Take up to max relevant sections
            this.relevantSections = relevantSections.length > MAX_RELEVANT_HIGHLIGHTS ?
              relevantSections.slice(0, MAX_RELEVANT_HIGHLIGHTS) :
              relevantSections;

            // Update highlight count
            this.highlightCountRelevant = this.relevantSections.length;

            if (this.relevantSections.length > 0) {
              this.highlightRelevantSections();
              this.scrollToMostRelevantSection();
            }
          }
        } catch (error) {
          console.warn('Error analyzing content:', error);
        }
      });

      // Update analytics
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({
          type: this.user.is_pro_team ?
            'update_pro_team_user_rag_view_count' :
            'update_pro_user_rag_view_count'
        }));
      } else {
        console.error('WebSocket is not connected');
      }
    },
    updateRagContentVisibility(isVisible) {
      this.$nextTick(() => {
        const messagesContainer = document.querySelector('.messages-container');
        const ragTokensContainer = document.querySelector('.rag-tokens-container');
        const chatInputWrapper = document.querySelector('.chat-input-wrapper');

        if (isVisible) {
          messagesContainer.classList.add('rag-content-visible');
          ragTokensContainer.classList.add('expanded');
          chatInputWrapper.classList.add('rag-content-visible');
        } else {
          messagesContainer.classList.remove('rag-content-visible');
          ragTokensContainer.classList.remove('expanded');
          chatInputWrapper.classList.remove('rag-content-visible');
        }
      });
    },
    handleRagViewLimitResponse(data) {
      if (data.within_limit) {
        this.showRagContent = true;
        this.updateRagContentVisibility(true);
        this.scrollToAllHighlights();
      } else {
        this.showTempMessageFn({
          message: data.message,
          type: 'warning',
          timeout: 5000,
        });
      }
    },
    scrollToNextHighlight(event) {
      const clickedElement = event.target.closest('.highlight-user-query, .highlight-search, .highlight-selected', 'highlight-relevant-section');
      if (!clickedElement) return;

      // If it's a relevant section highlight
      if (clickedElement.classList.contains('highlight-relevant-section')) {
        const score = clickedElement.dataset.score;
        const contentElement = this.$refs.ragContentText;
        const relevantElements = contentElement.querySelectorAll('.highlight-relevant-section');
        const currentIndex = Array.from(relevantElements).indexOf(clickedElement);

        // Move to next relevant section
        const nextIndex = (currentIndex + 1) % relevantElements.length;
        const nextElement = relevantElements[nextIndex];

        if (nextElement) {
          this.scrollToElement(nextElement);
          // Update current highlight
          contentElement.querySelectorAll('.highlight-current').forEach(el =>
            el.classList.remove('highlight-current')
          );
          nextElement.classList.add('highlight-current');
        }
        return;
      }

      const word = clickedElement.dataset.word;
      const currentIndex = parseInt(clickedElement.dataset.index);
      const contentElement = this.$refs.ragContentText;
      const highlightElements = contentElement.querySelectorAll(`[data-word="${word}"]`);

      let nextIndex = -1;
      if (this.currentHighlightWord !== word) {
        // New word clicked, start from the beginning
        this.currentHighlightWord = word;
        nextIndex = 0;
      } else {
        // Find the next occurrence of the same word
        for (let i = 0; i < highlightElements.length; i++) {
          if (parseInt(highlightElements[i].dataset.index) > this.currentHighlightIndex) {
            nextIndex = i;
            break;
          }
        }
        // If no next element, loop back to the first one
        if (nextIndex === -1 && highlightElements.length > 0) {
          nextIndex = 0;
        }
      }

      if (nextIndex !== -1) {
        const nextElement = highlightElements[nextIndex];
        this.currentHighlightIndex = parseInt(nextElement.dataset.index);

        const containerRect = contentElement.getBoundingClientRect();
        const highlightRect = nextElement.getBoundingClientRect();

        // Calculate scroll position
        let scrollOffset = contentElement.scrollTop + (highlightRect.top - containerRect.top) - 50;

        // Ensure we don't scroll past the content
        const maxScroll = contentElement.scrollHeight - contentElement.clientHeight;
        scrollOffset = Math.min(scrollOffset, maxScroll);

        // Smooth scroll
        contentElement.scrollTo({
          top: scrollOffset,
          behavior: 'smooth'
        });

        // Remove previous highlight-current class
        const currentHighlight = contentElement.querySelector('.highlight-current');
        if (currentHighlight) {
          currentHighlight.classList.remove('highlight-current');
        }

        // Add highlight-current class to the new element
        nextElement.classList.add('highlight-current');
      }
    },
    scrollToHighlight(highlightClass) {
      if (!this.$refs.ragContentText) return;
      const contentElement = this.$refs.ragContentText;
      const highlightElements = contentElement.getElementsByClassName(highlightClass);

      let count;
      switch(highlightClass) {
        case 'highlight-search':
          count = this.highlightCountSearch;
          this.currentHighlightType = 'search';
          break;
        case 'highlight-selected':
          count = this.highlightCountSelected;
          this.currentHighlightType = 'selected';
          break;
        case 'highlight-user-query':
          count = this.highlightCountUserQuery;
          this.currentHighlightType = 'userQuery';
          break;
        case 'highlight-relevant-section':
          count = this.relevantSections.length;
          this.currentHighlightType = 'relevantSection';
          break;
        default:
          count = 0;
      }

      if (count > 0) {
        // If highlightIndex is -1 or out of bounds, reset it to 0
        if (this.highlightIndex < 0 || this.highlightIndex >= count) {
          this.highlightIndex = 0;
        }

        const targetHighlight = highlightElements[this.highlightIndex];
        const containerRect = contentElement.getBoundingClientRect();
        const highlightRect = targetHighlight.getBoundingClientRect();

        // Calculate scroll position
        let scrollOffset = contentElement.scrollTop + (highlightRect.top - containerRect.top) - 50;

        // Ensure we don't scroll past the content
        const maxScroll = contentElement.scrollHeight - contentElement.clientHeight;
        scrollOffset = Math.min(scrollOffset, maxScroll);

        // Smooth scroll
        contentElement.scrollTo({
          top: scrollOffset,
          behavior: 'smooth'
        });

        // Remove 'current' class from all highlights
        contentElement.querySelectorAll('.highlight-current').forEach(el => el.classList.remove('highlight-current'));

        // Add 'current' class to the current highlight
        targetHighlight.classList.add('highlight-current');

      }
    },
    scrollToAllHighlights() {
      this.$nextTick(() => {
        this.scrollToHighlight('highlight-user-query');
        this.scrollToHighlight('highlight-search');
        this.scrollToHighlight('highlight-selected');
        this.scrollToHighlight('highlight-relevant-section');
      });
    },
    nextRelevantSection() {
      const contentElement = this.$refs.ragContentText;
      if (!contentElement) return;

      const relevantElements = contentElement.querySelectorAll('.highlight-relevant-section');
      if (relevantElements.length === 0) return;

      // Find current highlighted section
      const currentIndex = Array.from(relevantElements).findIndex(el =>
        el.classList.contains('highlight-current')
      );

      // Calculate next index
      const nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % relevantElements.length;
      const nextElement = relevantElements[nextIndex];

      if (nextElement) {
        this.scrollToElement(nextElement);
        // Update current highlight
        contentElement.querySelectorAll('.highlight-current').forEach(el =>
          el.classList.remove('highlight-current')
        );
        nextElement.classList.add('highlight-current');
      }
    },

    previousRelevantSection() {
      const contentElement = this.$refs.ragContentText;
      if (!contentElement) return;

      const relevantElements = contentElement.querySelectorAll('.highlight-relevant-section');
      if (relevantElements.length === 0) return;

      // Find current highlighted section
      const currentIndex = Array.from(relevantElements).findIndex(el =>
        el.classList.contains('highlight-current')
      );

      // Calculate previous index
      const prevIndex = currentIndex === -1 ?
        relevantElements.length - 1 :
        (currentIndex - 1 + relevantElements.length) % relevantElements.length;

      const prevElement = relevantElements[prevIndex];

      if (prevElement) {
        this.scrollToElement(prevElement);
        // Update current highlight
        contentElement.querySelectorAll('.highlight-current').forEach(el =>
          el.classList.remove('highlight-current')
        );
        prevElement.classList.add('highlight-current');
      }
    },
    updateTokenDisplay(value) {
      try {
        // Ensure value is a number
        const numericValue = Number(value) || 0;

        // Convert to string, pad, and split into array
        const paddedValue = Math.max(0, Math.floor(numericValue))
          .toString()
          .padStart(5, '0');

        // Safely create digits array
        const newDigits = paddedValue
          .slice(0, 7)  // Ensure we only take first 7 digits
          .split('')
          .map(digit => Number(digit) || 0);  // Convert to numbers, default to 0

        // Ensure array is exactly 7 digits
        while (newDigits.length < 7) {
          newDigits.unshift(0);
        }

        // Update state
        this.ragTokenDigits = newDigits;

        // Only animate if we have valid digits
        if (newDigits.every(digit => typeof digit === 'number')) {
          this.animateTokens(newDigits);
        }
      } catch (error) {
        console.warn('Error updating token display:', error);
        // Fallback to zeros if there's an error
        this.ragTokenDigits = [0, 0, 0, 0, 0];
      }
    },
    animateTokens() {
      const digitColumns = document.querySelectorAll('.rag-token-digit-column');
      const numberString = this.ragTokenDigits.join('');

      digitColumns.forEach((column, index) => {
        if (index < numberString.length) {
          const newValue = parseInt(numberString[index]);
          column.classList.add('rolling');
          column.style.transform = `translateY(-${newValue * 40}px)`;

          setTimeout(() => {
            column.classList.remove('rolling');
          }, 2000);
        } else {
          // Reset any extra digits to 0
          column.style.transform = 'translateY(0)';
        }
      });
    },
    handleSearchQuery() {
      try {
        // Guard clauses for null/undefined cases
        if (!this.searchQuery) {
          this.clearHighlights();
          return;
        }

        // Check if search is already in progress or unchanged
        if (this.isSearching || this.searchQuery === this.lastSearchQuery) {
          return;
        }

        // Store last query
        this.lastSearchQuery = this.searchQuery || '';

        // Check minimum length
        if (this.searchQuery.trim().length < 2) {
          this.clearHighlights();
          return;
        }

        // Set search flag
        this.isSearching = true;

        // Wrap in try-catch for animation frame
        this.$nextTick(() => {
          try {
            requestAnimationFrame(() => {
              try {
                this.performSearch();
              } catch (searchError) {
                console.warn('Error in performSearch:', searchError);
                this.isSearching = false;
              }
            });
          } catch (animationError) {
            console.warn('Error in requestAnimationFrame:', animationError);
            this.isSearching = false;
          }
        });
      } catch (error) {
        console.warn('Error in handleSearchQuery:', error);
        this.isSearching = false;
        this.clearHighlights();
      }
    },
    performSearch() {
      const start = performance.now();
      this.updateHighlightCount();
      this.currentHighlightType = 'search';

      if (this.highlightCountSearch > 0) {
        this.highlightIndex = 0;
        this.scrollToHighlightByIndex();
      }

      const end = performance.now();

      this.isSearching = false;

      if (end - start > 1000) {
        this.showPerformanceWarning();
      }
    },
    showPerformanceWarning() {
      this.showTempMessageFn({
        message: "Search is taking longer than expected. Consider refining your search query.",
        type: 'warning',
        timeout: 5000,
      });
    },
    clearHighlights() {
      this.$nextTick(() => {
        this.resetHighlightCount();
        this.currentHighlightType = null;
        this.highlightIndex = -1;
        this.$refs.ragContentText.querySelectorAll('.highlight-search, .highlight-selected, .highlight-user-query').forEach(el => {
          el.classList.remove('highlight-search', 'highlight-selected', 'highlight-user-query');
        });
      });
    },
    formatUrl(url) {
      try {
        const urlObj = new URL(url);
        // Get domain and first part of path
        const domain = urlObj.hostname;
        const path = urlObj.pathname;

        if (path.length > 30) {
          // Show domain + first 30 chars of path + ...
          return `${domain}${path.substring(0, 30)}...`;
        }

        if (url.length > 50) {
          // If full URL is too long, trim it
          return `${url.substring(0, 50)}...`;
        }

        return url;
      } catch (e) {
        console.warn('Invalid URL:', e);
        return url;
      }
    },
    handleContentClick(event) {
      const metadataBlock = event.target.closest('.metadata-block');
      if (!metadataBlock || !metadataBlock.dataset.metadata) return;

      try {
        const metadata = JSON.parse(metadataBlock.dataset.metadata);
        let type;
        let id;

        switch(metadata.type) {
          case 'file':
            type = 'FileSubmission';
            id = metadata.id;
            break;
          case 'text':
            type = 'TextSubmission';
            id = metadata.id;
            break;
          case 'url':
            type = 'ChatMessage';
            id = metadata.id || metadata.source;
            break;
          case 'youtube':
            type = 'YouTubeTranscriptSubmission';
            id = metadata.id;
            break;
          case 'ecrag':
            type = 'ChatMessage';
            id = metadata.id;
            break;
          default:
            console.warn('Unknown metadata type:', metadata.type);
            return;
        }

        // Set active object which will trigger content fetch
        this.$store.dispatch('websocket/setActiveObject', {
          type,
          id,
        });
      } catch (error) {
        console.error('Error handling metadata click:', error);
      }
    },

    replaceURLsAndEntities(text) {
      if (!text) return text;

      // First handle simple URL replacements
      text = text.replace(/\[URL:\s*(https?:\/\/[^\]]+)\]/g, (match, url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="inline-url">${url}</a>`;
      });

      // Handle entity replacements
      text = text.replace(/# (eid-\d+)__(eoid-\d+)/g, (match, eid, eoid) => {
        const basePath = 'https://ecsp-sch.unstruct.ai/kjalleda_55002_uni3_universe';
        const entityNum = eid.split('-')[1];
        const adosUrl = `${basePath}/ados?q=eid:${entityNum}`;

        return `<a href="${adosUrl}" target="_blank" rel="noopener noreferrer" class="inline-entity">${eoid}</a>`;
      });

      // First handle citations within highlights
      text = text.replace(/\[c(\d+)\]/g, (match, num) => {
        return `<span class="inline-citation">c${num}</span>`;
      });

      // Match the complete metadata block including nested braces
      const findMetadata = /\[META:\s*({(?:[^{}]|{[^{}]*})*})\]/g;

      // Replace each metadata block found
      return text.replace(findMetadata, (fullMatch, jsonPart) => {
        if (!jsonPart) return fullMatch; // Return original if no JSON part found

        try {
          const metadata = JSON.parse(jsonPart);

          // Common attributes for all metadata blocks
          const commonAttrs = `
            role="button"
            tabindex="0"
            class="metadata-block"
          `;

          let replacement = '';

          // Special handling for eccs_project type
          if (metadata.type === 'eccs_project') {
            const fileList = metadata.files.map(file => {
              const fileData = {
                type: file.type,
                id: file.id,
                fileName: file.fileName,
                fileSize: file.fileSize,
                fileType: file.fileType
              };

              return `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(fileData)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <path d="M4 6a2 2 0 0 1 2-2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z" />
                      <path d="M14 4v6h6" />
                    </svg>
                    <span class="file-number">#${file.id}</span>
                    <span class="file-name">${file.fileName}</span>
                    <span class="file-size">${(file.fileSize / 1024).toFixed(1)}KB</span>
                  </div>
                  <span class="file-type">${file.fileType}</span>
                </div>`;
            }).join('\n');

          replacement = `
            <div class="eccs-project-container">
              <div class="eccs-project-header">
                <svg class="source-icon" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                  <path d="M3 3h18v18H3z"/>
                  <path d="M8 12h8M12 8v8"/>
                </svg>
                <span>Project #${metadata.id} (${metadata.files.length} files)</span>
              </div>
              <div class="eccs-files-list">
                ${fileList}
              </div>
            </div>`;
        } else {
          switch (metadata.type) {
            case 'file': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify({
                      type: 'file',
                      id: metadata.id,
                      fileName: metadata.fileName
                    })}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <path d="M4 6a2 2 0 0 1 2-2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z" />
                      <path d="M14 4v6h6" />
                    </svg>
                    <span class="file-number">#${metadata.id}</span>
                    <span class="file-name">${metadata.fileName}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'url': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <path d="M12 4L4 8v8l8 4l8-4V8L12 4z" />
                      <path d="M12 12l8-4M12 12v8M4 8l8 4" />
                    </svg>
                    <span class="trimmed-url">${this.formatUrl(metadata.source)}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'text': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <rect x="4" y="4" width="16" height="16" rx="2" />
                      <path d="M8 8h8M8 12h8M8 16h4" />
                    </svg>
                    <span class="text-id">Text #${metadata.id}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'youtube': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <path d="M12 8l6 4l-6 4V8z" />
                      <path d="M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z" />
                    </svg>
                    <span class="youtube-id">YouTube #${metadata.id}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'search': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                    <span class="search-query">Search: ${metadata.id}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'ecrag': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <circle cx="12" cy="12" r="10" />
                      <path d="M16 12l-4 4l-4-4" />
                    </svg>
                    <span class="ecrag-id">ECRAG #${metadata.id}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            default:
              console.warn('Unknown metadata type:', metadata.type);
              return text;
          }
        }

        // Replace the entire metadata block with our generated HTML
        return replacement;

        } catch (e) {
          console.error('Error processing metadata:', e,
            '\nJSON string length:', jsonPart.length,
            '\nLast 50 characters:', jsonPart.slice(-50)
          );
          return fullMatch;
        }
      });
    },

    getSourceFromMetadata(metadata, citation) {
      try {
        const metadataObj = JSON.parse(metadata);
        const [start, end] = metadataObj.citation_range.split('-').map(Number);
        const citationNum = Number(citation);

        if (citationNum >= start && citationNum <= end) {
          switch (metadataObj.type) {
            case 'url':
              return new URL(metadataObj.source).hostname;
            case 'file': {
              const fileMatch = metadataObj.id.match(/(\d+)\s*File_Name:\s*(.+)/);
              return fileMatch ? `File #${fileMatch[1]}` : `File ${metadataObj.id}`;
            }
            case 'text':
              return `Text ${metadataObj.id}`;
            case 'youtube':
              return `YouTube ${metadataObj.id}`;
            default:
              return 'Unknown Source';
          }
        }
      } catch (e) {
        console.warn('Error parsing metadata:', e);
      }
      return 'Unknown Source';
    },
  }
}
</script>

<style scoped>
.rag-tokens-container {
  position: fixed;
  z-index: 1;
  right: 0px;
  width: 39%;
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}

.rag-tokens-container:not(.collapsed) {
  top: 60px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

/* When expanded and mini */
.rag-tokens-container.expanded.mini {
  width: 42%;
}

/* When expanded and NOT mini */
.rag-tokens-container.expanded {
  width: 37%;
}

/* hide on mobile */
@media (max-width: 768px) {
  .rag-tokens-container,.rag-tokens-container.expanded {
    display: none;
  }
}

@media (max-width: 900px) {
  .rag-tokens-container,.rag-tokens-container.expanded {
    display: none;
  }
}

.rag-tokens-container.collapsed {
  width: 175px;
  height: 65px;
  bottom: 0px;
  right: 0px;
  border: none;
  padding: 0;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

.toggle-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.rag-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px 0px 10px;
}

.rag-tokens-title {
  font-weight: bold;
  font-size: 12px;
}

.rag-tokens-display-wrapper {
  position: relative;
  height: 40px;
  overflow: hidden;
}

.rag-tokens-display {
  display: flex;
  justify-content: center;
}

.rag-token-digit {
  position: relative;
  margin: 0 2px;
  width: 20px;
  height: 40px;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rag-token-digit-column {
position: absolute;
top: 0;
left: 0;
width: 100%;
transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);
display: flex;
flex-direction: column;
align-items: center;
}

.rag-token-digit-column span {
display: block;
align-items: center;
justify-content: center;
height: 40px;
line-height: 40px;
text-align: center;
width: 100%;
font-size: 18px;
}

.rolling {
transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);
}
.rag-content-container {
  border-radius: 4px;
  overflow: hidden;
}

.theme--dark .rag-tokens-container {
  background: linear-gradient(to right, rgba(230, 240, 255, 0.1) 0%, rgba(230, 240, 255, 0.2) 50%, rgba(230, 240, 255, 0.1) 100%);
}

.rag-content-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 0.875rem;
  line-height: 1.35rem;
  max-height: 93vh; /* DO NOT REMOVE */
  padding: 0px 30px;
  overflow-y: auto;
}
  /* For Webkit browsers (Chrome, Safari, newer versions of Edge) */
  ::-webkit-scrollbar {
    width: 8px;  /* Slightly narrower for subtlety */
  }

  ::-webkit-scrollbar-track {
    background: rgba(30, 30, 30, 0.5);  /* Dark, semi-transparent background */
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(80, 80, 80, 0.7);  /* Slightly lighter, more opaque thumb */
    border-radius: 4px;  /* Rounded corners for the thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(100, 100, 100, 0.8);  /* Lighter on hover for better visibility */
  }

  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(80, 80, 80, 0.7) rgba(30, 30, 30, 0.5);
  }

  /* For Internet Explorer and Edge (older versions) */
  body {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  /* Optional: Hide scrollbars when not scrolling */
  .scroll-container {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.3s ease;
  }

  .scroll-container:hover {
    scrollbar-color: rgba(80, 80, 80, 0.7) rgba(30, 30, 30, 0.5);
  }

  /* Ensure the scrollbar doesn't affect layout */
  @supports (scrollbar-gutter: stable) {
    .scroll-container {
      scrollbar-gutter: stable;
    }
  }
.custom-hover {
position: absolute;
top: 0;
left: 0;
z-index: 1;
transition: all 0.3s ease;
}

.custom-hover::before {
content: '';
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(128, 128, 128, 0.2);
opacity: 0;
transition: opacity 0.3s ease;
border-radius: inherit;
}

.custom-hover:hover::before {
opacity: 1;
}

.custom-hover:hover {
transform: scale(1.1);
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-hover:hover .v-icon {
color: #004bfb !important;
}

.custom-hover .v-icon {
position: relative;
z-index: 1;
}
.custom-hover .v-badge__badge {
z-index: 2;
}

.custom-hover:hover .v-badge__badge {
transform: scale(0.91);  /* Counteract the button scaling */
}

/* Ensure proper alignment within the button */
.custom-hover.v-btn--icon {
display: flex;
align-items: center;
justify-content: center;
}

.custom-hover.v-btn--icon .v-icon {
margin: 0;
}

.theme--dark.v-card {
background-color: inherit !important;
}
/* Custom highlight colors with white text */
:root {
--highlight-primary-bg: rgba(59, 130, 246, 0.2);
--highlight-secondary-bg: rgba(167, 139, 250, 0.2);
--highlight-success-bg: rgba(34, 197, 94, 0.2);
--highlight-warning-bg: rgba(234, 179, 8, 0.2);
}

/* Common styles for all highlights */
:deep([class*="highlight-"]) {
position: relative;
border-radius: 4px;
padding: 0.15em 0.4em;
margin: 0 0.1em;
font-weight: 500;
transition: all 0.3s ease;
display: inline-block;
line-height: 1.4;
backdrop-filter: blur(4px);
box-decoration-break: clone;
-webkit-box-decoration-break: clone;
}

/* Individual highlight styles */
:deep(.highlight-relevant-section) {
background: linear-gradient(120deg,
  rgba(167, 139, 250, 0.25),
  rgba(167, 139, 250, 0.15)
);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

:deep(.highlight-user-query) {
background: linear-gradient(120deg,
  rgba(59, 130, 246, 0.25),
  rgba(59, 130, 246, 0.15)
);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

:deep(.highlight-search) {
background: linear-gradient(120deg,
  rgba(34, 197, 94, 0.25),
  rgba(34, 197, 94, 0.15)
);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
/* Make selected highlights more prominent */
:deep(.highlight-selected) {
  background: linear-gradient(120deg,
    rgba(16, 185, 129, 0.3),
    rgba(16, 185, 129, 0.2)
  );
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.2);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  animation: selectPulse 2s ease-in-out infinite;
}

:deep(.highlight-current) {
  font-weight: 600;
  background: linear-gradient(120deg,
    rgba(236, 72, 153, 0.25),
    rgba(236, 72, 153, 0.15)
  );
  box-shadow:
    0 0 0 2px rgba(236, 72, 153, 0.3),
    0 4px 8px rgba(0, 0, 0, 0.15);
  transform: scale(1.02);
  z-index: 10;
  padding: 10px;
  animation: currentPulse 2s ease-in-out infinite;
}

/* Enhanced citation highlight style */
:deep(.citation-highlight) {
background: linear-gradient(120deg,
  rgba(59, 130, 246, 0.35),
  rgba(59, 130, 246, 0.25)
);
box-shadow:
  0 0 0 2px rgba(59, 130, 246, 0.2),
  0 4px 8px rgba(0, 0, 0, 0.1);
padding: 0.2em 0.5em;
margin: 0 0.2em;
border-radius: 4px;
font-weight: 500;
animation: citationEmphasis 0.4s cubic-bezier(0.4, 0, 0.2, 1);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
backdrop-filter: blur(8px);
}

@keyframes citationEmphasis {
0% {
  opacity: 0.6;
  transform: translateY(-4px);
  box-shadow:
    0 0 0 0 rgba(59, 130, 246, 0.4),
    0 0 0 rgba(0, 0, 0, 0);
}
100% {
  opacity: 1;
  transform: translateY(0);
  box-shadow:
    0 0 0 2px rgba(59, 130, 246, 0.2),
    0 4px 8px rgba(0, 0, 0, 0.1);
}
}

:deep(.metadata-block) {
display: flex;
justify-content: space-between;
align-items: center;
padding: 16px 20px;
margin: 12px 0;
border-radius: 12px;

/* Modern glassmorphism effect */
backdrop-filter: blur(12px);
-webkit-backdrop-filter: blur(12px);
background: linear-gradient(
  135deg,
  rgba(29, 78, 216, 0.15) 0%,
  rgba(30, 64, 175, 0.25) 50%,
  rgba(17, 24, 39, 0.35) 100%
);

/* Subtle border glow */
border: 1px solid rgba(147, 197, 253, 0.15);
box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.1),
            0 0 16px -2px rgba(147, 197, 253, 0.15);

/* Smooth transitions */
transition: all 0.3s ease-in-out;
}

/* Hover state for better interactivity */
:deep(.metadata-block):hover {
transform: translateY(-2px);
background: linear-gradient(
  135deg,
  rgba(29, 78, 216, 0.2) 0%,
  rgba(30, 64, 175, 0.3) 50%,
  rgba(17, 24, 39, 0.4) 100%
);
border-color: rgba(147, 197, 253, 0.25);
box-shadow: 0 8px 24px -6px rgba(0, 0, 0, 0.15),
            0 0 20px -4px rgba(147, 197, 253, 0.2);
}

:deep(.highlight-selected) {
background: linear-gradient(120deg,
  rgba(236, 72, 153, 0.25),
  rgba(236, 72, 153, 0.15)
);
box-shadow: 0 0 0 2px rgba(236, 72, 153, 0.2);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
animation: selectPulse 2s ease-in-out infinite;
}

:deep(.metadata-block) {
display: flex;
justify-content: space-between;
align-items: center;
padding: 16px 20px;
margin: 12px 0;
border-radius: 12px;

/* Modern glassmorphism with pink/rose tint */
backdrop-filter: blur(12px);
-webkit-backdrop-filter: blur(12px);
background: linear-gradient(
  135deg,
  rgba(236, 72, 153, 0.08) 0%,
  rgba(219, 39, 119, 0.12) 50%,
  rgba(190, 24, 93, 0.15) 100%
);

/* Subtle border glow matching the rose theme */
border: 1px solid rgba(236, 72, 153, 0.1);
box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.1),
            0 0 16px -2px rgba(236, 72, 153, 0.1);

/* Smooth transitions */
transition: all 0.3s ease-in-out;
}

/* Hover state with enhanced glow */
:deep(.metadata-block):hover {
transform: translateY(-2px);
background: linear-gradient(
  135deg,
  rgba(236, 72, 153, 0.12) 0%,
  rgba(219, 39, 119, 0.16) 50%,
  rgba(190, 24, 93, 0.2) 100%
);
border-color: rgba(236, 72, 153, 0.15);
box-shadow: 0 8px 24px -6px rgba(0, 0, 0, 0.15),
            0 0 20px -4px rgba(236, 72, 153, 0.15);
}


/* Optional: Add animation for content */
:deep(.metadata-block) > * {
opacity: 0.95;
transition: opacity 0.2s ease;
}

:deep(.metadata-block):hover > * {
opacity: 1;
}

:deep(.metadata-main) {
display: flex;
align-items: center;
gap: 8px;
min-width: 0;
}

:deep(.file-prefix) {
opacity: 0.7;
}

:deep(.file-number) {
font-family: monospace;
padding: 2px 6px;
background: rgba(230, 240, 255, 0.1);
border-radius: 4px;
}

:deep(.file-name) {
font-weight: 500;
}

:deep(.citation-range) {
font-size: 0.9em;
opacity: 0.7;
padding: 2px 8px;
background: rgba(230, 240, 255, 0.1);
border-radius: 4px;
margin-left: 12px;
flex-shrink: 0; /* Prevent citation range from shrinking */
}

:deep(.metadata-block a) {
text-decoration: none;
}

:deep(.metadata-block a:hover) {
text-decoration: underline;
opacity: 0.8;
}

/* Light theme overrides */
:deep([class*='light'] .metadata-block) {
background: linear-gradient(
  to right,
  rgba(230, 240, 255, 0.05) 0%,
  rgba(230, 240, 255, 0.1) 50%,
  rgba(230, 240, 255, 0.05) 100%
);
}

:deep([class*='light'] .file-number),
:deep([class*='light'] .citation-range) {
background: rgba(230, 240, 255, 0.2);
}

:deep(.trimmed-url) {
display: inline-block;
max-width: 400px; /* Adjust based on your layout */
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
color: inherit;
text-decoration: none;
position: relative;
}

:deep(.trimmed-url:hover) {
text-decoration: underline;
}

:deep(.trimmed-url:hover::after) {
content: attr(title);
position: absolute;
top: -30px;
left: 50%;
transform: translateX(-50%);
background: rgba(0, 0, 0, 0.8);
color: white;
padding: 4px 8px;
border-radius: 4px;
font-size: 12px;
white-space: nowrap;
z-index: 1000;
pointer-events: none;
}

/* Add inline citation styling */
:deep(.inline-citation) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4em;
  height: 1.4em;
  font-size: 0.75rem;
  font-weight: 500;
  /* Softer blue background with better contrast */
  color: #f8fafc;
  background-color: #3b82f6;
  /* Add subtle gradient for depth */
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.1)
  );
  border-radius: 999px;
  padding: 0 0.4em;
  margin: 0 0.15em;
  text-decoration: none;
  transition: all 0.15s ease;
  position: relative;
  user-select: none;
  backdrop-filter: blur(4px);
  vertical-align: middle;
  margin-bottom: 10px;
  /* Add subtle shadow for depth */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

:deep(.inline-citation:hover) {
  /* Slightly lighter on hover for feedback */
  color: #ffffff;
  background-color: #2563eb;
  /* Add subtle lift effect */
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Dark theme adjustments */
:deep([class*='dark'] .inline-citation) {
  /* Darker, more muted blue for dark theme */
  color: #e2e8f0;
  background-color: #3b82f6;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.05),
    rgba(0, 0, 0, 0.2)
  );
  /* Softer shadow for dark theme */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(255, 255, 255, 0.05);
}

:deep([class*='dark'] .inline-citation:hover) {
  color: #f8fafc;
  background-color: #2563eb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(255, 255, 255, 0.1);
}

/* When citations appear within highlighted text */
:deep([class*="highlight-"] .inline-citation) {
  background-color: rgba(59, 130, 246, 0.9);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

:deep([class*="highlight-"] .inline-citation:hover) {
  background-color: rgba(37, 99, 235, 0.95);
}

/* Make citations look good in highlighted text */
:deep([class*="highlight-"] .inline-citation) {
background-color: rgba(255, 255, 255, 0.15);
border-color: rgba(255, 255, 255, 0.2);
margin: 0 0.2em;
}

:deep([class*="highlight-"] .inline-citation:hover) {
background-color: rgba(255, 255, 255, 0.25);
border-color: rgba(255, 255, 255, 0.3);
}

/* Make citations within highlights stand out */
:deep(.highlight-selected .inline-citation),
:deep(.highlight-current .inline-citation) {
background-color: rgba(255, 255, 255, 0.25);
border-color: rgba(255, 255, 255, 0.35);
transform: scale(1.1);
box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}
/* Animations for better visual feedback */
@keyframes selectPulse {
  0%, 100% {
    box-shadow:
      0 0 0 2px rgba(251, 191, 36, 0.2),    /* Yellow pulse */
      0 0 20px rgba(251, 191, 36, 0.3);
  }
  50% {
    box-shadow:
      0 0 0 4px rgba(251, 191, 36, 0.3),
      0 0 20px rgba(251, 191, 36, 0.3);
  }
}

@keyframes currentPulse {
  0%, 100% {
    box-shadow:
      0 0 0 2px rgba(59, 130, 246, 0.3),    /* Blue pulse */
      0 4px 8px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow:
      0 0 0 4px rgba(59, 130, 246, 0.4),
      0 6px 12px rgba(0, 0, 0, 0.15);
  }
}

.metadata-block .source-icon {
flex-shrink: 0;
opacity: 0.75;
transition: all 0.3s ease;
}

.metadata-block .metadata-main {
display: flex;
align-items: center;
gap: 8px;
}

.metadata-block a:hover .source-icon,
.metadata-block .metadata-main:hover .source-icon {
opacity: 1;
transform: scale(1.1);
filter: drop-shadow(0 0 2px rgba(99, 102, 241, 0.3));
}

/* Style updates for different source types */
.metadata-block .file-number {
color: #004bfb;
font-weight: 500;
}

.metadata-block .file-name {
color: #4B5563;
}

.metadata-block .text-id,
.metadata-block .youtube-id {
color: #4B5563;
}

.metadata-block .trimmed-url {
color: #004bfb;
text-decoration: none;
transition: color 0.2s ease;
}

.metadata-block .trimmed-url:hover {
color: #4F46E5;
}

:deep(.highlight-semantic-match) {
display: inline;
position: relative;
z-index: 1;
}

:deep(.highlight-semantic-match.highlight-current) {
animation: currentPulse 2s ease-in-out infinite;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #4B5563;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
/* Add new styles for the badge */
:deep(.v-badge__badge) {
  font-size: 10px !important;
  height: 20px !important;
  min-width: 20px !important;
  padding: 0 4px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
}

:deep(.v-badge__badge.primary) {
  background-color: #004bfb !important;
  color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

/* Optional: Add hover effect for the badge */
:deep(.custom-hover:hover .v-badge__badge) {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

:deep(.inline-url) {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  padding: 2px 6px;
  border-radius: 4px;
  background: rgba(0, 75, 251, 0.1);
  transition: all 0.2s ease;
  font-size: 0.9em;
}

:deep(.inline-url:hover) {
  background: rgba(0, 75, 251, 0.15);
  text-decoration: underline;
}

:deep(.inline-url)::before {
  content: '🔗';
  margin-right: 4px;
  font-size: 0.9em;
}

:deep(.inline-entity) {
  display: inline-flex;
  align-items: center;
  color: #2563eb;
  text-decoration: none;
  padding: 2px 6px;
  border-radius: 4px;
  background: rgba(37, 99, 235, 0.1);
  transition: all 0.2s ease;
  font-size: 0.9em;
  font-family: monospace;
}

:deep(.inline-entity:hover) {
  background: rgba(37, 99, 235, 0.15);
  text-decoration: underline;
}

:deep(.inline-entity)::before {
  content: '🔍';
  margin-right: 4px;
  font-size: 0.9em;
}

/* Style adjustments for when URLs or entities are inside highlights */
:deep([class*="highlight-"] .inline-url),
:deep([class*="highlight-"] .inline-entity) {
  background: rgba(255, 255, 255, 0.2);
  color: inherit;
}

:deep([class*="highlight-"] .inline-url:hover),
:deep([class*="highlight-"] .inline-entity:hover) {
  background: rgba(255, 255, 255, 0.3);
}

.rag-tokens-container :deep(.v-badge__badge) {
  font-size: 10px !important;
  height: 20px !important;
  min-width: 20px !important;
  padding: 24px 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
}

/* Optional: Add specific badge styles for collapsed state */
.rag-tokens-container.collapsed :deep(.v-badge__badge) {
  padding: 24px !important;
  /* Add any specific styles for collapsed state */
}
</style>
