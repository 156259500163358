<template>
  <v-card class="submissions-status-card">
    <v-card-title class="headline">
      My Uploads / Sources
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="search-field"
      ></v-text-field>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="refreshSubmissions"
            :loading="isLoading"
            v-bind="attrs"
            v-on="on"
            class="refresh-btn"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh submissions</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-treeview
        v-if="treeItems.length > 0"
        :items="treeItems"
        :search="search"
        :open="openAll"
        :loading="isLoading"
        item-key="id"
        open-on-click
        class="submissions-treeview"
      >
        <template v-slot:prepend="{ item }">
          <v-icon :color="getIconColor(item.type)">{{ getIcon(item.type) }}</v-icon>
        </template>
        <template v-slot:label="{ item }">
          <div class="d-flex align-center submission-item" @click="handleItemClick(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span class="item-name" v-bind="attrs" v-on="on">{{ truncateText(item.name, 50) }}</span>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
            <v-chip
              v-if="item.status"
              :color="getStatusColor(item.status)"
              small
              class="ml-2 status-chip"
            >
              {{ item.status }}
            </v-chip>
            <v-chip
              v-if="item.index_build_status"
              :color="getStatusColor(item.index_build_status)"
              small
              class="ml-2 status-chip"
            >
              Index: {{ item.index_build_status }}
            </v-chip>
            <span v-if="item.created_at" class="ml-2 text-caption created-at">
              {{ item.created_at | formatRelativeDate }}
            </span>
            <v-icon small class="ml-2 chat-hint">mdi-chat-processing-outline</v-icon>
            <v-tooltip bottom v-if="item.sensitivity_level && user.is_pro_team">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-if="item.sensitivity_level && user.is_pro_team"
                  x-small
                  :color="getSensitivityColor(item.sensitivity_level)"
                  text-color="white"
                  class="mt-1 ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon x-small left>{{ getSensitivityIcon(item.sensitivity_level) }}</v-icon>
                  {{ item.sensitivity_level }}
                </v-chip>
              </template>
              <v-card class="pa-3">
                <h3 class="headline subtitle-1 font-weight-bold mb-2">Sensitivity Levels</h3>
                <p class="mb-0"><strong>Internal:</strong> Visible to the user who submitted the data, and to team members</p>
                <p class="mb-0"><strong>Confidential:</strong> Visible to the user who submitted the data, and to Admins and Owners of this Universe</p>
              </v-card>
            </v-tooltip>
          </div>
        </template>
      </v-treeview>
      <v-alert
        v-else
        type="info"
        outlined
        class="no-submissions-alert"
      >
        No submissions found for this planet. Try creating a new submission or adjusting your search criteria.
      </v-alert>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SubmissionsStatus',
  data() {
    return {
      search: '',
      openAll: [],
    }
  },
  props: {
    projectId: {
      type: [String, Number],
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  watch: {
    projectId(newValue) {
      this.fetchSubmissions(newValue)
    }
  },
  computed: {
    ...mapState('user_submissions', ['textSubmissions', 'fileSubmissions', 'youtubeTranscriptSubmissions', 'eccsProjects', 'loading']),
    ...mapState('websocket', ['tempMessage', 'showTempMessage']),
    isLoading() {
      return this.loading
    },
    treeItems() {
      const projectMap = new Map(this.eccsProjects.map(p => [p.id, { ...p, type: 'project', children: [] }]))

      const allSubmissions = [
        ...this.textSubmissions.map(s => ({ ...s, type: 'Text' })),
        ...this.fileSubmissions.map(s => ({ ...s, type: 'File' })),
        ...this.youtubeTranscriptSubmissions.map(s => ({ ...s, type: 'YouTube' }))
      ]

      // Sort all submissions by created_at in descending order
      allSubmissions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

      allSubmissions.forEach(submission => {
        const project = projectMap.get(submission.eccs_project_id)
        if (project) {
          project.children.push({
            id: `${submission.type}-${submission.id}`,
            name: this.getSubmissionName(submission, submission.type),
            type: submission.type,
            status: submission.status,
            index_build_status: submission.index_build_status,
            created_at: submission.created_at,
            sensitivity_level: submission.sensitivity_level,
          })
        }
      })

      // Sort projects by the most recent submission in each project
      return Array.from(projectMap.values())
        .filter(project => project.children.length > 0)
        .sort((a, b) => {
          const aLatest = a.children[0].created_at
          const bLatest = b.children[0].created_at
          return new Date(bLatest) - new Date(aLatest)
        })
    },
  },
  methods: {
    ...mapActions('user_submissions', ['fetchSubmissions']),
    ...mapActions('websocket', ['setActiveObject', 'showTempMessageFn']),
    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + '...';
    },
    refreshSubmissions() {
      this.fetchSubmissions(this.projectId)
    },
    getSensitivityColor(level) {
      switch(level) {
        case 'Internal':
          return '#004bfb';
        case 'Confidential':
          return 'red';
        default:
          return 'grey';
      }
    },
    getSensitivityIcon(level) {
      switch(level) {
        case 'Internal':
          return 'mdi-lock-open-variant-outline';
        case 'Confidential':
          return 'mdi-lock';
        default:
          return 'mdi-eye-off';
      }
    },
    updateOpenAll() {
      this.openAll = this.treeItems.map(item => item.id)
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case 'completed':
        case 'success':
          return 'success';
        case 'processing':
        case 'indexing':
          return 'info';
        case 'failed':
          return 'error';
        case 'pending':
          return 'warning';
        default:
          return 'grey';
      }
    },
    getIconColor(type) {
      switch (type) {
        case 'project': return 'purple'
        case 'Text': return 'blue'
        case 'File': return 'green'
        case 'YouTube': return 'red'
        default: return 'grey'
      }
    },
    getIcon(type) {
      switch (type) {
        case 'project': return 'mdi-earth'
        case 'Text': return 'mdi-text-box-outline'
        case 'File': return 'mdi-paperclip'
        case 'YouTube': return 'mdi-youtube'
        default: return 'mdi-file-outline'
      }
    },
    getSubmissionName(submission, type) {
      switch (type) {
        case 'Text':
          return `Text Submission ${submission.id}`
        case 'File':
          return submission.file_name
        case 'YouTube':
          return submission.video_title || `YouTube Transcript ${submission.id}`
        default:
          return `Submission ${submission.id}`
      }
    },
    handleItemClick(item) {
      if (item.type === 'project') {
        this.setActiveObject({
          type: 'ECCSProject',
          id: item.id,
        });
      } else {
        const [type, id] = item.id.split('-');
        this.setActiveObject({
          type: type === 'Text' ? 'TextSubmission' : type === 'File' ? 'FileSubmission' : 'YouTubeTranscriptSubmission',
          id: id,
        });
      }
      this.$emit('close-dialog');
    },
    showFeedback(message) {
      this.$vuetify.snackbar = {
        text: message,
        color: 'info',
        timeout: 2000,
        bottom: true,
      };
    },
  },
  mounted() {
    this.fetchSubmissions(this.projectId)
  },
}
</script>

<style scoped>
.submissions-status-card {
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.5);
}

.headline {
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
}

.search-field {
  max-width: 300px;
}

.submissions-treeview {
  padding: 16px;
}

.submission-item {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.item-name {
  font-weight: 500;
}

.status-chip {
  font-size: 0.75rem;
  font-weight: bold;
}

.created-at {
  color: #6c757d;
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  color: #10B981;
}

.submission-item:hover .chat-hint {
  opacity: 1;
  animation: pulse 1.5s infinite;
}

.no-submissions-alert {
  margin-top: 16px;
  color: #1565c0;
  border-color: #1565c0;
}

.refresh-btn {
  transition: transform 0.3s ease;
}

.refresh-btn:hover {
  transform: rotate(180deg);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.refresh-btn.v-btn--loading {
  animation: pulse 1.5s infinite;
}
</style>
