<template>
  <div class="settings-box-container" :class="{ 'collapsed': !showSettings }">
    <div class="settings-header">
      <v-btn
        icon
        @click="toggleSettings"
        :color="showSettings ? 'grey' : 'grey'"
        class="toggle-btn"
      >
        <v-icon>
          {{ showSettings ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>

      <!-- Collapsed header content -->
      <div v-if="!showSettings" class="collapsed-header">
        <v-icon small color="grey" class="mr-2">mdi-keyboard-settings-outline</v-icon>
        <span class="settings-title-small">Answer Settings Values</span>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small class="ml-2" color="grey" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span>Configure AI response parameters</span>
        </v-tooltip>
      </div>
    </div>

    <v-expand-transition>
      <div v-if="showSettings" class="settings-content">
        <div class="settings-header">
          <span class="settings-box-title">Answer Settings</span>
          <v-tooltip left max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" color="A9A9A9">
                <v-icon small class="help-icon">mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <v-card class="pa-4">
              <h3 class="headline mb-2">UnStruct's Configurable Answer Settings</h3>
              <v-divider class="mb-3"></v-divider>
              <p class="body-2 mb-2">
                These settings allow fine-tuning of AI responses, showcasing UnStruct's powerful configurability:
              </p>
              <ul class="body-2 pl-4 mb-3">
                <li><strong>LLM Model & Temperature:</strong> Control the AI model and its creativity level</li>
                <li><strong>RAG Token Threshold:</strong> Adjust the amount of context provided to the AI</li>
                <li><strong>Relationship Thresholds:</strong> Fine-tune entity and relationship matching</li>
                <li><strong>Web Search Options:</strong> Customize external information retrieval</li>
              </ul>
              <p class="body-2 mb-2"><strong>Impact on Answers:</strong></p>
              <ul class="body-2 pl-4 mb-3">
                <li>Higher temperature: More creative, diverse responses</li>
                <li>Lower thresholds: More inclusive, but potentially less precise matches</li>
                <li>Increased RAG tokens: More context, potentially more accurate but slower responses</li>
              </ul>
              <v-divider class="mb-3"></v-divider>
              <p class="body-2 font-italic">
                UnStruct's extensive configurability allows you to tailor the AI's behavior to your specific needs,
                balancing accuracy, creativity, and performance.
              </p>
            </v-card>
          </v-tooltip>
        </div>

        <div class="settings-box-content">
          <v-chip-group column>
            <v-chip v-for="(value, key) in compactSettings" :key="key" x-small outlined class="ma-1">
              <strong>{{ formatKey(key) }}:</strong>&nbsp;
              <span class="setting-value-compact">
                {{ formatValue(value) }}
              </span>
            </v-chip>
          </v-chip-group>
        </div>

        <div class="settings-actions mt-2">
          <v-btn x-small text @click="toggleFullSettings" color="primary">
            {{ showFullSettings ? 'Hide Details' : 'Show Details' }}
          </v-btn>
        </div>

        <v-expand-transition>
          <div v-if="showFullSettings" class="full-settings-wrapper mt-2">
            <v-card flat>
              <v-card-text>
                <v-list dense>
                  <v-list-item v-for="(value, key) in settings" :key="key">
                    <v-list-item-content>
                      <v-list-item-title class="setting-key text-caption">{{ formatKey(key) }}</v-list-item-title>
                      <v-list-item-subtitle
                        class="setting-value text-caption"
                        :class="{ 'pre-formatted': Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' }"
                      >
                        {{ formatValue(value) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </div>
        </v-expand-transition>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'AnswerSettingsBox',
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFullSettings: false,
      showSettings: false
    }
  },
  computed: {
    compactSettings() {
      const important = ['llmModelName', 'useDefaultPrompt', 'rag_strategy', 'ragTokenThreshold', 'relationshipMatchThreshold'];
      return Object.fromEntries(
        Object.entries(this.settings).filter(([key]) => important.includes(key))
      );
    }
  },
  methods: {
    toggleSettings() {
      this.showSettings = !this.showSettings;
      if (!this.showSettings) {
        this.showFullSettings = false;
      }
    },
    toggleFullSettings() {
      this.showFullSettings = !this.showFullSettings;
    },
    formatKey(key) {
      let formattedKey = key.startsWith('google_') ? 'web_' + key.slice(7) : key;

      // Split the key by underscores, capitalize each word, and join them back
      return formattedKey.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    formatValue(value) {
      if (typeof value === 'boolean') return value ? 'Yes' : 'No';
      if (typeof value === 'number') return value.toFixed(2);
      if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object') {
        return value.map(item => `\n  ${item.site}: ${item.filter}`).join('');
      }
      return value;
    },
  }
}
</script>

<style scoped>
.settings-box-container {
  font-family: 'Roboto', sans-serif;
  position: fixed;
  border-radius: 8px;
  padding: 10px;
  width: 250px;
  max-height: calc(80vh - 200px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1004;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
    .settings-box-container {
      top: 50%;
    }
  }

  @media (max-width: 900px) {
    .settings-box-container {
      top: 50%;
    }
  }

.settings-box-container.collapsed {
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.toggle-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.settings-content {
  margin-top: 30px;
}

.settings-header {
  display: flex;
  align-items: center;
  padding: 8px;
}

.collapsed-header {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.settings-title-small {
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
}

.settings-box-title {
  font-size: 14px;
  font-weight: 500;
}

.settings-actions {
  display: flex;
  align-items: center;
}

.settings-box-content {
  border-radius: 4px;
  padding: 8px;
}

.setting-key {
  font-weight: 500;
}

.setting-value {
  color: #64B5F6 !important;
}

.setting-value-compact {
  color: #64B5F6 !important;
  font-size: 0.75rem;
}

.help-icon {
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.help-icon:hover {
  color: #004bfb;
  opacity: 1;
}

.full-settings-wrapper {
  border-radius: 4px;
  transition: all 0.3s ease;
  max-height: 300px;
  overflow-y: auto;
}

.pre-formatted {
  white-space: pre-wrap;
  font-family: 'Roboto Mono', monospace;
  font-size: 11px;
  padding: 6px;
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 6px;
}

</style>
