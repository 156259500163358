<!-- ResourcesManagerIcon.vue -->
<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      :width="size"
      :height="size"
      :class="classes"
    >
      <!-- Base container with rounded corners -->
      <path
        d="M4 6c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linejoin="round"
      />

      <!-- Modern upload arrow -->
      <path
        d="M12 16V8m0 0l-3 3m3-3l3 3"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <!-- Base line -->
      <path
        d="M7 16h10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        opacity="0.9"
      />

      <!-- Subtle top accent -->
      <path
        d="M7 6h10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        opacity="0.5"
      />
    </svg>
  </template>

  <script>
  export default {
    name: 'ResourcesManagerIcon',
    props: {
      size: {
        type: [Number, String],
        default: 24
      },
      classes: {
        type: [String, Object, Array],
        default: ''
      }
    }
  }
  </script>
