<template>
  <v-card>
    <v-card-title class="d-flex align-center headline">
      <span>
        <v-icon left>mdi-web</v-icon>
        Web Search and Indexing Status
      </span>
      <v-tooltip right max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            v-bind="attrs"
            v-on="on"
            class="ml-2"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <v-card class="pa-4">
          <h3 class="headline mb-2">Web Search and Indexing Table</h3>
          <v-divider class="mb-3"></v-divider>
          <p class="body-2 mb-2">This table displays the status of web searches and index building for your queries:</p>
          <ul class="body-2 pl-4 mb-3">
            <li>Each liked message initiates a web search, OR_</li>
            <li>If the search setting `Saved Crawled Content for Every Query` is enabled, the crawled content is already saved</li>
            <li>Relevant information is processed and indexed</li>
            <li>Your personal knowledge base is continuously enhanced</li>
          </ul>
          <p class="body-2 mb-2"><strong>Impact:</strong></p>
          <ul class="body-2 pl-4">
            <li>Improves accuracy of future searches</li>
            <li>Enhances relevance of AI responses</li>
            <li>Builds a personalized information repository</li>
          </ul>
          <v-divider class="my-3"></v-divider>
          <p class="caption font-italic">
            Monitor this table to track the growth of your personalized knowledge base.
          </p>
        </v-card>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn icon @click="refreshData">
        <v-icon dark>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>
    <v-text-field
      v-model="search"
      label="Search"
      class="mb-4 ml-4 mr-4 mt-4"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="filteredLikedMessagesProcessing"
      :items-per-page="10"
      :loading="loading"
      class="elevation-1 liked-messages-table"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      @click:row="handleRowClick"
    >
      <template v-slot:item.user_message="{ item }">
        {{ truncateMessage(item.user_message, 50) }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatRelativeDate }}
      </template>
      <template v-slot:item.web_crawl_status="{ item }">
        <v-chip :color="getStatusColor(item.web_crawl_status)">
          {{ item.web_crawl_status }}
        </v-chip>
      </template>
      <template v-slot:item.index_build_status="{ item }">
        <v-chip :color="getStatusColor(getIndexBuildStatus(item))">
          {{ getIndexBuildStatus(item) }}
        </v-chip>
      </template>
      <template v-slot:item.web_crawl_last_attempted_at="{ item }">
        {{ item.web_crawl_last_attempted_at | formatRelativeDate }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ID</th>
                  <th class="text-left">URL</th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Attempts</th>
                  <th class="text-left">Last Updated</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(url, index) in item.urls"
                  :key="`${item.id}-${url.url}-${index}`"
                  class="url-row"
                  @click="handleUrlClick(url)"
                >
                  <td>{{ url.id }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          :href="url.url"
                          target="_blank"
                          rel="noopener noreferrer"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop
                          class="url-link"
                        >
                          {{ shortenUrl(url.url) }}
                        </a>
                      </template>
                      <span>{{ url.url }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-chip :color="getStatusColor(url.status)">
                      {{ url.status }}
                    </v-chip>
                  </td>
                  <td>{{ url.failure_count }}</td>
                  <td>{{ url.last_attempted_at | formatRelativeDate }}</td>
                  <td>
                    <v-icon small color="primary" class="chat-hint">mdi-chat-processing-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:item.action>
        <v-icon small color="primary" class="chat-hint">mdi-chat-processing-outline</v-icon>
      </template>
      <template v-slot:no-data>
        No web search or indexing data available.
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'LikedMessagesProcessingStatus',
  data() {
    return {
      search: "",
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'User Message', value: 'user_message' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Web Crawl Status', value: 'web_crawl_status' },
        { text: 'Index Build Status', value: 'index_build_status' },
        { text: 'Last Crawl Attempt', value: 'web_crawl_last_attempted_at' },
        { text: '', value: 'data-table-expand' },
        { text: '', value: 'action', sortable: false, width: "48px" },
      ],
      expanded: [],
    };
  },
  computed: {
    ...mapState('user_interactions', ['likedMessagesProcessing', 'loading']),
    ...mapState('websocket', ['tempMessage', 'showTempMessage']),
    filteredLikedMessagesProcessing() {
      return this.likedMessagesProcessing.filter((message) => {
        return message.user_message.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    ...mapActions('user_interactions', ['fetchLikedMessagesProcessing']),
    ...mapActions('websocket', ['setActiveObject', 'showTempMessageFn']),
    getIndexBuildStatus(item) {
      if (!item.thumbs_up) {
        return 'n/a';
      }
      return item.index_build_status || 'pending';
    },
    shortenUrl(url) {
      const maxLength = 30;
      if (url.length <= maxLength) return url;
      return url.substring(0, maxLength - 3) + '...';
    },
    handleUrlClick(url) {
      this.setActiveObject({
        type: 'Website',
        id: url.id,
      });
      this.$emit('close-dialog');
    },
    getStatusColor(status) {
      switch (status) {
        case 'pending':
          return 'warning';
        case 'processing':
          return 'primary';
        case 'success':
          return 'success';
        case 'failed':
          return 'error';
        case 'skipped':
          return 'secondary';
        case 'n/a':
          return 'grey';
        default:
          return 'grey';
      }
    },
    truncateMessage(message, maxLength) {
      if (message.length <= maxLength) return message;
      return message.substr(0, maxLength) + '...';
    },
    refreshData() {
      let projectId = localStorage.getItem('project');

      // Check if projectId exists
      if (projectId) {
        this.fetchLikedMessagesProcessing(projectId);
      } else {
        console.warn('No project ID found in localStorage');
        // Handle the case when no project ID is found, maybe set a default or show an error message
      }
    },
    handleRowClick(item) {
      this.setActiveObject({
        type: "LikedMessage",
        id: item.id,
      });
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>
.liked-messages-table ::v-deep tbody tr,
.url-row {
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
}

.liked-messages-table ::v-deep tbody tr:hover,
.url-row:hover {
  background-color: rgba(33, 150, 243, 0.1) !important;
  box-shadow: 0 0 15px rgba(33, 150, 243, 0.5);
}

.chat-hint {
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  color: #004bfb;
}

.liked-messages-table ::v-deep tbody tr:hover .chat-hint,
.url-row:hover .chat-hint {
  opacity: 1;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.url-link {
  color: inherit;
  text-decoration: none;
}

.url-link:hover {
  text-decoration: underline;
}

.v-tooltip__content {
  opacity: 1 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-card {
  border: 1px solid rgba(0, 198, 255, 0.2) !important;
}

.headline {
  padding: 10px 20px !important;
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
}

ul {
  list-style-type: none;
}

ul li::before {
  content: "•";
  color: #004bfb;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
</style>
