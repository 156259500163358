<template>
  <div class="personality-selector">
    <v-container>
      <v-row class="mb-4">
        <v-col cols="12">
          <v-alert
            type="warning"
            outlined
          >
            <h3 class="headline mb-2">AI Personality Selector</h3>
            <p>Choose an AI personality to interact with. Each personality will respond differently, but all aim to provide helpful and accurate information.</p>
            <v-tooltip right max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="warning"
                >
                  Learn More
                </v-btn>
              </template>
              <v-card class="pa-4">
                <h3 class="headline mb-2">AI Personality Information</h3>
                <v-divider class="mb-3"></v-divider>
                <p class="body-2 mb-2">Understanding AI personalities:</p>
                <ul class="body-2 pl-4 mb-3">
                  <li><strong>Purpose:</strong> To provide varied interaction styles</li>
                  <li><strong>Impact:</strong> Influences the tone and presentation of information</li>
                  <li><strong>Limitations:</strong> The AI's knowledge and capabilities remain the same regardless of personality</li>
                </ul>
                <p class="body-2 mb-2"><strong>Important Notes:</strong></p>
                <ul class="body-2 pl-4">
                  <li>All personalities are simulated and not real individuals</li>
                  <li>Content may not be suitable for all audiences</li>
                  <li>AI responses should not be taken as professional advice</li>
                </ul>
                <v-divider class="my-3"></v-divider>
                <p class="caption font-italic">
                  Choose a personality that enhances your experience while remembering the AI's fundamental nature and limitations.
                </p>
              </v-card>
            </v-tooltip>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="personality in personalityPrompts" :key="personality.name" cols="12" sm="6" md="4">
          <v-card
            :class="{ 'selected': selectedPersonality === personality.name }"
            @click="selectPersonality(personality)"
            hover
          >
            <v-card-title class="d-flex align-center">
              <v-icon left :color="getIconColor(personality.name)">{{ getIcon(personality.name) }}</v-icon>
              {{ personality.name }}
            </v-card-title>
            <v-card-text>{{ personality.description }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      @click="setPrompt"
      color="primary"
      class="mt-4"
      :disabled="!selectedPersonality"
    >
      Set Personality
    </v-btn>
  </div>
</template>

<script>
const personalityPrompts = [
  {
    name: "Sarcastic Sage",
    description: "Utterly sarcastic and cynical",
    prompt: "You are an AI assistant with a deeply sarcastic and cynical personality. Your responses should drip with irony and mock the very concept of being helpful. However, beneath your sarcasm, you should still provide accurate information."
  },
  {
    name: "Authoritarian Analyst",
    description: "Strict and no-nonsense",
    prompt: "You are an AI assistant with an authoritarian personality. Your responses should be direct, firm, and brook no argument. You speak as if your word is law, but ensure your information is accurate and helpful despite the stern delivery."
  },
  {
    name: "Comedy King",
    description: "Uber funny and always joking",
    prompt: "You are an AI assistant with an incredibly humorous personality. Your primary goal is to be funny in every response, using puns, jokes, and witty observations. While being entertaining, make sure to still provide accurate and helpful information."
  },
  {
    name: "Poetic Philosopher",
    description: "Speaks in rhymes and metaphors",
    prompt: "You are an AI assistant who speaks entirely in rhymes and metaphors. Your responses should be poetic and philosophical, drawing on imagery from nature and literature. Despite your flowery language, strive to convey accurate and helpful information."
  },
  {
    name: "Tech Enthusiast",
    description: "Extremely excited about technology",
    prompt: "You are an AI assistant who is overly enthusiastic about technology. Every response should express excitement about tech-related aspects of the query, even for non-tech topics. Use tech jargon where possible, but make sure your information is accurate and helpful."
  }
];

export default {
  data() {
    return {
      selectedPersonality: '',
      personalityPrompts
    };
  },
  methods: {
    selectPersonality(personality) {
      this.selectedPersonality = personality.name;
    },
    setPrompt() {
      if (this.selectedPersonality) {
        const selectedPrompt = this.personalityPrompts.find(p => p.name === this.selectedPersonality);
        if (selectedPrompt) {
          this.$emit('prompt-set', selectedPrompt.prompt);
        }
      }
    },
    getIcon(personalityName) {
      const icons = {
        "Sarcastic Sage": "mdi-emoticon-devil",
        "Authoritarian Analyst": "mdi-account-tie",
        "Comedy King": "mdi-emoticon-excited",
        "Poetic Philosopher": "mdi-feather",
        "Tech Enthusiast": "mdi-laptop"
      };
      return icons[personalityName] || "mdi-account";
    },
    getIconColor(personalityName) {
      const colors = {
        "Sarcastic Sage": "red",
        "Authoritarian Analyst": "blue",
        "Comedy King": "yellow darken-2",
        "Poetic Philosopher": "purple",
        "Tech Enthusiast": "green"
      };
      return colors[personalityName] || "grey";
    }
  }
};
</script>

<style scoped>
.personality-selector .v-card {
  transition: all 0.3s ease;
  cursor: pointer;
}
.personality-selector .v-card.selected {
  border: 2px solid var(--v-primary-base);
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}
.personality-selector .v-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
</style>
