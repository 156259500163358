<!-- EntityResultsAnimation.vue -->
<template>
    <div class="entity-results-visualization" :class="{ 'collapsed': isCollapsed }">
      <!-- Entity Status Bar -->
      <div class="entity-status-bar" @click="toggleCollapse">
        <div class="status-icon">
          <div :class="['status-dot', { 'pulse': !finalAnswerReceived }]"></div>
        </div>
        <div class="status-text">{{ statusMessage }}</div>
        <div class="results-counter" v-if="!isCollapsed">
          {{ processedEntities.length }}/{{ entities.length }}
        </div>
        <div class="collapse-icon">
          <i :class="isCollapsed ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-up'"></i>
        </div>
      </div>

      <!-- Animated Entities Container -->
      <transition-group
        name="entity-transition"
        tag="div"
        class="entities-container"
        v-if="!isCollapsed"
      >
        <div
          v-for="entity in processedEntities"
          :key="entity.id"
          class="entity-item"
        >
          <div class="entity-connector">
            <div class="connector-line"></div>
            <div class="connector-dot"></div>
          </div>
          <div class="entity-content">
            <a
              :href="entity.url"
              target="_blank"
              rel="noopener noreferrer"
              class="entity-text"
            >
              {{ entity.name }}
              <i class="mdi mdi-open-in-new text-xs ml-1"></i>
            </a>
            <div class="entity-count" v-if="entity.count > 1">
              Referenced {{ entity.count }} times
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </template>

  <script>
  export default {
    name: 'EntityResultsAnimation',
    props: {
      entities: {
        type: Array,
        required: true,
        default: () => []
      },
      isSearching: {
        type: Boolean,
        default: false
      },
      finalAnswerReceived: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        processedEntities: [],
        statusMessages: [
          'Processing knowledge graph...',
          'Analyzing entity relationships...',
          'Extracting relevant entities...',
          'Preparing entity results...'
        ],
        completedMessage: 'Entity extraction completed - Click to expand/collapse',
        currentStatusIndex: 0,
        isCollapsed: false
      }
    },
    computed: {
      statusMessage() {
        return this.finalAnswerReceived ? this.completedMessage : this.statusMessages[this.currentStatusIndex]
      }
    },
    watch: {
      entities: {
        immediate: true,
        handler(newEntities) {
          this.processedEntities = []
          if (newEntities.length > 0) {
            this.animateEntities(newEntities)
          }
        }
      },
      finalAnswerReceived: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.$emit('update:finalAnswer', true)
            setTimeout(() => {
              this.isCollapsed = true
              this.$emit('collapsed', true)
            }, 300)
          }
        }
      }
    },
    methods: {
      animateEntities(entities) {
        entities.forEach((entity, index) => {
          setTimeout(() => {
            this.processedEntities.push(entity)
            this.currentStatusIndex = (index % this.statusMessages.length)
          }, index * 800)
        })
      },
      toggleCollapse() {
        this.isCollapsed = !this.isCollapsed
        this.$emit('collapsed', this.isCollapsed)
      }
    }
  }
  </script>

  <style scoped>
  .entity-results-visualization {
    padding: 1rem;
    background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
    border-radius: 8px;
    margin: 1rem 0;
    transition: all 0.3s ease;
  }

  .entity-results-visualization.collapsed {
    padding: 0.5rem;
  }

  .entity-status-bar {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .collapsed .entity-status-bar {
    margin-bottom: 0;
  }

  .status-icon {
    margin-right: 1rem;
  }

  .status-dot {
    width: 8px;
    height: 8px;
    background: #10B981;
    border-radius: 50%;
  }

  .status-dot.pulse {
    animation: pulse 2s infinite;
  }

  .status-text {
    flex-grow: 1;
    font-size: 0.9rem;
  }

  .results-counter {
    font-size: 0.8rem;
    color: #999;
    margin-right: 1rem;
  }

  .collapse-icon {
    color: #666;
    transition: transform 0.3s ease;
  }

  .collapsed .collapse-icon i {
    transform: rotate(180deg);
  }

  .entities-container {
    position: relative;
    transition: all 0.3s ease;
  }

  .entity-item {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
    position: relative;
  }

  .entity-connector {
    width: 24px;
    margin-right: 1rem;
    position: relative;
  }

  .connector-line {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #e0e0e0;
    transform: translateX(-50%);
  }

  .connector-dot {
    width: 8px;
    height: 8px;
    background: #004bfb;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .entity-content {
    flex-grow: 1;
    background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .entity-text {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    word-break: break-all;
  }

  .theme--dark .entity-text {
    color: #fff;
  }

  .theme--light .entity-text {
    color: #333;
  }

  .entity-text:hover {
    text-decoration: underline;
    color:  #2196F3;
  }

  .entity-count {
    font-size: 0.8rem;
    color: #666;
    margin-top: 0.25rem;
  }

  /* Transitions */
  .entity-transition-enter-active,
  .entity-transition-leave-active {
    transition: all 0.5s ease;
  }

  .entity-transition-enter,
  .entity-transition-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  </style>
