<template>
  <div class="rag-strategy-container-help" :class="{ 'collapsed': !isExpanded }">
    <div class="rag-strategy-header">
      <v-btn icon @click="toggleExpand" :color="isExpanded ? 'grey' : 'grey'" class="toggle-btn">
        <v-icon>{{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>

      <!-- Collapsed header content -->
      <div v-if="!isExpanded" class="collapsed-header">
        <v-icon small color="grey" class="mr-2">mdi-arrow-decision-auto-outline</v-icon>
        <span class="rag-strategy-title-small">Decision Tree: {{ strategyName }}</span>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon x-small class="ml-2" color="grey" v-bind="attrs" v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ strategyDescription }}</span>
        </v-tooltip>
      </div>

      <span v-if="isExpanded" class="rag-strategy-title">RAG Strategy: {{ strategyName }}</span>
      <v-tooltip v-if="isExpanded" left max-width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-icon small left class="ml-4 rag-explanation-icon" color="primary" v-bind="attrs" v-on="on">
            mdi-lightbulb-on-outline
          </v-icon>
        </template>
        <v-card class="rag-tooltip-card">
          <v-card-title class="headline">Understanding RAG in UnStruct</v-card-title>
          <v-card-text>
            <p><strong>RAG (Retrieval-Augmented Generation)</strong> is a crucial technique in modern AI, especially for Large Language Models (LLMs).</p>
            <v-divider class="my-3"></v-divider>
            <h4 class="subtitle-1 font-weight-bold mb-2">How RAG Works:</h4>
            <ol class="mb-3">
              <li>Retrieves relevant information from external sources</li>
              <li>Augments the LLM's knowledge with this retrieved data</li>
              <li>Generates responses based on combined knowledge</li>
            </ol>
            <h4 class="subtitle-1 font-weight-bold mb-2">Benefits of RAG:</h4>
            <ul class="mb-3">
              <li>Enhances accuracy and relevance of AI responses (if you feed relevant data)</li>
              <li>Provides up-to-date information beyond the model's training data</li>
              <li>Allows for context-specific and domain-expert level responses</li>
            </ul>
            <h4 class="subtitle-1 font-weight-bold mb-2">RAG in UnStruct:</h4>
            <p>UnStruct uniquely displays the RAG content sent to LLMs, offering transparency in how responses are generated. This feature allows users to understand the sources and context influencing the AI's answers.</p>
            <v-alert dense outlined type="info" class="mt-3">
              By showing RAG data, UnStruct empowers users to critically evaluate AI responses and understand the basis of the information provided.
            </v-alert>
          </v-card-text>
        </v-card>
      </v-tooltip>
      <v-icon small v-if="isExpanded" right :color="isExpanded ? 'primary' : 'grey'" @click="toggleExpand">{{ strategyIcon }}</v-icon>
    </div>
    <v-expand-transition>
      <div v-if="isExpanded" class="rag-strategy-content">
        <p>{{ strategyDescription }}</p>
        <v-expansion-panels v-model="panel" flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="py-1">
              <v-icon small>mdi-arrow-decision-auto-outline</v-icon>
              View decision tree and execution steps
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="subtitle-1 font-weight-medium mt-2 mb-2">Strategy selection order:</p>
              <ol class="decision-tree">
                <li v-for="(step, index) in decisionTree" :key="index">{{ step }}</li>
              </ol>
              <p class="subtitle-1 font-weight-medium mt-4 mb-2">Execution steps:</p>
              <ol class="strategy-steps">
                <li v-for="(step, index) in strategySteps" :key="`exec-${index}`">{{ step }}</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'RagStrategyExplainer',
  props: {
    ragStrategy: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      panel: null,
      isExpanded: false
    }
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    }
  },
  computed: {
    strategyName() {
      switch(this.ragStrategy) {
        case 'ecrag': return 'ECRAG'
        case 'web_search': return 'Web Search'
        case 'llm_passthrough': return 'LLM Passthrough'
        default: return 'Default Catch-All'
      }
    },
    strategyIcon() {
      switch(this.ragStrategy) {
        case 'ecrag': return 'mdi-atom-variant'
        case 'web_search': return 'mdi-web'
        case 'llm_passthrough': return 'mdi-atom'
        default: return 'mdi-help-circle-outline'
      }
    },
    strategyColor() {
      switch(this.ragStrategy) {
        case 'ecrag': return '#4CAF50'
        case 'web_search': return '#FF5722'
        case 'llm_passthrough': return '#FFC107'
        default: return '#9E9E9E'
      }
    },
    strategyDescription() {
      switch(this.ragStrategy) {
        case 'ecrag':
          return 'Uses internal knowledge bases for context-aware responses, prioritizing IAO-specific entities.'
        case 'web_search':
          return 'Utilizes real-time web information for up-to-date answers when no IAO is selected.'
        case 'llm_passthrough':
          return 'Relies on the AI model\'s pre-trained knowledge, and can be used with or without an IAO.'
        default:
          return 'Catch-all strategy for active objects without direct raw text mapping.'
      }
    },
    decisionTree() {
      return [
        'Check for IAO selection',
        'Use ECRAG with IAO-specific entities if selected',
        'Revert to raw text if ECRAG fails or exceeds LLM window',
        'Fall back to adjusted ECRAG if raw text still exceeds limit',
        'Use LLM Passthrough if no IAO and strategy selected',
        'Use Web Search if no IAO and strategy selected',
        'Default to catch-all strategy for other cases'
      ]
    },
    strategySteps() {
      switch(this.ragStrategy) {
        case 'ecrag':
          return [
            'Classify query and generate concept map',
            'Retrieve top related entities',
            'Build context from original entities',
            'Add context from related entities if needed',
            'Generate response using combined context'
          ]
        case 'web_search':
          return [
            'Search the web for relevant content',
            'Process and summarize results',
            'Generate response using summarized context'
          ]
        case 'llm_passthrough':
          return [
            'Send query directly to language model',
            'Generate response based on pre-trained knowledge'
          ]
        default:
          return [
            'Retrieve content from active object',
            'Trim or summarize if exceeding token limit',
            'Generate response using processed content'
          ]
      }
    }
  }
}
</script>

<style scoped>
.rag-strategy-container-help {
  position: fixed;
  width: 250px;
  max-height: 40vh;
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  z-index: 1003;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
    .rag-strategy-container-help {
      top: 70%;
    }
  }

  @media (max-width: 900px) {
    .rag-strategy-container-help {
      top: 70%;
    }
  }

.rag-strategy-container-help.collapsed {
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.rag-strategy-header {
  display: flex;
  align-items: center;
  padding: 8px;
}

.collapsed-header {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.rag-strategy-title-small {
  font-size: 12px;
  margin-right: 8px;
}

.rag-strategy-title {
  font-size: 14px;
}

.rag-strategy-content {
  font-size: 12px;
}

.toggle-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.v-expansion-panels {
  background: transparent !important;
  box-shadow: none !important;
}

.v-expansion-panel {
  border-radius: 4px !important;
  margin-bottom: 8px;
}

.v-expansion-panel-header {
  font-size: 12px !important;
  padding: 0 12px !important;
  min-height: 36px !important;
}

.v-expansion-panel-content {
  padding: 0 12px 12px;
}

ol {
  list-style-type: none;
  padding-left: 0;
  counter-reset: item;
}

ol li {
  counter-increment: item;
  margin-bottom: 6px;
  position: relative;
  padding-left: 20px;
  font-size: 11px;
}

ol li:before {
  content: counter(item);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 1px;
  line-height: 16px;
}

::-webkit-scrollbar {
  width: 6px;
}

</style>
