<template>
  <div class="cosmic-background">
    <div class="content">
      <div class="header-section">
        <h2 class="primary-mission-color">Select a topic</h2>
        <button class="refresh-button" @click="refreshQuestions" :class="{ 'refreshing': isRefreshing }">
          <span class="refresh-icon">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M21.5 2v6h-6M2.5 22v-6h6M2 12c0-2.8 1.2-5.2 3.2-7C7 3.2 9.6 2 12.5 2M22 12c0 2.8-1.2 5.2-3.2 7-1.8 1.8-4.4 3-7.3 3"/>
            </svg>
          </span>
          <span class="button-text">Explore More Topics</span>
        </button>
      </div>

      <!-- Rest of your template remains the same -->
      <div class="feature-grid">
        <div v-for="(feature, index) in features"
            :key="index"
            class="feature-box"
            :style="{ '--index': index }"
            :class="{ 'feature-box-delayed': index > 0 }"
            @click="handleFeatureClick(feature.question)">
          <div class="feature-icon" v-html="feature.icon"></div>
          <h4>{{ feature.title }}</h4>

          <div class="question-area">
            <p class="question-text">{{ feature.question }}</p>
            <div class="topics">
              <span v-for="(topic, tIndex) in feature.topics"
                    :key="tIndex"
                    class="topic-tag"
                    @click.stop="handleTopicClick(topic)">
                #{{ topic }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const questionPool = {
  aiml: {
    title: 'AI & Machine Learning',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M21 11c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12V5l9-4 9 4v6z"/></svg>',
    questions: [
    {
        question: "How do large language models handle context windows differently?",
        topics: ['Transformers', 'Neural-Networks', 'Context']
      },
      {
        question: "What are the latest developments in transformer architecture?",
        topics: ['Architecture', 'Deep-Learning', 'Models']
      },
      {
        question: "How does retrieval-augmented generation prevent hallucination?",
        topics: ['RAG', 'Accuracy', 'Verification']
      },
      {
        question: "What role does attention mechanism play in modern AI?",
        topics: ['Attention', 'Neural-Networks', 'Architecture']
      },
      {
        question: "How has transfer learning evolved in recent years?",
        topics: ['Transfer-Learning', 'Training', 'Models']
      }
    ]
  },
  research: {
    title: 'Science & Innovation',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><circle cx="12" cy="12" r="10"/><path d="M12 16v-4M12 8h.01"/></svg>',
    questions: [
      {
        question: "What advances have been made in fusion energy recently?",
        topics: ['Fusion', 'Energy', 'Physics']
      },
      {
        question: "How is synthetic biology reshaping medicine?",
        topics: ['Biotech', 'Medicine', 'Innovation']
      },
      {
        question: "What are the latest breakthroughs in quantum computing?",
        topics: ['Quantum', 'Computing', 'Research']
      },
      {
        question: "How is CRISPR technology evolving?",
        topics: ['CRISPR', 'Genetics', 'Medicine']
      },
      {
        question: "What progress has been made in brain-computer interfaces?",
        topics: ['Neurotech', 'BCI', 'Innovation']
      }
    ]
  },
  business: {
    title: 'Business & Technology',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M22 12h-4l-3 9L9 3l-3 9H2"/></svg>',
    questions: [
      {
        question: "How are companies implementing zero-trust security?",
        topics: ['Security', 'Enterprise', 'Zero-Trust']
      },
      {
        question: "What impact is Web3 having on traditional business models?",
        topics: ['Web3', 'Business', 'Innovation']
      },
      {
        question: "How is edge computing transforming IoT applications?",
        topics: ['Edge', 'IoT', 'Computing']
      },
      {
        question: "What are the challenges in scaling microservices architecture?",
        topics: ['Microservices', 'Architecture', 'Scaling']
      },
      {
        question: "How are organizations handling multi-cloud environments?",
        topics: ['Cloud', 'Infrastructure', 'Enterprise']
      }
    ]
  },
  future: {
    title: 'Future Tech',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/></svg>',
    questions: [
      {
        question: "How close are we to practical quantum supremacy?",
        topics: ['Quantum', 'Computing', 'Future']
      },
      {
        question: "What progress has been made in metaverse technology?",
        topics: ['Metaverse', 'VR', 'AR']
      },
      {
        question: "How is 6G research shaping future communications?",
        topics: ['6G', 'Communications', 'Networks']
      },
      {
        question: "What developments are happening in neural interfaces?",
        topics: ['Neural', 'Interface', 'BCI']
      },
      {
        question: "How is biomimicry influencing technology design?",
        topics: ['Biomimicry', 'Design', 'Innovation']
      },
      {
        question: "How are AI companies approaching the challenges of model alignment?",
        topics: ['Alignment', 'Safety', 'Ethics']
      },
      {
        question: "What advances have been made in multimodal AI models?",
        topics: ['Multimodal', 'Vision', 'Language']
      },
      {
        question: "How are companies implementing responsible AI development?",
        topics: ['Ethics', 'Governance', 'Safety']
      },
      {
        question: "What are the latest developments in AI model compression?",
        topics: ['Efficiency', 'Optimization', 'Deployment']
      }
    ]
  },
  applications: {
    title: 'AI Applications',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M12 2H2v10l9.29 9.29c.94.94 2.48.94 3.42 0l6.58-6.58c.94-.94.94-2.48 0-3.42L12 2Z"/></svg>',
    questions: [
      {
        question: "How are enterprises implementing AI for process automation?",
        topics: ['Automation', 'Enterprise', 'Workflow']
      },
      {
        question: "What impact is AI having on content creation and marketing?",
        topics: ['Content', 'Marketing', 'Generation']
      },
      {
        question: "How is AI transforming healthcare diagnostics?",
        topics: ['Healthcare', 'Diagnostics', 'Medicine']
      },
      {
        question: "What are the emerging AI applications in financial services?",
        topics: ['Finance', 'FinTech', 'Banking']
      }
    ]
  },
  market: {
    title: 'AI Market & Industry',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M3 3v18h18"/><path d="m19 9-5 5-4-4-3 3"/></svg>',
    questions: [
      {
        question: "How is the AI chip market evolving with new competitors?",
        topics: ['Hardware', 'Chips', 'Computing']
      },
      {
        question: "What are the key trends in AI startup funding?",
        topics: ['Startups', 'Funding', 'Market']
      },
      {
        question: "How are traditional enterprises adopting AI solutions?",
        topics: ['Enterprise', 'Adoption', 'Integration']
      },
      {
        question: "What's the current state of AI regulation globally?",
        topics: ['Regulation', 'Policy', 'Compliance']
      }
    ]
  },
  infrastructure: {
    title: 'AI Infrastructure',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M12 2v6m0 12v2M4.93 4.93l4.24 4.24m5.66 5.66 4.24 4.24M2 12h6m12 0h2M4.93 19.07l4.24-4.24m5.66-5.66 4.24-4.24"/></svg>',
    questions: [
      {
        question: "What innovations are happening in AI-specific cloud infrastructure?",
        topics: ['Cloud', 'Infrastructure', 'Scaling']
      },
      {
        question: "How are companies optimizing their AI training pipelines?",
        topics: ['Training', 'Optimization', 'Pipeline']
      },
      {
        question: "What solutions exist for AI model deployment at scale?",
        topics: ['Deployment', 'Scaling', 'MLOps']
      },
      {
        question: "How are organizations handling AI data governance?",
        topics: ['Governance', 'Data', 'Compliance']
      }
    ]
  },
  aiJobs: {
    title: 'AI & Future of Work',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M20 7h-3V4c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v3H4c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2z"/></svg>',
    questions: [
      {
        question: "How is AI impacting different job sectors and which skills will be most valuable?",
        topics: ['Jobs', 'Skills', 'Future-Work']
      },
      {
        question: "What new job roles are emerging due to AI advancement?",
        topics: ['Careers', 'AI-Jobs', 'Employment']
      },
      {
        question: "How can professionals prepare for AI transformation in their industry?",
        topics: ['Upskilling', 'Career-Development', 'AI-Readiness']
      },
      {
        question: "What industries are most resilient to AI automation?",
        topics: ['Automation', 'Job-Security', 'Industry-Trends']
      }
    ]
  },

  healthMed: {
    title: 'Health & Medicine',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M22 12h-4l-3 9L9 3l-3 9H2"/></svg>',
    questions: [
      {
        question: "How do GLP-1 drugs like Ozempic and Wegovy work for weight loss?",
        topics: ['Weight-Loss', 'GLP-1', 'Medicine']
      },
      {
        question: "What are the broader implications of GLP-1 drugs on healthcare and society?",
        topics: ['Healthcare', 'Society', 'Medical-Impact']
      },
      {
        question: "How are weight loss medications changing obesity treatment approaches?",
        topics: ['Obesity', 'Treatment', 'Healthcare']
      },
      {
        question: "What new weight loss treatments are in development?",
        topics: ['Research', 'Medicine', 'Innovation']
      }
    ]
  },

  politics: {
    title: 'Politics & Policy',
    icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/></svg>',
    questions: [
      {
        question: "How are different countries approaching AI regulation?",
        topics: ['Regulation', 'Policy', 'Global']
      },
      {
        question: "What are the key technology policy issues in upcoming elections?",
        topics: ['Elections', 'Tech-Policy', 'Politics']
      },
      {
        question: "How might election outcomes impact tech industry regulation?",
        topics: ['Politics', 'Tech-Industry', 'Regulation']
      },
      {
        question: "What role is AI playing in political campaigns and election processes?",
        topics: ['AI', 'Elections', 'Campaigns']
      }
    ]
  },
};

export default {
  name: 'FeatureGrid',
  data() {
    return {
      features: [],
      isRefreshing: false
    }
  },
  methods: {
    getRandomQuestions() {
      const categories = Object.keys(questionPool);
      const selectedFeatures = [];

      // Randomly select 4 categories
      const selectedCategories = categories
        .sort(() => 0.5 - Math.random())
        .slice(0, 4);

      // For each category, pick a random question
      selectedCategories.forEach(category => {
        const categoryData = questionPool[category];
        const randomQuestion = categoryData.questions[
          Math.floor(Math.random() * categoryData.questions.length)
        ];

        selectedFeatures.push({
          icon: categoryData.icon,
          title: categoryData.title,
          question: randomQuestion.question,
          topics: randomQuestion.topics
        });
      });

      return selectedFeatures;
    },
    handleFeatureClick(question) {
      this.$emit('chat-input', question);
    },
    handleTopicClick(topic) {
      const topicQuestion = `What are the latest developments in ${topic.replace('-', ' ')}?`;
      this.$emit('chat-input', topicQuestion);
    },
    async refreshQuestions() {
      this.isRefreshing = true;

      // Add animation delay
      await new Promise(resolve => setTimeout(resolve, 300));

      this.features = this.getRandomQuestions();

      // Reset animation
      setTimeout(() => {
        this.isRefreshing = false;
      }, 300);
    }
  },
  created() {
    this.features = this.getRandomQuestions();
  }
}
</script>

<style scoped>
.primary-mission-color {
  margin-bottom: 10px;
}

.cosmic-background {
  width: 100%;
  height: 90vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  position: relative;
}

.content {
  text-align: center;
  padding: 2rem;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Orbitron', sans-serif;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  max-width: 1000px;
  margin: 35px auto;
  padding: 0 1rem;
}

.feature-box {
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
  cursor: pointer;
}

.theme--dark .feature-box {
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.2) 50%, rgba(230,240,255,0.1) 100%);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.feature-box-delayed {
  animation-delay: calc(var(--index) * 0.1s);
}

.theme--dark .feature-box:hover {
  transform: translateY(-2px);
  border-color: rgba(255, 255, 255, 0.2);
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.3) 50%, rgba(230,240,255,0.1) 100%);
}

.theme--light .feature-box {
  background: linear-gradient(
    to right,
    rgba(147, 197, 253, 0.1) 0%,
    rgba(96, 165, 250, 0.15) 50%,
    rgba(147, 197, 253, 0.1) 100%
  );
  backdrop-filter: blur(10px);
  border: 1px solid rgba(96, 165, 250, 0.15);
  box-shadow: 0 2px 10px rgba(147, 197, 253, 0.1);
}

.theme--light .feature-box:hover {
  transform: translateY(-2px);
  border-color: rgba(96, 165, 250, 0.3);
  background: linear-gradient(
    to right,
    rgba(147, 197, 253, 0.15) 0%,
    rgba(96, 165, 250, 0.25) 50%,
    rgba(147, 197, 253, 0.15) 100%
  );
  box-shadow: 0 4px 12px rgba(147, 197, 253, 0.15);
}

.feature-icon {
  color: #10B981;
  margin: 0 auto 1rem;
  width: 2.5rem;
  height: 2.5rem;
}

.feature-box h4 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: inherit;
}

.question-text {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.75rem;
}

.topics {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.topic-tag {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background: rgba(96, 165, 250, 0.1);
  color: #10B981;
  transition: all 0.2s ease;
}

.theme--dark .topic-tag {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.topic-tag:hover {
  background: rgba(96, 165, 250, 0.2);
  transform: translateY(-1px);
}

.theme--dark .topic-tag:hover {
  background: rgba(255, 255, 255, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .feature-grid {
    grid-template-columns: 1fr;
  }

  .feature-box {
    padding: 1.25rem;
  }
}

.header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 20px;
  padding: 0 1rem;
}

.refresh-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(96, 165, 250, 0.2);
  background: rgba(96, 165, 250, 0.1);
  color: #10B981;
  cursor: pointer;
  transition: all 0.3s ease;
}

.refresh-button:hover {
  background: rgba(96, 165, 250, 0.2);
  transform: translateY(-1px);
}

.refresh-icon {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.refreshing .refresh-icon {
  transform: rotate(180deg);
}


.theme--dark .button-text {
  color: white;
}

/* Animation for feature boxes refresh */
.feature-box {
  transition: all 0.3s ease;
}

.refreshing .feature-box {
  opacity: 0;
  transform: translateY(10px);
}
</style>
