<template>
  <div class="citation-renderer" v-html="formattedHtml" @click="handleCitationClick"></div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CitationRenderer',
  props: {
    html: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      formattedHtml: ''
    };
  },

  watch: {
    html: {
      immediate: true,
      handler(newHtml) {
        if (!newHtml) return;
        this.formattedHtml = this.processLargeCitationGroups(newHtml);
      }
    }
  },

  methods: {
    safeDecodeSourceText(text) {
    if (!text) return '';

    try {
        // First try direct decodeURIComponent
        return decodeURIComponent(text);
    } catch (uriError) {
        try {
            // If that fails, try to clean up the text first
            const cleaned = text
                .replace(/%(?![0-9A-Fa-f]{2})/g, '%25') // Fix incomplete percent encoding
                .replace(/[^ -~]/g, encodeURIComponent) // Re-encode non-ASCII using printable ASCII range
                .replace(/\+/g, ' '); // Handle plus signs

            return decodeURIComponent(cleaned);
        } catch (fallbackError) {
            // If all decoding fails, return the original text
            console.warn('Failed to decode source text:', text, fallbackError);
            return text;
        }
    }
},
    processLargeCitationGroups(html) {
      if (!html) return '';

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // First pass: Process each citation link to show metadata in tooltip
      const citations = tempDiv.querySelectorAll('.citation-link');
      citations.forEach(citation => {
          try {
              // Decode and sanitize metadata before parsing
              const rawMetadata = citation.dataset.metadata || '{}';
              const cleanMetadata = this.sanitizeJsonString(rawMetadata);
              const metadata = JSON.parse(cleanMetadata);

              // Create formatted metadata header for display only
              const metadataHeader = this.formatMetadataHeader(metadata);
              citation.dataset.tooltipHeader = metadataHeader;

              // Store back sanitized metadata
              citation.dataset.metadata = JSON.stringify(metadata);

              // Safely decode source text if present
              if (citation.dataset.sourceText) {
                  citation.dataset.sourceText = this.safeDecodeSourceText(citation.dataset.sourceText);
              }
          } catch (error) {
              console.warn('Error processing citation metadata:', error, 'Raw metadata:', citation.dataset.metadata);
              // Set fallback metadata to prevent future errors
              citation.dataset.metadata = '{}';
          }
      });

      // Second pass: Find all citation groups (consecutive citation-link spans)
      const spans = Array.from(tempDiv.querySelectorAll('.citation-link'));

      let currentIndex = 0;
      while (currentIndex < spans.length) {
        try {
          let groupEnd = currentIndex;

          while (groupEnd + 1 < spans.length &&
                this.areSpansAdjacent(spans[groupEnd], spans[groupEnd + 1])) {
            groupEnd++;
          }

          const groupSize = groupEnd - currentIndex + 1;
          if (groupSize > 4) {
            this.createCollapsibleGroup(
              spans.slice(currentIndex, currentIndex + 4),
              spans.slice(currentIndex + 4, groupEnd + 1),
              tempDiv
            );
          }

          currentIndex = groupEnd + 1;
        } catch (error) {
          console.warn('Error processing citation groups:', error);
          currentIndex++;
        }
      }
      return tempDiv.innerHTML;
    },

    sanitizeJsonString(jsonString) {
      try {
          // Remove any HTML tags.
          const withoutTags = jsonString.replace(/<[^>]*>/g, '');

          // Decode HTML entities
          const decoded = this.decodeHtmlEntities(withoutTags);

          // Fix any double-encoded quotes
          const fixedQuotes = decoded.replace(/&quot;/g, '"')
                                  .replace(/\\"/g, '"')
                                  .replace(/""/, '"');

          // Ensure it's valid JSON structure
          if (!fixedQuotes.startsWith('{')) {
              return '{}';
          }

          return fixedQuotes;
      } catch (error) {
          console.warn('Error sanitizing JSON string:', error);
          return '{}';
      }
    },

    decodeHtmlEntities(str) {
      const txt = document.createElement('textarea');
      txt.innerHTML = str;
      return txt.value;
    },

    formatMetadataHeader(metadata) {
      if (!metadata || Object.keys(metadata).length === 0) return '';

      let header = '╭──────────── Source Info ────────────╮\n';

      if (metadata.type) {
        header += `Type: ${this.capitalizeFirst(metadata.type)}\n`;
      }

      if (metadata.title) {
        header += `Title: ${metadata.title}\n`;
      }

      if (metadata.fileName) {
        header += `File: ${metadata.fileName}\n`;
      }

      if (metadata.date) {
        header += `Date: ${new Date(metadata.date).toLocaleDateString()}\n`;
      }

      if (metadata.url) {
        header += `URL: ${metadata.url}\n`;
      }

      header += '╰─────────────────────────────────────╯\n\n';

      return header;
    },

    capitalizeFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    areSpansAdjacent(span1, span2) {
      // Check if there's only whitespace or commas between spans
      let node = span1.nextSibling;
      while (node && node !== span2) {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent.trim();
          if (text && text !== ',') return false;
        } else if (node.nodeType === Node.ELEMENT_NODE && !node.classList.contains('citation-link')) {
          return false;
        }
        node = node.nextSibling;
      }
      return true;
    },

    createCollapsibleGroup(visibleSpans, hiddenSpans, container) {
      if (!hiddenSpans.length) return;

      const groupId = Math.random().toString(36).substr(2, 9);

      // Create button container
      const buttonContainer = document.createElement('span');
      buttonContainer.className = 'citation-button-container';

      // Create expand/collapse buttons...
      const expandButton = document.createElement('span');
      expandButton.className = 'citation-expand';
      expandButton.dataset.group = groupId;
      expandButton.setAttribute('role', 'button');
      expandButton.setAttribute('tabindex', '0');
      expandButton.textContent = '...';

      const collapseButton = document.createElement('span');
      collapseButton.className = 'citation-collapse';
      collapseButton.dataset.group = groupId;
      collapseButton.setAttribute('role', 'button');
      collapseButton.setAttribute('tabindex', '0');
      collapseButton.style.display = 'none';
      collapseButton.textContent = '←';

      buttonContainer.appendChild(expandButton);
      buttonContainer.appendChild(collapseButton);

      // Create hidden group
      const hiddenGroup = document.createElement('span');
      hiddenGroup.className = 'citation-group-hidden';
      hiddenGroup.dataset.group = groupId;
      hiddenGroup.style.display = 'none';

      // Process spans with safe decoding
      const processSpan = (span) => {
        try {
          let sourceText = span.dataset.sourceText;
          if (sourceText) {
            span.dataset.sourceText = this.safeDecodeSourceText(sourceText).trim();
          }
          return span.cloneNode(true);
        } catch (error) {
          console.warn('Error processing span:', error);
          return span.cloneNode(true);
        }
      };

       // Add spans to hidden group
       hiddenSpans.forEach(span => {
        try {
            hiddenGroup.appendChild(processSpan(span));
        } catch (error) {
            console.warn('Error appending span:', error);
        }
      });

      // Insert elements
      const lastVisibleSpan = visibleSpans[visibleSpans.length - 1];
      lastVisibleSpan.after(buttonContainer, hiddenGroup);

      // Remove original hidden spans
      hiddenSpans.forEach(span => {
        try {
            span.remove();
        } catch (error) {
            console.warn('Error removing span:', error);
        }
      });
    },
    handleExpandCollapse(target) {
      const groupId = target.dataset.group;
      const hiddenGroup = document.querySelector(`.citation-group-hidden[data-group="${groupId}"]`);
      const buttonContainer = target.closest('.citation-button-container');
      const expandButton = buttonContainer.querySelector('.citation-expand');
      const collapseButton = buttonContainer.querySelector('.citation-collapse');

      if (target.classList.contains('citation-expand')) {
        if (hiddenGroup && collapseButton) {
          expandButton.style.display = 'none';
          hiddenGroup.style.display = 'inline';
          collapseButton.style.display = 'inline-flex';
        }
      } else if (target.classList.contains('citation-collapse')) {
        if (hiddenGroup && expandButton) {
          collapseButton.style.display = 'none';
          hiddenGroup.style.display = 'none';
          expandButton.style.display = 'inline-flex';
        }
      }
    },
    handleCitationClick(event) {
      event.stopPropagation();
      const target = event.target;

      if (target.classList.contains('citation-expand') ||
          target.classList.contains('citation-collapse')) {
          this.handleExpandCollapse(target);
          return;
      }

      const citationLink = target.closest('.citation-link');
      if (!citationLink) return;

      try {
        const citation = citationLink.dataset.citation;

        // Safely decode source text
        let sourceText = '';
        try {
            const rawSourceText = citationLink.dataset.sourceText || '';
            sourceText = this.decodeHtmlEntities(decodeURIComponent(rawSourceText));
        } catch (decodeError) {
            sourceText = citationLink.dataset.sourceText || '';
        }

        // Safely parse metadata
        let metadata = {};
        try {
            const cleanMetadata = this.sanitizeJsonString(citationLink.dataset.metadata || '{}');
            metadata = JSON.parse(cleanMetadata);
        } catch (metadataError) {
            console.warn('Error parsing metadata:', metadataError);
        }

        // Remove previous highlights
        this.removeHighlights();

        // Add active class to citation
        citationLink.classList.add('citation-link-active');

        // Handle different citation types
        if (metadata && metadata.type && metadata.id) {
            this.$store.dispatch('websocket/setActiveObject', {
                type: this.getObjectType(metadata.type),
                id: metadata.id,
                rag_strategy: 'llm_passthrough'
            });
        }

        // Highlight text if available
        if (sourceText) {
            this.highlightInRag(sourceText);
        }

        this.$emit('citation-click', {
            citation,
            element: citationLink,
            sourceText,
            metadata
        });
      } catch (error) {
          console.error('Error handling citation click:', error);
      }
    },

    getObjectType(type) {
      const typeMap = {
          'file': 'FileSubmission',
          'text': 'TextSubmission',
          'youtube': 'YouTubeTranscriptSubmission',
          'search': 'ChatMessage',
          'ecrag': 'ChatMessage'
      };
      return typeMap[type] || 'ChatMessage';
    },

    highlightInRag(text) {
      const ragContainer = document.querySelector('.rag-container');
      if (!ragContainer) return;

      const walker = document.createTreeWalker(
        ragContainer,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      let node;
      while ((node = walker.nextNode())) {
        if (node.textContent.includes(text)) {
          const range = document.createRange();
          range.setStart(node, node.textContent.indexOf(text));
          range.setEnd(node, node.textContent.indexOf(text) + text.length);

          const highlight = document.createElement('span');
          highlight.className = 'citation-highlight';
          range.surroundContents(highlight);

          highlight.scrollIntoView({ behavior: 'smooth', block: 'center' });
          break;
        }
      }
    },

    removeHighlights() {
      document.querySelectorAll('.citation-link-active')
        .forEach(el => el.classList.remove('citation-link-active'));

      document.querySelectorAll('.citation-highlight')
        .forEach(el => {
          const parent = el.parentNode;
          parent.replaceChild(document.createTextNode(el.textContent), el);
          parent.normalize();
        });
    }
  }
});
</script>

<style scoped>
:deep(.citation-link) {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4em;
  height: 1.4em;
  font-size: 0.75rem;
  font-weight: 600;
  background-color: rgba(37, 99, 235, 0.2);
  border-radius: 999px;
  padding: 0 0.4em;
  margin: 0 0.15em;
  text-decoration: none;
  position: relative;
  z-index: 1;
  user-select: none;
  backdrop-filter: blur(4px);
  transition: z-index 0s, all 0.15s ease;
}

/* Raise z-index when hovered */
:deep(.citation-link:hover) {
  z-index: 10002;
}

:deep(.citation-link::after) {
  content: attr(data-source-text);
  position: absolute;
  height: 300px;
  overflow-y: auto !important;
  width: 250px;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 10px;
  background: rgba(15, 23, 42, 0.95);
  color: #f8fafc;
  padding: 0.5em 0.8em;
  border-radius: 0.375rem;
  font-size: 0.95rem;
  white-space: pre-wrap;
  pointer-events: auto;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
              0 2px 4px -1px rgba(0, 0, 0, 0.1);
  line-height: 1.4;
  text-align: left;
}

/* Show tooltip on hover */
:deep(.citation-link:hover::after) {
  opacity: 1;
  visibility: visible;
}

/* Desktop-only adjustments for right edge */
@media screen and (min-width: 769px) {
  :deep(.citation-link::after) {
    @media (hover: hover) {
      &:hover {
        left: auto;
        right: 100%;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  :deep(.citation-link::after) {
    position: fixed;
    width: 90vw;
    max-height: 50vh;
    left: 50% !important;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    max-width: calc(100vw - 32px);
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    z-index: 10001;
  }

  :deep(.citation-link.tooltip-visible::before) {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
  }
}

/* Height adjustments */
@media screen and (max-height: 768px) {
  :deep(.citation-link::after) {
    max-height: 40vh;
  }
}

/* Very small screens */
@media screen and (max-width: 320px) {
  :deep(.citation-link::after) {
    width: calc(100vw - 24px);
    max-height: 60vh;
  }
}

/* Active state */
:deep(.citation-link-active) {
  color: #ffffff;
  background-color: rgba(59, 130, 246, 0.5);
  border-color: rgba(147, 197, 253, 0.5);
  transform: scale(1.1);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  font-weight: 600;
  z-index: 10002;
}

/* Dark theme variation */
:deep([class*='dark'] .citation-link-active) {
  background-color: rgba(59, 130, 246, 0.6);
  border-color: rgba(147, 197, 253, 0.6);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

/* Expand/Collapse buttons */
:deep(.citation-expand),
:deep(.citation-collapse) {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4em;
  height: 1.4em;
  font-size: 0.75rem;
  font-weight: 600;
  color: #cde0ff;
  background-color: rgba(230, 240, 255, 0.1);
  border-radius: 999px;
  padding: 0 0.4em;
  margin: 0 0.15em;
  transition: all 0.15s ease;
  user-select: none;
}

:deep(.citation-expand:hover),
:deep(.citation-collapse:hover) {
  background-color: rgba(230, 240, 255, 0.2);
  color: #e2edff;
}

/* Hidden citation group */
:deep(.citation-group-hidden) {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease;
}

:deep(.citation-group-hidden[style*="display: inline"]) {
  opacity: 1;
  max-height: none;
}

/* Highlight */
:deep(.citation-highlight) {
  background-color: rgba(205, 224, 255, 0.15);
  border-radius: 4px;
  padding: 2px 0;
  transition: background-color 0.3s ease;
}

/* Scrollbar styling */
:deep(.citation-link::after::-webkit-scrollbar) {
  width: 4px;
}

:deep(.citation-link::after::-webkit-scrollbar-track) {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
}

:deep(.citation-link::after::-webkit-scrollbar-thumb) {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

/* Mobile tooltip visibility */
:deep(.citation-link.tooltip-visible) {
  position: relative;
  z-index: 10002;
}

</style>
