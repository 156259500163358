<template>
    <v-card class="entity-container" elevation="4" v-if="iaoObjects.length">
      <v-card-title>
        <v-icon left color="primary">mdi-image-filter-center-focus</v-icon>
        Sources
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col v-for="obj in limitedIAOObjects" :key="obj.id" cols="12" sm="6" md="4" lg="3">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 8 : 2"
                :class="{ 'on-hover': hover }"
                @click="handleIAOClick(obj)"
              >
                <v-card-text class="d-flex align-center">
                  <v-avatar :color="getRandomColor()" size="40" class="mr-3 flex-shrink-0">
                    <v-icon dark>{{ getRandomIcon().name }}</v-icon>
                  </v-avatar>
                  <div class="source-text overflow-hidden">
                    <span class="class-name text-truncate">{{ obj.className }}</span>
                    <span class="object-id text-truncate">ID: {{ obj.id }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col v-if="remainingIAOObjectsCount > 0" cols="12" sm="6" md="4" lg="3">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 8 : 2"
                :class="{ 'on-hover': hover }"
              >
                <v-card-text class="d-flex align-center">
                  <v-avatar color="#004bfb" size="40" class="mr-3 flex-shrink-0">
                    <v-icon dark>mdi-dots-horizontal</v-icon>
                  </v-avatar>
                  <span class="source-text">{{ remainingIAOObjectsCount }} more</span>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </template>

  <script>
  import { mapActions } from 'vuex';
  export default {
    props: {
      iaoObjects: {
        type: Array,
        default: () => []
      },
      limit: {
        type: Number,
        default: 8
      }
    },
    computed: {
      limitedIAOObjects() {
        return this.iaoObjects.slice(0, this.limit);
      },
      remainingIAOObjectsCount() {
        return Math.max(0, this.iaoObjects.length - this.limit);
      }
    },
    methods: {
      ...mapActions('websocket', ['setActiveObject', 'showTempMessageFn']),
      handleIAOClick(obj) {
      this.setActiveObject({
        type: obj.className,
        id: obj.id
      });
    },
      getRandomColor() {
        const colors = ['primary', 'secondary', 'accent', 'error', 'warning', 'info', 'success'];
        return colors[Math.floor(Math.random() * colors.length)];
      },
      getRandomIcon() {
        const icons = [
          { name: 'mdi-file-document-outline' },
          { name: 'mdi-text-box-outline' },
          { name: 'mdi-web' },
          { name: 'mdi-clipboard-text-outline' },
          { name: 'mdi-message-text-outline' },
          { name: 'mdi-atom'},
          { name: 'mdi-atom-variant'}
        ];
        return icons[Math.floor(Math.random() * icons.length)];
      }
    }
  }
  </script>

  <style scoped>
  .v-card {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .v-card.on-hover {
    box-shadow: 0 0 15px rgba(0, 198, 255, 0.4) !important;
  }

  .v-card__text {
    font-weight: 500;
    padding: 8px !important;
    display: flex;
    align-items: center;
  }
  .v-card__title {
    padding: 0px !important;
  }
  .source-text {
    display: flex;
    flex-direction: column;
    min-width: 0; /* This is important for text-truncate to work */
    flex: 1;
  }
  .class-name {
    font-weight: bold;
    font-size: 1em;
    display: block;
  }
  .object-id {
    font-size: 0.8em;
    display: block;
  }
  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  </style>
