<template>
  <ValidationObserver v-slot="{ invalid }" slim>
    <v-dialog v-model="dialog" persistent max-width="460px">
      <v-card class="rounded-lg">
        <v-card-title class="d-flex align-center py-4 px-4">
          <span class="text-h6 font-weight-medium">{{ editMode ? 'Rename' : 'New' }} Chat</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom max-width="300">
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on" color="grey">mdi-help-circle-outline</v-icon>
            </template>
            <span>Give your chat a meaningful name to easily find it later</span>
          </v-tooltip>
        </v-card-title>

        <v-card-text class="pt-2 pb-4 px-4">
          <ValidationProvider name="Name" rules="required" v-slot="{ errors, valid }">
            <v-text-field
              v-model="name"
              :error-messages="errors"
              :success="valid"
              label="Chat Name"
              placeholder="e.g. Project Research, Meeting Notes..."
              outlined
              dense
              required
              autofocus
              @keyup.enter="!invalid && handleSave()"
              class="mb-4"
            ></v-text-field>
          </ValidationProvider>

          <color-picker-input v-model="color"></color-picker-input>
        </v-card-text>

        <v-card-actions class="pa-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="close"
            class="text-caption px-4"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="invalid"
            @click="handleSave"
            class="text-caption px-6"
          >
            {{ editMode ? 'Save' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ColorPickerInput from "@/components/ColorPickerInput.vue";

export default {
  name: 'GalaxyCreateDialog',
  components: {
    ColorPickerInput,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      localOrganization: {
        name: localStorage.getItem('organization'),
        slug: localStorage.getItem('organization')
      },
    };
  },
  computed: {
    ...mapFields("project", [
      "selected.id",
      "selected.name",
      "selected.color",
      "selected.loading",
      "selected.organization",
      "dialogs.showCreateEdit",
    ]),
    ...mapFields("route", ["params"]),
    ...mapState('project', {
      dialog: state => state.dialogs.showCreateEdit,
      editMode: state => !!state.selected.id,
      selected: state => state.selected,
    }),
  },
  methods: {
    ...mapActions('project', ['save', 'closeCreateEdit']),
    ...mapActions('websocket', ['showTempMessageFn']),
    async handleSave() {
      try {
        // Validate organization exists
        if (!this.localOrganization.slug) {
          this.showTempMessageFn({
            message: 'Organization not found. Please refresh the page.',
            type: 'error',
            timeout: 5000,
          });
          return;
        }

        // Create payload with guaranteed organization data
        const payload = {
          name: this.name,
          color: this.color,
          organization: this.localOrganization,
          id: this.id || null,
        };

        // Set payload in store before saving
        this.$store.commit('project/SET_SELECTED', payload);

        const result = await this.save();

        if (result.success) {
          this.$nextTick(() => {
            this.emitClose();
            this.close();
          });
        } else {
          this.handleSaveError(result);
        }
      } catch (error) {
        console.error('Error saving chat:', error);
        this.showTempMessageFn({
          message: 'An unexpected error occurred',
          type: 'error',
          timeout: 5000,
        });
      }
    },

    handleSaveError(result) {
      if (result.status === 403 && result.data && result.data.detail) {
        this.showTempMessageFn({
          message: result.data.detail[0].msg,
          type: 'error',
          timeout: 5000,
        });
      } else {
        this.showTempMessageFn({
          message: `Error ${this.editMode ? 'updating' : 'creating'} chat`,
          type: 'error',
          timeout: 5000,
        });
      }
    },
    close() {
      this.closeCreateEdit();
    },
    emitClose() {
      const currentId = this.selected ? this.selected.id : null;
      this.$emit('dialog-closed', currentId);
    },
     // Add method to refresh organization data
     refreshOrganizationData() {
      const orgSlug = localStorage.getItem('organization');
      if (orgSlug) {
        this.localOrganization = {
          name: orgSlug,
          slug: orgSlug
        };
      }
    },
  },
  created() {
    // Initial organization setup
    this.refreshOrganizationData();

    // Store the handler as a property so we can remove it later
    this.storageEventHandler = (e) => {
      if (e.key === 'organization') {
        this.refreshOrganizationData();
      }
    };

    // Add the stored handler
    window.addEventListener('storage', this.storageEventHandler);
  },

  beforeDestroy() {
    // Remove the same handler that was added
    window.removeEventListener('storage', this.storageEventHandler);
  },
  watch: {
    // Watch for dialog opening to refresh organization data
    dialog(newVal) {
      if (newVal) {
        this.refreshOrganizationData();
      }
    }
  },
};
</script>

<style scoped>
.v-card {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1) !important;
}
</style>
